import { Breadcrumbs } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import React from "react";
import { DeleteProjectWidget } from './AdminProjectsPanel.js';
import { AdminProjectPanelSchedulingSection } from "./AdminProjectPanelSchedulingSection.js";
import { ProjectPanelAbodeSection } from "./AdminProjectPanelAbodeSection.js";
import { AdminQuotesPanel } from "./AdminQuotesPanel.js";
import { AdminProjectPanelPermittingSection } from "./AdminProjectPanelPermittingSection.js";
import { AdminProjectPanelRecommendationsSection } from "./AdminProjectPanelRecommendationsSection.js";
import { AdminProjectPanelCollectionSection } from "./AdminProjectPanelCollectionSection.js";
import { HomeAggregate, SeeAirUser, ProjectAggregate, AdminProjectsTab, AdminProjectTabSchema } from "@seeair/schemas";
import { getLatestAssessment, inferProjectStatus } from "@seeair/shared";
import { FullWithDesignedSection, COLOR_WHITE, RStack, HStack, TextRLg, TextSm, HSpacer, StepperTabs } from "@seeair/shared-components";
import { AdminProjectPanelImplementationSection } from './AdminProjectPanelImplementationSection.js';

export function AdminProjectPage({
    home,
    user,
    project,
    tab,
    contractors,
}: {
    home: HomeAggregate;
    user: SeeAirUser;
    project: ProjectAggregate;
    tab?: AdminProjectsTab;
    contractors: Array<SeeAirUser>
}) {
    const assessment = getLatestAssessment(home.assessments)!
    const inferredStatus = inferProjectStatus(project,assessment)
    console.log(`inferredStatus:${inferredStatus}`)
    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <Breadcrumbs>
                <Link to={"/admin"}>Admin</Link>
                <Link
                    to={"/admin/assessment/$assessment_id"}
                    params={{ assessment_id: assessment.assessment_id }}
                    search={{ tab: "home_details" }}
                >
                    Assessment
                </Link>
                <Link
                    to={"/admin/project/$project_id"}
                    params={{ project_id: project.project_id }}
                    search={{ tab }}
                >
                    {project.project_title}
                </Link>
            </Breadcrumbs>
            <RStack xl leftCenter>
                <HStack leftCenter>
                    <TextRLg light classNames={"mx-4"}>
                        {project.project_title}
                    </TextRLg>
                </HStack>
                <HStack leftCenter>
                    <div className={"mx-4"}>
                        <TextSm m0 classNames={"mx-2"}>{user.name}</TextSm>
                        <TextSm light m0 classNames={"mx-2"}>
                            {user.email}
                        </TextSm>
                    </div>
                    <a href={`https://app.hubspot.com/contacts/46365478/record/0-3/${project.hubspot_deal_id}`} target={"_blank"} rel={"noreferrer"}>
                        Hubspot deal: {project.hubspot_deal_id??"none"}
                    </a>
                    <Link className={"mx-4"} to={"/homes/$home_id"} params={{ home_id: home.home_id }}>
                        Homeowner View
                    </Link>
                    <Link className={"mx-4"} to={"/contractor/home/$home_id"} params={{ home_id: home.home_id }}>
                        Contractor View
                    </Link>
                    <HSpacer />
                    <DeleteProjectWidget project={project} />
                </HStack>
            </RStack>
            <StepperTabs
                size={"md"}
                schema={AdminProjectTabSchema}
                status={inferredStatus}
                steps={{
                    "design": {
                        label: "Design",
                        description: "Build Recommendations",
                        content: <AdminProjectPanelRecommendationsSection home={home} project={project} />
                    },
                    "quote": {
                        label: "Quote",
                        description: "Generate Quote",
                        content: <AdminQuotesPanel project={project} home={home} />
                    },
                    "scheduling": {
                        label: "Schedule",
                        description: "Assign Crew & Schedule",
                        content: <AdminProjectPanelSchedulingSection project={project} contractors={contractors} home={home} />
                    },
                    "permitting": {
                        label: "Permitting",
                        description: "Permits & Disclosures",
                        content: <AdminProjectPanelPermittingSection home={home} project={project} />
                    },
                    "implementation": {
                        label: "Implementation",
                        description: "Confirm Line ItemS & CoC",
                        content: <AdminProjectPanelImplementationSection home={home} project={project} />
                    },
                    "collection": {
                        label: "Collection",
                        description: "Final Invoice & Comms",
                        content: <AdminProjectPanelCollectionSection project={project} home={home} />
                    },
                    "wrap": {
                        label: "Wrap",
                        description: "Assemble Abode Packet",
                        content: <ProjectPanelAbodeSection home={home} project={project} assessment={assessment} />
                    }
                }} />
        </FullWithDesignedSection>
    );
}