import React, {useEffect, useState} from "react";
import {COLOR_LIGHT_GREY} from './Theme.js';
import {FullWithDesignedSection, HStack, TextRXl} from './DesignBase.js';
import {Carousel} from "@mantine/carousel";
import {ChevronLeft, ChevronRight} from './Icons.js';
import classNames from "classnames";
import {DesignedTwoColumnCard} from './DesignComponents.js';

export function DesignedCaroselSection() {
    const initialIndex = 0
    const [activeIndex, setActiveIndex] = useState(initialIndex)
    const [emblaApi, setEmblaApi] = useState(null as any)
    useEffect(() => {
        if (emblaApi) {
            emblaApi.reInit()
        }
    }, [emblaApi])
    return <FullWithDesignedSection background={COLOR_LIGHT_GREY} classNames={"min-h-320 md:min-h-216"}>
        <HStack center>
            <TextRXl center>The Next Generation Energy Assessment</TextRXl>
        </HStack>
        <HStack ymargin>
            <Carousel
                slideGap={"xl"}
                withIndicators={true}
                withControls={true}
                loop={true}
                slideSize={"50%"}
                align={"center"}
                style={{maxWidth: "100%"}}
                previousControlIcon={<ChevronLeft/>}
                nextControlIcon={<ChevronRight/>}
                initialSlide={initialIndex}
                onSlideChange={(index: number) => setActiveIndex(index)}
                getEmblaApi={(embla: any) => {
                    setEmblaApi(embla)
                }}
            >
                <Carousel.Slide>
                    <div className={classNames("w-auto", {active: activeIndex == 0})}>
                        <DesignedTwoColumnCard
                            imgSrc={"/design-assets/app-user.jpg"}
                            title={"Your Home in the Palm of Your Hand"}
                            text={"There is a time and a place for phone calls and e-mail. The SeeAir app brings everything about your home into one place so you can view your assessment, navigate your plan and manage your projects from the first one to the last."}
                            // linkText="Learn More >"
                            // linkTo="/learn/science"
                        />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide>
                    <div className={classNames("w-auto", {active: activeIndex == 1})}>
                        <DesignedTwoColumnCard
                            imgSrc={"/design-assets/circuit.png"}
                            title={"AI-Powered Building Science"}
                            text={"The physics behind home performance are complicated and best practices are evolving at a pace that is difficult to keep up with. Our proprietary algorithms arm SeeAir advisors with tools to ensure quality and consistency from one home to the next."}
                            // linkText="Learn More >"
                            // linkTo="/learn/science"
                        />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide>
                    <div className={classNames("w-auto", {active: activeIndex == 2})}>
                        <DesignedTwoColumnCard
                            imgSrc={"/design-assets/digital-twin.png"}
                            title={"Your Home in 3D"}
                            text={"Between energy assessors, electricians, and contractors of various trades, it may feel like a lot of chefs in the kitchen! We create a digital twin of your home so that all parties are on the same page to avoid costly coordination and communication errors."}
                            // linkText="Learn More >"
                            // linkTo="/learn/science"
                        />
                    </div>
                </Carousel.Slide>
            </Carousel>
        </HStack>
    </FullWithDesignedSection>
}