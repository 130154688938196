import {Autocomplete, CloseButton, Loader} from "@mantine/core";

import {RecommendationTemplate} from "@seeair/schemas";
import {getUseMutationOpt, HStack, SheetsDataContext, TextXl, trpc, VStack} from "@seeair/shared-components";
import React, {useContext, useState} from "react";

export function NewRecommendationInputPanel({home_id, assessment_id}: { home_id: string, assessment_id: string }) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const [templateLabel, setTemplateLabel] = useState<string>('')
    const {
        isPending: isAddRecPending,
        mutate: addRec
    } = trpc.ADMIN.addRecommendation.useMutation(getUseMutationOpt(trpc.useUtils(),()=>setTemplateLabel('')))
    const recFmt = (rec: RecommendationTemplate) => `${rec.id} - ${rec.title}`
    return <VStack>
        <TextXl>Add recommendation</TextXl>
        <HStack>
            <Autocomplete
                label={"Choose template"}
                maxDropdownHeight={350}
                data={recommendationsSheet.map((rec) => ({
                    value: rec.id,
                    label: recFmt(rec),
                }))}
                value={templateLabel}
                onChange={(label) => setTemplateLabel(label)}
                onOptionSubmit={(id) => {
                    const found = recommendationsSheet.find((rec) => rec.id === id)
                    if (!found) {
                        throw new Error('Failed to find matching rec')
                    }
                    addRec({home_id, assessment_id, recNumber: found.id})

                }}
                rightSection={
                    isAddRecPending
                        ? <Loader/>
                        : templateLabel && (
                        <CloseButton
                            onClick={() => setTemplateLabel('')}
                        />
                    )
                }
            />
        </HStack>
    </VStack>
}