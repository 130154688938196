import { ContractorProject } from "@seeair/schemas";
import { HStack, TextBase, VStack } from './DesignBase.js';
import React, { useState } from "react"
import { DesignedIconButton } from './DesignComponents.js';
import { Checkbox, NumberInput } from "@mantine/core";
import { trpc } from './trpc.js';
import { getUseMutationOpt } from './mutationHelper.js';
import { MantineInputUnitLabel } from './MantineInputUnitLabel.js';
export function BlowerDoorInputPane({ project }: { project: ContractorProject }) {
    const [skipped,setSkipped]=useState(!!project.blower_door_tests?.skipped)
    const [blowerDoorIn, setBlowerDoorIn] = useState(project.blower_door_tests?.testIn?.blowerDoorMeasurement_cfm)
    const [blowerDoorOut, setBlowerDoorOut] = useState(project.blower_door_tests?.testOut?.blowerDoorMeasurement_cfm)
    const { mutate: saveBlowerDoor, isPending } = trpc.CONTRACTOR.saveBlowerDoorTest.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack classNames={"my-8"}>
        <NumberInput
            disabled={isPending||skipped}
            label={"Blower Door In"}
            value={blowerDoorIn}
            onChange={(value) => setBlowerDoorIn(typeof value == "string" ? parseFloat(value) : value)}
            rightSectionWidth={"3rem"}
            rightSection={<MantineInputUnitLabel label={"CFM"} />}
            allowNegative={false}
        />
        <NumberInput
            disabled={isPending||skipped}
            label={"Blower Door Out"}
            value={blowerDoorOut}
            onChange={(value) => setBlowerDoorOut(typeof value == "string" ? parseFloat(value) : value)}
            rightSectionWidth={"3rem"}
            rightSection={<MantineInputUnitLabel label={"CFM"} />}
            allowNegative={false}
        />
        <Checkbox 
            className={"mt-4"}
            label={"Skipped"}
            checked={skipped} 
            onChange={(e)=>setSkipped(e.currentTarget.checked)}
        />
        <HStack leftCenter>
            <DesignedIconButton
                disabled={!skipped && (blowerDoorIn == null || blowerDoorOut == null)}
                icon={<TextBase>Save Blower Door Values</TextBase>}
                tooltip={blowerDoorIn == null || blowerDoorOut == null ? "Missing Values" : "Save Blower Door Values"}
                onClick={() => {
                    saveBlowerDoor({
                        home_id: project.home_id,
                        project_id: project.project_id,
                        data: {
                            v: '1',
                            testIn: skipped?null :{
                                blowerDoorMeasurement_cfm: blowerDoorIn!
                            },
                            testOut: skipped?null:{
                                blowerDoorMeasurement_cfm: blowerDoorOut!
                            },
                            skipped
                        }
                    })
                }}
            />
            {
                project.blower_door_tests != null
                    ? <TextBase>Complete ✅</TextBase>
                    : <TextBase>Incomplete ❌</TextBase>
            }
        </HStack>
    </VStack>
}