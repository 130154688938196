import { Checkbox, Loader, NativeSelect, TextInput } from "@mantine/core";
import { DesignedIconButton } from './DesignComponents.js';
import { IconCancel, IconDeviceFloppy, IconPencil } from "@tabler/icons-react";
import React, { useState } from "react";
import { HStack } from './DesignBase.js';
import classNames from "classnames";
import { AdminComboboxOption } from "@seeair/schemas";

export function EditableSingleTextFieldWidget({
    initialValue,
    label,
    isPending,
    saveValue,
    showOverride,
    options,
}: {
    options?: Array<AdminComboboxOption>;
    showOverride?: boolean;
    initialValue: string;
    label: string;
    isPending: boolean;
    saveValue: (newValue: string, override: boolean) => void;
}) {
    const [editing, setEditing] = useState(false);
    const [override, setOverride] = useState(false);
    const [fieldValue, setFieldValue] = useState(initialValue);
    const icon = isPending ? <Loader size={"sm"} /> : editing ? <IconDeviceFloppy /> : <IconPencil />;
    let optionsWithBlank:Array<AdminComboboxOption> | undefined = undefined
    if (options) {
        if (options.find(o=>o.id=="")) {
            optionsWithBlank = options
        } else {
            optionsWithBlank = [...options,{id:"",name:""}]
        }
    }
    const input = optionsWithBlank ? (
        <NativeSelect
            label={label}
            disabled={!editing || isPending}
            value={fieldValue}
            data={optionsWithBlank.map((o) => ({ label: o.name, value: o.id }))}
            onChange={(event) => setFieldValue(event.target.value)}
        />
    ) : (
        <TextInput
            label={label}
            classNames={{label:classNames({"text-danger":initialValue.length == 0})}}
            disabled={!editing || isPending}
            value={fieldValue}
            onChange={(event) => setFieldValue(event.target.value)}
        />
    );
    return (
        <HStack leftCenter shrink classNames={classNames("px-4",{
            "border-primary-light-blue":initialValue.length > 0,
            "border-danger":initialValue.length == 0
        })}>
            {input}
            <DesignedIconButton
                disabled={(editing && initialValue == fieldValue) || isPending}
                onClick={() => {
                    if (editing) {
                        saveValue(fieldValue, override);
                        setEditing(false);
                    } else {
                        setEditing(true);
                    }
                }}
                icon={icon}
                tooltip={editing?"Save":"Edit"}
            />
            {editing && showOverride && (
                <Checkbox
                    disabled={!editing}
                    label={"Override"}
                    checked={override}
                    onChange={(event) => setOverride(event.currentTarget.checked)}
                />
            )}
            {editing && (
                <React.Fragment>
                    <DesignedIconButton
                        disabled={isPending}
                        onClick={() => {
                            setEditing(false);
                            setFieldValue(initialValue);
                        }}
                        icon={<IconCancel />}
                        tooltip={"Cancel"}
                    />
                </React.Fragment>
            )}
        </HStack>
    );
}
