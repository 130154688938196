import {
    Loader,
    SegmentedControl,
    Switch, Tabs
} from "@mantine/core";
import {
    Assessment,
    CommonUILineItems,
    HomeAggregate,
    ProjectAggregate,
    Recommendation,
    RecommendationAggregate,
    RecommendationLineItems
} from "@seeair/schemas";
import { NewRecommendationInputPanel } from './NewRecommendationInputPanel.js';
import {
    DesignedIconButton,
    Badge,
    getUseMutationOpt,
    HDivider,
    HStack,
    ProductDataContext,
    ProjectIcon,
    RStack, Text2Xl,
    TextLg, TextSm,
    trpc,
    VStack,
    CommonDiscountsTable,
    CommonLineItemTable
} from "@seeair/shared-components";
import {
    IconCancel,
    IconDeviceFloppy,
    IconPencil,
    IconRowRemove,
    IconTrash
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { recHasLineItems, recToNum } from "@seeair/shared";
import { Link } from "@tanstack/react-router";
import { RecommendationFinancialsTable } from './AdminRecommendationFinancialsTable.js';
import classNames from "classnames";

export function AdminRecommendationsPanel({ home, assessment, selected }: {
    home: HomeAggregate,
    assessment: Assessment,
    selected?: string
}) {

    const thisRecs = home.recommendations.filter(r => r.assessment_id == assessment.assessment_id)
    const floatingRecs = home.recommendations.filter(r => !r.assessment_id)
    return (
        <VStack>
            <RecTabs recs={thisRecs} projects={home.projects} selected={selected} assessment={assessment} />
            <HDivider />
            <div>
                <NewRecommendationInputPanel assessment_id={assessment.assessment_id} home_id={home.home_id} />
            </div>
            {floatingRecs.length > 0 &&
                <RecTabs recs={floatingRecs} projects={home.projects} assessment={assessment} />}

        </VStack>
    )
}

export function RecTabs({ recs, projects, assessment, selected }: {
    recs: Array<RecommendationAggregate>,
    assessment: Assessment,
    projects: Array<ProjectAggregate>,
    selected?: string
}) {
    const [value, setValue] = useState(recs[0]?.recommendation_id as string | null)
    useEffect(() => {
        if (recs.map(r => r.recommendation_id).includes(selected ?? "")) {
            setValue(selected!)
        }
    }, [recs, selected])
    return <Tabs
        value={value}
        onChange={setValue}
        orientation={"vertical"}
        classNames={{ tab: `border border-gray-500 aria-selected:bg-gray-200` }}
    >
        <Tabs.List>
            {[...recs]
                .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
                .map((r) => {
                    const proj = projects.find(p => p.project_id == r.project_id)
                    return <Tabs.Tab key={r.recommendation_id} value={r.recommendation_id}>
                        <RecControl assessment={assessment} rec={r} proj={proj} recLink={false}
                            projLink={true} col={true} showDelete={true} showRemove={false} />
                    </Tabs.Tab>
                })}
        </Tabs.List>

        {[...recs]
            .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
            .map((r) => {
                const proj = projects.find(p => p.project_id == r.project_id)
                return <Tabs.Panel key={r.recommendation_id} value={r.recommendation_id}>
                    <RecPanel rec={r} proj={proj} />
                </Tabs.Panel>
            })}
    </Tabs>
}

export function RecControl({ rec, proj, assessment, recLink, projLink, col, showRemove, showDelete }: {
    rec: Recommendation,
    proj: ProjectAggregate | undefined,
    assessment: Assessment,
    recLink: boolean,
    projLink: boolean,
    col: boolean,
    showRemove: boolean,
    showDelete: boolean
}) {
    const {
        isPending: isDeleteRecPending,
        mutate: deleteRec
    } = trpc.ADMIN.deleteRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetRecHiddenPending,
        isError: isSetRecHiddenError,
        mutate: setRecHidden
    } = trpc.ADMIN.setRecommendationsHidden.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetProjectPending,
        mutate: setProject
    } = trpc.ADMIN.setProjectForRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))

    return <RStack leftCenter col={col}>
        <HStack leftCenter>
            <ProjectIcon sm rec_number={rec.original_rec_id} />
            <TextSm>{rec.original_rec_id}</TextSm>
            {recLink
                ? <Link to={"/admin/assessment/$assessment_id"} params={{ assessment_id: assessment?.assessment_id }}
                    search={{
                        tab: "recommendations",
                        selected: rec.recommendation_id
                    }}>
                    <TextLg classNames={"ml-4"}>{rec.title}</TextLg>
                </Link>
                : <TextLg classNames={"ml-4"}>{rec.title}</TextLg>
            }
        </HStack>

        <HStack rightCenter={!col} leftCenter={col} classNames={classNames({ "mr-10": !col })}>

            <Badge>{rec.status}</Badge>
            {proj && <Badge blue classNames={"mx-2"}>
                {projLink
                    ? <Link className={"text-white"} to={"/admin/assessment/$assessment_id"}
                        params={{ assessment_id: assessment?.assessment_id }} search={{
                            tab: "projects",
                            selected: proj.project_id
                        }}>{proj.project_title}</Link>
                    : proj.project_title
                }
            </Badge>}
            <Switch
                className={"my-4"}
                size={"xl"}
                defaultChecked={!rec.hidden || false}
                onChange={(e) => {
                    setRecHidden({
                        home_id: rec.home_id,
                        recommendation_id: rec.recommendation_id,
                        hidden: !e.currentTarget.checked
                    })
                }}
                onLabel={"Visible"}
                offLabel={"Hidden"}
                label={""}
                disabled={isSetRecHiddenPending || isSetRecHiddenError}
            />
            {proj && showRemove && <DesignedIconButton
                tooltip={`Remove from project`}
                onClick={() => setProject({
                    home_id: proj.home_id,
                    recommendation_id: rec.recommendation_id,
                    project_id: null,
                    selected: false
                })}
                disabled={isSetProjectPending}
                icon={isSetProjectPending ? <Loader size={"sm"} /> : <IconRowRemove />}
            />
            }
            {showDelete && <DesignedIconButton
                tooltip={"delete rec"}
                onClick={() => {
                    deleteRec({
                        recommendation_id: rec.recommendation_id
                    })
                }}
                icon={<IconTrash size={"1rem"} />}
                disabled={isDeleteRecPending}
                stopPropagation
            />
            }

        </HStack>
    </RStack>
}




function RecommendationLineItemsTable({ rec }: { rec: RecommendationAggregate }) {
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.line_items ?? {} as CommonUILineItems)
    const {
        mutate,
        isPending
    } = trpc.ADMIN.setRecommendationLineItems.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))
    return <VStack hAuto classNames={"my-4"}>
        <HStack hAuto rightCenter>
            {editing && <DesignedIconButton
                tooltip={"Save Lineitems"}
                disabled={isPending}
                icon={<IconDeviceFloppy />}
                onClick={() => {
                    mutate({
                        recommendation_id: rec.recommendation_id,
                        line_items: data as RecommendationLineItems
                    })
                }} />}
            <DesignedIconButton
                tooltip={editing ? "Cancel" : "Edit Lineitems"}
                onClick={() => {
                    if (editing) {
                        setData(rec.line_items ?? {})
                    }
                    setEditing(!editing)
                }}
                disabled={isPending}
                icon={editing ? <IconCancel /> : <IconPencil />}
            />
        </HStack>
        <CommonLineItemTable
            hideAdminItems={false}
            showLineItemDiscounts={true}
            isPending={isPending}
            editing={editing}
            original_rec_id={rec.original_rec_id}
            lineItems={data}
            setLineItems={setData}
            hidePrice={false}
            allowCreateProduct={true}
        />
    </VStack>
}

function RecommendationDiscountsTable({ rec }: { rec: RecommendationAggregate }) {
    const { incentives } = useContext(ProductDataContext)
    const filteredIncentives = incentives
        .filter(i => i.recNums.includes(rec.original_rec_id))
        .map(i => ({ ...i, name: i.title, id: i.incentive_id }))

    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.discounts ?? {})
    const {
        mutate,
        isPending
    } = trpc.ADMIN.setRecommendationDiscounts.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))
    return <VStack hAuto>
        <HStack hAuto rightCenter>
            {editing && <DesignedIconButton
                tooltip={"Save All Incentives"}
                disabled={false}
                icon={<IconDeviceFloppy />}
                onClick={() => {
                    mutate({
                        recommendation_id: rec.recommendation_id,
                        discounts: data
                    })
                }} />}
            <DesignedIconButton
                tooltip={editing ? "Cancel" : "Edit incentives"}
                onClick={() => {
                    if (editing) {
                        setData(rec.discounts ?? {})
                    }
                    setEditing(!editing)
                }}
                disabled={isPending}
                icon={editing ? <IconCancel /> : <IconPencil />}
            />
        </HStack>
        <CommonDiscountsTable
            discounts={data}
            setDiscounts={setData}
            original_rec_id={rec.original_rec_id}
            editing={editing}
            isPending={isPending}
        />
    </VStack>

}

type UseLineItems = 'line_items' | 'estimates'

function RecPanel({ rec }: { rec: RecommendationAggregate, proj: ProjectAggregate | undefined }) {
    const [useLineItems, setUseLineItems] = useState(Object.values(rec.line_items ?? {}).length > 0 ? 'line_items' : 'estimates')

    const {
        mutate,
        isPending,
    } = trpc.ADMIN.setRecommendationLineItems.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack hAuto>
        <Text2Xl center>{rec.title}</Text2Xl>
        <HStack leftCenter hAuto>
            <TextLg>Cost Representation</TextLg>
            <SegmentedControl
                data={['line_items', 'estimates']}
                value={useLineItems as string}
                onChange={(value) => {
                    setUseLineItems(value as UseLineItems)
                }} />
            {
                useLineItems == 'estimates' && <DesignedIconButton
                    disabled={isPending || !recHasLineItems(rec)}
                    icon={<IconTrash />}
                    onClick={() => {
                        mutate({ recommendation_id: rec.recommendation_id, line_items: {} })
                    }}
                    tooltip={"Delete All LineItems"} />
            }
        </HStack>
        {
            useLineItems == 'line_items' &&
            <React.Fragment>
                <RecommendationLineItemsTable
                    rec={rec} />
            </React.Fragment>
        }
        <RecommendationDiscountsTable rec={rec} />
        <RecommendationFinancialsTable rec={rec} includeEstimates={useLineItems != 'line_items'} />
    </VStack>
}