import { Assessment, UTILITY_COMPANY_EVERSOURCE, UTILITY_COMPANY_NATIONAL_GRID } from "@seeair/schemas";
import { EditableSingleTextFieldWidget, getUseMutationOpt, trpc } from "@seeair/shared-components";
import React from "react";

export function AssessmentUtilityCompanyWidget({ assessment }: { assessment: Assessment }) {
    const { isPending: isPending, mutate: mutate } = trpc.ADMIN.setAssessmentUtilityCompany.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    return (
        <EditableSingleTextFieldWidget
            label={"utility_company"}
            isPending={isPending}
            options={[
                {name:"National Grid",id:UTILITY_COMPANY_NATIONAL_GRID},
                {name:"Eversource",id:UTILITY_COMPANY_EVERSOURCE},
            ]}
            initialValue={assessment.utility_company ?? ""}
            saveValue={(utility_company) =>
                mutate({
                    assessment_id: assessment.assessment_id,
                    utility_company,
                })
            }
        />
    );
}