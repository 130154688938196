import { AdminAssessmentTab, Assessment, Home, HomeAggregate, SeeAirUser } from "@seeair/schemas";
import { buildShortAddress } from "@seeair/shared";
import {
    AdminDocumentsTable,
    AssessmentFilesTables,
    COLOR_WHITE,
    DeleteWidget,
    DesignedIconButton,
    EditAddressPanel,
    EditMissingHomeDetailsPanel,
    EditUtilitiesPanel,
    FullWithDesignedSection,
    getUseMutationOpt,
    HSpacer,
    HStack,
    RStack,
    TextRLg,
    TextRXl,
    TextSm,
    trpc
} from "@seeair/shared-components";
import React, { useEffect, useState } from "react";
import { AdminRecommendationsPanel } from "./AdminRecommendationsPanel.js";
import { Breadcrumbs, Tabs } from "@mantine/core";
import {
    IconBulb,
    IconChecklist,
    IconFiles,
    IconHammer,
    IconHome,
    IconMap, IconMist,
    IconPlayerSkipForward, IconPlug,
    IconRobot,
    IconSpy,
    IconSun
} from "@tabler/icons-react";
import { AssessmentStatusWidget } from "./AssessmentStatusWidget.js";
import { Link } from "@tanstack/react-router";
import { AssessmentSolarPanel } from "./AssessmentSolarPanel.js";
import { AdminAssessmentProjectsTab } from "./AdminProjectsPanel.js";
import { AdminPermissionsPanel } from "./AdminPermissionsPanel.js";
import { AssessmentHubspotLinkWidget } from "./AssessmentHubspotLinkWidget.js";
import { AssessmentDataCollectionPanel } from "./AssessmentDataCollectionPanel.js";
import { AssessmentProjectNumberWidget } from "./AssessmentProjectNumberWidget.js";
import { AdminIsmsPanel } from './AdminIsmsPanel.js';
import { AssessmentUtilityCompanyWidget } from './AssessmentUtilityCompanyWidget.js';

export function AdminAssessmentPage({
    home,
    user,
    assessment,
    tab = "home_details",
    selected,
    contractors,
}: {
    home: HomeAggregate;
    user: SeeAirUser;
    assessment: Assessment;
    tab: AdminAssessmentTab;
    selected?: string;
    contractors: Array<SeeAirUser>
}) {
    const [selectedTab, setSelectedTab] = useState(tab);
    const { isPending: isAutomatedAssessmentRunning, mutate: runAutomatedAssessment } =
        trpc.ADMIN.runAutomatedAssessment.useMutation(getUseMutationOpt(trpc.useUtils()));
    useEffect(() => {
        setSelectedTab(tab);
    }, [tab]);
    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <Breadcrumbs>
                <Link to={"/admin"}>Admin</Link>
                <Link
                    to={"/admin/assessment/$assessment_id"}
                    params={{ assessment_id: assessment.assessment_id }}
                    search={{ tab, selected }}
                >
                    Assessment
                </Link>
            </Breadcrumbs>
            <RStack xl leftCenter>
                <HStack leftCenter>
                    <TextRXl>{buildShortAddress(home.address)}</TextRXl>
                    <TextRLg light classNames={"mx-4"}>
                        {assessment.assessment_label}
                    </TextRLg>
                </HStack>
                <HStack leftCenter>
                    <div className={"mx-4"}>
                        <TextSm m0 classNames={"mx-2"}>{user.name}</TextSm>
                        <TextSm light m0 classNames={"mx-2"}>
                            {user.email}
                        </TextSm>
                    </div>
                    <Link className={"mx-4"} to={"/homes/$home_id"} params={{ home_id: home.home_id }}>
                        Homeowner View
                    </Link>
                    <Link className={"mx-4"} to={"/contractor/home/$home_id"} params={{ home_id: home.home_id }}>
                        Contractor View
                    </Link>
                    <HSpacer />
                    <DeleteHomeWidget home={home} />
                </HStack>
            </RStack>
            <RStack xl>
                <AssessmentStatusWidget assessment={assessment} />
                <AssessmentHubspotLinkWidget assessment={assessment} />
                <AssessmentProjectNumberWidget assessment={assessment} />
                <AssessmentUtilityCompanyWidget assessment={assessment} />
                <DesignedIconButton
                    onClick={() =>
                        runAutomatedAssessment({
                            home_id: home.home_id,
                            assessment_id: assessment.assessment_id,
                        })
                    }
                    disabled={
                        isAutomatedAssessmentRunning ||
                        !["bim_team_analysis", "internal_review", "pending_homeowner_review"].includes(
                            assessment.assessment_status ?? "not_started",
                        )
                    }
                    tooltip={"Run Automated Assessment Tasks"}
                    icon={<IconRobot />}
                    title={"Run Automation"}
                />
            </RStack>
            <Tabs
                value={selectedTab}
                onChange={(value) => {
                    setSelectedTab((value ?? "home_details") as AdminAssessmentTab);
                    const url = new URL(window.location.href);
                    if (value) {
                        url.searchParams.set("tab", value);
                        window.history.pushState({}, "", url);
                    }
                    // await navigate({search: (prevSearch)=>prevSearch,replace:true}) //this stopped working
                }}
                classNames={{ tab: `border border-gray-500 aria-selected:bg-gray-200` }}
            >
                <Tabs.List>
                    <Tabs.Tab value={"isms"} leftSection={<IconPlug />}>
                        ISMs
                    </Tabs.Tab>
                    <Tabs.Tab value={"home_details"} leftSection={<IconHome />}>
                        Home Details
                    </Tabs.Tab>
                    <Tabs.Tab value={"assessment_files"} leftSection={<IconFiles />}>
                        Assessment Files
                    </Tabs.Tab>
                    <Tabs.Tab value={"utilities"} leftSection={<IconBulb />}>
                        Utilities
                    </Tabs.Tab>
                    <Tabs.Tab value={"recommendations"} leftSection={<IconChecklist />}>
                        Recommendations
                    </Tabs.Tab>
                    <Tabs.Tab value={"projects"} leftSection={<IconHammer />}>
                        Projects
                    </Tabs.Tab>
                    <Tabs.Tab value={"documents"} leftSection={<IconFiles />}>
                        Documents
                    </Tabs.Tab>
                    <Tabs.Tab value={"other"} leftSection={<IconMist />}>
                        Other
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={"isms"}>
                    <AdminIsmsPanel home={home} assessment={assessment} />
                </Tabs.Panel>
                <Tabs.Panel value={"home_details"}>
                    <EditMissingHomeDetailsPanel assessment={assessment} selected={selected} />
                </Tabs.Panel>
                <Tabs.Panel value={"assessment_files"}>
                    <AssessmentFilesTables assessment={assessment} home={home} selected={selected} />
                </Tabs.Panel>
                <Tabs.Panel value={"utilities"}>
                    <EditUtilitiesPanel home={home} assessment={assessment} />
                </Tabs.Panel>
                <Tabs.Panel value={"recommendations"}>
                    <AdminRecommendationsPanel home={home} assessment={assessment} selected={selected} />
                </Tabs.Panel>
                <Tabs.Panel value={"projects"}>
                    <AdminAssessmentProjectsTab home={home} assessment={assessment} selected={selected} contractors={contractors} />
                </Tabs.Panel>
                <Tabs.Panel value={"documents"}>
                    <AdminDocumentsTable home={home} assessment={assessment} />
                </Tabs.Panel>
                <Tabs.Panel value={"other"}>
                    <OtherTabsPanel home={home} assessment={assessment} />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    );
}

export function DeleteHomeWidget({ home }: { home: Home }) {
    const { mutate: deleteHome, isPending } = trpc.ADMIN.deleteHome.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    return (
        <DeleteWidget
            deleteTitle={"Delete Home"}
            disabled={isPending}
            name={buildShortAddress(home.address)}
            deleteFn={() => {
                deleteHome({ home_id: home.home_id });
            }}
        />
    );
}
function OtherTabsPanel({home,assessment}:{home:HomeAggregate,assessment:Assessment}) {
    return <Tabs
        classNames={{ tab: `border border-gray-500 aria-selected:bg-gray-200` }}
    >
        <Tabs.List>
            <Tabs.Tab value={"address"} leftSection={<IconMap />}>
                Address
            </Tabs.Tab>
            <Tabs.Tab value={"solar"} leftSection={<IconSun />}>
                Solar
            </Tabs.Tab>
            <Tabs.Tab value={"data_collection"} leftSection={<IconPlayerSkipForward />}>
                Data Collection
            </Tabs.Tab>
            <Tabs.Tab value={"permissions"} leftSection={<IconSpy />}>
                Permissions
            </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={"address"}>
            <EditAddressPanel home={home} />
        </Tabs.Panel>
        <Tabs.Panel value={"solar"}>
            <AssessmentSolarPanel home={home} assessment={assessment} />
        </Tabs.Panel>
        <Tabs.Panel value={"data_collection"}>
            <AssessmentDataCollectionPanel home={home} assessment={assessment} />
        </Tabs.Panel>
        <Tabs.Panel value={"permissions"}>
            <AdminPermissionsPanel home={home} />
        </Tabs.Panel>
    </Tabs>
}
