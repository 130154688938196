import {
    HomeDetails,
    LocationId,
    NormalizedEnergyUsageMonth,
    UtilityAgeEnum
} from "@seeair/schemas";
import dayjs, {Dayjs} from "dayjs";

export const heatPumpRecNumbers = ['60','61','62','63']
export const weatherizationRecNumbers = ['15','22']
const unfilteredHeaters: Array<HomeDetails["primary_heating"]> = ['Electric baseboard heater']
const primaryOilHeaters: Array<HomeDetails["primary_heating"]> = ['Oil furnace', 'Oil Boiler']
const primaryGasHeaters: Array<HomeDetails["primary_heating"]> = ['Central gas furnace', 'Gas boiler']
const primaryHeaterFurnaces: Array<HomeDetails["primary_heating"]> = ['Oil furnace', 'Central gas furnace','Propane (LPG) central furnace','Room (through-the-wall) gas furnace']
const heatPumpHeatingAppliances:Array<HomeDetails["primary_heating"]> = [
    "Electric heat pump",
    "Ground coupled heat pump",
    "Minisplit (ductless) heat pump"
]
const centralHeatingAppliances:Array<HomeDetails["primary_heating"]> = [
    "Electric heat pump",
    "Central gas furnace",
    "Ground coupled heat pump",
    "Electric furnace",
    "Propane (LPG) central furnace"
]
const centralCoolingAppliances:Array<HomeDetails["primary_cooling"]> = [
    "Central air conditioner"
]
const secondaryGasHeaters: Array<HomeDetails["secondary_heating"]> = ['Central gas furnace', 'Gas boiler', 'Room (through-the-wall) gas furnace']
const electricWaterHeaters: Array<HomeDetails["water_heater_type"]> = ["Tank-less Electric Water Heater", "Tank Electric Water Heater"]
const gasWaterHeaters: Array<HomeDetails["water_heater_type"]> = ["Tank-less Natural Gas Water Heater", "Tank Natural Gas Water Heater"]
const tanklessWaterHeaters: Array<HomeDetails["water_heater_type"]> = ["Tank-less Natural Gas Water Heater", "Tank-less Electric Water Heater"]
export function hasGasStove(details: HomeDetails): boolean {
    return details.stove =="Gas" || details.stove_secondary == "Gas" || details.stove_tertiary == "Gas"
}
export function hasUnfilteredHeater(details:HomeDetails): boolean {
    return unfilteredHeaters.includes(details.primary_heating)
}
export function hasGasWaterHeater(details: HomeDetails): boolean {
    return gasWaterHeaters.includes(details.water_heater_type)
}
export function hasTanklessWaterHeater(details: HomeDetails): boolean {
    return tanklessWaterHeaters.includes(details.water_heater_type)
}
export function hasElectricWaterHeater(details: HomeDetails): boolean {
    return electricWaterHeaters.includes(details.water_heater_type)
}
export function hasPrimaryGasHeat(details: HomeDetails): boolean {
    return primaryGasHeaters.includes(details.primary_heating)
}
export function hasPrimaryHeatFurnace(details: HomeDetails): boolean {
    return primaryHeaterFurnaces.includes(details.primary_heating)
}
export function hasPrimaryOilHeat(details: HomeDetails): boolean {
    return primaryOilHeaters.includes(details.primary_heating)
}
export function hasPrimaryHeatPumpHeat(details: HomeDetails):boolean {
    return heatPumpHeatingAppliances.includes(details.primary_heating)
}
export function homeDetailsImpliesDucts(details: HomeDetails):boolean {
    return centralHeatingAppliances.includes(details.primary_heating) ||
        centralCoolingAppliances.includes(details.primary_cooling)
}
export function requiresOilInput(details: HomeDetails): boolean {
    if (!details.primary_heating) {
        return true //we don't know yet, they need to fill in more details
    }
    if(hasPrimaryOilHeat(details)) {
        return true
    }
    return false
}
export function hasPrimaryCooling(details: HomeDetails): boolean {
    return details.primary_cooling != 'None'
}
export function requiresGasInput(details: HomeDetails): boolean {
    if (!details.stove || !details.primary_heating) {
        return true //we don't know yet, they need to fill in more details
    }
    if(hasGasStove(details)) {
        return true
    }
    if (hasPrimaryGasHeat(details)) {
        return true
    }
    if (secondaryGasHeaters.includes(details.secondary_heating)) {
        return true
    }
    if(hasGasWaterHeater(details)){
        return true
    }
    return false
}

export function calculateBaseloadUsage(usageArray:Array<NormalizedEnergyUsageMonth>, k:keyof Pick<NormalizedEnergyUsageMonth,'electric_usage'|'gas_usage'|'oil_usage'>):number{
    const mapped = usageArray.map(o=>({value:o[k]??0,date:dayjs.unix(o.date).format("YYYY-MM-DD")}))
    // console.log(`${k} mapped ${JSON.stringify(mapped)}`)
    const sorted = mapped.sort((a,b)=>a.value - b.value)
    // console.log(`${k} sorted ${JSON.stringify(sorted)}`)
    const sliced = sorted.slice(0,3)
    // console.log(`${k} sliced ${JSON.stringify(sliced)}`)
    const average = sliced.reduce((acc,v)=>acc+(v.value/3),0)
    // console.log(`${k} average: ${average}`)
    return average
    // return [...usageArray]
    //     .sort((a,b)=>(a[k] ?? 0) - (b[k] ?? 0))
    //     .slice(0,3)
    //     .reduce((acc,v)=>acc + (v[k] ?? 0)/3,0)
}

export function isWinterMonth(d:Dayjs,location_id:LocationId): boolean{
    const month = d.month()+1
    return month < 5 || month > 9
}
export function isWaterHeaterNew(homeDetails:HomeDetails):boolean {
    return isApplianceNew(homeDetails.water_heater_age ?? "Not Applicable")
}
export function isPrimaryHeaterNew(homeDetails:HomeDetails):boolean {
    return isApplianceNew(homeDetails.primary_heating_age ?? "Not Applicable")
}
function isApplianceNew(age:UtilityAgeEnum): boolean {
    return age == '<5 Years' || age == '5-10 Years'
}
export function thermsToKwh(therms:number):number{
    return therms * 29.3
}
export function kwhToTherms(kwh:number):number{
    return kwh / 29.3
}