import { Divider } from "@mantine/core";
import { VStack } from "@seeair/shared-components";
import { AdminUnattributedPaymentsPanel } from "./AdminUnattributedPaymentsPanel.js";
import { AdminRecentPaymentsPanel } from "./AdminRecentPaymentsPanel.js";

export function AdminPaymentsManager() {
    return <VStack>
        <Divider label={"Unattributed Payments"} />
        <AdminUnattributedPaymentsPanel />
        <Divider label={"Recent Payment Events"} />
        <AdminRecentPaymentsPanel />
    </VStack>
}