import { HomeAggregate } from "@seeair/schemas";
import { ProjectFilesTable, UserDataContext, VStack } from "@seeair/shared-components";
import { Divider, Table } from "@mantine/core";
import React, { useContext } from "react";
import { getAllDocuments } from "@seeair/shared";

export function HomeownerDocumentsPanel({home}:{home:HomeAggregate}) {
    const {user} = useContext(UserDataContext)
    return <VStack>
        <Divider size={"xl"} label={"E-Sign Documents"} className={"mt-8"}/>
        <Table striped highlightOnHover withTableBorder classNames={{ tr: "min-h-24" }}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>For</Table.Th>
                    <Table.Th>URL</Table.Th>
                    <Table.Th>Created</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {getAllDocuments(home)
                    .sort((d1,d2)=>d1.created_date.localeCompare(d2.created_date))
                    .map((d) => (
                    <Table.Tr key={d.document_id}>
                        <Table.Td>{d.name}</Table.Td>
                        <Table.Td>{d.for}</Table.Td>
                        <Table.Td>
                            {
                                d.homeowner_url && d.homeowner_email && <a href={d.homeowner_url} target={"_blank"} rel={"noreferrer"}>
                                    Link
                                    (signed:{d.homeowner_completed_date ? " ✅ " :" ❌ "}
                                    {d.status=='document.waiting_pay' && " paid: ❌ "})
                                </a>
                            }
                        </Table.Td>
                        <Table.Td>{d.created_date}</Table.Td>
                    </Table.Tr>
                ))}
            </Table.Tbody>
        </Table>
        <Divider size={"xl"} label={"Other Files"}  className={"mt-8"} />
        <ProjectFilesTable projects={home.projects} home={home} filter={["permit","permit_documentation"]} forRole={"homeowner"} />
           </VStack>
}