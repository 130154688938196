import {Recommendation} from "@seeair/schemas";
import React, {ReactElement, useContext} from "react";
import {SheetsDataContext} from './SheetsDataContext.js';
import {TextBase, TextLg, VStack} from './DesignBase.js';
import {
    IconHeartChecked,
    IconHeartUnchecked,
    IconLeafChecked,
    IconLeafUnchecked,
    IconPriceTagChecked,
    IconPriceTagUnchecked
} from './Icons.js';
import classNames from "classnames";
import {Proforma} from './Proforma.js';
import {
    DISCLAIMER_ESTIMATED_PRICE,
    DISCLAIMER_OTHER_COMPANIES,
    recDataToProformaData,
    recommendationLineItemsToProformaData,
    shouldShowOtherCompanyDisclaimer
} from "@seeair/shared";


export function RecommendationSummaryPanel({rec}:{rec:Recommendation}) {

    const {recommendationsSheet} = useContext(SheetsDataContext)
    const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
    return <VStack>
        <ImpactSummary checkedIcon={<IconHeartChecked/>} uncheckedIcon={<IconHeartUnchecked/>}
                       score={matchingRec?.healthCategory || 0}
                       summary={matchingRec?.healthSummary || ""} title={"Health Impact"}
                       description={matchingRec?.healthDescription || ""} expanded={true} category={"health"}
        />
        <ImpactSummary checkedIcon={<IconLeafChecked/>} uncheckedIcon={<IconLeafUnchecked/>}
                       score={matchingRec?.climateCategory || 0}
                       summary={matchingRec?.climateSummary || ""} title={"Climate Impact"}
                       description={matchingRec?.climateDescription || ""} expanded={true}
                       category={"climate"}/>
        <ImpactSummary checkedIcon={<IconPriceTagChecked/>} uncheckedIcon={<IconPriceTagUnchecked/>}
                       score={matchingRec?.priceCategory || 0}
                       summary={matchingRec?.priceSummary || ""} title={"Price Impact"}
                       description={matchingRec?.priceDescription || ""} expanded={false}
                       category={"price"}/>
    </VStack>
}
export function ImpactSummary({checkedIcon, uncheckedIcon, score, summary, title, description, expanded, category, rec = null}: {
    checkedIcon: ReactElement,
    uncheckedIcon: ReactElement,
    score: number,
    summary: string,
    title: string,
    description: string,
    expanded: boolean,
    category: 'health' | 'price' | 'climate',
    rec?: Recommendation | null
}) {
    const n = Math.floor(score)
    return <div className={classNames("flex items-center", {
        "flex-row": !expanded,
        "flex-col": expanded
    })}>
        {
            expanded
                ? <React.Fragment>
                    {
                        // we will show the score and description if it is there, but if we have a proforma and no description, we'll skip score and description
                        (category != 'price' || (!rec || description.length > 0)) && <React.Fragment>
                            <div className={classNames("px-8 flex flex-row items-center justify-start w-full")}>
                                {[
                                    ...list(n).map(i => <React.Fragment key={`check-${i}`}>{checkedIcon}</React.Fragment>),
                                    ...(n < 3 ? list(3 - n).map(i => <React.Fragment
                                        key={`uncheck-${i}`}>{uncheckedIcon}</React.Fragment>) : []),
                                    <TextLg key={'title'} classNames={"ml-8"}>{title}</TextLg>
                                ]}
                            </div>
                            <TextBase classNames={classNames("mx-8")}>
                                {description}
                            </TextBase>
                        </React.Fragment>
                    }
                    {rec && expanded && !rec.project_id && <RecDataProforma rec={rec}/>}
                </React.Fragment>
                : <React.Fragment>
                    <div className={classNames("px-8 flex flex-row items-center justify-start")}>
                        {checkedIcon}
                    </div>
                    <TextLg classNames={classNames("mx-8")}>
                        {summary}
                    </TextLg>
                </React.Fragment>
        }
    </div>
}

function RecDataProforma({rec}: { rec: Recommendation }) {
    const finePrint = [DISCLAIMER_ESTIMATED_PRICE]
    if(shouldShowOtherCompanyDisclaimer([rec.original_rec_id])) {
        finePrint.push(DISCLAIMER_OTHER_COMPANIES)
    }
    if(Object.values(rec.line_items ?? {}).length > 0) {
        return <Proforma title={"Itemized Cost"}
                  finePrint={finePrint}
                  data={recommendationLineItemsToProformaData([rec])}/>
    }
    return <Proforma title={"Estimated Cost"}
                     finePrint={finePrint}
                     data={recDataToProformaData(rec)}
    />
}


function list(n: number): Array<number> {
    return Array.from(new Array(n).keys())
}