import {
    Address,
    adminProductIds,
    AdminProjectsTab,
    Assessment,
    ASSESSMENT_LABEL_BASELINE,
    AssessmentDocumentEnum,
    AssessmentFile,
    AssessmentFilesEnum,
    AssessmentStatus,
    BaseFile,
    CombustionSafetyHeatingSchema,
    CombustionSafetyHeatingTestHeatingSchema,
    CombustionSafetyHotWaterSchema,
    CombustionSafetyPrepSchema,
    CombustionSafetySafetySchema,
    CombustionSafetyTabs,
    CombustionSafetyTest,
    CombustionSafetyTestHotWaterSchema,
    ContractorAssessment,
    ContractorHome,
    ContractorLineItem,
    ContractorLineItems,
    ContractorProject,
    ContractorProjectLineItems,
    ContractorRecommendation,
    CostedProformaIncentive,
    DisplayDocument,
    HomeAggregate,
    HomeDetails,
    HUBSPOT_DEAL_PIPELINE_ASSESSMENT,
    HubspotDeal,
    HubspotLineItemAggregate,
    HubspotProduct,
    ProformaCost,
    ProformaData,
    ProformaDataSource,
    ProformaIncentive,
    ProformaItem,
    ProformaPayback,
    ProjectAggregate,
    ProjectDocument,
    ProjectDocumentEnum,
    ProjectLineItems,
    Recommendation,
    RecommendationDiscount,
    RecommendationDiscounts,
    RecommendationIncentive,
    RecommendationLineItem,
    RecommendationLineItems,
    SeeAirUser,
    SparseRecommendation,
    UserRole,
} from "@seeair/schemas"
import dayjs from "dayjs"
import { formatMoney, formatYears } from "./util.js"
import {
    hasGasWaterHeater,
    hasPrimaryGasHeat,
    hasPrimaryHeatFurnace,
    hasTanklessWaterHeater,
    weatherizationRecNumbers,
} from "./buildingScience.js"
import { IHubspotProductCatalog } from "./iHubspotCrmClient.js"
import { calculateDiscountPercentageForIndividualLineItem } from "./seeAirFinancialUtil.js"
import Decimal from "decimal.js"

export function getLatestAssessment(assessments: Array<Assessment>): Assessment | undefined {
    let latest
    for (const assessment of assessments) {
        if (!latest || dayjs(assessment.created_at).isAfter(latest.created_at)) {
            latest = assessment
        }
    }
    return latest
}

const massSaveFullServiceProgramRecNums = ["10", "11", "12", "15", "22", "30"]

export function shouldShowOtherCompanyDisclaimer(recNumbers: Array<string>): boolean {
    return !recNumbers.every((n) => massSaveFullServiceProgramRecNums.includes(n))
}

export function recStatusToString(r: Recommendation | ContractorRecommendation): string {
    switch (r.status) {
        case "done":
            return "Done"
        case "in_progress":
            return "In Progress"
        case "not_started":
            return "Not Started"
    }
}

export function getRecommendationTitle(r: Recommendation | ContractorRecommendation): string {
    if (IsHomeOwnerRecommendation(r)) {
        return `${r.title}${r.type == "PRO" ? "" : " (DIY)"}`
    } else {
        return r.title
    }
}

export function buildExtraShortAddress(address: Address) {
    return address.address1 ?? ""
}

export function buildShortAddress(address: Address) {
    return [address.address1, address.city].filter(Boolean).join(" ")
}

export function formatAddressForZillow(address: Address): string {
    return `${address.address1.replace(" ", "-")},-${address.city},-${address.state},-${address.postalCode}`
}

export function isHomeOwner(user: SeeAirUser | undefined | null): boolean {
    return !!user && !isContractor(user)
}

export function isSiteAdmin(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "admin"
}

export function isContractor(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "contractor" || user?.role == "contractor-admin"
}

export function isContractorAdmin(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "contractor-admin"
}

export function parseUserRole(user: SeeAirUser | undefined): UserRole | null {
    switch (user?.role) {
        case "contractor-admin":
            return "contractor-admin"
        case "admin":
            return "admin"
        case "contractor":
            return "contractor"
        case "homeowner":
            return "homeowner"
        default:
            return null
    }
}

function getLowestRecNumberInProject(project: ProjectAggregate | ContractorProject): number {
    return project.recommendations.map((r) => parseInt(r.original_rec_id)).sort()[0] ?? 0
}

//first all projects that are in progress, sorted by lowest rec num
//second all recs without a project, sorted by status then rec num
//third all finished projects
//finally ism project

export function recToNum(
    r: Recommendation | ContractorRecommendation | undefined,
    projects: Array<ProjectAggregate> | Array<ContractorProject>,
): number {
    if (!r || !r.original_rec_id) {
        return 0
    }
    const project = projects.find((p) => p.project_id == r.project_id)
    let projectModifier: number
    let statusModifier: number
    if (project) {
        const stage = getProjectStage(project)
        projectModifier = getLowestRecNumberInProject(project) - 10000
        if (project.project_type == PROJECT_TYPE_ISM) {
            statusModifier = 500
        } else {
            statusModifier = stage == "drafting" ? 0 : stage == "done" ? 200 : -200
        }
    } else {
        projectModifier = 10000
        if (IsHomeOwnerRecommendation(r) && r.type == "DIY") {
            statusModifier = r.status == "in_progress" ? 300 : r.status == "done" ? 400 : 350
        } else {
            statusModifier = r.status == "in_progress" ? -200 : r.status == "done" ? 200 : 0
        }
    }
    return parseInt(r.original_rec_id) + statusModifier + projectModifier
}

/*

    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
 */
export function recIncentiveToProformaIncentive(
    recIncentive: RecommendationIncentive,
    recommendation: Recommendation,
): ProformaIncentive {
    let cost: ProformaCost
    if (recIncentive.percentage != null) {
        if (recommendation.rec_data.Upfront_Cost_Low == recommendation.rec_data.Upfront_Cost_High) {
            cost = {
                type: "single",
                cost: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER,
                }),
            }
        } else {
            cost = {
                type: "range",
                low: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER,
                }),
                high: percentageWithCap(recommendation.rec_data.Upfront_Cost_High, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER,
                }),
            }
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            cost = { type: "single", cost: recIncentive.amountLow! }
        } else {
            cost = { type: "range", low: recIncentive.amountLow!, high: recIncentive.amountHigh! }
        }
    }
    return {
        title: recIncentive.title,
        cost,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id],
    }
}

export function productMatchesRecNumber(
    product: HubspotProduct | null | undefined,
    recNumber: string,
): boolean {
    return (product?.properties?.recommendation_id ?? "")
        .split(",")
        .map((o) => o.trim())
        .includes(recNumber)
}

export function productMatchesPartNumber(
    product: HubspotProduct | null | undefined,
    partNumber: string,
): boolean {
    return (product?.properties?.seeair_part_number ?? "")
        .split(",")
        .map((o) => o.trim())
        .includes(partNumber)
}
export const PARTNUMBER_INTERIOR_WALL_CAVITY = "15.4"
export const PARTNUMBER_EXTERIOR_VINYL_SIDED_WALL_CAVITY = "15.3"
export const PARTNUMBER_EXTERIOR_WOOD_SIDED_WALL_CAVITY = "15.2"
export function projectIncludesPartNumber(
    project: ProjectAggregate,
    partNumber: string,
    allProducts: Array<HubspotProduct>,
): boolean {
    let lineItems: Array<RecommendationLineItem>
    if (project.completed_line_items) {
        lineItems = Object.values(project.completed_line_items).flatMap((r) => Object.values(r.line_items))
    } else if (project.quoted_line_items) {
        lineItems = Object.values(project.quoted_line_items).flatMap((r) => Object.values(r.line_items))
    } else {
        lineItems = project.recommendations.flatMap((r) => Object.values(r.line_items ?? {}))
    }

    return lineItems.some((li) => {
        const product = allProducts.find((f) => f.id == li.product_id)
        return product && product.properties.seeair_part_number == partNumber
    })
}

export function recIncentiveWithLineItemsToProformaIncentive(
    recIncentive: RecommendationIncentive,
    recommendation: Recommendation,
    lineItems: Array<HubspotLineItemAggregate>,
): ProformaIncentive {
    const cost: number = lineItems
        .filter((o) => productMatchesRecNumber(o.product, recommendation.original_rec_id))
        .reduce(
            (acc, v) =>
                acc +
                (recIncentive.discountOnRemainder
                    ? parseFloat(v.properties.amount ?? "0") //if discount is on remainder apply against amount which accounts for quantity and discount
                    : parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0")), // if discount is not on remainder, find the undiscounted total by multiplying price and quantity
            0,
        )

    let incentiveAmount: ProformaCost
    if (recIncentive.percentage != null) {
        incentiveAmount = {
            type: "single",
            cost: percentageWithCap(cost, {
                percentage: recIncentive.percentage,
                cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER,
            }),
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            incentiveAmount = { type: "single", cost: recIncentive.amountLow! }
        } else {
            incentiveAmount = { type: "range", low: recIncentive.amountLow!, high: recIncentive.amountHigh! }
        }
    }
    return {
        title: recIncentive.title,
        cost: incentiveAmount,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id],
    }
}

// export function recDiscountToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation): ProformaIncentive {
//     const cost: number = lineItems.filter(o => productMatchesRecNumber(o.product, recommendation.original_rec_id)).reduce((acc, v) =>
//             acc + (recIncentive.discountOnRemainder
//                 ? parseFloat(v.properties.amount ?? "0") //if discount is on remainder apply against amount which accounts for quantity and discount
//                 : (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0"))), // if discount is not on remainder, find the undiscounted total by multiplying price and quantity
//         0)
//
//     let incentiveAmount: ProformaCost
//     if (recIncentive.percentage != null) {
//         incentiveAmount = {
//             type: 'single',
//             cost: percentageWithCap(cost, {
//                 percentage: recIncentive.percentage,
//                 cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
//             })
//         }
//     } else {
//         if (recIncentive.amountLow == recIncentive.amountHigh) {
//             incentiveAmount = {type: 'single', cost: recIncentive.amountLow!}
//         } else {
//             incentiveAmount = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
//         }
//     }
//     return {
//         title: recIncentive.title,
//         cost: incentiveAmount,
//         timeOfPurchase: recIncentive.timeOfPurchase,
//         url: recIncentive.url,
//         recNumbers: [recommendation.original_rec_id]
//     }
// }
export function recHasLineItems(recommendation: Recommendation): boolean {
    return Object.values(recommendation.line_items ?? {}).length > 0
}

export function recDataToProformaData(recommendation: Recommendation): ProformaData {
    const rec_data = recommendation.rec_data
    const differenceInNetAndUpfrontCost =
        rec_data.Net_Cost_Low != null &&
        rec_data.Upfront_Cost_Low != null &&
        rec_data.Net_Cost_High != null &&
        rec_data.Upfront_Cost_High &&
        (rec_data.Net_Cost_Low < rec_data.Upfront_Cost_Low ||
            rec_data.Net_Cost_High < rec_data.Upfront_Cost_High)
    let incentives: Array<ProformaIncentive>
    if (recommendation.discounts && Object.values(recommendation.discounts).length > 0) {
        incentives = Object.values(recommendation.discounts).map((o) =>
            recIncentiveToProformaIncentive(o, recommendation),
        )
    } else {
        if (differenceInNetAndUpfrontCost) {
            incentives = [
                {
                    title: "Aggregated", //TODO perhaps render this specially
                    cost: toProformaCost([
                        rec_data.Upfront_Cost_Low - rec_data.Net_Cost_Low!,
                        rec_data.Upfront_Cost_High - rec_data.Net_Cost_High!,
                    ]),
                    timeOfPurchase: false,
                    recNumbers: [recommendation.original_rec_id],
                },
            ]
        } else {
            incentives = []
        }
    }
    return {
        lineItems: [],
        gross: toProformaCost([rec_data.Upfront_Cost_Low, rec_data.Upfront_Cost_High]),
        incentives,
        annual_savings: toProformaCost([rec_data.Annual_Savings_Low, rec_data.Annual_Savings_High]),
        source: "estimate",
    }
}

function lineItemToProformaItem(item: HubspotLineItemAggregate, recs: Array<Recommendation>): ProformaItem {
    const potentialRecIds = (item.product?.properties?.recommendation_id?.split(",") ?? []).map((o) =>
        o.trim(),
    )
    const recNumbers = recs
        .filter((r) => potentialRecIds.includes(r.original_rec_id))
        .map((r) => r.original_rec_id)
    return {
        title: item.properties.name ?? "",
        price_per_unit: parseFloat(item.properties.price ?? "0"),
        quantity: parseFloat(item.properties.quantity ?? "0"),
        recNumbers,
    }
}

// export function hubspotLineItemsToProformaData(
//     lineItems: Array<HubspotLineItemAggregate>,
//     recommendations: Array<Recommendation>,
// ): ProformaData {
//     const sumAnnualSavingsHigh = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_High, 0);
//     const sumAnnualSavingsLow = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_Low, 0);
//     const undiscountedLineItemSum = lineItems.reduce(
//         (acc, v) => acc + parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0"),
//         0,
//     );
//     const incentives = recommendations.flatMap(
//         (r) =>
//             Object.values(r.discounts ?? {})
//                 .map((i) => recIncentiveWithLineItemsToProformaIncentive(i, r, lineItems))
//                 ?.filter((i) => proformaCostGreaterThenZero(i.cost)) ?? [],
//     );
//     return {
//         lineItems: lineItems.map((o) => lineItemToProformaItem(o, recommendations)),
//         incentives,
//         annual_savings: toProformaCost([sumAnnualSavingsLow, sumAnnualSavingsHigh]),
//         gross: { type: "single", cost: undiscountedLineItemSum },
//     };
// }

//todo maybe use decimal.js here - its just estimates, so maybe it doesn't matter
export function recommendationLineItemsToProformaData(
    recommendations: Array<Recommendation>,
    project?: ProjectAggregate,
): ProformaData {
    const sumAnnualSavingsHigh = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_High, 0)
    const sumAnnualSavingsLow = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_Low, 0)

    let undiscountedLineItemSum = 0

    const lineItems: Array<ProformaItem> = []
    const incentives: Array<ProformaIncentive> = []
    const remainderDiscounts: { [id: string]: RecommendationDiscount } = {}
    const remainderByRec: { [id: string]: number } = {}

    let rs: Array<SparseRecommendation>
    let source: ProformaDataSource
    if (project?.completed_line_items) {
        rs = mapProjectLineItemsToSparseRecommendationsArray(project.completed_line_items)
        source = "completed"
    } else if (project?.quoted_line_items) {
        rs = mapProjectLineItemsToSparseRecommendationsArray(project.quoted_line_items)
        source = "quote"
    } else {
        rs = recommendations
        source = "estimate"
    }
    for (const r of rs) {
        const lineItemsForRec: Array<ProformaItem> = Object.values(r.line_items ?? {})
            .filter(li=>!adminProductIds.includes(li.product_id))
            .map((li) => {
            const discount = calculateDiscountPercentageForIndividualLineItem(li,{})
            const mappedItem = {
                price_per_unit: new Decimal(1).minus(discount).times(li.price_per_unit).toNumber(),
                quantity: li.quantity,
                recNumbers: [r.original_rec_id],
                title: li.name,
            }
            return mappedItem
        })
        const recLineItemSum = lineItemsForRec.reduce((acc, v) => acc + v.quantity * v.price_per_unit, 0)
        undiscountedLineItemSum += recLineItemSum
        const discountsArray = Object.values(r.discounts ?? {}).sort((d1, d2) => d1.order - d2.order)
        const incentivesForRec: Array<CostedProformaIncentive> = []
        for (const d of discountsArray) {
            if (d.discountOnRemainder) {
                //put this in a map to dedupe (so that caps are correctly calculated)
                remainderDiscounts[d.incentive_id] = d
            } else {
                const cost: ProformaCost = { type: "single", cost: percentageWithCap(recLineItemSum, d) }
                const mappedDiscount: CostedProformaIncentive = {
                    title: d.title,
                    url: d.url,
                    cost,
                    timeOfPurchase: d.timeOfPurchase,
                    recNumbers: [r.original_rec_id],
                }
                incentivesForRec.push(mappedDiscount)
            }
        }
        incentives.push(...incentivesForRec)
        lineItems.push(...lineItemsForRec)
        //apply discounts to the total, not by order (we can figure that out later if it matters)
        remainderByRec[r.original_rec_id] = incentivesForRec.reduce(
            (acc, v) => acc - v.cost.cost,
            recLineItemSum,
        )
    }
    for (const d of Object.values(remainderDiscounts)) {
        let recsSum = 0
        const recNumbers: Array<string> = []
        for (const rn of d.recNums) {
            if (remainderByRec[rn]) {
                recsSum += remainderByRec[rn]
                recNumbers.push(rn)
            }
        }
        const cost: ProformaCost = { type: "single", cost: percentageWithCap(recsSum, d) }
        const mappedDiscount: ProformaIncentive = {
            title: d.title,
            url: d.url,
            cost,
            timeOfPurchase: d.timeOfPurchase,
            recNumbers,
        }
        incentives.push(mappedDiscount)
    }
    //apply remainder style discounts all at the end
    return {
        lineItems,
        incentives,
        annual_savings: toProformaCost([sumAnnualSavingsLow, sumAnnualSavingsHigh]),
        gross: { type: "single", cost: undiscountedLineItemSum },
        source,
    }
}

export function isProformaPaybackFiniteAndNotNegative(payback: ProformaPayback): boolean {
    if (payback.type == "single") {
        return isFinite(payback.years) && payback.years >= 0
    }
    return isFinite(payback.high) && payback.high >= 0 && isFinite(payback.low) && payback.low >= 0
}

export function proformaCostGreaterThenZero(cost: ProformaCost): boolean {
    if (cost.type == "single") {
        return cost.cost > 0
    } else {
        return cost.high > 0
    }
}

export function getRecNumbersForProject(project: ProjectAggregate) {
    return project.recommendations.map((r) => r.original_rec_id).join(",")
}

export function toProformaCost([low, high]: [number, number]): ProformaCost {
    if (low == high) {
        return { type: "single", cost: low }
    } else if (low < high) {
        return { type: "range", low, high }
    } else {
        return { type: "range", low: high, high: low }
    }
}

export function getLatestFinishedAssessment(home: HomeAggregate): Assessment | "not_found" {
    const finishedAssessments = home.assessments.filter(
        (a) => a.assessment_status == "done" || a.assessment_status == "pending_homeowner_review",
    )
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(
            (a) => a.assessment_label != ASSESSMENT_LABEL_BASELINE,
        )
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return "not_found"
}

export function getLatestFinishedContractorAssessment(
    home: ContractorHome,
): ContractorAssessment | "not_found" {
    const finishedAssessments = home.assessments.filter(
        (a) => a.assessment_status == "done" || a.assessment_status == "pending_homeowner_review",
    )
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(
            (a) => a.assessment_label != ASSESSMENT_LABEL_BASELINE,
        )
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return "not_found"
}

export function getFileVersionsSortedByLatest<F extends BaseFile>(files: Array<F>): Array<F> {
    return [...files].sort((a, b) => dayjs(a.created_date).unix() - dayjs(b.created_date).unix()).reverse()
}

export function getLatestFile(
    assessment: ContractorAssessment,
    file: AssessmentFilesEnum,
): AssessmentFile | "not_found" {
    const files = Object.values(assessment.assessment_files_list ?? {}).filter((f) => f.type == file)
    return getFileVersionsSortedByLatest(files)[0] ?? "not_found"
}

export function formatMoneyRange(cost: ProformaCost): string {
    if (cost.type == "single") {
        return formatMoney(cost.cost)
    }
    return `${formatMoney(cost.low)} to ${formatMoney(cost.high)}`
}

export function formatYearsRange(payback: ProformaPayback): string {
    if (payback.type == "single") {
        return formatYears(payback.years)
    }
    return `${formatYears(payback.low)} to ${formatYears(payback.high)}`
}

function percentageWithCap(cost: number, { percentage, cap }: { percentage: number; cap: number }): number {
    return Math.min(cap, cost * percentage)
}

function applyIncentive(
    cost: number,
    incentive: RecommendationIncentive,
    lowOrHigh: keyof Pick<RecommendationIncentive, "amountHigh" | "amountLow">,
): number {
    if (incentive.percentage != null) {
        return percentageWithCap(cost, {
            percentage: incentive.percentage,
            cap: incentive.cap ?? Number.MAX_SAFE_INTEGER,
        })
    } else {
        return incentive[lowOrHigh] ?? 0
    }
}

export function calculateNetCost(data: ProformaData, includeRebates: boolean): ProformaCost {
    const sumOfIncentivesLow = data.incentives
        .filter((o) => includeRebates || o.timeOfPurchase)
        .reduce((acc, { cost }) => acc + (cost.type == "range" ? cost.low : cost.cost), 0)
    const sumOfIncentivesHigh = data.incentives
        .filter((o) => includeRebates || o.timeOfPurchase)
        .reduce((acc, { cost }) => acc + (cost.type == "range" ? cost.high : cost.cost), 0)

    if (sumOfIncentivesHigh == sumOfIncentivesLow && data.gross.type == "single") {
        return { type: "single", cost: data.gross.cost - sumOfIncentivesLow }
    } else {
        return {
            type: "range",
            high: (data.gross.type == "range" ? data.gross.high : data.gross.cost) - sumOfIncentivesHigh,
            low: (data.gross.type == "range" ? data.gross.low : data.gross.cost) - sumOfIncentivesLow,
        }
    }
}

export function calculatePaybackPeriod(data: ProformaData): ProformaPayback {
    // console.log(`calculate payback: ${JSON.stringify(data)}`)
    const annualSavingAvg =
        data.annual_savings.type == "range"
            ? (data.annual_savings.high + data.annual_savings.low) / 2
            : data.annual_savings.cost
    const net = calculateNetCost(data, true)
    // console.log(`annualSavingsAvg: ${annualSavingAvg}`)
    // console.log(`net: ${JSON.stringify(net)}`)
    if (net.type == "single") {
        const paybackResponse: ProformaPayback = { type: "single", years: net.cost / annualSavingAvg }
        // console.log(`paybackResponse: ${JSON.stringify(paybackResponse)}`)
        return paybackResponse
    } else {
        const paybackResponse: ProformaPayback = {
            type: "range",
            low: net.low / annualSavingAvg,
            high: net.high / annualSavingAvg,
        }
        // console.log(`paybackResponse: ${JSON.stringify(paybackResponse)}`)
        return paybackResponse
    }
}

export const HS_DEAL_DONE = "closedwon"
export const HS_DEAL_IN_PROGRESS = "presentationscheduled"
export const HS_PAYMENT_AWAITING = "PENDING"
export const HS_PAYMENT_PAID = "PAID"
export const HS_SIGNED = "APPROVAL_NOT_NEEDED"

export type ProjectStage = "drafting" | "quoted" | "done"

export function getProjectStage(project: ProjectAggregate | ContractorProject): ProjectStage {
    if (project.project_type == PROJECT_TYPE_ISM) {
        return "done"
    }
    const quote = getMostRecentProjectDocumentByType(project, "quote")
    // console.log(`getting Project stage, found quote:${JSON.stringify(quote)}`)
    if (quote == "not_found" || IsHomeOwnerProject(project)) {
        // console.log("returniung drafting")
        return "drafting"
    }
    if (!!quote.homeowner_paid_date) {
        // console.log("returning done")
        return "done"
    }
    // console.log("returning quoted")
    return "quoted"
}
export function getMostRecentProjectDocumentByType(
    project: ProjectAggregate | ContractorProject,
    type: ProjectDocumentEnum,
): ProjectDocument | "not_found" {
    return (
        Object.values(project.project_documents ?? {})
            .filter((d) => d.type == type)
            .sort((d1, d2) => d1.created_date.localeCompare(d2.created_date))
            .reverse()[0] ?? "not_found"
    )
}
export function getProjectStageString(stage: ProjectStage): string {
    switch (stage) {
        case "drafting":
            return "Drafting"
        case "quoted":
            return "Quoted"
        default:
            return "Done"
    }
}

export function isAssessmentDeal(deal: HubspotDeal): boolean {
    return deal.properties.pipeline == HUBSPOT_DEAL_PIPELINE_ASSESSMENT
}

export function IsHomeAggregate(h: HomeAggregate | ContractorHome): h is HomeAggregate {
    return Object.keys(h).includes("recommendations")
}
export function LineItemHasPrice(
    h: RecommendationLineItem | ContractorLineItem,
): h is RecommendationLineItem {
    return Object.keys(h).includes("price_per_unit")
}

export function IsHomeOwnerRecommendation(r: Recommendation | ContractorRecommendation): r is Recommendation {
    return Object.keys(r).includes("type")
}

export function IsHomeOwnerProject(p: ProjectAggregate | ContractorProject): p is ProjectAggregate {
    return Object.keys(p).includes("hubspot_quote_payment_status")
}

export const PROJECT_TYPE_ISM = "ISM"
export const PROJECT_TYPE_SEEAIR = "SEEAIR"

const contractorRelevantDocuments: Array<AssessmentDocumentEnum | ProjectDocumentEnum> = [
    "certificate-of-completion",
    "task-order",
]
export function getAllDocumentsForContractor(home: ContractorHome): Array<DisplayDocument> {
    return getAllDocuments(home).filter((d) => d.type && contractorRelevantDocuments.includes(d.type))
}
export function getAllDocuments(home: ContractorHome, project?: ProjectAggregate): Array<DisplayDocument> {
    let assessmentDocuments: Array<DisplayDocument> = []
    if (!project) {
        assessmentDocuments = home.assessments.flatMap((a) =>
            Object.values(a.assessment_documents ?? {}).map((d) => ({
                ...d,
                project_id: null,
                assessment_id: a.assessment_id,
                for:
                    a.assessment_label == ASSESSMENT_LABEL_BASELINE
                        ? "Baseline Assessment"
                        : "Follow-up Assessment",
            })),
        )
    }
    const projectDocuments: Array<DisplayDocument> = home.projects
        .filter((p) => !project || project.project_id == p.project_id) //if project was passed, filter to just this project
        .flatMap((p) =>
            Object.values(p.project_documents ?? {}).map((d) => ({
                ...d,
                project_id: p.project_id,
                assessment_id: null,
                for: p.project_title,
            })),
        )
    return [...assessmentDocuments, ...projectDocuments]
}

export function isWeatherizationProject(project: ContractorProject): boolean {
    return project.recommendations.some((r) => weatherizationRecNumbers.includes(r.original_rec_id))
}

export function countProjectLineItems(project: ProjectAggregate): number {
    return project.recommendations?.flatMap((r) => Object.keys(r.line_items ?? {}))?.length ?? 0
}
const doneWithDesignAssessmentStatuses: Array<AssessmentStatus> = ["done", "pending_homeowner_review"]
export function inferProjectStatus(project: ProjectAggregate, assessment: Assessment): AdminProjectsTab {
    const isWeatherizationProject = project.recommendations.some((r) =>
        weatherizationRecNumbers.includes(r.original_rec_id),
    )
    if (
        !doneWithDesignAssessmentStatuses.includes(assessment.assessment_status ?? "not_started") ||
        project.recommendations.length == 0
    ) {
        return "design"
    }
    if (!documentExistsInProject(project, "quote")) {
        return "quote"
    }
    if (!documentExistsInProject(project, "permit-auth")) {
        return "permitting"
    }
    if (
        (project.project_crew ?? []).length == 0 ||
        Object.values(project.homeowner_availability ?? {}).length == 0 ||
        Object.values(project.scheduled_dates ?? {}).length == 0
    ) {
        return "scheduling"
    }
    if (Object.values(project.completed_line_items ?? {}).length == 0) {
        return "implementation"
    }
    if (isWeatherizationProject && !documentExistsInProject(project, "certificate-of-completion")) {
        return "implementation"
    }
    if (!documentExistsInProject(project, "invoice")) {
        return "collection"
    }
    return "wrap"
}

export function inferCstStatusFromCst(
    combustion_safety_tests: CombustionSafetyTest | null | undefined,
): CombustionSafetyTabs {
    if (!combustion_safety_tests) {
        return "prep"
    }
    const parsePrep = CombustionSafetyPrepSchema.safeParse(combustion_safety_tests.prep)
    const parseSafety = CombustionSafetySafetySchema.safeParse(combustion_safety_tests.safety)
    const parseHotWaterInfo = CombustionSafetyHotWaterSchema.safeParse(combustion_safety_tests.hotWaterInfo)
    const parseWaterIn = CombustionSafetyTestHotWaterSchema.safeParse(combustion_safety_tests.hotWaterTestIn)
    const parseWaterOut = CombustionSafetyTestHotWaterSchema.safeParse(
        combustion_safety_tests.hotWaterTestOut,
    )
    const parseHeatingInfo = CombustionSafetyHeatingSchema.safeParse(combustion_safety_tests.heatingInfo)
    const parseHeatingIn = CombustionSafetyHeatingTestHeatingSchema.safeParse(
        combustion_safety_tests.heatingIn,
    )
    const parseHeatingOut = CombustionSafetyHeatingTestHeatingSchema.safeParse(
        combustion_safety_tests.heatingOut,
    )
    if (!parsePrep.success) {
        return "prep"
    }
    if (!parseSafety.success) {
        return "safety"
    }
    if (!parseHotWaterInfo.success) {
        return "hotWaterInfo"
    }
    if (!parseWaterIn.success) {
        console.log(`parseWaterIn failed: ${JSON.stringify(parseWaterIn.error)}`)
        return "hotWaterTestIn"
    }
    if (!parseHeatingInfo.success) {
        console.log(`parseHeatingInfo failed: ${JSON.stringify(parseHeatingInfo.error)}`)
        return "heatingInfo"
    }
    if (!parseHeatingIn.success) {
        console.log(`parseHeatingIn failed: ${JSON.stringify(parseHeatingIn.error)}`)
        return "heatingIn"
    }
    if (!parseWaterOut.success) {
        console.log(`parseWaterOut failed: ${JSON.stringify(parseWaterOut.error)}`)
        return "hotWaterTestOut"
    }
    if (!parseHeatingOut.success) {
        console.log(`parseHeatingOut failed: ${JSON.stringify(parseHeatingOut.error)}`)
        return "heatingOut"
    }
    return "result"
}
export function hasPricePerUnit(o: any): o is { price_per_unit: number } {
    if (!o) {
        return false
    }
    return Object.keys(o).includes("price_per_unit")
}
export function hasDiscounts(o: any): o is Record<string, { discounts: RecommendationDiscounts }> {
    if (!o) {
        return false
    }
    return Object.values(o).some((v) => Object.keys(v as any).includes("discounts"))
}
export function documentExistsInProject(
    project: ContractorProject,
    documentType: ProjectDocumentEnum,
): boolean {
    return !!Object.values(project.project_documents ?? {}).find((d) => d.type == documentType)
}
export function summarizeQuotedLineItemChanges(project: ProjectAggregate): Array<string> {
    const recLineItems = project.recommendations.flatMap((r) => Object.values(r.line_items ?? {}))
    const recDiscountIds = project.recommendations.flatMap((r) =>
        Object.values(r.discounts ?? {}).map((d) => `${r.original_rec_id}-${d.incentive_id}`),
    )

    const quotedLineItems = Object.values(project.quoted_line_items ?? {}).flatMap((r) =>
        Object.values(r.line_items),
    )
    const quotedDiscountIds = Object.keys(project.quoted_line_items ?? {}).flatMap((r) =>
        Object.values(project.quoted_line_items![r]!.discounts).map((d) => `${r}-${d.incentive_id}`),
    )

    console.log(
        `summarizing with recDiscounts: ${JSON.stringify(recDiscountIds)}, and QuoteDiscounts:${JSON.stringify(quotedDiscountIds)}`,
    )
    return summarizeLineItemChanges(quotedLineItems, quotedDiscountIds, recLineItems, recDiscountIds)
}
function getDiscountIds(
    projectLineItems: ProjectLineItems | ContractorProjectLineItems | undefined | null,
): Array<string> {
    if (!projectLineItems || !hasDiscounts(projectLineItems)) {
        return []
    }
    const recNums = Object.keys(projectLineItems)
    let discountIds: Array<string> = []
    for (const r of recNums) {
        discountIds = [
            ...discountIds,
            ...Object.values(projectLineItems[r]!.discounts ?? {}).map((d) => `${r}-${d.incentive_id}`),
        ]
    }
    return discountIds
}
export function summarizeCompletedLineItemChanges(
    project: ContractorProject | ProjectAggregate,
): Array<string> {
    const quotedLineItems = Object.values(project.quoted_line_items ?? {}).flatMap((r) =>
        Object.values(r.line_items),
    )
    const quotedDiscountIds = getDiscountIds(project.quoted_line_items)
    const completedLineItems = Object.values(project.completed_line_items ?? {}).flatMap((r) =>
        Object.values(r.line_items),
    )
    const completedDiscountIds = getDiscountIds(project.completed_line_items)
    return summarizeLineItemChanges(
        quotedLineItems,
        quotedDiscountIds,
        completedLineItems,
        completedDiscountIds,
    )
}
export function summarizeLineItemChanges(
    existingLineItems: Array<ContractorLineItem>,
    existingDiscountIds: Array<string>,
    newLineItems: Array<ContractorLineItem>,
    newDiscountIds: Array<string>,
): Array<string> {
    const lineItemChanges: Array<string> = []
    const discountChanges: Array<string> = []

    for (const c of newLineItems) {
        const q = existingLineItems.find((li) => li.product_id == c.product_id)
        if (q) {
            if (c.quantity != q.quantity) {
                lineItemChanges.push(`Changed quantity of ${c.name} (${q.quantity} to ${c.quantity})`)
            }
            if (hasPricePerUnit(c) && hasPricePerUnit(q) && c.price_per_unit != q.price_per_unit) {
                lineItemChanges.push(
                    `Changed price of ${c.name} (${q.price_per_unit} to ${c.price_per_unit})`,
                )
            }
        } else {
            lineItemChanges.push(`Added ${c.name} (${c.quantity})`)
        }
    }
    for (const q of existingLineItems.filter(
        (li) => !newLineItems.find((c) => c.product_id == li.product_id),
    )) {
        lineItemChanges.push(`Removed ${q.name} (${q.quantity})`)
    }
    for (const c of newDiscountIds) {
        const q = existingDiscountIds.find((d) => d == c)
        if (!q) {
            discountChanges.push(`Added discount ${c}`)
        }
    }
    for (const q of existingDiscountIds.filter((d) => !newDiscountIds.includes(d))) {
        discountChanges.push(`Removed discount ${q}`)
    }

    if (
        lineItemChanges.length > 0 &&
        discountChanges.length == 0 &&
        (existingDiscountIds.length > 0 || newDiscountIds.length > 0)
    ) {
        //only add this if there were some other changes (because empty list implies no changes), also only add this if there were any discounts so the contractor portal doesn't see this
        discountChanges.push(`No discount changes`)
    }

    if (discountChanges.length > 0 && lineItemChanges.length == 0) {
        lineItemChanges.push(`No line item changes`)
    }
    return [...lineItemChanges, ...discountChanges]
}

export function derriveInitialCstData(email: string, homeDetails: HomeDetails): CombustionSafetyTest {
    return {
        v: "1",
        prep: {
            dryer: "On (Living Space)",
            bathFans: homeDetails.baths ?? 1,
            kitchenFan: "Recirculating",
            airHandler: "On",
            doorToCAZ: "Closed",
        },
        safety: {
            coDetector: "Yes",
            unburnedGasOdor: "No",
            corrodedFluePipes: "No",
            unventedGasAppliances: "No",
            natDraftEquipmentIssues: "No",
        },
        hotWaterInfo: {
            fuel: hasGasWaterHeater(homeDetails) ? "Natural Gas" : "Electric",
            ventMaterial: homeDetails.water_heating_venting == "vented" ? "PVC" : "Galvanized",
            type: hasTanklessWaterHeater(homeDetails) ? "On-Demand" : "Standalone Tank",
            ventPressure: homeDetails.water_heating_venting == "vented" ? "Forced Draft" : "Atmospheric",
            ventTermination: "Chimney",
        },
        hotWaterTestIn: {
            passSpillage: "Yes",
            passSpillageWithHeatingFiring: "Yes",
        },
        hotWaterTestOut: {
            passSpillage: "Yes",
            passSpillageWithHeatingFiring: "Yes",
        },
        heatingInfo: {
            fuel: hasPrimaryGasHeat(homeDetails) ? "Natural Gas" : "Oil",
            type: hasPrimaryHeatFurnace(homeDetails) ? "Furnace" : "All Electric",
            ventMaterial: homeDetails.primary_heating_venting == "vented" ? "PVC" : "Galvanized",
            ventPressure: homeDetails.primary_heating_venting == "vented" ? "Forced Draft" : "Atmospheric",
            ventTermination: "Chimney",
        },
        heatingIn: {
            passSpillage: "Yes",
        },
        heatingOut: {
            passSpillage: "Yes",
        },
        naturalIn: {
            dhwPassSpillage: "Yes",
            dhwSpillageWithHeatingSystem: "Yes",
            heatingPassSpillage: "Yes",
        },
        naturalOut: {
            dhwPassSpillage: "Yes",
            dhwSpillageWithHeatingSystem: "Yes",
            heatingPassSpillage: "Yes",
        },
        result: {
            result: "Incomplete",
            tester_email: email,
            date: dayjs().toISOString(),
        },
    }
}
function mapContractorLineItemsToFullLineItems(
    lineItems: ContractorLineItems,
    recLineItemsFromQuote: RecommendationLineItems,
    catalog: IHubspotProductCatalog,
): RecommendationLineItems {
    return Object.keys(lineItems).reduce((acc, v) => {
        const vli = lineItems[v]!
        const matchingLineItemFromQuote = Object.values(recLineItemsFromQuote).find(
            (li) => li.product_id == vli.product_id,
        )
        const matchingProduct = catalog.getProductById(vli.product_id)
        if (matchingProduct == "not_found") {
            throw `product: (${vli.product_id}) not found for line item: ${vli.name}`
        }
        return {
            ...acc,
            [v]: {
                ...vli,
                price_per_unit:
                    matchingLineItemFromQuote?.price_per_unit ??
                    parseFloat(matchingProduct.properties.price ?? "0"),
                discounts: matchingLineItemFromQuote?.discounts ?? {},
            },
        }
    }, {})
}
export function mapContractorProjectLineItemsToFullProjectLineItems(
    lineItems: ContractorProjectLineItems,
    project: ProjectAggregate,
    catalog: IHubspotProductCatalog,
): ProjectLineItems {
    return Object.keys(lineItems).reduce((acc, v) => {
        const matchingProjectRec =
            (project.completed_line_items ?? {})[v] ?? (project.quoted_line_items ?? {})[v]
        return {
            ...acc,
            [v]: {
                discounts: matchingProjectRec?.discounts,
                line_items: mapContractorLineItemsToFullLineItems(
                    lineItems[v]!.line_items,
                    matchingProjectRec?.line_items ?? {},
                    catalog,
                ),
            },
        }
    }, {})
}
export function mapProjectLineItemsToSparseRecommendationsArray(
    lineItems: ProjectLineItems,
): Array<SparseRecommendation> {
    return Object.keys(lineItems).reduce(
        (acc, v) => [
            ...acc,
            {
                original_rec_id: v,
                line_items: lineItems[v]!.line_items,
                discounts: lineItems[v]!.discounts,
            },
        ],
        [] as Array<SparseRecommendation>,
    )
}
