import {TextBase, TextLink, TextXl} from "./DesignBase.js";
import {Link} from "@tanstack/react-router";
import {ErrorBoxPage} from "./ErrorBoxPage.js";
import React from "react";

export function AuthError({error}:{error:string}) {
    return <ErrorBoxPage>
        <TextXl m0 medium red>Error</TextXl>
        <TextBase center>{getErrorDescription(error)}</TextBase>
        <Link
            preload={false}
            to={"/"}
        >
            <TextLink>Go home</TextLink>
        </Link>
    </ErrorBoxPage>
}
function getErrorDescription(error: string | undefined): string {
    switch (error) {
        case "AccessDenied":
            return "You may still be on the waitlist and not yet activated"
        case "OAuthAccountNotLinked":
            return "You need to log in using email"
        default:
            return "Something went wrong"
    }
}