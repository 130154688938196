import { CombustionSafetyHeatingSchema, CombustionSafetyHeatingTestHeatingSchema, CombustionSafetyHotWaterSchema, CombustionSafetyPrepSchema, CombustionSafetyResultSchema, CombustionSafetySafetySchema, CombustionSafetyTabsSchema, CombustionSafetyTestHotWaterSchema, ContractorAssessment, ContractorProject } from "@seeair/schemas";
import { HStack, TextBase, TextSmItalic, VStack } from './DesignBase.js';
import React, { useContext } from "react"
import { DesignedIconButton } from './DesignComponents.js';
import { NumberInput, SegmentedControl, Table, TextInput } from "@mantine/core";
import { trpc } from './trpc.js';
import { getUseMutationOpt } from './mutationHelper.js';
import { useForm } from "@mantine/form";
import { derriveInitialCstData, inferCstStatusFromCst } from "@seeair/shared";
import { UserDataContext } from './UserDataContext.js';
import { StepperTabs } from './StepperTabs.js';
import { MantineInputUnitLabel } from './MantineInputUnitLabel.js';


const numberInputPa = {
    rightSection:<MantineInputUnitLabel label={"pa"} />,
    allowDecimal:true,
    allowNegative:true,
    rightSectionWidth:"2rem"
}
const numberInputF = {
    rightSection:<MantineInputUnitLabel label={"pa"} />,
    allowDecimal:true,
    allowNegative:true,
    rightSectionWidth:"2rem"
}
const numberInputN = {
    rightSection:<MantineInputUnitLabel label={"#"} />,
    allowDecimal:false,
    allowNegative:false,
    rightSectionWidth:"2rem"
}
const numberInputPpm = {
    rightSection:<MantineInputUnitLabel label={"ppm"} />,
    allowDecimal:true,
    allowNegative:true,
    rightSectionWidth:"3rem"
}

export function CombustionSafetyTestInputPane({ project, assessment }: { project: ContractorProject, assessment: ContractorAssessment }) {
    const { user } = useContext(UserDataContext)
    const inferredStatus = inferCstStatusFromCst(project.combustion_safety_tests)
    console.log(`inferredStatus: ${inferredStatus}`)
    const cstForm = useForm({
        initialValues: project.combustion_safety_tests ?? derriveInitialCstData(user!.email, assessment.home_details ?? {}),
        validate: {
            prep: {
                natWinterTime_pa:(v)=>v?null:"Missing Value",
                worstCase_pa:(v)=>v?null:"Missing Value",
                changeInPressure_pa:(v)=>v?null:"Missing Value",
                exteriorTemp_F:(v)=>v?null:"Missing Value",
                minAllowableDraft_pa:(v)=>v?null:"Missing Value",
            },
        }
    })
    const { mutate: saveCst, isPending } = trpc.CONTRACTOR.saveCombustionSafetyTest.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack classNames={"my-8"}>
        <StepperTabs
            size={"xs"}
            schema={CombustionSafetyTabsSchema}
            status={inferredStatus}
            steps={{
                "prep": {
                    content: <Table id={"combustion-prep"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Dryer</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('prep.dryer')}
                                        data={CombustionSafetyPrepSchema.shape.dryer.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Bath Fans</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('prep.bathFans')}
                                        {...numberInputN}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Kitchen Fan</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('prep.kitchenFan')}
                                        data={CombustionSafetyPrepSchema.shape.kitchenFan.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Air Handler</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('prep.airHandler')}
                                        data={CombustionSafetyPrepSchema.shape.airHandler.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Door to CAZ</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('prep.doorToCAZ')}
                                        data={CombustionSafetyPrepSchema.shape.doorToCAZ.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Nat WinterTime</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('prep.natWinterTime_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Worst Case</Table.Td>
                                <Table.Td>

                                    <NumberInput
                                        {...cstForm.getInputProps('prep.worstCase_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Change In Pressure</Table.Td>
                                <Table.Td>

                                    <NumberInput
                                        {...cstForm.getInputProps('prep.changeInPressure_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Exterior Temp</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('prep.exteriorTemp_F')}
                                        {...numberInputF}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Min Allowable Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('prep.minAllowableDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "safety": {
                    content: <Table id={"combustion-safety"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>CO Detector</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('safety.coDetector')}
                                        data={CombustionSafetySafetySchema.shape.coDetector.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Unburned Gas Odor</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('safety.unburnedGasOdor')}
                                        data={CombustionSafetySafetySchema.shape.unburnedGasOdor.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Corroded Flue Pipes</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('safety.corrodedFluePipes')}
                                        data={CombustionSafetySafetySchema.shape.corrodedFluePipes.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Unvented Gas Appliances</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        transitionDuration={0}
                                        {...cstForm.getInputProps('safety.unventedGasAppliances')}
                                        data={CombustionSafetySafetySchema.shape.unventedGasAppliances.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Nat Draft Equiptment Issues</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('safety.natDraftEquipmentIssues')}
                                        data={CombustionSafetySafetySchema.shape.natDraftEquipmentIssues.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "hotWaterInfo": {
                    label:"Hot Water",
                    content: <Table id={"combustion-hotWaterInfo"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Fuel</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterInfo.fuel')}
                                        data={CombustionSafetyHotWaterSchema.shape.fuel.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Type</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterInfo.type')}
                                        data={CombustionSafetyHotWaterSchema.shape.type.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Material</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterInfo.ventMaterial')}
                                        data={CombustionSafetyHotWaterSchema.shape.ventMaterial.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Pressure</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterInfo.ventPressure')}
                                        data={CombustionSafetyHotWaterSchema.shape.ventPressure.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Termination</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterInfo.ventTermination')}
                                        data={CombustionSafetyHotWaterSchema.shape.ventTermination.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "hotWaterTestIn": {
                    label:"Water Test In",
                    content: <Table id={"combustion-hotWaterTestIn"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterTestIn.passSpillage')}
                                        data={CombustionSafetyTestHotWaterSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestIn.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Pass Spillage With Heat Firing</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterTestIn.passSpillageWithHeatingFiring')}
                                        data={CombustionSafetyTestHotWaterSchema.shape.passSpillageWithHeatingFiring.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Draft With Heating System</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestIn.draftWithHeatingSystem_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>CO Without Heating System On</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestIn.carbonMonoxideWithoutHeatingSystem_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>CO With Heating System On</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestIn.carbonMonoxideWithHeatingSystem_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "heatingInfo": {
                    label: "Heating",
                    content: <Table id={"combustion-heatingInfo"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Fuel</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingInfo.fuel')}
                                        data={CombustionSafetyHeatingSchema.shape.fuel.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Type</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingInfo.type')}
                                        data={CombustionSafetyHeatingSchema.shape.type.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Material</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingInfo.ventMaterial')}
                                        data={CombustionSafetyHeatingSchema.shape.ventMaterial.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Pressure</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingInfo.ventPressure')}
                                        data={CombustionSafetyHeatingSchema.shape.ventPressure.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Vent Termination</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingInfo.ventTermination')}
                                        data={CombustionSafetyHeatingSchema.shape.ventTermination.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "heatingIn": {
                    label:"Heating Test In",
                    content: <Table id={"combustion-heatingIn"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingIn.passSpillage')}
                                        data={CombustionSafetyHeatingTestHeatingSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingIn.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Carbon Monoxide</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingIn.carbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Ambient CZA CO</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingIn.ambientCazCarbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "hotWaterTestOut": {
                    label:"Water Test Out",
                    content: <Table id={"combustion-hotWaterTestOut"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterTestOut.passSpillage')}
                                        data={CombustionSafetyTestHotWaterSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestOut.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Pass Spillage With Heat Firing</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('hotWaterTestOut.passSpillageWithHeatingFiring')}
                                        data={CombustionSafetyTestHotWaterSchema.shape.passSpillageWithHeatingFiring.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Draft With Heating System</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestOut.draftWithHeatingSystem_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>CO Without Heating System On</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestOut.carbonMonoxideWithoutHeatingSystem_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>CO With Heating System On</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('hotWaterTestOut.carbonMonoxideWithHeatingSystem_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "heatingOut": {
                    label:"Heating Test Out",
                    content: <Table id={"combustion-heatingOut"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('heatingOut.passSpillage')}
                                        data={CombustionSafetyHeatingTestHeatingSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingOut.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Carbon Monoxide</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingOut.carbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Ambient CZA CO</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('heatingOut.ambientCazCarbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                },
                "naturalIn": {
                    label:"Nat In",
                    content: <Table id={"combustion-natIn"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('naturalIn.passSpillage')}
                                        data={CombustionSafetyHeatingTestHeatingSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalIn.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Carbon Monoxide</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalIn.carbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Ambient CZA CO</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalIn.ambientCazCarbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                        </Table>
                },
                "naturalOut": {
                    label:"Nat Out",
                    content: <Table id={"combustion-natOut"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Pass Spillage</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('naturalOut.passSpillage')}
                                        data={CombustionSafetyHeatingTestHeatingSchema.shape.passSpillage.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Standalone Draft</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalOut.standaloneDraft_pa')}
                                        {...numberInputPa}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Carbon Monoxide</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalOut.carbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Ambient CZA CO</Table.Td>
                                <Table.Td>
                                    <NumberInput
                                        {...cstForm.getInputProps('naturalOut.ambientCazCarbonMonoxide_ppm')}
                                        {...numberInputPpm}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                        </Table>
                },
                "result": {
                    content: <Table id={"combustion-result"}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Result</Table.Td>
                                <Table.Td>
                                    <SegmentedControl
                                        {...cstForm.getInputProps('result.result')}
                                        data={CombustionSafetyResultSchema.shape.result.options}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Tester Email</Table.Td>
                                <Table.Td>
                                    <TextInput
                                        {...cstForm.getInputProps('result.tester_email')}
                                    />
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Time</Table.Td>
                                <Table.Td>                                        
                                    <TextSmItalic>{cstForm.values.result.date}</TextSmItalic>
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                }
            }}

        />
        <HStack leftCenter>
            <DesignedIconButton
                disabled={isPending}
                icon={<TextBase>Save CST Data</TextBase>}
                tooltip={"Save CST Data"}
                onClick={() => {
                    saveCst({
                        home_id:project.home_id,
                        project_id: project.project_id,
                        data: cstForm.values
                    })
                    cstForm.validate()
                }}
            />
            {
                project.combustion_safety_tests?.result?.result && project.combustion_safety_tests?.result?.result != "Incomplete"
                    ? <TextBase>Complete ✅</TextBase>
                    : <TextBase>Incomplete ❌</TextBase>
            }
        </HStack>
    </VStack>
}

