import { Drawer, Loader } from "@mantine/core";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { PRIMARY_BLUE } from "./Theme.js";

export function LoadingDrawer() {
    const fetchCount = useIsFetching()
    const mutateCount = useIsMutating()
    return <Drawer
        size={"4rem"}
        position={"bottom"}
        opened={fetchCount+mutateCount > 0}
        withCloseButton={false}
        withOverlay={false}
        onClose={() => { }}
        classNames={{body:"m-0 p-0"}}
    >
        <div className={"flex flex-row h-16 max-h-16 px-16 items-center justify-end w-full overflow-hidden"}>
            <span className={"mx-4"} style={{ color: PRIMARY_BLUE }}>
                {
                    mutateCount > 0
                        ? "Working"
                        : "Loading"
                }
            </span>
            <Loader type={"bars"} color={PRIMARY_BLUE} /></div>
    </Drawer>
}