import {createFileRoute} from '@tanstack/react-router'
import {
    TermsOfService,
    Layout,
    PageTitle,
    FullWithDesignedSection, COLOR_WHITE
} from "@seeair/shared-components";
import React from "react";
import {Helmet} from "react-helmet-async";

export const Route = createFileRoute('/terms')({
    component: function TermsPageComponent() {
        return <Layout>
            <Helmet>
                <title>SeeAir</title>
                <meta name={"description"}
                      content={"SeeAir specializes in no-cost home energy and health assessments and is a proud MassSave partner. Enhance your home's energy efficiency with our expert services."}/>
                <link rel={"canonical"} href={"https://seeair.com/terms"}/>
            </Helmet>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <PageTitle text={"Terms Of Service"}/>
                <TermsOfService/>
            </FullWithDesignedSection>
        </Layout>
    }
})
