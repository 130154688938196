import {
    DeleteWidget,
    DesignedIconButton,
    getUseMutationOpt,
    HStack,
    RecommendationsAccordion,
    TextSm,
    trpc,
    VStack,
} from "@seeair/shared-components"
import { Assessment, HomeAggregate, ProjectAggregate, SeeAirUser } from "@seeair/schemas"
import { Modal, Table, TextInput } from "@mantine/core"
import { IconEye, IconPlus } from "@tabler/icons-react"
import React, { useState } from "react"
import { Link } from "@tanstack/react-router"

export function AdminAssessmentProjectsTab({
    home,
    assessment,
    selected,
    contractors,
}: {
    home: HomeAggregate
    assessment: Assessment
    selected?: string
    contractors: Array<SeeAirUser>
}) {
    const [previewProject, setPreviewProject] = useState(null as ProjectAggregate | null)
    const [syncProject, setSyncProject] = useState(null as ProjectAggregate | null)
    const [project_title, setProjectTitle] = useState("")
    const { isPending: isCreateProjectPending, mutate: createProject } = trpc.ADMIN.createProject.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    )
    return (
        <VStack>
            <AdminProjectsTable
                home={home}
                setPreviewProject={setPreviewProject}
                setSyncProject={setSyncProject}
                selected={selected}
                assessment={assessment}
                contractors={contractors}
            />
            <HStack leftCenter classNames={"mt-8"}>
                <TextSm>New Project</TextSm>
                <TextInput
                    classNames={{ input: "w-72", root: "mx-4" }}
                    label={"Project Title"}
                    value={project_title}
                    onChange={(event) => setProjectTitle(event.currentTarget.value)}
                />
                <DesignedIconButton
                    tooltip={"Create Project"}
                    disabled={isCreateProjectPending || project_title.length < 1}
                    onClick={() => createProject({ home_id: home.home_id, project_title })}
                    icon={<IconPlus />}
                />
            </HStack>
            <Modal
                title={previewProject?.project_title}
                fullScreen
                opened={!!previewProject}
                onClose={() => setPreviewProject(null)}
            >
                {previewProject && (
                    <RecommendationsAccordion
                        hideAdminItems={false}
                        projects={[previewProject!]}
                        recs={previewProject.recommendations}
                        filteredRecs={[]}
                        includeProjects={true}
                        isContractor={false}
                    />
                )}
            </Modal>
        </VStack>
    )
}

function AdminProjectsTable({
    home,
    setPreviewProject
}: {
    home: HomeAggregate
    setPreviewProject: (p: ProjectAggregate) => void
    setSyncProject: (p: ProjectAggregate) => void
    selected?: string
    assessment: Assessment
    contractors: Array<SeeAirUser>
}) {
    return (
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Project</Table.Th>
                    <Table.Th>Preview</Table.Th>
                    <Table.Th>Delete</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {home.projects.map(p => <Table.Tr key={p.project_id}>
                    <Table.Td>
                        <Link
                            to={"/admin/project/$project_id"}
                            params={{ project_id: p.project_id }}
                        >
                            {p.project_title}
                        </Link>
                    </Table.Td>
                    <Table.Td><DesignedIconButton
                        tooltip={"Preview For Homeowner"}
                        disabled={false}
                        icon={<IconEye />}
                        stopPropagation
                        onClick={() => {
                            setPreviewProject(p)
                        }}
                    /></Table.Td>
                    <Table.Td><DeleteProjectWidget project={p} /></Table.Td>
                </Table.Tr>)}
            </Table.Tbody>
        </Table>)
}

export function DeleteProjectWidget({ project }: { project: ProjectAggregate }) {
    const { mutate: deleteProject } = trpc.ADMIN.deleteProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const { mutate: renameProject } = trpc.ADMIN.renameProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return (
        <DeleteWidget
            name={`${project.project_title}`}
            deleteTitle={`Delete Project`}
            deleteFn={() => {
                deleteProject({ project_id: project.project_id })
            }}
            renameTitle={`Rename Project`}
            renameFn={(name) => {
                renameProject({ project_id: project.project_id, project_title: name })
            }}
        />
    )
}


// function ProjectPanelHubspotSection({ project }: { project: ProjectAggregate }) {
//     const [syncModalOpen, setSyncModalOpen] = useState(false)
//     return (
//         <Accordion.Item value={"hubspot"}>
//             <Accordion.Control>
//                 <HStack leftCenter>
//                     <TextLg center>Hubspot</TextLg>
//                     <Badge orange>
//                         {project.hubspot_deal_id ? (
//                             <a
//                                 href={`https://app.hubspot.com/contacts/46365478/record/0-3/${project.hubspot_deal_id}`}
//                             >
//                                 Deal Link
//                             </a>
//                         ) : (
//                             "Not yet created"
//                         )}
//                     </Badge>
//                     {project.hubspot_deal_stage && (
//                         <Badge>
//                             {"Stage "}
//                             {project.hubspot_deal_stage}
//                         </Badge>
//                     )}
//                     {project.hubspot_quote_status && (
//                         <Badge>
//                             {"Quote "}
//                             {project.hubspot_quote_status}
//                         </Badge>
//                     )}
//                     {project.hubspot_quote_signature_status && (
//                         <Badge>
//                             {"Signature "}
//                             {project.hubspot_quote_signature_status}
//                         </Badge>
//                     )}
//                     {project.hubspot_quote_payment_status && (
//                         <Badge>
//                             {"Payment "}
//                             {project.hubspot_quote_payment_status}
//                         </Badge>
//                     )}
//                 </HStack>
//             </Accordion.Control>
//             <Accordion.Panel>
//                 <HStack between>
//                     <HStack leftCenter>
//                         {project.hubspot_deal_id && (
//                             <a
//                                 className={"mx-2"}
//                                 href={`https://app.hubspot.com/contacts/46365478/record/0-3/${project.hubspot_deal_id}`}
//                                 target={"_blank"}
//                                 rel={"noreferrer"}
//                             >
//                                 Hubspot Deal Link
//                             </a>
//                         )}
//                         {project.hubspot_quote_url && (
//                             <a
//                                 className={"mx-2"}
//                                 href={project.hubspot_quote_url}
//                                 target={"_blank"}
//                                 rel={"noreferrer"}
//                             >
//                                 Hubspot Quote URL
//                             </a>
//                         )}
//                     </HStack>
//                 </HStack>

//                 <TextLg medium>Deal Line Items</TextLg>
//                 <LineItemTable line_items={project.hubspot_deal_line_items ?? []} />
//                 <TextLg>
//                     {"Deal Amount "}
//                     {project.hubspot_deal_amount}
//                 </TextLg>
//                 <TextLg medium>Quote Line Items</TextLg>
//                 <LineItemTable line_items={project.hubspot_quote_line_items ?? []} />

//                 <TextLg>
//                     {"Quote Amount "}
//                     {project.hubspot_quote_amount}
//                 </TextLg>
//                 <TextSm>{project.hubspot_last_modified}</TextSm>
//             </Accordion.Panel>
//         </Accordion.Item>
//     )
// }
