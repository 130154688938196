import {
    AbsoluteCenterStack,
    FullWithDesignedSection,
    HStack,
    Text2Xl,
    TextBase,
    TextError,
    TextSm,
    VStack
} from './DesignBase.js';
import { COLOR_WHITE } from './Theme.js';
import React, { useContext, useEffect, useState } from "react";
import {
    buildShortAddress,
    documentExistsInProject,
    extractBucketAndKeyFromHttpUrl,
    extractBucketAndKeyFromS3Url, formatMoney, getAllDocumentsForContractor,
    getLatestFile,
    getLatestFinishedContractorAssessment,
    summarizeCompletedLineItemChanges,
    weatherizationRecNumbers
} from "@seeair/shared";
import { Breadcrumbs, Divider, Grid, Image, Loader, Table, Tabs, Textarea } from "@mantine/core";
import { Icon3dCubeSphere, IconFiles, IconHammer, IconPhoto, IconTornado, IconWall } from "@tabler/icons-react";
import { ContractorAssessment, ContractorHome, ContractorProject, ContractorTab } from "@seeair/schemas";
import { trpc } from './trpc.js';
import { Link } from "@tanstack/react-router";
import JSZip from "jszip";
import { DigitalTwinSection } from './DigitalTwinSection.js';
import { RecommendationsAccordion } from './RecommendationsAccordion.js';
import { UserDataContext } from './UserDataContext.js';
import { BlowerDoorInputPane } from './BlowerDoorInputPane.js';
import { CombustionSafetyTestInputPane } from './CombustionSafetyTestInputPane.js';
import { ContractorCompletedLineItemsInputPane } from './ContractorCompletedLineItemsInputPane.js';
import { getUseMutationOpt } from './mutationHelper.js';
import { ProjectFilesTable } from './ProjectFilesTable.js';
import { DesignedIconButton } from './DesignComponents.js';

export function ContractorPortalHome({ home, selectedProjectId, tab }: {
    home: ContractorHome,
    selectedProjectId?: string,
    tab: ContractorTab
}) {
    const initiallyShowViewer = tab == 'viewer'
    const [selectedTab, setSelectedTab] = useState(tab)
    useEffect(() => {
        setSelectedTab(tab)
    }, [tab])
    const latestAssessment = getLatestFinishedContractorAssessment(home)
    const weatherizationProject = home.projects.find(p =>
        p.recommendations.some(r => weatherizationRecNumbers.includes(r.original_rec_id)))
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Breadcrumbs>
            <Link to={"/contractor"}>Contractor</Link>
            <Link to={"/contractor/home/$home_id"}
                params={{ home_id: home.home_id }}
                search={{
                    project: selectedProjectId,
                    tab: selectedTab
                }}
            >
                Home
            </Link>
        </Breadcrumbs>
        <VStack>

            <HStack leftCenter>
                <VStack><Text2Xl>{buildShortAddress(home.address)}</Text2Xl></VStack>
                <VStack classNames={"mx-8"}>
                    <TextBase>{home.owner.name}</TextBase>
                    <TextBase light>{home.owner.email}</TextBase>
                </VStack>
                {weatherizationProject && latestAssessment != 'not_found' && <VStack classNames={"mx-4"}>
                    <TextSm m0 light center>Hancock Project Number</TextSm>
                    <TextBase m0 center>{latestAssessment.project_number}</TextBase>
                </VStack>}
                {weatherizationProject && <WeatherizationProjectCost project={weatherizationProject} />}
            </HStack>
            {
                latestAssessment == 'not_found'
                    ? <HStack center><TextBase>No Projects Ready</TextBase></HStack>
                    : <Tabs
                        value={selectedTab}
                        onChange={(value) => {
                            setSelectedTab((value ?? 'plan') as ContractorTab)
                            const url = new URL(window.location.href);
                            if (value) {
                                url.searchParams.set('tab', value)
                                window.history.pushState({}, '', url)
                            }
                        }}
                        classNames={{ tab: `border border-gray-500 aria-selected:bg-gray-200` }}
                    >
                        <Tabs.List className={"justify-center"}>
                            <Tabs.Tab value={"viewer"} leftSection={<Icon3dCubeSphere />}>Digital Twin</Tabs.Tab>
                            <Tabs.Tab value={"plan"} leftSection={<IconHammer />}>Project Plan</Tabs.Tab>
                            <Tabs.Tab value={"files"} leftSection={<IconPhoto />}>Photos</Tabs.Tab>
                            <Tabs.Tab value={"documents"} leftSection={<IconFiles />}>Documents</Tabs.Tab>
                            {
                                weatherizationProject && <Tabs.Tab value={"testing"} leftSection={<IconTornado />}>Testing</Tabs.Tab>
                            }
                            {
                                weatherizationProject && <Tabs.Tab value={"completion"} leftSection={<IconWall />}>Completion</Tabs.Tab>
                            }

                        </Tabs.List>
                        <div className={"my-12"}>
                            <Tabs.Panel value={"viewer"}>
                                <ContractorPortalViewer
                                    latestAssessment={latestAssessment}
                                    initiallyShowViewer={!!initiallyShowViewer}
                                    home={home} />
                            </Tabs.Panel>
                            <Tabs.Panel value={"plan"}>
                                <ContractorPortalProjects home={home} selectedProjectId={selectedProjectId} />
                            </Tabs.Panel>
                            <Tabs.Panel value={"files"}>
                                <ContractorPortalPhotos assessment={latestAssessment} />
                            </Tabs.Panel>
                            <Tabs.Panel value={"documents"}>
                                <ContractorPortalDocuments home={home} />
                            </Tabs.Panel>
                            {
                                weatherizationProject && <Tabs.Panel value={"testing"}>
                                    <ContractorPortalTesting home={home} project={weatherizationProject} assessment={latestAssessment} />
                                </Tabs.Panel>
                            }
                            {
                                weatherizationProject && <Tabs.Panel value={"completion"}>
                                    <ContractorPortalCompletion project={weatherizationProject} />
                                </Tabs.Panel>
                            }
                        </div>
                    </Tabs>
            }
        </VStack>
    </FullWithDesignedSection>
}
export function WeatherizationProjectCost({ project }: { project: ContractorProject }) {
    const { data: costRes } = trpc.CONTRACTOR.getProjectCost.useQuery({ home_id: project.home_id, project_id: project.project_id })
    return <VStack classNames={"mx-4"}>
        <TextSm m0 light center>Weatherization Project Cost</TextSm>
        <TextBase m0 center>{costRes ? formatMoney(costRes.cost) : "--"}</TextBase>
    </VStack>
}
function ContractorPortalDocuments({ home }: { home: ContractorHome }) {
    const { user } = useContext(UserDataContext)
    return <VStack>
        <Divider size={"xl"} label={"E-Sign Documents"} />

        <Table striped highlightOnHover withTableBorder classNames={{ tr: "min-h-24" }}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>For</Table.Th>
                    <Table.Th>Homeowner URL</Table.Th>
                    <Table.Th>Crew Chief Sign Link</Table.Th>
                    <Table.Th>Created</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {getAllDocumentsForContractor(home)
                    .sort((d1, d2) => d1.created_date.localeCompare(d2.created_date))
                    .map((d) => (
                        <Table.Tr key={d.document_id}>
                            <Table.Td>{d.name}</Table.Td>
                            <Table.Td>{d.for}</Table.Td>
                            <Table.Td>
                                {d.homeowner_url && <a href={d.homeowner_url} target={"_blank"} rel={"noreferrer"}>For {d.homeowner_email}  {d.homeowner_completed_date ? "✅" : "❌"}</a>}
                            </Table.Td>
                            <Table.Td>
                                {
                                    d.crew_chief_url && d.crew_chief_email && <a href={d.crew_chief_url!} target={"_blank"} rel={"noreferrer"}>Sign Link {d.crew_chief_completed_date ? "✅" : "❌"}</a>
                                }
                            </Table.Td>
                            <Table.Td>{d.created_date}</Table.Td>
                        </Table.Tr>
                    ))}
            </Table.Tbody>
        </Table>
        <Divider size={"xl"} label={"Other FiLes"} />
        <ProjectFilesTable home={home} projects={home.projects} filter={["permit", "permit_documentation"]} forRole={"contractor"} />


    </VStack>
}
function ContractorPortalPhotos({ assessment }: { assessment: ContractorAssessment }) {
    const { data: presignedImageUrls } = trpc.HOMEOWNER.getAssessmentImagesDownloadUrls.useQuery({
        assessment_id: assessment.assessment_id,
        home_id: assessment.home_id
    }, { retry: false })
    return <VStack>
        <Grid>
            {
                Object.values(assessment.assessment_files_list ?? {})
                    .filter(f => f.type.endsWith("_image"))
                    .map(fv => {
                        const presignedImageUrl = presignedImageUrls?.urls?.find(o => {
                            const { Bucket: fvBucket, Key: fvKey } = extractBucketAndKeyFromS3Url(fv.s3_url)
                            const { Bucket: presignedBucket, Key: presignedKey } = extractBucketAndKeyFromHttpUrl(o)
                            return fvBucket == presignedBucket && fvKey == presignedKey
                        })
                        return <Grid.Col key={fv.s3_url} span={{ base: 12, md: 6, lg: 3 }}>
                            {
                                presignedImageUrls
                                    ? <Image
                                        radius={"sm"}
                                        src={presignedImageUrl}
                                    />
                                    : <Loader />
                            }

                        </Grid.Col>
                    })
            }
        </Grid>
        <HStack center><TextBase>{"If you require any other information, please do not hesitate to reach out to "}<a
            href={"mailto:support@seeair.com"}>SeeAir Support</a></TextBase></HStack>
    </VStack>
}

export function ContractorPortalProjects({ home, selectedProjectId }: { home: ContractorHome, selectedProjectId?: string }) {
    return home.projects.length > 0
        ? <RecommendationsAccordion
            recs={home.projects.flatMap(p => p.recommendations)}
            projects={home.projects}
            hideAdminItems={true}
            includeProjects={true}
            isContractor={true}
            selectedProjectId={selectedProjectId}
            filteredRecs={[]}
        />
        : <TextBase>No Projects For Home</TextBase>
}


function ContractorPortalViewer2D({ home, assessment }: { assessment: ContractorAssessment, home: ContractorHome }) {
    const [floorPlanImageUrls, setFloorPlanImageUrls] = useState([] as Array<string>)
    const {
        data: floorPlanZipUrl,
        isPending: floorPlanZipIsPending,
        error: floorPlanZipError
    } = trpc.CONTRACTOR.getAssessmentFloorPlanZipDownloadUrl.useQuery({
        home_id: home.home_id,
        assessment_id: assessment.assessment_id
    })
    useEffect(() => {
        void (async () => {
            if (floorPlanZipUrl && floorPlanZipUrl.url) {
                const zipFileResponse = await fetch(floorPlanZipUrl.url)
                const zipFileBlob = await zipFileResponse.blob()
                const zip = new JSZip();
                const content = await zip.loadAsync(zipFileBlob);
                const urls: Array<string> = [];
                for (const fileName in content.files) {
                    if (fileName.endsWith(".png")) {
                        const fileData = await content.files[fileName]!.async("blob");
                        const url: string = URL.createObjectURL(fileData);
                        urls.push(url);
                    }
                }
                setFloorPlanImageUrls(urls)
            }
        })()
    }, [floorPlanZipUrl]);
    if (floorPlanZipIsPending) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><Loader /></AbsoluteCenterStack></VStack>
    } else if (floorPlanZipError) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><TextError>{"Error Loading Floor Plan: "}{floorPlanZipError.message}</TextError></AbsoluteCenterStack></VStack>
    }
    return <VStack>
        <Grid>
            {
                floorPlanImageUrls.map((url, i) => <Grid.Col
                    key={i} span={{ base: 12, lg: 6 }}>
                    <Image
                        radius={"sm"}
                        src={url}
                    />
                </Grid.Col>)
            }
        </Grid>
    </VStack>
}
function ContractorPortalViewer({ home, initiallyShowViewer, latestAssessment }: {
    home: ContractorHome,
    initiallyShowViewer: boolean,
    latestAssessment: ContractorAssessment,
}) {
    const latest3dRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')

    return <div>

        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <Divider my={"sm"} label={<TextSm>3D Digital Twin</TextSm>} labelPosition={"center"} color={"black"} />
        <DigitalTwinSection
            file={latest3dRenderingFile}
            home={home}
            isContractor={true}
            initiallyShowViewer={initiallyShowViewer} />
        {/*<Divider my="sm" label={<TextSm>2D Floorplan</TextSm>} labelPosition="center" color={"black"}/>*/}
        {/*{*/}
        {/*    <ContractorPortalViewer2D home={home} assessment={latestAssessment}/>*/}
        {/*}*/}

    </div>

}
function ContractorPortalTesting({ assessment, project }: { home: ContractorHome, assessment: ContractorAssessment, project: ContractorProject }) {
    return <VStack>
        <Divider size={"xl"} label={"Combustion Safey Tests"} />
        <CombustionSafetyTestInputPane project={project} assessment={assessment} />
        <Divider size={"xl"} label={"Blower Door Tests"} />
        <BlowerDoorInputPane project={project} />
    </VStack>
}
function ContractorPortalCompletion({ project }: { project: ContractorProject }) {
    return <VStack>
        <Divider size={"xl"} label={"Finalize Line Items"} />
        <ContractorCompletedLineItemsInputPane project={project} />
        <Divider size={"xl"} label={"Certificate Of Completion"} />
        <CertificateOfCompletionPane project={project} />
    </VStack>
}

export function CertificateOfCompletionPane({ project }: { project: ContractorProject }) {
    const [notes, setNotes] = useState("")
    const { isPending: isGenerateCocPending, mutate: generateCoc } =
        trpc.CONTRACTOR.publishPandadocCoCForProject.useMutation(
            getUseMutationOpt(trpc.useUtils()),
        )
    let cocWarning = ""
    const cocAlreadyExists = documentExistsInProject(project, "certificate-of-completion")
    const requiresCoc = project.recommendations.some(r => weatherizationRecNumbers.includes(r.original_rec_id))
    const missingBlowerDoor = !project.blower_door_tests?.skipped == true && (!project.blower_door_tests?.testIn || !project.blower_door_tests?.testOut)
    const missingCst = !project.combustion_safety_tests?.result
    const missingFinalLineItems = Object.keys(project.completed_line_items ?? {}).length == 0
    const cocDisabled = !requiresCoc || isGenerateCocPending || cocAlreadyExists || missingBlowerDoor || missingCst || missingFinalLineItems
    if (!requiresCoc) {
        cocWarning = "Not Required"
    } else if (cocAlreadyExists) {
        cocWarning = "Doc Already Exists"
    } else if (missingBlowerDoor) {
        cocWarning = "Missing Blower Door"
    } else if (missingCst) {
        cocWarning = "Missing Combustion Safety Test"
    } else if (missingFinalLineItems) {
        cocWarning = "Save Finalized Line Items"
    }
    const changesSummary = summarizeCompletedLineItemChanges(project)
    return <React.Fragment>
        {
            !missingFinalLineItems && <React.Fragment>
                <TextBase>Changes Summary</TextBase>
                {
                    changesSummary.length == 0
                        ? <TextBase>No changes from quote</TextBase>
                        : <ul>{changesSummary.map(c => <li key={c}>{c}</li>)}</ul>
                }
            </React.Fragment>
        }

        <Textarea value={notes} onChange={(e) => setNotes(e.currentTarget.value)} label={"CoC Notes"} />
        <HStack leftCenter>
            <DesignedIconButton
                icon={<TextBase>Publish Certificate Of Completion 🐼</TextBase>}
                onClick={() => {
                    generateCoc({
                        project_id: project.project_id,
                        home_id: project.home_id,
                        notes
                    })
                }}
                disabled={cocDisabled}
                tooltip={"Publish"}
            />
            <TextError>{cocWarning}</TextError>
        </HStack>
    </React.Fragment>
}