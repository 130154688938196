import React from "react";
import classNames from "classnames";
import { ReactNode } from '@tanstack/react-router';

export function RSplitLayout({ left, right }: { left: ReactNode, right: ReactNode }) {
    const style = {
        // height: 'calc(100vh - 2rem)',
        // maxHeight: 'calc(100vh - 2rem)'
    }
    return <div className={classNames("flex items-center flex-row")}>
        {/*this should be hidden unless on xl screen or high def landscape*/}
        <div className={"h-screen max-h-screen w-2/5vw bg-slate-200 overflow-auto flex-initial hidden hd-landscape:flex xl:flex @container"}>
            {left}
        </div>
        {/*this should always be visible*/}
        <div className={"h-screen w-screen max-h-screen max-w-screen hd-landscape:w-3/5vw xl:w-3/5vw overflow-hidden @container"} style={style}>
            {right}
        </div>
    </div>
}