import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { UserDataContext } from './UserDataContext.js';
import { useNavigate } from "@tanstack/react-router";
import { isContractor, isHomeOwner, isSiteAdmin } from "@seeair/shared";
import { SeeAirUser } from "@seeair/schemas";
import { showErrorNotification, showSuccessNotification } from './mutationHelper.js';
import { SuspenseLoader } from './DesignComponents.js';

function getHomeForRole(role: string): string {
    switch (role) {
        case 'homeowner':
            return '/homes'
        case 'admin':
            return '/admin'
        case 'contractor':
            return '/contractor'
        default:
            return "/"
    }
}

export function AuthenticationCheck({
    children,
    shouldRedirect,
    redirectHome = false,
    redirectTo,
    redirectSearch = {},
    notify
}: PropsWithChildren<{
    shouldRedirect: (user: SeeAirUser | null) => boolean,
    redirectHome?: boolean,
    redirectTo: string,
    redirectSearch?: { [_: string]: any },
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    notify: 'success' | 'ignore' | string
}>) {
    const { user, isLoading } = useContext(UserDataContext)
    const navigate = useNavigate()
    const [shouldMount, setShouldMount] = useState(!isLoading)
    useEffect(() => {
        void (async () => {
            if (!isLoading) {
                if (shouldRedirect(user)) {
                    if (notify == 'success') {
                        showSuccessNotification()
                    }
                    const params = new URLSearchParams(window.location.search)
                    if (params.get("attempt")) { // this should prevent loops
                        await navigate({ to: '/', search: {} })
                    } else if (redirectHome && user && user.role) {
                        await navigate({ to: getHomeForRole(user.role) })
                    } else {
                        setTimeout(async () => {
                            const currentOrigin=new URL(location.href).origin
                            console.log(`about to redirect to: ${redirectTo} from ${currentOrigin}`)
                            try {
                                await navigate({
                                    to: redirectTo.startsWith(currentOrigin)?redirectTo.slice(currentOrigin.length):redirectTo,
                                    search: (prevSearch: any) => ({
                                        ...prevSearch,
                                        attempt: "1",
                                        ...redirectSearch,
                                        ...(notify == 'success' || notify == 'ignore' ? {} : { message: notify }) as any
                                    })
                                })
                                console.log("redirect done?")
                            } catch (e) {
                                showErrorNotification(e as {message:string})
                            }
                        }, 500)
                    }
                } else {
                    setShouldMount(true)
                }
            }
        })()
    }, [user, isLoading, navigate, shouldRedirect, redirectHome, redirectTo, notify, redirectSearch])
    if (!shouldMount) {
        return SuspenseLoader
    }
    return <React.Fragment>{children}</React.Fragment>
}

export function HomeownerUserRedirectToSignInCheck({ children }: PropsWithChildren) {
    return <AuthenticationCheck
        shouldRedirect={(user) => !isSiteAdmin(user) && !isHomeOwner(user)}
        redirectTo={"/auth/signin"}
        redirectSearch={{ redirect: location.pathname }}
        notify={"Not authorized to view Home Owner page"}
    >
        {children}
    </AuthenticationCheck>
}

export function AdminUserRedirectToSignInCheck({ children }: PropsWithChildren) {
    return <AuthenticationCheck
        shouldRedirect={(user) => !isSiteAdmin(user)}
        redirectTo={"/auth/signin"}
        redirectSearch={{ redirect: location.pathname }}
        notify={"Not authorized to view Admin page"}
    >
        {children}
    </AuthenticationCheck>
}

export function ContractorUserRedirectToSignInCheck({ children }: PropsWithChildren) {
    return <AuthenticationCheck
        shouldRedirect={(user) => !isSiteAdmin(user) && !isContractor(user)}
        redirectTo={"/auth/signin"}
        redirectSearch={{ redirect: location.pathname }}
        notify={"Not authorized to view Contractor page"}
    >
        {children}
    </AuthenticationCheck>
}