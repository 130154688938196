import { z } from "zod";
import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi";
import { PandadocStatusEnumSchema } from './pandadoc.js'

extendZodWithOpenApi(z);
export const ASSESSMENT_LABEL_BASELINE = "Baseline";
export const AssessmentStatusSchema = z.enum([
    "not_started",
    "homeowner_providing_inputs",
    "collecting_data",
    "bim_team_analysis",
    "internal_review",
    "pending_homeowner_review",
    "done",
]);
export const ProjectStatusSchema = z.enum([
    "in_progress",
    "done_paid"
])
export type ProjectStatus =z.infer<typeof ProjectStatusSchema>
export const HubspotEligibilitySurveySchema = z.object({
    email: z.string(),
    firstname: z.string().nullish(),
    lastname: z.string().nullish(),
    utility_providers: z.string().nullish(),
    address: z.string().nullish(),
    address_line_2: z.string().nullish(),
    city: z.string().nullish(),
    zip: z.string().nullish(),
    country: z.string().nullish(),
    state: z.string().nullish(),
    ownership: z.string().nullish(),
    priorities: z.string().nullish(),
    prior_assessment: z.string().nullish(),
    prior_assessment_date: z.string().nullish(),
    wall_insulation: z.string().nullish(),
    discounted_rate: z.string().nullish(),
});
export type HubspotEligibilitySurveyData = z.infer<typeof HubspotEligibilitySurveySchema>;
export const HubspotRentcastDetailsSchema = z.object({
    beds: z.string().nullish(),
    baths: z.string().nullish(),
    yearBuilt: z.string().nullish(),
    sqft: z.string().nullish(),
    fireplace: z.string().nullish(),
    floors: z.string().nullish(),
});
export type HubspotRentcastDetails = z.infer<typeof HubspotRentcastDetailsSchema>;
// const currentYear = new Date().getFullYear() // this breaks and returns 1970 in cloudflare
export const BaseHomeDetailsSchema = z.object({
    beds: z.number().min(0).max(100).optional().describe("Beds"),
    sqft: z.number().min(0).optional(),
    baths: z.number().min(0).max(100).optional(),
    floors: z.number().min(0).max(10).optional(),
    yearBuilt: z.number().min(0).optional(),
    fireplace: z.boolean().optional(),
    discounted_rate: z.boolean().optional(),
    prior_assessment: z.boolean().optional(),
});
export type BaseHomeDetails = z.infer<typeof BaseHomeDetailsSchema>;

export const HomeWalkthroughFilesSchema = z.object({
    window_image: z.string().url().optional(),
});
export type HomeWalkthroughFiles = z.infer<typeof HomeWalkthroughFilesSchema>;
const StoveEnumSchema = z.enum(["Gas", "Electric", "Induction"]);
export type StoveEnum = z.infer<typeof StoveEnumSchema>;
const LightbulbEnumSchema = z.enum(["Incandescent", "CFL", "Halogen", "LED"]);
export type LightbulbEnum = z.infer<typeof LightbulbEnumSchema>;
export const HomeWalkthroughDetailsSchema = z.object({
    lightbulbs: z.array(LightbulbEnumSchema).optional(),
    stove: StoveEnumSchema.optional(),
    stove_secondary: StoveEnumSchema.optional(),
    stove_tertiary: StoveEnumSchema.optional(),
    thermostat: z.enum(["Smart", "Traditional", "Programmable"]).optional(),
    solar: z.boolean().optional(),
    solar_orientation: z
        .array(z.enum(["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"]))
        .optional(),
    solar_age: z.enum(["<5 Years", "5-10 Years", "10-15 Years", "15-20 Years", ">20 Years"]).optional(),
    solar_count: z.number().optional(),
    solar_capacity: z.number().optional(),
    battery: z.boolean().optional(),
    battery_capacity: z.number().optional(),
});
export type HomeWalkthroughDetails = z.infer<typeof HomeWalkthroughDetailsSchema>;

export const HomeWalkthroughSurveyResultsSchema =
    HomeWalkthroughDetailsSchema.merge(HomeWalkthroughFilesSchema);
export type HomeWalkthroughSurveyResults = z.infer<typeof HomeWalkthroughSurveyResultsSchema>;

export const HeatingAndCoolingWalkthroughFilesSchema = z.object({
    insulation_image: z.string().url().optional(),
    crawlspace_image: z.string().url().optional(),
    primary_cooling_image: z.string().url().optional(),
    secondary_cooling_image: z.string().url().optional(),
    primary_heating_image: z.string().url().optional(),
    secondary_heating_image: z.string().url().optional(),
    water_heater_image: z.string().url().optional(),
});
export const UtilityAgeSchema = z.enum([
    "<5 Years",
    "5-10 Years",
    "10-15 Years",
    "15-20 Years",
    ">20 Years",
    "Not Applicable",
]);
export type UtilityAgeEnum = z.infer<typeof UtilityAgeSchema>;
export type HeatingAndCoolingWalkthroughFiles = z.infer<typeof HeatingAndCoolingWalkthroughFilesSchema>;
export const HeatingApplianceEnumSchema = z.enum([
    "Central gas furnace",
    "Room (through-the-wall) gas furnace",
    "Gas boiler",
    "Propane (LPG) central furnace",
    "Propane (LPG) wall furnace / Propane (LPG) boiler",
    "Oil furnace",
    "Oil Boiler",
    "Electric furnace",
    "Electric heat pump",
    "Electric baseboard heater",
    "Ground coupled heat pump",
    "Minisplit (ductless) heat pump",
    "Electric boiler",
    "Wood Stove",
    "Pellet Stove",
    "None",
]);
export type HeatingApplianceEnum = z.infer<typeof HeatingApplianceEnumSchema>;
export const HeatingVentingEnumSchema = z.enum(["atmospheric", "vented"]);
export type HeatingVentingEnum = z.infer<typeof HeatingVentingEnumSchema>;
export const CoolingApplianceEnumSchema = z.enum([
    "Central air conditioner",
    "Room air conditioner",
    "Electric heat pump",
    "Minisplit (ductless) heat pump",
    "Ground coupled heat pump",
    "Direct evaporative cooling",
    "None",
]);
export type CoolingApplianceEnum = z.infer<typeof CoolingApplianceEnumSchema>;
export const HeatingAndCoolingWalkthroughDetailsSchema = z.object({
    attic: z.boolean().optional(),
    attic_accessible: z.boolean().optional(),
    crawlspace: z.boolean().optional(),
    primary_cooling: CoolingApplianceEnumSchema.optional(),
    primary_cooling_age: UtilityAgeSchema.optional(),
    primary_cooling_merv: z
        .enum([
            "MERV 8",
            "MERV 9",
            "MERV 10",
            "MERV 11",
            "MERV 12",
            "MERV 13",
            "MERV 14",
            "HEPA",
            "None",
            "Not Applicable",
        ])
        .optional(),
    secondary_cooling: CoolingApplianceEnumSchema.optional(),
    secondary_cooling_age: UtilityAgeSchema.optional(),
    secondary_cooling_merv: z
        .enum([
            "MERV 8",
            "MERV 9",
            "MERV 10",
            "MERV 11",
            "MERV 12",
            "MERV 13",
            "MERV 14",
            "HEPA",
            "None",
            "Not Applicable",
        ])
        .optional(),
    electric_panel: z.enum(["100amp", "200amp", "Other"]).optional(),
    primary_heating: HeatingApplianceEnumSchema.optional(),
    primary_heating_age: UtilityAgeSchema.optional(),
    primary_heating_venting: HeatingVentingEnumSchema.optional(),
    secondary_heating: HeatingApplianceEnumSchema.optional(),
    secondary_heating_age: UtilityAgeSchema.optional(),
    secondary_heating_venting: HeatingVentingEnumSchema.optional(),
    water_heater_age: UtilityAgeSchema.optional(),
    water_heating_venting: HeatingVentingEnumSchema.optional(),
});
export type HeatingAndCoolingWalkthroughDetails = z.infer<typeof HeatingAndCoolingWalkthroughDetailsSchema>;

export const HeatingAndCoolingWalkthroughSurveyResultsSchema =
    HeatingAndCoolingWalkthroughDetailsSchema.merge(HeatingAndCoolingWalkthroughFilesSchema);

export const DerivedHomeDetailsSchema = z.object({
    //window_image
    window_type: z.enum(["Single Pane", "Double Pane", "Triple Pane"]).optional(),
    //insulation_image

    insulation_type: z
        .enum([
            "Cellulose, loose fill",
            "Cellulose, high density",
            "Fiberglass, batts",
            "Fiberglass, loose fill",
            "Fiberglass, loose fill, fluffed below manufacturer's standards",
            "Rockwool",
            "Vermiculite",
            "Poly-isocyanurate, rigid board",
            "Polystyrene, expanded rigid board",
            "Polystyrene, extruded rigid board",
            "Low Density Urethane, sprayed foam",
            "Urethane, sprayed foam",
            "Urea Formaldehyde Foam",
        ])
        .optional(),
    insulation_depth: z.number().optional(),
    //primary_cooling_image
    primary_cooling_btus: z.number().optional(),
    primary_cooling_seer: z.number().optional(),
    //secondary_cooling_image
    secondary_cooling_btus: z.number().optional(),
    secondary_cooling_seer: z.number().optional(),
    //primary_heating_image
    primary_heating_btus: z.number().optional(),
    primary_heating_afue: z.number().optional(),
    //secondary_heating_image
    secondary_heating_btus: z.number().optional(),
    secondary_heating_afue: z.number().optional(),
    //water_heater_image
    water_heater_btus: z.number().optional(),
    water_heater_afue: z.number().optional(),
    water_heater_type: z
        .enum([
            "Tank-less Electric Water Heater",
            "Tank-less Natural Gas Water Heater",
            "Tank Natural Gas Water Heater",
            "Tank Heat Pump Water Heater",
            "Tank Electric Water Heater",
            "Tank Solar Water Heater",
        ])
        .optional(),
    siding_materials: z
        .array(
            z.enum([
                "Vinyl",
                "Wood Shingle",
                "Manufactured Shingle",
                "Wood Lap Board",
                "Manufactured Lap Board",
                "Wood Board & Batten",
                "Brick",
                "Stone",
                "Cement",
                "Asbestos",
            ]),
        )
        .optional(),
});

export const HomeDetailsSchema = BaseHomeDetailsSchema.merge(HomeWalkthroughDetailsSchema)
    .merge(HeatingAndCoolingWalkthroughDetailsSchema)
    .merge(DerivedHomeDetailsSchema);
export type HomeDetails = z.infer<typeof HomeDetailsSchema>;

export const TypeFormHomeDetailsSchema = HomeWalkthroughDetailsSchema.merge(HomeWalkthroughFilesSchema)
    .merge(HeatingAndCoolingWalkthroughDetailsSchema)
    .merge(HeatingAndCoolingWalkthroughFilesSchema);
export const PartialTypeFormHomeDetailsSchema = TypeFormHomeDetailsSchema.partial();
export type PartialTypeFormHomeDetails = z.infer<typeof PartialTypeFormHomeDetailsSchema>;
export const PartialHomeDetailsDBSchema = HomeDetailsSchema.partial();
export type PartialHomeDetailsDB = z.infer<typeof PartialHomeDetailsDBSchema>;

// Mapping a zod Object into a zod union schema with objects of the original schema
export function mapZodObjectToZodUnion(homeDetails: z.ZodObject<any>) {
    const zodForm: z.ZodTypeAny[] = [];
    for (const field in homeDetails.shape) {
        zodForm.push(
            z
                .object({
                    [field]: homeDetails.shape[field],
                })
                .describe(field),
        );
    }
    return z.object({
        Details: z.union(zodForm as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]]).optional(),
    });
}

export const RecommendationDiscountSchema = z.object({
    incentive_id: z.string(),
    title: z.string(),
    timeOfPurchase: z.boolean(),
    rebate: z.boolean().nullish(),
    discountOnRemainder: z.boolean(),
    url: z.string().optional(),
    // amount: z.number().optional(), //moved to using cap in place of amount to simplify
    percentage: z.number(),
    cap: z.number(),
    recNums: z.array(z.string()),
    order: z.number(),
});
export type RecommendationDiscount = z.infer<typeof RecommendationDiscountSchema>;

export const RecommendationDiscountsSchema = z.record(z.string(), RecommendationDiscountSchema);
export type RecommendationDiscounts = Record<string, RecommendationDiscount>;
export const RecommendationLineItemSchema = z.object({
    line_item_id: z.string(),
    name: z.string(),
    quantity: z.number(),
    description: z.string().nullish(),
    price_per_unit: z.number(),
    product_id: z.string(),
    discounts: z.record(z.string(), RecommendationDiscountSchema),
});
export type RecommendationLineItem = z.infer<typeof RecommendationLineItemSchema>;
export const RecommendationLineItemsSchema = z.record(z.string(), RecommendationLineItemSchema);
export type RecommendationLineItems = Record<string, RecommendationLineItem>;
export const RecommendationIncentiveSchema = z.object({
    title: z.string(),
    timeOfPurchase: z.boolean(),
    discountOnRemainder: z.boolean().optional(),
    url: z.string().optional(),
    amountLow: z.number().optional(),
    amountHigh: z.number().optional(),
    percentage: z.number().optional(),
    cap: z.number().optional(),
});
export type RecommendationIncentive = z.infer<typeof RecommendationIncentiveSchema>;
export const RecommendationFinancialsSchema = z.object({
    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    // incentives: z.array(RecommendationIncentiveSchema).optional(),
    Net_Cost_Low: z.number().optional(),
    Net_Cost_High: z.number().optional(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
});
export const EnergyChartMonthSchema = z.object({
    Date: z.number(),
    Other_Usage: z.number(),
    Heating_Usage: z.number(),
    Cooling_Usage: z.number(),
    Other_Cost: z.number(),
    Heating_Cost: z.number(),
    Cooling_Cost: z.number(),
});
export type EnergyChartMonth = z.infer<typeof EnergyChartMonthSchema>;
export const ElectrificationDataSchema = z.object({
    energy_use: z.object({ Electric: z.number(), Gas: z.number(), Oil: z.number() }),
    energy_cost: z.object({ Electric: z.number(), Gas: z.number(), Oil: z.number() }),
    energy_chart: z.array(EnergyChartMonthSchema),
    avg_gas_cost: z.number(),
    avg_oil_cost: z.number(),
    avg_electricity_cost: z.number(),
    baseload_gas_usage: z.number(),
    baseload_electricity_usage: z.number(),
    baseload_oil_usage: z.number(),
    hvac_sizing: z.object({
        heating_load_current: z.number().nullish(),
        cooling_load_current: z.number().nullish(),
        // heating
        heating_load: z.number().nullish(),
        heating_infiltration: z.number().nullish(),
        heating_insulation: z.number().nullish(),
        heating_radiation: z.number().nullish(),
        heating_afue: z.number().nullish(),
        // cooling
        cooling_load: z.number().nullish(),
        cooling_infiltration: z.number().nullish(),
        cooling_insulation: z.number().nullish(),
        cooling_radiation: z.number().nullish(),
    }),
});
export type ElectrificationData = z.infer<typeof ElectrificationDataSchema>;

export const AssessmentFilesEnumSchema = z.enum([
    //Home Walkthrough images
    "window_image",
    "insulation_image",
    "electric_panel_image",
    "crawlspace_image",
    "primary_cooling_image",
    "secondary_cooling_image",
    "primary_heating_image",
    "secondary_heating_image",
    "water_heater_image",
    "primary_heating_label_image",
    "primary_cooling_label_image",
    "water_heater_label_image",
    "stove_image",
    "siding_image",
    "rim_joist_image",
    "knob_and_tube_image",
    "electrical_meter_image",
    //unclassified home walkthrough images
    "unclassified_image",
    //home walkthrough video
    "capture_video",
    //from cubicasa
    "capture_rendered_floor_plan",
    "capture_rendering_raw",
    //bim team created
    "capture_rendering_cleaned",
    "capture_rendering_enhanced",
    //from autodesk
    "capture_rendering_enhanced.svf",
    "capture_rendering_enhanced.thumbnail",
]);
export type AssessmentFilesEnum = z.infer<typeof AssessmentFilesEnumSchema>;
export const AssessmentFileTypeFormatted: Record<AssessmentFilesEnum,string>={
    capture_rendered_floor_plan: "Rendered Floor Plans",
    window_image: "Window Images",
    insulation_image: "Attic Insulation Images",
    electric_panel_image: "Electric Panel Images",
    crawlspace_image: "Crawlspace Images",
    primary_cooling_image: "Primary Cooling Appliance Images",
    secondary_cooling_image: "Secondary Cooling Appliance Images",
    primary_heating_image: "Primary Heating Appliance Images",
    secondary_heating_image: "Secondary Heating Appliance Images",
    water_heater_image: "Water Heater Appliance Images",
    primary_heating_label_image: "Primary Heating Label Images",
    primary_cooling_label_image: "Primary Cooling Label Images",
    water_heater_label_image: "Water Heater Label Images",
    stove_image: "Stove Images",
    siding_image: "Exterior Siding Images",
    rim_joist_image: "Rim Joist Images",
    knob_and_tube_image: "Wiring Images",
    electrical_meter_image: "Electrical Meter Images",
    unclassified_image: "Unclassified Images",
    capture_video: "CubiCapture Videos",
    capture_rendering_raw: "CubiCapture 3D Raw Renderings",
    capture_rendering_cleaned: "Digital Twin Cleaned",
    capture_rendering_enhanced: "Digitial Twin Enhanced",
    "capture_rendering_enhanced.svf": "Digital Twin Enhanced SVF",
    "capture_rendering_enhanced.thumbnail": "Digital Twin Thumbnail"
}
export const ProjectFilesEnumSchema = z.enum([
    "permit",
    "permit_documentation"
])
export type ProjectFilesEnum = z.infer<typeof ProjectFilesEnumSchema>
export const ProjectFileTypeFormatted: Record<ProjectFilesEnum,string>={
    "permit": "Permits",
    permit_documentation: "Permit Documentation",
}
export const BaseFileSchema = z.object({
    file_id: z.string(),
    name: z.string(),
    s3_url: z.string().url(),
    created_by: z.string(),
    created_date: z.string().datetime(),
    metadata: z.string().nullish(),
});
export type BaseFile=z.infer<typeof BaseFileSchema>
export const AssessmentFileSchema = BaseFileSchema.merge(z.object({
    type: AssessmentFilesEnumSchema,
}));
export const AssessmentFilesSchema = z.record(z.string(), AssessmentFileSchema);

export type AssessmentFiles = z.infer<typeof AssessmentFilesSchema>;
export type AssessmentFile = z.infer<typeof AssessmentFileSchema>;

export const ProjectFileSchema = BaseFileSchema.merge(z.object({
    type: ProjectFilesEnumSchema,
}));
export const ProjectFilesSchema = z.record(z.string(),ProjectFileSchema)
export type ProjectFile=z.infer<typeof ProjectFileSchema>
export type ProjectFiles=z.infer<typeof ProjectFilesSchema>
export const ProjectDocumentEnumSchema = z.enum([
    "quote",
    "permit-auth",
    "invoice",
    "certificate-of-completion",
    "wall-disclosure",
    "task-order"]);
export const AssessmentDocumentEnumSchema = z.enum([
    "hpc-contractor-information",
    "ism-national-grid",
    "ism-eversource",
]);

export const AnyDocumentEnumSchema = ProjectDocumentEnumSchema.or(AssessmentDocumentEnumSchema)
export type AnyDocumentEnum=z.infer<typeof AnyDocumentEnumSchema>
const BaseDocumentSchema = z.object({
    document_id: z.string(),
    name: z.string(),
    status: PandadocStatusEnumSchema.nullish(),
    homeowner_url: z.string().nullish(),
    homeowner_email: z.string().nullish(),
    homeowner_completed_date: z.string().datetime().nullish(),
    homeowner_paid_date: z.string().datetime().nullish(),
    crew_chief_url: z.string().nullish(),
    crew_chief_email: z.string().nullish(),
    crew_chief_completed_date: z.string().datetime().nullish(),
    seeair_rep_url: z.string().nullish(),
    seeair_rep_email: z.string().nullish(),
    seeair_rep_completed_date: z.string().datetime().nullish(),
    created_date: z.string().datetime()
});
export type BaseDocument = z.infer<typeof BaseDocumentSchema>
const DisplayDocumentSchema = BaseDocumentSchema.merge(z.object({
    type: ProjectDocumentEnumSchema.or(AssessmentDocumentEnumSchema).nullable(),
    for: z.string(),
    assessment_id: z.string().nullable(),
    project_id: z.string().nullable()
}))
export type DisplayDocument = z.infer<typeof DisplayDocumentSchema>
export type ProjectDocumentEnum = z.infer<typeof ProjectDocumentEnumSchema>;
export const ProjectDocumentSchema = BaseDocumentSchema.merge(
    z.object({
        type: ProjectDocumentEnumSchema.nullable(),
    }),
);
export type ProjectDocument = z.infer<typeof ProjectDocumentSchema>;
export const ProjectDocumentsSchema = z.record(z.string(), ProjectDocumentSchema); //document_id to document
export type ProjectDocuments = z.infer<typeof ProjectDocumentsSchema>;

export const UTILITY_COMPANY_NATIONAL_GRID = "ng"
export const UTILITY_COMPANY_EVERSOURCE = "es"
export type AssessmentDocumentEnum = z.infer<typeof AssessmentDocumentEnumSchema>;

export const AssessmentDocumentSchema = BaseDocumentSchema.merge(
    z.object({
        type: AssessmentDocumentEnumSchema.nullable(),
    }),
);
export type AssessmentDocument = z.infer<typeof AssessmentDocumentSchema>;
export const AssessmentDocumentsSchema = z.record(z.string(), AssessmentDocumentSchema);//document_id to document
export type AssessmentDocuments = z.infer<typeof AssessmentDocumentsSchema>;

//
// export const OldAssessmentFileSchema = z.object({
//     name: z.string(),
//     s3_url: z.string().url(),
//     created_by: z.string(),
//     created_date: z.string().datetime(),
//     metadata: z.string().nullish()
// })
// export const OldAssessmentFilesSchema = z.record(AssessmentFilesEnumSchema, z.array(OldAssessmentFileSchema).optional())
//
// export type OldAssessmentFiles = z.infer<typeof OldAssessmentFilesSchema>
// export type OldAssessmentFile = z.infer<typeof OldAssessmentFileSchema>
export const AssessmentNoteSchema = z.object({
    message: z.string(),
    created_by: z.string(),
    created_date: z.string().datetime(),
});
export type AssessmentNote = z.infer<typeof AssessmentNoteSchema>;

const SolarCostSchema = z.object({
    currencyCode: z.string(),
    units: z.string().optional(),
    nanos: z.number().optional(),
});
const SolarSavingsSchema = z.object({
    savingsYear1: SolarCostSchema,
    savingsYear20: SolarCostSchema,
    presentValueOfSavingsYear20: SolarCostSchema,
    financiallyViable: z.boolean().nullish(),
    savingsLifetime: SolarCostSchema,
    presentValueOfSavingsLifetime: SolarCostSchema,
});

/*this is just a partial schema confiriming the data that the rscript currently uses*/
const SolarFinancialAnalysisSchema = z.object({
    monthlyBill: SolarCostSchema.optional(),
    panelConfigIndex: z.number(),
    financialDetails: z
        .object({
            initialAcKwhPerYear: z.number().optional(),
            remainingLifetimeUtilityBill: SolarCostSchema.optional(),
            federalIncentive: SolarCostSchema.optional(),
            stateIncentive: SolarCostSchema.optional(),
            utilityIncentive: SolarCostSchema.optional(),
            lifetimeSrecTotal: SolarCostSchema.optional(),
            costOfElectricityWithoutSolar: SolarCostSchema.optional(),
            netMeteringAllowed: z.boolean().optional(),
            solarPercentage: z.number().optional(),
            percentageExportedToGrid: z.number().optional(),
        })
        .optional(),
    leasingSavings: z
        .object({
            leasesAllowed: z.boolean().optional(),
            leasesSupported: z.boolean().optional(),
            annualLeasingCost: SolarCostSchema.optional(),
            savings: SolarSavingsSchema.optional(),
        })
        .optional(),
    cashPurchaseSavings: z
        .object({
            outOfPocketCost: SolarCostSchema,
            upfrontCost: SolarCostSchema,
            rebateValue: SolarCostSchema,
            paybackYears: z.number(),
            savings: SolarSavingsSchema,
        })
        .optional(),
    financedPurchaseSavings: z
        .object({
            annualLoanPayment: SolarCostSchema.optional(),
            rebateValue: SolarCostSchema.optional(),
            loanInterestRate: z.number().optional(),
            savings: SolarSavingsSchema.optional(),
        })
        .optional(),
});
export type SolarFinancialAnalysis = z.infer<typeof SolarFinancialAnalysisSchema>;
export const SolarPotentialSchema = z.object({
    maxArrayPanelsCount: z.number().optional(),
    maxArrayAreaMeters2: z.number().optional(),
    maxSunshineHoursPerYear: z.number(),
    carbonOffsetFactorKgPerMwh: z.number().optional(),
    wholeRoofStats: z.object({}).optional(),
    financialAnalyses: z.array(SolarFinancialAnalysisSchema),
    solarPanelConfigs: z.array(
        z.object({
            panelsCount: z.number().optional(),
            yearlyEnergyDcKwh: z.number().optional(),
            roofSegmentSummaries: z
                .array(
                    z.object({
                        pitchDegrees: z.number(),
                        azimuthDegrees: z.number(),
                        panelsCount: z.number(),
                        yearlyEnergyDcKwh: z.number(),
                        segmentIndex: z.number(),
                    }),
                )
                .optional(),
        }),
    ),
});
export type SolarPotential = z.infer<typeof SolarPotentialSchema>;
export const AirSensorDataSchema = z.object({
    device_id: z.string(),
    datetime: z.string().datetime(),
    datetime_local: z.string().datetime(),
    hour: z.number(),
    sensors_temp: z.number(),
    sensors_radon: z.number().optional(),
    sensors_pressure: z.number().optional(),
    sensors_pm25: z.number().optional(),
    sensors_voc: z.number().optional(),
    sensors_lux: z.number().optional(),
    sensors_co2: z.number(),
    sensors_humid: z.number().optional(),
});
export type AirSensorData = z.infer<typeof AirSensorDataSchema>;
export const RecDataSchema = RecommendationFinancialsSchema.merge(
    z.object({ Prerequisites: z.array(z.string()).optional() }),
);

export type RecData = z.infer<typeof RecDataSchema>;

export const EnergyDataRecordSchema = z.object({
    date: z.string() /*.date()*/, //TODO rename to end_date
    num_days: z.number().optional(), //TODO change to not optional
    usage: z.number(),
    cost: z.number(),
    estimated: z.boolean().nullish(),
});

export type EnergyDataRecord = z.infer<typeof EnergyDataRecordSchema>;
export const AssessmentRunResultSchema = z.array(z.string());
export type AssessmentRunResult = z.infer<typeof AssessmentRunResultSchema>;
export const AddressSchema = z
    .object({
        address1: z.string(),
        address2: z.string().nullish(),
        neighborhood: z.string().nullish(),
        city: z.string(),
        county: z.string().nullish(),
        state: z.string(),
        countryCode: z.string(),
        postalCode: z.string(),
        address_aliases: z.array(z.string()).nullish(),
    })
    .openapi("Address");
export type Address = z.infer<typeof AddressSchema>;

export const DataCollectionEnumSchema = z.enum([
    "collect-optional",
    "collect-mandatory",
    "skip",
    "opted-out",
]);
export type DataCollectionEnum = z.infer<typeof DataCollectionEnumSchema>;
const AssessmentDataCollectionKeys = [
    ...AssessmentFilesEnumSchema.options.map((o) => `assessment_files/${o}`),
    "electric_usage",
    "gas_usage",
    "assessment_meeting",
];
export const AssessmentFilesDataCollectionEnum = z.enum(
    AssessmentDataCollectionKeys as [string, ...string[]],
);

export const AssessmentDataCollectionConfigurationSchema = z.record(
    AssessmentFilesDataCollectionEnum,
    DataCollectionEnumSchema,
);
export type AssessmentDataCollectionConfiguration = z.infer<
    typeof AssessmentDataCollectionConfigurationSchema
>;
export const BASELINE_DATA_COLLECTION_REQUIREMENTS: AssessmentDataCollectionConfiguration = {
    electric_usage: "collect-optional", //setting to optional while piers is running most assessments
    gas_usage: "collect-optional", //setting to optional while piers is running most assessments
    assessment_meeting: "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/window_image": "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/electric_panel_image": "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/primary_heating_image": "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/water_heater_image": "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/capture_video": "collect-optional", //setting to optional while piers is running most assessments
    "assessment_files/insulation_image": "collect-optional", //attic may not be accessible
    "assessment_files/crawlspace_image": "collect-optional", //crawspace may not exist or be accessible
    "assessment_files/primary_cooling_image": "collect-optional", //may not exist
    "assessment_files/siding_image": "collect-optional", //may not exist
    // 'assessment_files/secondary_cooling_image': 'collect-optional', //may not exist, and don't even want to prompt if missing
    // 'assessment_files/secondary_heating_image': 'collect-optional',
};

// export const ADMIN_ROLLS: Array<UserRole> = ["admin", "contractor-admin"];
export const UserRolesSchema = z.enum(["admin", "contractor-admin", "contractor", "homeowner"]);
export type UserRole = z.infer<typeof UserRolesSchema>;

export const ProjectEventEnumSchema = z.enum(["install"]);
export const ProjectEventSchema = z.object({
    date: z.string(),
    type: ProjectEventEnumSchema,
    calendarId: z.string(),
    eventId: z.string(),
});
export const ProjectEventsSchema = z.record(z.string(), ProjectEventSchema);
export type ProjectEventEnum = z.infer<typeof ProjectEventEnumSchema>;
export type ProjectEvents = z.infer<typeof ProjectEventsSchema>;

export const SeeAirPermissionSchema = z.object({
    id: z.string(),
    access: z.enum(["read", "read-write"]),
});
export type SeeAirPermission = z.infer<typeof SeeAirPermissionSchema>;

export const ProjectLineItemsSchema = z.record(z.string(), z.object({
    line_items: RecommendationLineItemsSchema,
    discounts: RecommendationDiscountsSchema
}))
export type ProjectLineItems = z.infer<typeof ProjectLineItemsSchema>

export type SparseRecommendation={original_rec_id:string,line_items?:RecommendationLineItems|null|undefined,discounts?:RecommendationDiscounts|null|undefined}

export const IndivdualBlowerDoorTestSchema=z.object({
    blowerDoorMeasurement_cfm: z.number()
})
export const BlowerDoorTestSchema = z.object({
    v:z.literal('1'),
    testIn: IndivdualBlowerDoorTestSchema.nullish(),
    testOut: IndivdualBlowerDoorTestSchema.nullish(),
    skipped: z.boolean().nullish()
})
export type BlowerDoorTest = z.infer<typeof BlowerDoorTestSchema>


/*
CST
*/
export const CombustionSafetyHeatingTestHeatingSchema = z.object({
    passSpillage: z.enum(["Yes", "No", "N/A"]),
    standaloneDraft_pa: z.number().nullable(),
    carbonMonoxide_ppm: z.number().nullable(),
    ambientCazCarbonMonoxide_ppm: z.number().nullable()
})
export const CombustionSafetyTestHotWaterSchema = z.object({
    passSpillage: z.enum(["Yes", "No", "N/A"]),
    standaloneDraft_pa: z.number().nullable(),
    passSpillageWithHeatingFiring: z.enum(["Yes", "No", "N/A"]),
    draftWithHeatingSystem_pa: z.number().nullable(),
    carbonMonoxideWithoutHeatingSystem_ppm: z.number().nullable(),
    carbonMonoxideWithHeatingSystem_ppm: z.number().nullable()
})
export const CombustionSafetyTestNaturalSchema = z.object({
    dhwPassSpillage: z.enum(["Yes", "No", "N/A"]),
    dhwStandaloneDraftWithoutHeatingSystem_pa: z.number().nullable(),
    dhwStandaloneDraftWithHeatingSystem_pa: z.number().nullable(),
    dhwSpillageWithHeatingSystem: z.enum(["Yes", "No", "N/A"]),
    heatingPassSpillage: z.enum(["Yes", "No", "N/A"]),
    heatingStandaloneDraft_pa: z.number().nullable()
})
export const CombustionSafetyPrepSchema = z.object({ //step index 0
    dryer: z.enum(["None", "On (CAZ)", "On (Living Space)"]),
    bathFans: z.number(),
    kitchenFan: z.enum(["None", "On", "Recirculating"]),
    airHandler: z.enum(["None", "On", "Off"]),
    doorToCAZ: z.enum(["Open", "Closed"]),
    natWinterTime_pa: z.number(),
    worstCase_pa: z.number(),
    changeInPressure_pa: z.number(),
    exteriorTemp_F: z.number(),
    minAllowableDraft_pa: z.number()
})
export const CombustionSafetySafetySchema = z.object({ //step index 1
    coDetector: z.enum(["Yes", "No"]),
    unburnedGasOdor: z.enum(["Yes", "No"]),
    corrodedFluePipes: z.enum(["Yes", "No"]),
    unventedGasAppliances: z.enum(["Yes", "No"]),
    natDraftEquipmentIssues: z.enum(["Yes", "No"])
})
export const CombustionSafetyHotWaterSchema = z.object({ 
    fuel: z.enum(["Natural Gas", "Oil", "Propane", "Electric"]),
    type: z.enum(["Standalone Tank", "Indirect Tank", "Tankless Coil", "On-Demand", "Combi"]),
    ventMaterial: z.enum(["Galvanized", "B-Vent", "Stainless", "PVC", "Corrugated"]),
    ventPressure: z.enum(["Atmospheric", "Fan Assisted", "Forced Draft"]),
    ventTermination: z.enum(["Chimney", "Side Wall", "Inaccessible"])
})
export const CombustionSafetyHeatingSchema = z.object({ //step index 3
    fuel: z.enum(["Natural Gas", "Oil", "Propane", "Electric"]),
    type: z.enum(["Steam Boiler", "Hydronic Boiler", "Furnace", "All Electric"]),
    ventMaterial: z.enum(["Galvanized", "B-Vent", "Stainless", "PVC", "Corrugated"]),
    ventPressure: z.enum(["Atmospheric", "Fan Assisted", "Forced Draft"]),
    ventTermination: z.enum(["Chimney", "Side Wall", "Inaccessible"]),
})
const CombustionSafetyTestResultEnumSchema = z.enum(["Pass", "Pass w/ Disclosure", "Fail", "Incomplete", "All Electric"])
type CombustionSafetyTestResultEnum = z.infer<typeof CombustionSafetyTestResultEnumSchema>
export const CombustionSafetyResultSchema = z.object({ 
    result:CombustionSafetyTestResultEnumSchema,
    tester_email: z.string().nullish(),
    date: z.string().nullish(),
})
export const CombustionSafetyTestSchema = z.object({
    v:z.literal('1'),
    prep: CombustionSafetyPrepSchema.partial(), // 0
    safety: CombustionSafetySafetySchema.partial(), // 1
    hotWaterInfo: CombustionSafetyHotWaterSchema.partial(),// 2
    hotWaterTestIn: CombustionSafetyTestHotWaterSchema.partial(),// 3
    heatingInfo: CombustionSafetyHeatingSchema.partial(),// 4
    heatingIn:CombustionSafetyHeatingTestHeatingSchema.partial(),// 5
    hotWaterTestOut: CombustionSafetyTestHotWaterSchema.partial(), // 6
    heatingOut:CombustionSafetyHeatingTestHeatingSchema.partial(),// 7
    naturalIn: CombustionSafetyTestNaturalSchema.partial().nullish(),// 8
    naturalOut: CombustionSafetyTestNaturalSchema.partial().nullish(),// 9
    result: CombustionSafetyResultSchema.partial()// 10
})
export type CombustionSafetyTest = z.infer<typeof CombustionSafetyTestSchema>
export const PASSING_CST_RESULTS: Array<CombustionSafetyTestResultEnum> = ["All Electric", "Pass", "Pass w/ Disclosure"]

export const CombustionSafetyTabsSchema = z.enum(["prep","safety","hotWaterInfo","hotWaterTestIn","heatingInfo","heatingIn","hotWaterTestOut","heatingOut","naturalIn","naturalOut","result"])
export type CombustionSafetyTabs = z.infer<typeof CombustionSafetyTabsSchema>