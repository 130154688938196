import {z} from "zod";
import {extendZodWithOpenApi} from '@asteasolutions/zod-to-openapi';
import {
    AssessmentSchema,
    GetRecommendationsSchema,
    GetSharesSchema,
    HomeSchema,
    ProjectAggregateSchema,
    SeeAirUserSchema
} from "./refined-tables.js";
import {
    AddressSchema,
    AssessmentFileSchema, AssessmentFilesEnumSchema, AssessmentStatusSchema,
    HomeDetailsSchema,
    ProjectFileSchema,
    RecDataSchema,
    RecommendationDiscount,
    RecommendationDiscounts,
    RecommendationDiscountSchema,
    RecommendationFinancialsSchema,
    RecommendationLineItemSchema,
    SeeAirPermission
} from "./jsonb-schemas.js"
import {Message} from "@aws-sdk/client-sqs";


extendZodWithOpenApi(z);

export const KnownHomeDetailsKeys = {
    Beds: 'beds',
    Baths: 'baths',
    Sqft: 'sqft',
    Floors: 'floors',
    Cooling: 'cooling',
    Heating: 'heating',
} as const


extendZodWithOpenApi(z);

export type RRecommendationData = z.infer<typeof RecommendationFinancialsSchema>
export const RecInputSchema = z.object({
    original_rec_id: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    category: z.string(),
    type: z.string(),
    rec_data: RecDataSchema,
    discounts: z.array(RecommendationDiscountSchema),
    line_items: z.array(RecommendationLineItemSchema)
})
export type RecInputData = z.infer<typeof RecInputSchema>
export const RecManualInputDataSchema = RecInputSchema.merge(z.object({
    category: z.string().optional(),
    type: z.string().optional(),
}))

export type RecManualInputData = z.infer<typeof RecManualInputDataSchema>


export type LocationAssessment = {
    locationName: string
    infiltration: number
    insulation: number
    electrification: number
}


export const AwairDataSchema = z.record(z.string(), z.array(z.any()))


// export const UserSchema = z.object({
//     email: z.string(),
//     id: z.string(),
//     name: z.string().nullish(),
//     image: z.string().nullish(),
//     role: UserRolesSchema.nullish(),
// })
//
// export type User = z.infer<typeof UserSchema>

export const RentcastPropertiesSchema = z.object({
    // formattedAddress:z.string(),
    // addressLine1:z.string(),
    // addressLine2:z.string().nullish(),
    // city:z.string(),
    // state:z.string(),
    // zipCode:z.string(),
    // county:z.string(),
    // latitude:z.number(),
    // longitude:z.string(),
    bedrooms: z.number().optional(),
    bathrooms: z.number().optional(),
    yearBuilt: z.number().optional(),
    squareFootage: z.number().optional(),
    features: z.object({
        fireplace: z.boolean().optional(),
        floorCount: z.number().optional(),
        // heating:z.boolean(),
        // cooling:z.boolean(),
        // roofType:z.string(),
        // coolingType:z.string(),
        // heatingType:z.string(),
    }).optional()
})
export type RentcastProperties = z.infer<typeof RentcastPropertiesSchema>


export const RecommendationTemplateSchema = z.object({
    id: z.string(),
    title: z.string(),
    icon: z.string().nullish(),
    deal: z.enum(["No Deal", "Deal"]),
    type: z.enum(["DIY", "PRO"]),
    healthCategory: z.number().max(3),
    climateCategory: z.number().max(3),
    priceCategory: z.number().max(3),
    category: z.enum(["Electrification", "Insulation", "Infiltration"]),
    description: z.string().nullish(),
    healthSummary: z.string().nullish(),
    climateSummary: z.string().nullish(),
    priceSummary: z.string().nullish(),
    healthDescription: z.string().nullish(),
    climateDescription: z.string().nullish(),
    priceDescription: z.string().nullish(),
    prerequisites: z.array(z.string()),
    projectButtonText: z.string().nullish(),
    projectImageUrl: z.string().nullish(),
    projectUrl: z.string().nullish(),
    termsDocId: z.string().nullish(),
    installationPrep: z.string().nullish(),
})
export type RecommendationTemplate = z.infer<typeof RecommendationTemplateSchema>

export const RecommendationPartConfigSchema = z.object({
    id:z.string(),
    recommendation_id:z.string(),
    partTitle:z.string(),
    installationPrep:z.string()
})
export type RecommendationPartConfig = z.infer<typeof RecommendationPartConfigSchema>

export const ProjectFileUploadNonceSchema = z.object({
    project_id: z.string(),
    home_id: z.string(),
    fileRecord: ProjectFileSchema.omit({file_id: true})
})
export type ProjectFileUploadNonce = z.infer<typeof ProjectFileUploadNonceSchema>
export const AssessmentFileUploadNonceSchema = z.object({
    assessment_id: z.string(),
    fileRecord: AssessmentFileSchema.omit({file_id: true})
})
export type AssessmentFileUploadNonce = z.infer<typeof AssessmentFileUploadNonceSchema>
export const FileUpdateNonceSchema = z.object({
    assessment_id: z.string(),
    fileRecord: AssessmentFileSchema
})
export type FileUpdateNonce = z.infer<typeof FileUpdateNonceSchema>
export const FileDeleteNonceSchema = z.object({
    assessment_id: z.string(),
    file_id: z.string()
})
export type FileDeleteNonce = z.infer<typeof FileDeleteNonceSchema>

export const AuthzRolesSchema = z.enum(['site_admin', 'owner', 'write', 'read'])
export type AuthzRoles = z.infer<typeof AuthzRolesSchema>
export const HomeAuthorizationSchema = z.object({
    home: z.set(AuthzRolesSchema),
    projects: z.record(z.string(), z.set(AuthzRolesSchema)),
})
export type HomeAuthorization = z.infer<typeof HomeAuthorizationSchema>

export function getEmptyHomeAuthorization(): HomeAuthorization {
    return {
        home: new Set<AuthzRoles>([]),
        projects: {}
    }
}

export function getHomeAuthorizationAllEntities(projectIds: Array<string>,withHomeRoles:Array<AuthzRoles>, withProjectRoles: Array<AuthzRoles>): HomeAuthorization {
    const homeRoles = new Set<AuthzRoles>(withHomeRoles)
    const projectRoles = new Set<AuthzRoles>(withProjectRoles)
    return {
        home: homeRoles,
        projects: projectIds.reduce((acc, v) => ({...acc, [v]: projectRoles}), {})
    }
}

export function mergePermissionMaps(permissions1:Record<string,SeeAirPermission>, permissions2:Record<string,SeeAirPermission>):Record<string,SeeAirPermission> {
    const readOnly = [
        ...(Object.values(permissions1).filter(p=>p.access == 'read')),
        ...(Object.values(permissions2).filter(p=>p.access == 'read')),
    ]
    const readWrite = [
        ...(Object.values(permissions1).filter(p=>p.access == 'read-write')),
        ...(Object.values(permissions2).filter(p=>p.access == 'read-write')),
    ]
    return [...readOnly,...readWrite].reduce((acc,v)=>({...acc,[v.id]:v}),{})
}

export function getHomeAuthorizationFromSharePermissions(projectIds: Array<string>, accessLevels: Array<'read' | 'read-write' | undefined>, projectPermissions: Record<string,SeeAirPermission>): HomeAuthorization {
    const homeAuth = new Set<AuthzRoles>(accessLevels
        .filter(a => a)
        .flatMap(a => a == 'read-write' ? ['read','write'] : ['read']) as Array<'read' | 'write'>)
    console.log(`homeAuth: ${JSON.stringify(Array.from(homeAuth))}`)
    if (projectPermissions["*"]?.access == 'read-write') {
        return {
            home: homeAuth,
            projects: projectIds.reduce((acc, v) => ({
                ...acc,
                [v]: new Set<AuthzRoles>(['write', 'read'])
            }), {})
        }
    } else if (projectPermissions["*"]?.access == 'read') {
        return {
            home: homeAuth,
            projects: projectIds.reduce((acc, v) => ({
                ...acc,
                [v]: new Set<AuthzRoles>(['read'])
            }), {})
        }
    } else {
        return {
            home: homeAuth,
            projects: (Object.values(projectPermissions ?? {})).reduce((acc, v) => ({
                ...acc,
                [v.id]: new Set<AuthzRoles>(v.access == 'read-write'?['read','write']:['read'])
            }), {} as Record<string, Set<AuthzRoles>>)
        }
    }
}

export const AssessmentUpdateEventSchema = z.enum([
    "file-saved",
    "document-saved",
    "meeting-saved",
    "meeting-removed",
    "note-saved",
    "home-details-updated",
    "energy-data-saved",
    "assessment-data-updated",
    "solar-data-saved",
    "rec-added",
    "rec-deleted",
    "rec-updated",
    "status-changed",
    "data-requirements-changed",
    "project-updated",
    "project-deleted",
    'assessment-project-number-updated',
    'assessment-hubspot-id-updated',
    "check-document-for-completeness",
    "payment-saved"
])
export type AssessmentUpdateEvent = z.infer<typeof AssessmentUpdateEventSchema>

export const ExternalSyncRecordSchema = z.object({
    event: AssessmentUpdateEventSchema,
    assessment_id: z.string(),
    associated_id: z.string().nullish()
})
export type ExternalSyncRecord = z.infer<typeof ExternalSyncRecordSchema>


export const RecommendationAggregateSchema = GetRecommendationsSchema
    .omit({rec_data: true})
    .merge(z.object({rec_data: RecDataSchema}))
    .openapi("recommendation")
export type RecommendationAggregate = z.infer<typeof RecommendationAggregateSchema>
export const HomeOwnerSchema = SeeAirUserSchema.pick({
    name: true,
    email: true,
})
export type HomeOwner = z.infer<typeof HomeOwnerSchema>
export const HomeAggregateSchema = HomeSchema.merge(z.object({
    owner: HomeOwnerSchema,
    shares: z.array(GetSharesSchema.openapi("share")),
    assessments: z.array(AssessmentSchema.openapi("assessment")),
    /* for the life of me i can't figure out why we need to omit, then re-merge `rec_data` if we don't to this it shows up as a ZodLazy (with incorrect fields) to our code generator*/
    recommendations: z.array(RecommendationAggregateSchema).openapi("recommendations"),
    projects: z.array(ProjectAggregateSchema.openapi("projects")),
    // permissions: z.set(AuthzRolesSchema).openapi("permissions", {type: "object"}),
    authorization: HomeAuthorizationSchema.openapi("authorization", {type: "object"})
})).openapi("home")
export type HomeAggregate = z.infer<typeof HomeAggregateSchema>


export const AdminAssessmentSummarySchema = z.object({
    owner_email:z.string(),
    owner_name:z.string(),
    address:AddressSchema,
    home_id:z.string(),
    assessment_id:z.string(),
    assessment_status:AssessmentStatusSchema,
    aggregate_last_modified:z.number()
})
export type AdminAssessmentSummary = z.infer<typeof AdminAssessmentSummarySchema>
export const HomeDetailsFieldInfoSchema = z.object({
    type: z.string(),
    id: z.string(),
    title: z.string(),
    default: z.string().optional(),
    options: z.array(z.string())
})
export type HomeDetailsFieldInfo = z.infer<typeof HomeDetailsFieldInfoSchema>


export const AddressComponentTypeSchema = z.enum(["subpremise", "street_number", "route", "neighborhood", "locality", "administrative_area_level_2", "administrative_area_level_1", "country", "postal_code", "political"])
export type AddressComponentType = z.infer<typeof AddressComponentTypeSchema>
export const PlacesAddressSchema = z.object({
    formattedAddress: z.string(),
    location: z.object({
        latitude: z.number(),
        longitude: z.number(),
    }),
    addressComponents: z.array(z.object({
        shortText: z.string(),
        types: z.array(z.string()) //this should be AddressComponentTypeSchema, but we don't want to fail if we get unexpected options
    }))
})
export type PlacesAddress = z.infer<typeof PlacesAddressSchema>
export const NormalizedAddressSchema = z.object({
    normalizedComparable: z.string()
}).merge(PlacesAddressSchema)
export type NormalizedAddress = z.infer<typeof NormalizedAddressSchema>


export type Sheet = Array<Array<string>>


export const Dynamic3dViewerConfigSchema = z.object({
    buildingNodeNames: z.array(z.string()),
    hideToolbar: z.boolean()
})
export type Dynamic3dViewerConfig = z.infer<typeof Dynamic3dViewerConfigSchema>


export type ProformaCost = { type: 'range', high: number, low: number } | { type: 'single', cost: number }
// export type ProformaDiscount = ProformaCost | { type:'percent', percentage:number,cap:number}
export type ProformaPayback = { type: 'range', high: number, low: number } | { type: 'single', years: number }
export type ProformaIncentive = {
    title: string,
    description?: string,
    timeOfPurchase: boolean,
    cost: ProformaCost,
    url?: string,
    recNumbers: Array<string>
}
export type CostedProformaIncentive = ProformaIncentive & { cost: { type: 'single', cost: number } }
export type ProformaItem = {
    title: string,
    price_per_unit: number,
    quantity: number,
    recNumbers: Array<string>
}
export type ProformaDataSource = "estimate"|"quote"|"completed"
export type ProformaData = {
    lineItems: Array<ProformaItem>
    gross: ProformaCost
    incentives: Array<ProformaIncentive>
    annual_savings: ProformaCost
    source: ProformaDataSource
}
export function formatProformaDataSource(s:ProformaDataSource):string {
    switch (s) {
        case "completed": return "Final Cost"
        case "estimate": return "Estimated Cost"
        case "quote": return "Quoted Cost"
    }
}


export type LocationId = 'MA' | 'CA'

export type NormalizedEnergyUsageMonth = {
    date: number,
    electric_usage: number,
    electric_cost: number,
    gas_usage: number,
    gas_cost: number,
    oil_usage: number,
    oil_cost: number
}
export type NormalizedEnergyUsage = { [firstOfMonth: string]: NormalizedEnergyUsageMonth }

        
export const AdminProjectTabSchema = z.enum([
    'design','quote','permitting','scheduling','implementation','collection','wrap'
])
export type AdminProjectsTab = z.infer<typeof AdminProjectTabSchema>

export const AdminAssessmentTabSchema = z.enum([
    'isms','home_details','assessment_files', 'utilities', 'recommendations', 'projects','documents','other'
])
export const AdminOtherTabsSchema = z.enum(['address', 'solar','data_collection','permissions' ])
export type AdminAssessmentTab = z.infer<typeof AdminAssessmentTabSchema>
export const ContractorTabSchema = z.enum([
    'viewer', 'plan', 'files','documents','weatherization'
])
export type ContractorTab = z.infer<typeof ContractorTabSchema>
export const HomeOwnerTabSchema = z.enum([
    'energy', 'plan', 'viewer', 'share','documents'
])
export type HomeOwnerTab = z.infer<typeof HomeOwnerTabSchema>
export const AdminAssessmentPageSearchSchema = z.object({tab: AdminAssessmentTabSchema, selected: z.string().optional()})
export type AdminAssessmentPageSearch = z.infer<typeof AdminAssessmentPageSearchSchema>
export const AdminProjectPageSearchSchema = z.object({tab: AdminProjectTabSchema.optional()}).optional()
export type AdminProjectPageSearch = z.infer<typeof AdminProjectPageSearchSchema>
export const ContractorHomePageSearchSchema = z.object({project: z.string().optional(), tab:ContractorTabSchema.optional()})
export type ContractorHomePageSearch = z.infer<typeof ContractorHomePageSearchSchema>
export const HomeOwnerHomePageSearchSchema = z.object({project: z.string().optional(), tab:HomeOwnerTabSchema.optional()})
export type HomeOwnerHomePageSearch = z.infer<typeof HomeOwnerHomePageSearchSchema>

export type DlqMessage = Message & { QueueUrl: string }


export const AdminComboboxOptionSchema = z.object({ name: z.string(), id: z.string(), group: z.string().nullish() });
export type AdminComboboxOption = z.infer<typeof AdminComboboxOptionSchema>



export type CommonUILineItem = {
        line_item_id: string,
        name: string,
        quantity: number,
        description?: string | null,
        price_per_unit?: number,
        product_id: string,
        discounts?: Record<string,RecommendationDiscount>,
}
export type CommonUILineItems = Record<string,CommonUILineItem>
export type CommonUIProjectLineItems = Record<string,{
    line_items:CommonUILineItems,
    discounts:RecommendationDiscounts
}>
export const AiTestingRecordSchema = z.object({
    presigned_s3_url:z.string().nullish(),
    s3_url:z.string(),
    home_details:HomeDetailsSchema,
    assessment_file_type:AssessmentFilesEnumSchema,
    inferred_home_details:HomeDetailsSchema.nullish(),
    inferred_assessment_file_type:AssessmentFilesEnumSchema.nullish()
})
export type AiTestingRecord=z.infer<typeof AiTestingRecordSchema>
export const AiTestingSummarySchema = z.object({
    type:z.string(),
    total:z.number(),
    attempted:z.number(),
    correct:z.number()
})
export type AiTestingSummary=z.infer<typeof AiTestingSummarySchema>

export type ValidationResponse = { valid: true } | { valid: false, errors: Array<string> }
export function coalesceValidationResponses(validationResponses: Array<ValidationResponse>): ValidationResponse {
    const allValid = validationResponses.every(o => o.valid)
    if (allValid) {
        return {valid: true}
    } else {
        return {valid: false, errors: validationResponses.flatMap(o => o.valid ? [] : o.errors)}
    }
}
export const SafetyResponseSchema = z.object({
    safety_issue_present: z.boolean(),
    explanation: z.string().nullish(),
})
export type SafetyResponse = z.infer<typeof SafetyResponseSchema>

