import { Combobox, InputBase, useCombobox } from "@mantine/core";
import React, { useState } from "react";
import {AdminComboboxOption} from "@seeair/schemas";


export function AdminCombobox({
                                  options,
                                  onChange,
                                  disabled,
                                  initiallySelectedId,
                                  label,
                                  classNames
                              }: {
    initiallySelectedId?: string;
    disabled: boolean;
    options: Array<AdminComboboxOption>;
    onChange: (o: AdminComboboxOption) => void;
    label?: string;
    classNames?: string;
}) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption()
    });
    const [search, setSearch] = useState("");
    const searchWords = search.toLowerCase().trim().split(" ");
    const initiallySelected: AdminComboboxOption | undefined = options.find(
        (o) => o.id == initiallySelectedId
    );
    const shouldFilterOptions = options.every((p) => p.name !== search);
    const filteredOptions = shouldFilterOptions
        ? options.filter((p) => {
            return searchWords.length == 0 || searchWords.every((w) => p.name.toLowerCase().includes(w));
        })
        : options;
    const groupedOptions = filteredOptions
        .reduce(
            (acc, v) => {
                const newAcc = { ...acc };
                const group = v.group ?? "";
                const newGroupMembers = [...(newAcc[group] ?? [])];
                newGroupMembers.push(v);
                newAcc[group] = newGroupMembers;
                return newAcc;
            },
            {} as { [group: string]: Array<AdminComboboxOption> });
    const groupedOptionsList: Array<{ group: string, options: Array<AdminComboboxOption> }> =
        Object.keys(groupedOptions)
            .map(g => ({ group: g, options: groupedOptions[g]! }))
            .sort((g1, g2) => g1.group.localeCompare(g2.group));
    return (
        <Combobox
            disabled={disabled}
            store={combobox}
            withinPortal={false}
            onOptionSubmit={(val) => {
                onChange(options.find((p) => `${p.name}-${p.id}` == val)!);
                setSearch("");
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target>
                <InputBase
                    className={classNames}
                    disabled={disabled}
                    label={label}
                    rightSection={<Combobox.Chevron />}
                    value={search}
                    onChange={(event) => {
                        combobox.openDropdown();
                        combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                        combobox.selectFirstOption();
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => {
                        combobox.closeDropdown();
                        setSearch("");
                    }}
                    placeholder={initiallySelected?.name ?? "Search value"}
                    rightSectionPointerEvents={"none"}
                />
            </Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Options>
                    {groupedOptionsList.length > 0
                        ? (groupedOptionsList.map(g => (<Combobox.Group label={g.group} key={g.group}>
                            {g.options.map(o => (<Combobox.Option value={`${o.name}-${o.id}`} key={o.id}>
                                {o.name}
                            </Combobox.Option>))}
                        </Combobox.Group>)))
                        : (<Combobox.Empty>Nothing found</Combobox.Empty>)
                    }
                    {/*{filteredOptions.length > 0 ? (*/}
                    {/*    filteredOptions.map((p) => (*/}
                    {/*        <Combobox.Option value={`${p.name}-${p.id}`} key={p.id}>*/}
                    {/*            {p.name}*/}
                    {/*        </Combobox.Option>*/}
                    {/*    ))*/}
                    {/*) : (*/}
                    {/*    <Combobox.Empty>Nothing found</Combobox.Empty>*/}
                    {/*)}*/}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}