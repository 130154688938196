import { Menu, Loader, Divider, Modal } from "@mantine/core"
import React, { useState } from "react"
import { IconEye, IconRowInsertBottom } from "@tabler/icons-react"
import { HomeAggregate, ProjectAggregate } from "@seeair/schemas"
import { trpc, getUseMutationOpt, VStack, HStack, TextLg, DesignedIconButton, ProjectIcon, RecommendationsAccordion } from "@seeair/shared-components"
import { RecControl } from './AdminRecommendationsPanel.js'
import { AdminProjectLineItemsSyncPanel } from "./AdminProjectLineItemsSyncPanel.js"

export function AdminProjectPanelRecommendationsSection({ project, home }: { home: HomeAggregate, project: ProjectAggregate}) {
    const [previewModalOpen,setPreviewModalOpen] = useState(false)
    const { isPending: isSetProjectPending, mutate: setProject } =
        trpc.ADMIN.setProjectForRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <HStack leftCenter>
            <TextLg>Recommendations</TextLg>
            <Menu>
                <Menu.Target>
                    <div>
                        <DesignedIconButton
                            tooltip={"Add recommendation to project"}
                            icon={
                                isSetProjectPending ? <Loader size={"sm"} /> : <IconRowInsertBottom />
                            }
                            disabled={isSetProjectPending}
                            onClick={() => { }}
                        />
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Ungrouped Recommendations</Menu.Label>
                    {(home.recommendations ?? [])
                        .filter((r) => !r.project_id)
                        .map((r) => (
                            <Menu.Item
                                key={r.recommendation_id}
                                leftSection={<ProjectIcon sm rec_number={r.original_rec_id} />}
                                onClick={() =>
                                    setProject({
                                        home_id: project.home_id,
                                        recommendation_id: r.recommendation_id,
                                        project_id: project.project_id,
                                        selected: true,
                                    })
                                }
                            >
                                {r.title}
                            </Menu.Item>
                        ))}
                </Menu.Dropdown>
            </Menu>
        </HStack>
        {(project.recommendations ?? []).map((r) => (
            <HStack key={r.recommendation_id}>
                <RecControl
                    rec={r}
                    proj={project}
                    assessment={home.assessments.find(a => a.assessment_id == r.assessment_id)!}
                    recLink={true}
                    projLink={false}
                    col={false}
                    showRemove={true}
                    showDelete={false}
                />
            </HStack>
        ))}
        <Divider size={"xl"} label={"Sync & Preview"} labelPosition={"center"} />
        <DesignedIconButton
                        tooltip={"Preview For Homeowner"}
                        disabled={false}
                        icon={<IconEye />}
                        stopPropagation
                        onClick={() => {
                            setPreviewModalOpen(true)
                        }}
                    />
        <AdminProjectLineItemsSyncPanel home={home} project={project} />

        <Modal
            title={project.project_title}
            fullScreen
            opened={previewModalOpen}
            onClose={() => setPreviewModalOpen(false)}
        >
            <RecommendationsAccordion
                hideAdminItems={false}
                projects={[project!]}
                recs={project.recommendations}
                filteredRecs={[]}
                includeProjects={true}
                isContractor={false}
            />
        </Modal>
    </VStack>
}
