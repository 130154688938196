import { Divider, Modal, NumberInput } from "@mantine/core";
import { IconCancel, IconRefresh } from "@tabler/icons-react";
import { ProjectAggregate, SeeAirUser, HomeAggregate } from "@seeair/schemas";
import { trpc, getUseMutationOpt, VStack, AdminPillsCombobox, EditableCalendar, TextError, HStack, DesignedIconButton, TextBase, AdminDocumentsTable } from "@seeair/shared-components";
import React, { useState } from "react";
import { documentExistsInProject } from "@seeair/shared";


export function AdminProjectPanelSchedulingSection({
    project, home, contractors
}: {
    project: ProjectAggregate;
    home: HomeAggregate;
    contractors: Array<SeeAirUser>;
}) {
    const [taskOrderModalOpen, setTaskOrderModalOpen] = useState(false)
    const [basePercent, setBasePercent] = useState(0.82)
    const [retentionPercent, setRetentionPercent] = useState(0.05)
    const {
        isPending: isSetHomeownerAvailabilityPending, isSuccess: isSetHomeownerAvailabilitySuccess, mutate: setHomeownerAvailability,
    } = trpc.HOMEOWNER.setHomeownerAvailabilityForProject.useMutation(getUseMutationOpt(trpc.useUtils()));
    const {
        isPending: isSetScheduledDatesPending, isSuccess: isSetScheduledDatesSuccess, mutate: setScheduledDates,
    } = trpc.ADMIN.setScheduledDatesForProject.useMutation(getUseMutationOpt(trpc.useUtils()));
    const {
        isPending: isSetCrewPending, isSuccess: isSetCrewSuccess, mutate: mutateCrew,
    } = trpc.ADMIN.setProjectCrew.useMutation(getUseMutationOpt(trpc.useUtils()));
    const {
        isPending: isResyncCalPending, isSuccess: isResyncCalSuccess, mutate: resyncCal,
    } = trpc.ADMIN.syncCalendarInvites.useMutation(getUseMutationOpt(trpc.useUtils()));
    const contractorOptions = contractors.map((c) => ({ name: c.name ?? c.email, id: c.email }));
    const { isPending: isGenerateTaskOrderPending, mutate: generateTaskOrder } =
        trpc.ADMIN.publishTaskOrder.useMutation(getUseMutationOpt(trpc.useUtils(), () => { setTaskOrderModalOpen(false) }))
    const taskOrderAlreadyExists = documentExistsInProject(project, "task-order")
    const emptyCrew = (project.project_crew ?? []).length == 0
    const crewChief = (project.project_crew ?? [])[0]
    return (
        <VStack>
            <EditableCalendar
                alwaysEditing={false}
                shouldDisableDay={(_) => ""}
                title={"Homeowner Availability"}
                initialValue={project.homeowner_availability ?? []}
                isPending={isSetHomeownerAvailabilityPending}
                isSuccess={isSetHomeownerAvailabilitySuccess}
                save={(dates) => setHomeownerAvailability({
                    home_id: project.home_id,
                    project_id: project.project_id,
                    homeowner_availability: dates,
                })} />

            <Divider size={"xl"} label={"Assign & Schedule Crew"} labelPosition={"center"} />
            <AdminPillsCombobox
                disabled={false}
                alwaysEditing={false}
                isPending={isSetCrewPending}
                isSuccess={isSetCrewSuccess}
                save={(values) => {
                    mutateCrew({
                        project_id: project.project_id,
                        project_crew: values.map((o) => o.id),
                    });
                }}
                label={"Crew Emails"}
                initiallySelectedIds={project.project_crew ?? []}
                options={contractorOptions}
                // onChange={(newCrew) => setCrewEmails(newCrew.map((o) => o.id))}
                allowNewOption
                placeholder={"Crew Emails"}
                newOptionValidation={/.+@.+\..+/}
                newOptionValidationMessage={"Invalid Email"} />
            <HStack><DesignedIconButton
                icon={<IconRefresh />} onClick={() => resyncCal({ project_id: project.project_id })} disabled={isSetCrewPending || isResyncCalPending || isSetScheduledDatesPending} tooltip={"Resync GCal events"}
            /></HStack>
            <TextBase>Crew Chief: {crewChief}</TextBase>
            <HStack>
                <DesignedIconButton
                    icon={<TextBase>Configure Task Order</TextBase>}
                    onClick={() => setTaskOrderModalOpen(true)}
                    disabled={emptyCrew || taskOrderAlreadyExists}
                    tooltip={taskOrderAlreadyExists ? "Doc Already Exists" : "Generate Task Order"}
                />
            </HStack>
            {emptyCrew && (
                <TextError classNames={"m-8"}>Must set crew before scheduling</TextError>
            )}
            <EditableCalendar
                disabled={emptyCrew}
                alwaysEditing={false}
                shouldDisableDay={(d) => {
                    if (!project.homeowner_availability?.includes(d)) {
                        return "Homeowner Unavailable";
                    }
                    return "";
                }}
                title={"Scheduled Dates"}
                initialValue={Object.keys(project.scheduled_dates ?? {})}
                isPending={isSetScheduledDatesPending}
                isSuccess={isSetScheduledDatesSuccess}
                save={(dates) => setScheduledDates({ project_id: project.project_id, scheduled_dates: dates })} />

            <Divider size={"xl"} label={"Task Orders"} labelPosition={"center"} />
            <AdminDocumentsTable home={home} project={project} filter={["task-order"]} />
            <Modal
                opened={taskOrderModalOpen}
                title={"Generate Task Order"}
                onClose={() => setTaskOrderModalOpen(false)}
            >
                <TextBase>Crew Chief: {crewChief}</TextBase>

                <NumberInput
                    label={"Base Percent"}
                    min={1}
                    max={100}
                    clampBehavior={"strict"}
                    allowDecimal={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"%"}
                    value={basePercent * 100}
                    onChange={(value) => {
                        setBasePercent((typeof value == "string" ? parseFloat(value) : value) / 100)
                    }}
                />
                <NumberInput
                    label={"Retention Percent"}
                    min={1}
                    max={100}
                    clampBehavior={"strict"}
                    allowDecimal={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"%"}
                    value={retentionPercent * 100}
                    onChange={(value) => {
                        setRetentionPercent((typeof value == "string" ? parseFloat(value) : value) / 100)
                    }}
                />
                <HStack between>
                    <DesignedIconButton
                        icon={<TextBase>Create Task Order 🐼</TextBase>}
                        onClick={() => {
                            generateTaskOrder({
                                project_id: project.project_id,
                                basePercent,
                                retentionPercent
                            })
                        }}
                        disabled={isGenerateTaskOrderPending}
                        tooltip={"Generate In Pandadoc"}
                    />
                    <DesignedIconButton
                        icon={<IconCancel />}
                        onClick={() => setTaskOrderModalOpen(false)}
                        disabled={isGenerateTaskOrderPending}
                        tooltip={"Cancel"}
                    />
                </HStack>
            </Modal>
        </VStack>
    );
}
