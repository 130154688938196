import React from "react";
import { Assessment, AssessmentDocumentEnum, HomeAggregate, ProjectAggregate, ProjectDocumentEnum } from "@seeair/schemas";
import { Loader, Table } from "@mantine/core";
import { HStack, TextBase, TextSm, VStack } from "./DesignBase.js";
import { DesignedIconButton } from "./DesignComponents.js";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import { trpc } from "./trpc.js";
import { getUseMutationOpt } from "./mutationHelper.js";
import { getAllDocuments } from "@seeair/shared";
import { Link } from "@tanstack/react-router";

export function AdminDocumentsTable({
    home,
    assessment,
    project,
    filter
}: {
    home: HomeAggregate;
    assessment?: Assessment;
    project?: ProjectAggregate;
    filter?: Array<ProjectDocumentEnum | AssessmentDocumentEnum>
}) {
    const assessmentLinkId = assessment ? assessment.assessment_id : project?.recommendations[0]?.assessment_id
    const { isPending: isDeletePending, mutate: deleteDocument } = trpc.ADMIN.deleteDocument.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    const { isPending: isPublishHpcInfoDocPending, mutate: publishHpcInfoDoc } =
        trpc.ADMIN.publishHpcInfoDoc.useMutation(getUseMutationOpt(trpc.useUtils()));
    const hpcDocAlreadyExists = !!Object.values(assessment?.assessment_documents ?? {}).find(d => d.type == 'hpc-contractor-information')
    const allDocs = getAllDocuments(home, project)
    const filteredDocs = allDocs
        .filter(d => !filter || (d.type && filter.includes(d.type)))
        .sort((d1, d2) => d1.created_date.localeCompare(d2.created_date))
    return (
        <VStack>
            <HStack leftCenter>
                {assessment && <DesignedIconButton
                    icon={<TextBase>Publish HPC Info Doc 🐼</TextBase>}
                    onClick={() => {
                        publishHpcInfoDoc({
                            home_id: home.home_id,
                            assessment_id: assessment!.assessment_id,
                        });
                    }}
                    disabled={hpcDocAlreadyExists || isPublishHpcInfoDocPending || isDeletePending}
                    tooltip={hpcDocAlreadyExists ? "HPC Info Doc Already Exists" : "Publish PandaDoc"}
                />}
                {(isPublishHpcInfoDocPending || isDeletePending) && <Loader />}
            </HStack>
            <Table striped highlightOnHover withTableBorder classNames={{ tr: "min-h-24" }}>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>For</Table.Th>
                        <Table.Th>Type</Table.Th>
                        <Table.Th>Name (Edit Link)</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th>Homeowner URL</Table.Th>
                        <Table.Th>Crew Chief URL</Table.Th>
                        <Table.Th>SeeAir Rep Link</Table.Th>
                        <Table.Th>Created</Table.Th>
                        <Table.Th></Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {filteredDocs
                        .map((d) => (
                            <Table.Tr key={d.document_id}>
                                <Table.Td>{d.for}</Table.Td>
                                <Table.Td>{d.type}</Table.Td>
                                <Table.Td><a href={`https://app.pandadoc.com/a/#/documents/${d.document_id}`} target={"_blank"} rel={"noreferrer"}>{d.name}</a></Table.Td>
                                <Table.Td>{d.status}</Table.Td>
                                <Table.Td>
                                    {d.homeowner_url && (
                                        <HStack leftCenter>
                                            <TextBase>For {d.homeowner_email} {d.homeowner_completed_date ? " ✅" : "❌"}</TextBase>
                                            <DesignedIconButton
                                                icon={<IconCopy />}
                                                onClick={async () => {
                                                    await navigator.clipboard.writeText(d.homeowner_url!);
                                                }}
                                                disabled={!d.homeowner_url}
                                                tooltip={d.homeowner_url?"Copy URL":"Missing URL"}
                                            />
                                        </HStack>
                                    )}
                                </Table.Td>
                                <Table.Td>
                                    {d.crew_chief_url && (
                                        <HStack leftCenter>
                                            <TextBase>For {d.crew_chief_email} {d.crew_chief_completed_date ? " ✅" : "❌"}</TextBase>
                                            <DesignedIconButton
                                                icon={<IconCopy />}
                                                onClick={async () => {
                                                    await navigator.clipboard.writeText(d.crew_chief_url!);
                                                }}
                                                disabled={!d.crew_chief_url}
                                                tooltip={d.crew_chief_url?"Copy URL":"Missing URL"}
                                            />
                                        </HStack>
                                    )}
                                </Table.Td>
                                <Table.Td>
                                    {d.seeair_rep_url && (
                                        <a href={d.seeair_rep_url} target={"_blank"} rel={"noreferrer"}>
                                            For {d.seeair_rep_email} {d.seeair_rep_completed_date ? "✅" : "❌"}
                                        </a>
                                    )}
                                </Table.Td>
                                <Table.Td>{d.created_date}</Table.Td>
                                <Table.Td>
                                    <DesignedIconButton
                                        icon={<IconTrash />}
                                        onClick={() =>
                                            deleteDocument({
                                                assessment_id: d.assessment_id,
                                                project_id: d.project_id,
                                                document_id: d.document_id,
                                            })
                                        }
                                        disabled={isDeletePending}
                                        tooltip={"Delete Document"}
                                    />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                </Table.Tbody>
                <Table.Tfoot>
                    <Table.Tr>
                        <Table.Td colSpan={9}>
                            <VStack
                                center>
                                <TextSm>
                                    {filter&&`filter=${JSON.stringify(filter)} `}
                                    showing {filteredDocs.length} of
                                    {assessmentLinkId && <Link
                                        to={"/admin/assessment/$assessment_id"}
                                        params={{assessment_id:assessmentLinkId}}
                                        search={{tab:"documents"}}
                                        > {allDocs.length} total</Link>}</TextSm>
                            </VStack></Table.Td>
                    </Table.Tr>
                </Table.Tfoot>
            </Table>
        </VStack>
    );
}
