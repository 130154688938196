import { Divider } from "@mantine/core";
import { ProjectAggregate, HomeAggregate } from "@seeair/schemas";
import { getLatestAssessment } from "@seeair/shared";
import { VStack, AdminDocumentsTable, BlowerDoorInputPane, CombustionSafetyTestInputPane, TextError, CertificateOfCompletionPane } from "@seeair/shared-components";
import React from "react";
import { AdminCompletedLineItemsInputPane } from './AdminCompletedLineItemsInputPane.js';


export function AdminProjectPanelImplementationSection({
    project, home
}: {
    project: ProjectAggregate;
    home: HomeAggregate;
}) {
    const assessment = getLatestAssessment(home.assessments)
    return <VStack>
        <Divider size={"xl"} label={"Combustion Safey Tests"} />
        {
            assessment
                ? <CombustionSafetyTestInputPane project={project} assessment={assessment} />
                : <TextError>Could not find assessment for project</TextError>
        }

        <Divider size={"xl"} label={"Blower Door Tests"} />
        <BlowerDoorInputPane project={project} />
        <Divider size={"xl"} label={"Finalize Line Items"} />
        <AdminCompletedLineItemsInputPane project={project} />
        <Divider size={"xl"} label={"Certificate Of Completion"} />
        <CertificateOfCompletionPane project={project}/>
        <Divider size={"xl"} label={"Documents"} />
        <AdminDocumentsTable home={home} project={project} filter={["certificate-of-completion"]} />
    </VStack>
}