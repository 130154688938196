import React, { useContext, useState } from "react";
import { Textarea, Loader, Table, Divider } from "@mantine/core";
import { IconFileDollar } from "@tabler/icons-react";
import { ProjectAggregate, HomeAggregate } from "@seeair/schemas";
import { getLineItemsWithDiscountsFromProject, calculateInvoiceTotal, formatMoney, calculateTotalPayments, PreloadedHubspotProductCatalog } from "@seeair/shared";
import { trpc, getUseMutationOpt, DesignedIconButton, VStack, HStack, AbsoluteCenterStack, AdminDocumentsTable, ProductDataContext } from "@seeair/shared-components";
import { AdminProjectPaymentsPanel } from './AdminProjectPaymentsPanel.js';
import Decimal from "decimal.js";


export function AdminProjectPanelCollectionSection({
    project, home
}: {
    project: ProjectAggregate;
    home: HomeAggregate;
}) {
    const { products } = useContext(ProductDataContext)
    const catalog = new PreloadedHubspotProductCatalog(products)
    // const [invoiceAmount, setInvoiceAmount] = useState(0)
    const [invoiceComment, setInvoiceComment] = useState("")
    const { isPending: isCreateInvoicePending, mutate: createInvoice } =
        trpc.ADMIN.createPandadocInvoiceFromProject.useMutation(
            getUseMutationOpt(trpc.useUtils()),
        )
    const lineItems = getLineItemsWithDiscountsFromProject(project,catalog)
    const invoiceTotal = calculateInvoiceTotal(lineItems)
    const paymentsTotal = calculateTotalPayments(project)
    const invoiceAmount = new Decimal(invoiceTotal).minus(paymentsTotal).toNumber()
    const CreateInvoiceButton = () => <DesignedIconButton
        disabled={isCreateInvoicePending}
        icon={<IconFileDollar />}
        onClick={() => {
            createInvoice({
                project_id: project.project_id,
                invoiceAmount,
                comment: invoiceComment,
            })
        }}
        tooltip={"Create Invoice"}
    />
    return <VStack>
        <Divider size={"xl"} label={"Invoice Preview"} labelPosition={"center"} />
        <Table className={"text-sm mb-8"}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Payment Schedule</Table.Th>
                    <Table.Th>Amount</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                <Table.Tr>
                    <Table.Td>Project Cost</Table.Td>
                    <Table.Td>{formatMoney(invoiceTotal, true)}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>Prior Payments</Table.Td>
                    <Table.Td>{formatMoney(invoiceAmount - invoiceTotal, true)}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>Due Now</Table.Td>
                    <Table.Td>{formatMoney(invoiceAmount, true)}</Table.Td>
                </Table.Tr>
            </Table.Tbody>
        </Table>
        {/* <NumberInput
            label={"Invoice Amount"}
            min={1}
            max={invoiceTotal-paymentsTotal}
            clampBehavior={"strict"}
            allowDecimal={true}
            allowNegative={false}
            allowLeadingZeros={true}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={","}
            prefix={"$"}
            value={invoiceAmount}
            onChange={(value) => {
                setInvoiceAmount(typeof value == "string" ? parseFloat(value) : value)
            }}
        /> */}
        <Textarea
            className={"w-full"}
            label={"Invoice Comments"}
            placeholder={"Leave Blank for no Comments"}
            value={invoiceComment}
            onChange={(value) => {
                setInvoiceComment(value.currentTarget.value)
            }}
        />
        <HStack between center classNames={"my-4"}>
            <CreateInvoiceButton />
        </HStack>
        {isCreateInvoicePending && (
            <AbsoluteCenterStack>
                <Loader />
            </AbsoluteCenterStack>
        )}
        <Divider size={"xl"} label={"Recieved Payments"} labelPosition={"center"} />
        <AdminProjectPaymentsPanel project={project} />
        <Divider size={"xl"} label={"Invoices & Quotes"} labelPosition={"center"} />
        <AdminDocumentsTable home={home} project={project} filter={["invoice", "quote"]} />

    </VStack>
}