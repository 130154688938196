import { CommonUIProjectLineItems, ProjectAggregate, ProjectLineItems } from "@seeair/schemas";
import React, { useContext, useState } from "react";
import { CommonLineItemTable, CommonDiscountsTable, trpc, SheetsDataContext, getUseMutationOpt, VStack, HStack, TextLg, DesignedIconButton } from '@seeair/shared-components';
import { IconFileX, IconDeviceFloppy, IconCancel, IconPencil, IconCopy } from "@tabler/icons-react";
import { Checkbox } from "@mantine/core";

export function AdminCompletedLineItemsInputPane({
    project,
}: {
    project: ProjectAggregate,
}) {
    const [skipHubspot, setSkipHubspot] = useState(false)
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(project.completed_line_items ?? project.quoted_line_items ?? {} as CommonUIProjectLineItems)
    const { recommendationsSheet } = useContext(SheetsDataContext)
    const {
        mutate: setQuotedLineItems,
        isPending: setQuotedLineItemsIsPending
    } = trpc.ADMIN.setQuotedLineItemsForProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: setCompletedLineItems,
        isPending: setCompletedLineItemsIsPending
    } = trpc.ADMIN.setCompletedLineItemsForProject.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))

    return <VStack>{
        Object.values(project.quoted_line_items ?? {}).length == 0
            ? <HStack leftCenter>
                <DesignedIconButton
                    disabled={setQuotedLineItemsIsPending}
                    icon={<IconFileX />}
                    tooltip={"Set Quoted Line Items (from recommendations)"}
                    onClick={() => {
                        setQuotedLineItems({ project_id: project.project_id,skipHubspot })
                    }}
                />

                <Checkbox
                    checked={skipHubspot}
                    onChange={(e) => setSkipHubspot(e.currentTarget.checked)}
                    label={"(danger) skip hubspot"}
                />
            </HStack>
            : <HStack hAuto rightCenter>
                {editing && <DesignedIconButton
                    tooltip={"Save Lineitems"}
                    disabled={setCompletedLineItemsIsPending}
                    icon={<IconDeviceFloppy />}
                    onClick={() => {
                        setCompletedLineItems({
                            home_id: project.home_id,
                            project_id: project.project_id,
                            completedLineItems: data as ProjectLineItems,
                            skipHubspot
                        })
                    }} />}
                <DesignedIconButton
                    tooltip={editing ? "Cancel" : "Edit Lineitems"}
                    onClick={() => {
                        if (editing) {
                            setData(project.completed_line_items ?? project.quoted_line_items ?? {})
                        }
                        setEditing(!editing)
                    }}
                    disabled={setCompletedLineItemsIsPending}
                    icon={editing ? <IconCancel /> : <IconPencil />}
                />
                {Object.values(project.quoted_line_items ?? {}).length > 0 && Object.values(project.completed_line_items ?? {}).length == 0 &&
                    <DesignedIconButton
                        tooltip={"No Changes From Quote"}
                        disabled={setCompletedLineItemsIsPending}
                        icon={<IconCopy />}
                        onClick={() => {
                            setCompletedLineItems({
                                home_id: project.home_id,
                                project_id: project.project_id,
                                completedLineItems: data as ProjectLineItems,
                                skipHubspot
                            })
                        }} />
                }
                <Checkbox
                    checked={skipHubspot}
                    onChange={(e) => setSkipHubspot(e.currentTarget.checked)}
                    label={"(danger) skip hubspot"}
                />
            </HStack>
    }
        {
            Object.keys(data).map((recNum) => <VStack key={recNum}>
                <TextLg>{recommendationsSheet.find(r => r.id == recNum)?.title ?? "Unknown"}</TextLg>
                <CommonLineItemTable
                    hideAdminItems={false}
                    showLineItemDiscounts={true}
                    editing={editing}
                    isPending={setCompletedLineItemsIsPending}
                    original_rec_id={recNum}
                    lineItems={data[recNum]!.line_items}
                    setLineItems={(newLineItems) => {
                        const newData = { ...data, [recNum]: { line_items: newLineItems, discounts: data[recNum]!.discounts } }
                        setData(newData)
                    }}
                    hidePrice={false}
                    allowCreateProduct={true}
                />
                <CommonDiscountsTable
                    editing={editing}
                    isPending={setCompletedLineItemsIsPending}
                    original_rec_id={recNum}
                    discounts={data[recNum]!.discounts}
                    setDiscounts={(newDiscounts) => {
                        const newData = { ...data, [recNum]: { discounts: newDiscounts, line_items: data[recNum]!.line_items } }
                        setData(newData)
                    }}
                />
            </VStack>)
        }</VStack>
}