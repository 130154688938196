import {createFileRoute, Outlet} from '@tanstack/react-router'

import {HomeownerUserRedirectToSignInCheck, Layout, SheetsDataProvider} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/homes/_homes')({
    component: function HomesBaseComponent() {
        return <Layout loadingDrawer>
            <HomeownerUserRedirectToSignInCheck>
                <SheetsDataProvider>

                    <Helmet>
                        <title>SeeAir</title>
                        <meta name={"description"}
                              content={"SeeAir Home"}/>
                    </Helmet>
                    <Outlet/>
                </SheetsDataProvider>
            </HomeownerUserRedirectToSignInCheck>
        </Layout>
    }
})