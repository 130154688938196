import { EditableSingleTextFieldWidget, getUseMutationOpt, trpc } from "@seeair/shared-components";
import { Assessment, AssessmentStatus, AssessmentStatusSchema } from "@seeair/schemas";
import React from "react";

export function AssessmentStatusWidget({ assessment }: { assessment: Assessment }) {
    const { isPending: saveAssessmentIsPending, mutate: saveStatusChange } =
        trpc.ADMIN.setAssessmentStatus.useMutation(
            getUseMutationOpt(trpc.useUtils()),
        );
    return (
        <EditableSingleTextFieldWidget
            label={"assessment_status"}
            initialValue={assessment.assessment_status ?? ""}
            showOverride={true}
            saveValue={(status, override) => {
                saveStatusChange({
                    status: status as AssessmentStatus,
                    override,
                    assessment_id: assessment.assessment_id,
                    home_id: assessment.home_id,
                });
            }}
            isPending={saveAssessmentIsPending}
            options={AssessmentStatusSchema.options.map((o) => ({ name: o, id: o }))}
        />
    );
}
