import {
    AddressSchema,
    AirSensorDataSchema,
    AssessmentDataCollectionConfigurationSchema, AssessmentDocumentsSchema,
    AssessmentFilesEnumSchema,
    AssessmentFilesSchema,
    AssessmentNoteSchema,
    AssessmentStatusSchema,
    BlowerDoorTestSchema,
    CombustionSafetyTestSchema,
    EnergyDataRecord,
    EnergyDataRecordSchema,
    HomeDetails,
    HomeDetailsSchema,
    HubspotEligibilitySurveySchema, ProjectDocumentsSchema, ProjectEventsSchema,
    ProjectFilesSchema,
    ProjectLineItemsSchema,
    ProjectStatusSchema,
    RecDataSchema,
    RecommendationDiscountsSchema,
    RecommendationLineItemsSchema, SeeAirPermissionSchema,
    SolarPotential,
    SolarPotentialSchema
} from "./jsonb-schemas.js";
import {createInsertSchema, createSelectSchema} from "drizzle-zod";
import {z} from "zod";
import {
    HubspotLineItemAggregateSchema,
    HubspotMeetingPropertiesSchema,
} from "./hubspot.js";
import {assessments, homes, recommendations, projects, payment_events, manual_payments, ai_validation} from "./drizzle-energy.js";
import {waitlist,users,shares} from "./drizzle-authentication.js";
import { StripePaymentEventSchema, StripeWebhookTypeEnumSchema } from './stripe.js';

const refineAssessment = {
    electric_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    gas_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    oil_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    air_sensor_data: z.record(z.string(),AirSensorDataSchema).nullish(),
    // weather_data: z.object({}),
    solar_data: SolarPotentialSchema.nullish(),
    assessment_files_list: AssessmentFilesSchema.nullish(),
    assessment_documents: AssessmentDocumentsSchema.nullish(),
    assessment_notes: z.record(z.string(),AssessmentNoteSchema).nullish(),
    home_details: HomeDetailsSchema.nullish(),
    assessment_meetings: z.record(z.string(),HubspotMeetingPropertiesSchema).nullish(),
    data_collection_requirements: AssessmentDataCollectionConfigurationSchema.nullish(),
    project_number:z.string().nullish(),
    utility_company:z.string().nullish()
}
export type Home = z.infer<typeof HomeSchema>

export const AssessmentSchema = createSelectSchema(assessments, refineAssessment)
export type Assessment = z.infer<typeof AssessmentSchema>
export type AnalysisReadyAssessment = Assessment & {home_details:HomeDetails,electric_usage:Record<string,EnergyDataRecord>,solar_data:SolarPotential}
export const CreateAssessmentSchema = createInsertSchema(assessments, refineAssessment).omit({assessment_id: true})
export const UpdateAssessmentSchema = createInsertSchema(assessments, refineAssessment)
    .omit({
        // we won't allow updating these fields
        home_id: true,
        created_at: true,
    })
    .partial()
    .required({assessment_id: true})

const refineHome = {
    // home_details: HomeDetailsDBSchema,
    address: AddressSchema,
    deleted: z.boolean().nullish()
}
export const HomeSchema = createSelectSchema(homes, refineHome)
export const AddHomeSchema = createInsertSchema(homes, refineHome).omit({home_id: true})
export type AddHomeProps = z.infer<typeof AddHomeSchema>
const refineShare = {
    project_permissions: z.record(z.string(),SeeAirPermissionSchema).nullish(),
    granted_user_id:z.string().nullish(),
    granted_company_id:z.string().nullish()
}
export const GetSharesSchema = createSelectSchema(shares,refineShare)
export const UpdateShareSchema = GetSharesSchema.partial().merge(GetSharesSchema.pick({home_id:true,target_email: true}))
export type Share = z.infer<typeof GetSharesSchema>
export const InsertShareSchema = createInsertSchema(shares,refineShare)
export const SetShareSchema = createInsertSchema(shares,refineShare).omit({
    verification_code: true,
    verification_code_expires_at: true,
})
export const CreateShareSchema = createInsertSchema(shares,refineShare).omit({
        verification_code: true,
        verification_code_expires_at: true,
        granted_user_id: true
    })
export type CreateShare = z.infer<typeof CreateShareSchema>
export type SetShare = z.infer<typeof SetShareSchema>
export type InsertShare = z.infer<typeof InsertShareSchema>
export type UpdateShare = z.infer<typeof UpdateShareSchema>
const refineRecommendation = {
    rec_data:RecDataSchema,
    line_items: RecommendationLineItemsSchema.nullish(),
    discounts: RecommendationDiscountsSchema.nullish()

}
export const GetRecommendationsSchema = createSelectSchema(recommendations,refineRecommendation)
export type Recommendation = z.infer<typeof GetRecommendationsSchema>
export const InsertRecommendationsSchema = createInsertSchema(recommendations,refineRecommendation)
export type NewRecommendation = z.infer<typeof InsertRecommendationsSchema>

export const ProjectPaymentSchema=z.object({
    project_id:z.string(),
    payment_id:z.string(),
    amount:z.number(),
    created:z.number().nullish(),
    source:z.any(),
})
export type ProjectPayment=z.infer<typeof ProjectPaymentSchema>

const refineProject = {
    hubspot_deal_line_items: z.array(HubspotLineItemAggregateSchema).nullish(),
    // hubspot_quote_line_items: z.array(HubspotLineItemAggregateSchema).nullish(),
    homeowner_availability: z.array(z.string()).nullish(),
    scheduled_dates: ProjectEventsSchema.nullish(),
    project_crew: z.array(z.string()).nullish(),
    project_documents: ProjectDocumentsSchema.nullish(),
    quoted_line_items: ProjectLineItemsSchema.nullish(),
    completed_line_items: ProjectLineItemsSchema.nullish(),
    combustion_safety_tests: CombustionSafetyTestSchema.nullish(),
    blower_door_tests: BlowerDoorTestSchema.nullish(),
    project_status: ProjectStatusSchema.nullish(),
    deleted: z.boolean().nullish(),
    project_files: ProjectFilesSchema.nullish()
}
export const GetProjectsSchema = createSelectSchema(projects,refineProject)
export type Project = z.infer<typeof GetProjectsSchema>
export const ProjectAggregateSchema = GetProjectsSchema.merge(z.object({
    recommendations:z.array(GetRecommendationsSchema),
    payments:z.array(ProjectPaymentSchema).nullish()
}))
export type ProjectAggregate = z.infer<typeof ProjectAggregateSchema>
export const InsertProjectSchema = createInsertSchema(projects,refineProject)
export type InsertProject = z.infer<typeof InsertProjectSchema>
export const CreateProjectSchema = createInsertSchema(projects,refineProject).omit({project_id:true})
export type CreateProject = z.infer<typeof CreateProjectSchema>
export type UpdateProject = Partial<CreateProject> & {project_id:string}
export type AssessmentStatus = z.infer<typeof AssessmentStatusSchema>

const refineUser = {
    // role: UserRolesSchema
    created_at: z.date().nullish(),
    emailVerified: z.date().nullish(),
    company_id: z.string().nullish(),
    company_name: z.string().nullish()
}
export const SeeAirUserSchema = createSelectSchema(users,refineUser)
export type SeeAirUser = z.infer<typeof SeeAirUserSchema>
const refineWaitlist = {
    eligibility_data:HubspotEligibilitySurveySchema
}
export const GetWaitlistSchema = createSelectSchema(waitlist,refineWaitlist)
export type Waitlist = z.infer<typeof GetWaitlistSchema> 
const refinePaymentEvents={
    event_type:StripeWebhookTypeEnumSchema.nullable(),
    body:StripePaymentEventSchema.nullable()
}
export const GetPaymentEventSchema = createSelectSchema(payment_events,refinePaymentEvents)
export type PaymentEvent = z.infer<typeof GetPaymentEventSchema>
export const GetManualPaymentSchema = createSelectSchema(manual_payments,{})
export const InsertManualPaymentSchema = GetManualPaymentSchema.omit({id:true,created:true})
export type ManualPayment = z.infer<typeof GetManualPaymentSchema>
export type InsertManualPayment = z.infer<typeof InsertManualPaymentSchema>

const refineAiValidation = {
    home_details: HomeDetailsSchema.nullish(),
    inferred_home_details: HomeDetailsSchema.nullish(),
    assessment_file_type: AssessmentFilesEnumSchema,
    inferred_assessment_file_type: AssessmentFilesEnumSchema.nullish(),
}
export const AiValidationSchema = createSelectSchema(ai_validation,refineAiValidation)
export type AiValidation = z.infer<typeof AiValidationSchema>