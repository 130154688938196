import { HubspotProduct } from "@seeair/schemas"
import { productMatchesPartNumber, productMatchesRecNumber } from "./seeAirDataUtil.js"
import { IHubspotProductCatalog } from "./iHubspotCrmClient.js"

export class PreloadedHubspotProductCatalog implements IHubspotProductCatalog {
    private products:Array<HubspotProduct>
    constructor(products:Array<HubspotProduct>) {
        this.products = products
    }
    getProductById(productId:string):HubspotProduct | 'not_found' {
        return this.products!.filter(p=>p.id==productId)[0] ?? 'not_found'
    }
    getPreferredProduct(recNumber:string,partNumber:string):HubspotProduct | 'not_found' {
        const relevantProducts = this.getProducts(recNumber)
        const firstFavorite = relevantProducts.find(p=>productMatchesPartNumber(p,partNumber))
        return firstFavorite ?? 'not_found'
    }
    getProducts(recNumber:string):Array<HubspotProduct> {
        return this.products!.filter(p=>productMatchesRecNumber(p,recNumber))
    }
    getAllProducts():Array<HubspotProduct> {
        return [...this.products!]
    }
}