import React from "react"

export function PrivacyPolicy() {
    return (
        <div className={"c5 doc-content"}>
            <ol className={"c11 lst-kix_list_1-0 start"} start={1}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>WHAT INFORMATION DO WE COLLECT? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c10"}>Personal information you disclose to us </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>In Short: We collect personal information that you provide to us. </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We collect personal information that you voluntarily provide to us when you register on
                    the Services, express an interest in obtaining information about us or our products and
                    Services, when you participate in activities on the Services, or otherwise when you
                    contact us.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    Personal Information Provided by You. The personal information that we collect depends on
                    the context of your interactions with us and the Services, the choices you make, and the
                    products and features you use. The personal information we collect may include the
                    following:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_2-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>names </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>phone numbers </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>email addresses </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>mailing addresses </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>usernames </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>passwords </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>contact preferences </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>billing addresses </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>debit/credit card numbers </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>contact or authentication data </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c12"}>Sensitive Information.</span>
                <span className={"c0"}>&nbsp;We do not process sensitive information. </span>
            </p>
            <p className={"c3"}>
                <span className={"c12"}>Payment Data.</span>
                <span>
                    &nbsp;We may collect data necessary to process your payment if you make purchases, such as
                    your payment instrument number, and the security code associated with your payment
                    instrument. All payment data is stored by Stripe. You may find their privacy notice
                    link(s) here:{" "}
                </span>
                <span className={"c2"}>
                    <a
                        className={"c7"}
                        href={"https://www.google.com/url?q=https://stripe.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1723843829428414&amp;usg=AOvVaw3O4C0bsAK2BZBXPwIf97mI"}
                    >
                        https://stripe.com/privacy
                    </a>
                </span>
                <span className={"c0"}>. </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    All personal information that you provide to us must be true, complete, and accurate, and
                    you must notify us of any changes to such personal information.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>Information automatically collected </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: Some information - such as your Internet Protocol (IP) address and/or browser
                    and device characteristics - is collected automatically when you visit our Services.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We automatically collect certain information when you visit, use, or navigate the
                    Services. This information does not reveal your specific identity (like your name or
                    contact information) but may include device and usage information, such as your IP
                    address, browser and device characteristics, operating system, language preferences,
                    referring URLs, device name, country, location, information about how and when you use our
                    Services, and other technical information. This information is primarily needed to
                    maintain the security and operation of our Services, and for our internal analytics and
                    reporting purposes.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    Like many businesses, we also collect information through cookies and similar
                    technologies. The information we collect includes:
                </span>
            </p>
            <ul className={"c11 lst-kix_list_3-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
                        performance information our servers automatically collect when you access or use our
                        Services and which we record in log files. Depending on how you interact with us, this
                        log data may include your IP address, device information, browser type, and settings
                        and information about your activity in the Services (such as the date/time stamps
                        associated with your usage, pages and files viewed, searches, and other actions you
                        take such as which features you use), device event information (such as system
                        activity, error reports (sometimes called &quot;crash dumps&quot;), and hardware
                        settings).{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Device Data. We collect device data such as information about your computer, phone,
                        tablet, or other device you use to access the Services. Depending on the device used,
                        this device data may include information such as your IP address (or proxy server),
                        device and application identification numbers, location, browser type, hardware model,
                        Internet service provider and/or mobile carrier, operating system, and system
                        configuration information.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Location Data. We collect location data such as information about your device&#39;s
                        location, which can be either precise or imprecise. How much information we collect
                        depends on the type and settings of the device you use to access the Services. For
                        example, we may use GPS and other technologies to collect geolocation data that tells
                        us your current location (based on your IP address). You can opt out of allowing us to
                        collect this information either by refusing access to the information or by disabling
                        your Location setting on your device. However, if you choose to opt out, you may not
                        be able to use certain aspects of the Services.{" "}
                    </span>
                </li>
            </ul>
            <p className={"c4"}>
                <span className={"c0"}></span>
            </p>
            <p className={"c4"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={2}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>HOW DO WE PROCESS YOUR INFORMATION? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We process your information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention, and to comply with law. We may
                    also process your information for other purposes with your consent. &nbsp;
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We process your personal information for a variety of reasons, depending on how you
                    interact with our Services, including:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_4-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To facilitate account creation and authentication and otherwise manage user accounts.
                        We may process your information so you can create and log in to your account, as well
                        as keep your account in working order.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To deliver and facilitate delivery of services to the user. We may process your
                        information to provide you with the requested service.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To respond to user inquiries/offer support to users. We may process your information
                        to respond to your inquiries and solve any potential issues you might have with the
                        requested service.
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To send administrative information to you. We may process your information to send you
                        details about our products and services, changes to our terms and policies, and other
                        similar information.
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To fulfill and manage your orders. We may process your information to fulfill and
                        manage your orders, payments, returns, and exchanges made through the Services.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To request feedback. We may process your information when necessary to request
                        feedback and to contact you about your use of our Services.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To send you marketing and promotional communications. We may process the personal
                        information you send to us for our marketing purposes, if this is in accordance with
                        your marketing preferences. You can opt out of our marketing emails at any time. For
                        more information, see &quot;WHAT ARE YOUR PRIVACY RIGHTS?&quot; below.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To deliver targeted advertising to you. We may process your information to develop and
                        display personalized content and advertising tailored to your interests, location, and
                        more.
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To post testimonials. We post testimonials on our Services that may contain personal
                        information.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To protect our Services. We may process your information as part of our efforts to
                        keep our Services safe and secure, including fraud monitoring and prevention.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To evaluate and improve our Services, products, marketing, and your experience. We may
                        process your information when we believe it is necessary to identify usage trends,
                        determine the effectiveness of our promotional campaigns, and to evaluate and improve
                        our Services, products, marketing, and your experience.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To identify usage trends. We may process information about how you use our Services to
                        better understand how they are being used so we can improve them.
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        To determine the effectiveness of our marketing and promotional campaigns. We may
                        process your information to better understand how to provide marketing and promotional
                        campaigns that are most relevant to you.
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        To comply with our legal obligations. We may process your information to comply with
                        our legal obligations, respond to legal requests, and exercise, establish, or defend
                        our legal rights.
                    </span>
                </li>
            </ul>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={3}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We may share information in specific situations described in this section and/or
                    with the following categories of third parties.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c12"}>Vendors, Consultants, and Other Third-Party Service Providers.</span>
                <span className={"c0"}>
                    &nbsp;We may share your data with third-party vendors, service providers, contractors, or
                    agents (&quot;third parties&quot;) who perform services for us or on our behalf and
                    require access to such information to do that work. The categories of third parties we may
                    share personal information with are as follows:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_6-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Cloud Computing Services </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Data Analytics Services </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Data Storage Service Providers </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Payment Processors </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Performance Monitoring Tools </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Retargeting Platforms </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Sales &amp; Marketing Tools </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Social Networks </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>User Account Registration &amp; Authentication Services </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>Website Hosting Service Providers </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    We also may need to share your personal information in the following situations:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_5-0 start"}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c10"}>Business Transfers.</span>
                    <span className={"c0"}>
                        &nbsp;We may share or transfer your information in connection with, or during
                        negotiations of, any merger, sale of company assets, financing, or acquisition of all
                        or a portion of our business to another company.{" "}
                    </span>
                </li>
            </ul>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={4}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We may use cookies and other tracking technologies to collect and store your
                    information. We may use cookies and similar tracking technologies (like web beacons and
                    pixels) to access or store information. Specific information about how we use such
                    technologies and how you can refuse certain cookies is set out in our Cookie Notice.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={5}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>HOW LONG DO WE KEEP YOUR INFORMATION? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We keep your information for as long as necessary to fulfill the purposes
                    outlined in this privacy notice unless otherwise required by law.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We will only keep your personal information for as long as it is necessary for the
                    purposes set out in this privacy notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal requirements). No purpose in
                    this notice will require us keeping your personal information for longer than the period
                    of time in which users have an account with us.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    When we have no ongoing legitimate business need to process your personal information, we
                    will either delete or anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored in backup archives), then we
                    will securely store your personal information and isolate it from any further processing
                    until deletion is possible.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={6}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>HOW DO WE KEEP YOUR INFORMATION SAFE? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We aim to protect your personal information through a system of organizational
                    and technical security measures.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We have implemented appropriate and reasonable technical and organizational security
                    measures designed to protect the security of any personal information we process. However,
                    despite our safeguards and efforts to secure your information, no electronic transmission
                    over the Internet or information storage technology can be guaranteed to be 100% secure,
                    so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
                    third parties will not be able to defeat our security and improperly collect, access,
                    steal, or modify your information. Although we will do our best to protect your personal
                    information, transmission of personal information to and from our Services is at your own
                    risk. You should only access the Services within a secure environment.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={7}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>DO WE COLLECT INFORMATION FROM MINORS? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: We do not knowingly collect data from or market to children under 18 years of
                    age.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We do not knowingly solicit data from or market to children under 18 years of age. By
                    using the Services, you represent that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor dependent&#39;s use of the Services. If
                    we learn that personal information from users less than 18 years of age has been
                    collected, we will deactivate the account and take reasonable measures to promptly delete
                    such data from our records. If you become aware of any data we may have collected from
                    children under age 18, please contact us at support@seeair.io.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={8}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>WHAT ARE YOUR PRIVACY RIGHTS? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: You may review, change, or terminate your account at any time.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c12"}>Withdrawing your consent:</span>
                <span className={"c0"}>
                    &nbsp;If we are relying on your consent to process your personal information, which may be
                    express and/or implied consent depending on the applicable law, you have the right to
                    withdraw your consent at any time. You can withdraw your consent at any time by contacting
                    us by using the contact details provided.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    However, please note that this will not affect the lawfulness of the processing before its
                    withdrawal nor, when applicable law allows, will it affect the processing of your personal
                    information conducted in reliance on lawful processing grounds other than consent.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c12"}>Opting out of marketing and promotional communications:</span>
                <span className={"c0"}>
                    &nbsp;You can unsubscribe from our marketing and promotional communications at any time by
                    clicking on the unsubscribe link in the emails that we send, or by contacting us using the
                    details provided. You will then be removed from the marketing lists. However, we may still
                    communicate with you - for example, to send you service-related messages that are
                    necessary for the administration and use of your account, to respond to service requests,
                    or for other non-marketing purposes.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>Account Information</span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    &nbsp;If you would at any time like to review or change the information in your account or
                    terminate your account, you can:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_5-0"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Log in to your account settings and update your user account. </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>Contact us using the contact information provided. </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active databases. However, we may retain some information in our
                    files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
                    legal terms and/or comply with applicable legal requirements.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c12"}>Cookies and similar technologies:</span>
                <span className={"c0"}>
                    &nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can
                    usually choose to set your browser to remove cookies and to reject cookies. If you choose
                    to remove cookies or reject cookies, this could affect certain features or services of our
                    Services. If you have questions or comments about your privacy rights, you may email us at
                    https://www.seeair.io/contact. &nbsp;
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={9}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>CONTROLS FOR DO-NOT-TRACK FEATURES </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    Most web browsers and some mobile operating systems and mobile applications include a
                    Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy
                    preference not to have data about your online browsing activities monitored and collected.
                    At this stage no uniform technology standard for recognizing and implementing DNT signals
                    has been finalized. As such, we do not currently respond to DNT browser signals or any
                    other mechanism that automatically communicates your choice not to be tracked online. If a
                    standard for online tracking is adopted that we must follow in the future, we will inform
                    you about that practice in a revised version of this privacy notice.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={10}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: If you are a resident of California, Connecticut or Colorado, you are granted
                    specific rights regarding access to your personal information.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>What categories of personal information do we collect? </span>
            </p>
            <ul className={"c11 lst-kix_list_7-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Identifiers such as contact details</span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Personal information such as name</span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Protected classification characteristics under state and federal law such as gender
                        and date of birth
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Commercial information such as transaction information and purchase history
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>Inferences drawn from collected personal information</span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    We will use and retain the collected personal information as needed to provide the
                    Services or for as long as the user has an account with us.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We may also collect other personal information outside of these categories through
                    instances where you interact with us in person, online, or by phone or mail in the context
                    of:{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_8-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Receiving help through our customer support channels; </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Participation in customer surveys or contests; and </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        Facilitation in the delivery of our Services and to respond to your inquiries.{" "}
                    </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c10"}>How do we use and share your personal information? </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>We collect and share your personal information through: </span>
            </p>
            <ul className={"c11 lst-kix_list_9-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Targeting cookies/Marketing cookies </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>Social media cookies </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c12"}>Will your information be shared with anyone else?</span>
                <span className={"c0"}>&nbsp;</span>
            </p>
            <p className={"c3"}>
                <span>
                    We may disclose and/or sell your personal information with our service providers pursuant
                    to a written contract between us and each service provider. The categories of 3
                </span>
                <span className={"c13"}>rd</span>
                <span className={"c0"}>
                    &nbsp;parties include utility providers, contractors and local, state and federal
                    agencies.
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We may use your personal information for our own business purposes, such as for
                    undertaking internal research for technological development and demonstration. This is not
                    considered to be &quot;selling&quot; of your personal information.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>California Residents </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law
                    permits our users who are California residents to request and obtain from us, once a year
                    and free of charge, information about categories of personal information (if any) we
                    disclosed to third parties for direct marketing purposes and the names and addresses of
                    all third parties with which we shared personal information in the immediately preceding
                    calendar year. If you are a California resident and would like to make such a request,
                    please submit your request in writing to us using the contact information provided
                    below.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    If you are under 18 years of age, reside in California, and have a registered account with
                    the Services, you have the right to request removal of unwanted data that you publicly
                    post on the Services. To request removal of such data, please contact us using the contact
                    information provided below and include the email address associated with your account and
                    a statement that you reside in California. We will make sure the data is not publicly
                    displayed on the Services, but please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g., backups, etc.).{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>CCPA Privacy Notice </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    This section applies only to California residents. Under the California Consumer Privacy
                    Act (CCPA), you have the rights listed below.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    The California Code of Regulations defines a &quot;residents&quot; as:{" "}
                </span>
            </p>
            <ol className={"c11 lst-kix_list_10-0 start"} start={1}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        every individual who is in the State of California for other than a temporary or
                        transitory purpose and{" "}
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        (2) every individual who is domiciled in the State of California who is outside the
                        State of California for a temporary or transitory purpose{" "}
                    </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>All other individuals are defined as &quot;non-residents.&quot; </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    If this definition of &quot;resident&quot; applies to you, we must adhere to certain
                    rights and obligations regarding your personal information.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>Your rights with respect to your personal data</span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>Right to request deletion of the data - Request to delete </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    You can ask for the deletion of your personal information. If you ask us to delete your
                    personal information, we will respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but not limited to) the exercise
                    by another consumer of his or her right to free speech, our compliance requirements
                    resulting from a legal obligation, or any processing that may be required to protect
                    against illegal activities.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>Right to be informed &ndash; Request to know </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>Depending on the circumstances, you have a right to know: </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>whether we collect and use your personal information; </span>
            </p>
            <ul className={"c11 lst-kix_list_11-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>the categories of personal information that we collect; </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        the purposes for which the collected personal information is used;{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        whether we sell or share personal information to third parties;{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        the categories of personal information that we sold, shared, or disclosed for a
                        business purpose;{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        the categories of third parties to whom the personal information was sold, shared, or
                        disclosed for a business purpose;{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        the business or commercial purpose for collecting, selling, or sharing personal
                        information;{" "}
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        and the specific pieces of personal information we collected about you.{" "}
                    </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    In accordance with applicable law, we are not obligated to provide or delete consumer
                    information that is de-identified in response to a consumer request or to re-identify
                    individual data to verify a consumer request. &nbsp;
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>
                    Right to Non-Discrimination for the Exercise of a Consumer&#39;s Privacy Rights{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We will not discriminate against you if you exercise your privacy rights.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>
                    Right to Limit Use and Disclosure of Sensitive Personal Information{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>We do not process consumer&#39;s sensitive personal information. </span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>Verification process </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    Upon receiving your request, we will need to verify your identity to determine you are the
                    same person about whom we have the information in our system. These verification efforts
                    require us to ask you to provide information so that we can match it with information you
                    have previously provided us. For instance, depending on the type of request you submit, we
                    may ask you to provide certain information so that we can match the information you
                    provide with the information we already have on file, or we may contact you through a
                    communication method (e.g., phone or email) that you have previously provided to us. We
                    may also use other verification methods as the circumstances dictate.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    We will only use personal information provided in your request to verify your identity or
                    authority to make the request. To the extent possible, we will avoid requesting additional
                    information from you for the purposes of verification. However, if we cannot verify your
                    identity from the information already maintained by us, we may request that you provide
                    additional information for the purposes of verifying your identity and for security or
                    fraud-prevention purposes. We will delete such additionally provided information as soon
                    as we finish verifying you.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c9"}>Other privacy rights </span>
            </p>
            <ul className={"c11 lst-kix_list_12-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        You may object to the processing of your personal information.{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        You may request correction of your personal data if it is incorrect or no longer
                        relevant, or ask to restrict the processing of the information.{" "}
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        You can designate an authorized agent to make a request under the CCPA on your behalf.
                        We may deny a request from an authorized agent that does not submit proof that they
                        have been validly authorized to act on your behalf in accordance with the CCPA.{" "}
                    </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    You can opt out from the selling or sharing of your personal information by disabling
                    cookies in Cookie Preference Settings and clicking on the Do Not Sell or Share My Personal
                    Information link on our homepage.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span>To exercise these rights, you can contact us by email at </span>
                <span className={"c2"}>support@seeair.com</span>
                <span className={"c0"}>
                    . If you have a complaint about how we handle your data, we would like to hear from
                    you.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>Colorado Residents </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you
                    have the rights listed below. However, these rights are not absolute, and in certain
                    cases, we may decline your request as permitted by law.{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_13-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to be informed whether or not we are processing your personal data{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Right to access your personal data </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Right to correct inaccuracies in your personal data </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Right to request deletion of your personal data </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to obtain a copy of the personal data you previously shared with us{" "}
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to opt out of the processing of your personal data if it is used for targeted
                        advertising, the sale of personal data, or profiling in furtherance of decisions that
                        produce legal or similarly significant effects (&quot;profiling&quot;) &nbsp;
                    </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span>
                    We sell personal data to third parties or process personal data for targeted advertising.
                    You can opt out from the selling of your personal data, targeted advertising, or profiling
                    by disabling cookies in Cookie Preference Settings. To submit a request to exercise any of
                    the other rights described above, please email{" "}
                </span>
                <span className={"c2"}>support@seeair.com</span>
                <span className={"c0"}>. </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    If we decline to take action regarding your request and you wish to appeal our decision,
                    please email us at https://www.seeair.io/contact. Within forty-five (45) days of receipt
                    of an appeal, we will inform you in writing of any action taken or not taken in response
                    to the appeal, including a written explanation of the reasons for the decisions.{" "}
                </span>
            </p>
            <p className={"c3"}>
                <span className={"c10"}>Connecticut Residents </span>
            </p>
            <p className={"c3"}>
                <span className={"c0"}>
                    This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act
                    (CTDPA), you have the rights listed below. However, these rights are not absolute, and in
                    certain cases, we may decline your request as permitted by law.{" "}
                </span>
            </p>
            <ul className={"c11 lst-kix_list_14-0 start"}>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to be informed whether or not we are processing your personal data{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to access your personal data Right to correct inaccuracies in your personal
                        data{" "}
                    </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>Right to request deletion of your personal data </span>
                </li>
                <li className={"c1 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to obtain a copy of the personal data you previously shared with us{" "}
                    </span>
                </li>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        Right to opt out of the processing of your personal data if it is used for targeted
                        advertising, the sale of personal data, or profiling in furtherance of decisions that
                        produce legal or similarly significant effects (&quot;profiling&quot;){" "}
                    </span>
                </li>
            </ul>
            <p className={"c3"}>
                <span className={"c0"}>
                    We sell personal data to third parties or process personal data for targeted advertising.
                    You can opt out from the selling of your personal data, targeted advertising, or profiling
                    by disabling cookies in Cookie Preference Settings.
                </span>
            </p>
            <p className={"c3"}>
                <span>
                    To submit a request to exercise any of the other rights described above, please email{" "}
                </span>
                <span className={"c2"}>support@seeair.com</span>
                <span>
                    . If we decline to take action regarding your request and you wish to appeal our decision,
                    please email us at{" "}
                </span>
                <span className={"c2"}>support@seeair.com</span>
                <span className={"c0"}>
                    . Within sixty (60) days of receipt of an appeal, we will inform you in writing of any
                    action taken or not taken in response to the appeal, including a written explanation of
                    the reasons for the decisions.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={11}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>3RD PARTY CONTRACTORS </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    Your address and data about your home, including but not limited to survey and
                    environmental data, may be provided to 3rd party contractors if you elect to start a home
                    improvement project with SEEAIR. We will not provide your name, e-mail or phone number
                    without your prior consent.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={12}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>COOKIES </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    This website uses cookies and similar technologies, which are small files or pieces of
                    text that download to a device when a visitor accesses a website or app. For information
                    about viewing the cookies dropped on your device, visit The cookies Squarespace uses.
                    These functional and required cookies are always used, which allow Squarespace, our
                    hosting platform, to securely serve this website to you. These analytics and performance
                    cookies are used on this website, as described below, only when you acknowledge our cookie
                    banner. This website uses analytics and performance cookies to view site traffic,
                    activity, and other data.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={13}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>DO WE MAKE UPDATES TO THIS NOTICE? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span className={"c0"}>
                    In Short: Yes, we will update this notice as necessary to stay compliant with relevant
                    laws. &nbsp;We may update this privacy notice from time to time. The updated version will
                    be indicated by an updated &quot;Revised&quot; date and the updated version will be
                    effective as soon as it is accessible. If we make material changes to this privacy notice,
                    we may notify you either by prominently posting a notice of such changes or by directly
                    sending you a notification. We encourage you to review this privacy notice frequently to
                    be informed of how we are protecting your information.{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={14}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span>If you have questions or comments about this notice, you may email us at </span>
                <span className={"c2"}>support@seeair.com</span>
                <span className={"c0"}>
                    &nbsp;or contact us by post at: Seeair Inc. 275 River St, Unit 3, Cambridge, MA 02139,
                    United States{" "}
                </span>
            </p>
            <p className={"c3 c8"}>
                <span className={"c0"}></span>
            </p>
            <ol className={"c11 lst-kix_list_1-0"} start={15}>
                <li className={"c3 c6 li-bullet-0"}>
                    <span className={"c0"}>
                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?{" "}
                    </span>
                </li>
            </ol>
            <p className={"c3"}>
                <span>
                    Based on the applicable laws of your country, you may have the right to request access to
                    the personal information we collect from you, change that information, or delete it. To
                    request to review, update, or delete your personal information, please email us at{" "}
                    <span className={"c2"}>support@seeair.com</span>
                </span>
                <span className={"c0"}>. </span>
            </p>
        </div>
    )
}
