import { Combobox, Pill, PillsInput, Tooltip, useCombobox } from "@mantine/core"
import React, { useEffect, useState } from "react"
import { AdminComboboxOption } from "@seeair/schemas"
import classNames from "classnames"
import { HStack } from './DesignBase.js'
import { IconCancel, IconDeviceFloppy, IconPencil } from "@tabler/icons-react"
import { DesignedIconButton } from './DesignComponents.js'

export function AdminPillsCombobox({
    alwaysEditing,
    isSuccess,
    isPending,
    save,
    options,
    onChange = (v) => {},
    disabled,
    initiallySelectedIds = [],
    label,
    inputClassName,
    allowNewOption,
    newOptionValidation,
    placeholder = "",
    newOptionValidationMessage,
}: {
    initiallySelectedIds: Array<string>
    disabled: boolean
    alwaysEditing: boolean
    isPending: boolean
    isSuccess: boolean
    save: (values: Array<AdminComboboxOption>) => void
    options: Array<AdminComboboxOption>
    onChange?: (values: Array<AdminComboboxOption>) => void
    label?: string
    inputClassName?: string
    allowNewOption?: boolean
    newOptionValidation?: RegExp
    newOptionValidationMessage?: string
    placeholder?: string
}) {
    const [editing, setEditing] = useState(alwaysEditing)
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    })
    const [selectedIds, setSelectedIds] = useState(initiallySelectedIds)
    const [search, setSearch] = useState("")
    useEffect(() => {
        if (isSuccess && !alwaysEditing) {
            setEditing(false)
        }
    }, [isSuccess, alwaysEditing])
    const valid = !newOptionValidation || newOptionValidation.test(search)
    const searchWords = search.toLowerCase().trim().split(" ")
    const shouldFilterOptions = options.every((p) => p.name != search)
    const unselectedOptions = options.filter((o) => !selectedIds.includes(o.id))
    const filteredOptions = shouldFilterOptions
        ? unselectedOptions.filter((p) => {
              return searchWords.length == 0 || searchWords.every((w) => p.name.toLowerCase().includes(w))
          })
        : unselectedOptions
    const groupedOptions = filteredOptions.reduce(
        (acc, v) => {
            const newAcc = { ...acc }
            const group = v.group ?? ""
            const newGroupMembers = [...(newAcc[group] ?? [])]
            newGroupMembers.push(v)
            newAcc[group] = newGroupMembers
            return newAcc
        },
        {} as { [group: string]: Array<AdminComboboxOption> },
    )
    const mapIdsToOptions = (ids: Array<string>): Array<AdminComboboxOption> =>
        ids.map((id) => options.find((o) => o.id == id) ?? { id, name: id })
    const runOnChange = (newSelectedIds: Array<string>) => {
        onChange(mapIdsToOptions(newSelectedIds))
    }
    const groupedOptionsList: Array<{ group: string; options: Array<AdminComboboxOption> }> = Object.keys(
        groupedOptions,
    )
        .map((g) => ({ group: g, options: groupedOptions[g]! }))
        .sort((g1, g2) => g1.group.localeCompare(g2.group))
    const untouched =
        JSON.stringify([...selectedIds].sort()) == JSON.stringify([...initiallySelectedIds].sort())
    return (
        <HStack wAuto leftCenter>
            <Combobox
                disabled={disabled || !editing}
                store={combobox}
                withinPortal={false}
                onOptionSubmit={(val) => {
                    const selectedOption = options.find((p) => `${p.name}-${p.id}` == val)!
                    const newSelectedIds = [...selectedIds, selectedOption.id]
                    setSelectedIds(newSelectedIds)
                    setSearch("")
                    runOnChange(newSelectedIds)
                    combobox.closeDropdown()
                }}
            >
                <Combobox.DropdownTarget>
                    <PillsInput
                        className={inputClassName}
                        disabled={disabled || !editing}
                        label={label}
                        rightSection={<Combobox.Chevron />}
                        rightSectionPointerEvents={"none"}
                    >
                        <Pill.Group>
                            {selectedIds.map((id) => (
                                <Tooltip key={id} label={id}>
                                    <Pill
                                        disabled={disabled || !editing}
                                        withRemoveButton
                                        onRemove={() => {
                                            const newSelectedIds = selectedIds.filter((i) => i != id)
                                            setSelectedIds(newSelectedIds)
                                            runOnChange(newSelectedIds)
                                        }}
                                    >
                                        {options.find((o) => o.id == id)?.name ?? id}
                                    </Pill>
                                </Tooltip>
                            ))}
                            <Combobox.EventsTarget>
                                <PillsInput.Field
                                    value={search}
                                    onChange={(event) => {
                                        combobox.openDropdown()
                                        combobox.updateSelectedOptionIndex()
                                        setSearch(event.currentTarget.value)
                                        combobox.selectFirstOption()
                                    }}
                                    onClick={() => combobox.openDropdown()}
                                    onFocus={() => combobox.openDropdown()}
                                    onBlur={() => {
                                        combobox.closeDropdown()
                                        setSearch("")
                                    }}
                                    onKeyDown={(event) => {
                                        if (
                                            allowNewOption &&
                                            valid &&
                                            event.key == "Enter" &&
                                            search.length > 0
                                        ) {
                                            event.preventDefault()
                                            const newSelectedIds = [...selectedIds, search]
                                            setSelectedIds(newSelectedIds)
                                            setSearch("")
                                            runOnChange(newSelectedIds)
                                        }
                                    }}
                                    placeholder={placeholder}
                                />
                            </Combobox.EventsTarget>
                        </Pill.Group>
                    </PillsInput>
                </Combobox.DropdownTarget>
                <Combobox.Dropdown>
                    <Combobox.Options>
                        {groupedOptionsList.length > 0 ? (
                            groupedOptionsList.map((g) => (
                                <Combobox.Group label={g.group} key={g.group}>
                                    {g.options.map((o) => (
                                        <Combobox.Option value={`${o.name}-${o.id}`} key={o.id}>
                                            {o.name}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Group>
                            ))
                        ) : (
                            <Combobox.Empty
                                className={classNames({
                                    "cursor-pointer": allowNewOption,
                                    "text-danger": !valid && search.length > 0,
                                })}
                                onClick={() => {
                                    if (allowNewOption && valid && search.length > 0) {
                                        const newSelectedIds = [...selectedIds, search]
                                        setSelectedIds(newSelectedIds)
                                        setSearch("")
                                        runOnChange(newSelectedIds)
                                    }
                                }}
                            >
                                {allowNewOption
                                    ? valid
                                        ? `Add: '${search}'`
                                        : search.length == 0
                                          ? "Nothing found"
                                          : `${newOptionValidationMessage ?? "Validation Failed"}: ${search}`
                                    : "Nothing found"}
                            </Combobox.Empty>
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            {!alwaysEditing && (
                <div>
                    <DesignedIconButton
                        disabled={isPending || !!disabled}
                        icon={editing ? <IconCancel /> : <IconPencil />}
                        title={editing ? "Cancel" : "Edit"}
                        onClick={() => {
                            if (editing) {
                                setEditing(false)
                                setSelectedIds(initiallySelectedIds)
                            } else {
                                setEditing(true)
                            }
                        }}
                        tooltip={""}
                    />
                </div>
            )}
            {editing && (
                <div>
                    <DesignedIconButton
                        disabled={isPending || untouched}
                        title={"Save"}
                        icon={<IconDeviceFloppy />}
                        onClick={() => {
                            save(mapIdsToOptions(selectedIds))
                        }}
                        tooltip={untouched ? "Unchanged" : ""}
                    />
                </div>
            )}
        </HStack>
    )
}
