import React from "react";
import { RecommendationPartConfig, RecommendationTemplate} from "@seeair/schemas";

type SheetsData = {
    recommendationsSheet:Array<RecommendationTemplate>
    partsSheet:Array<RecommendationPartConfig>
}
const DefaultSheetsData:SheetsData = {
    recommendationsSheet:[],
    partsSheet:[]
}
export const SheetsDataContext = React.createContext<SheetsData>(DefaultSheetsData)
