import { createFileRoute } from '@tanstack/react-router'
import { TextError } from '@seeair/shared-components'
import {
  AdminProjectPageSearch,
  AdminProjectPageSearchSchema,
} from '@seeair/schemas'
import React from 'react'
import { trpc } from '@seeair/shared-components'
import { AdminProjectPage } from '@seeair/admin-components'

export const Route = createFileRoute(
  '/admin/_admin/project/$project_id',
)({
  component: function AdminProjectPageComponent() {
    const { project_id } = Route.useParams()
    const search = Route.useSearch()
    const [{ home, user }] =
      trpc.ADMIN.loadHomeAggregateByProjectId.useSuspenseQuery({
        project_id,
      })
    const [contractors] = trpc.ADMIN.loadContractors.useSuspenseQuery()
    const project = home?.projects?.find(
      (p) => p.project_id == project_id,
    )
    if (!home || !project) {
      return <TextError>Project Not Found</TextError>
    }
    return (
      <AdminProjectPage
        home={home}
        project={project}
        user={user}
        tab={search?.tab}
        contractors={contractors}
      />
    )
  },
  validateSearch: (data) => {
    // console.log(`validate search ${JSON.stringify(data)}`)
    if (data == null || Object.keys(data).length == 0) {
      return { } as AdminProjectPageSearch
    }
    return AdminProjectPageSearchSchema.parse(data)
  },
})
