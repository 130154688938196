import { HomeAggregate, ProjectAggregate } from "@seeair/schemas"
import {
    AbsoluteCenterStack,
    DesignedIconButton, getUseMutationOpt,
    HStack,
    TextSm,
    trpc,
    VStack
} from "@seeair/shared-components"
import {
    calculateDealAmount,
    countProjectLineItems
} from "@seeair/shared"
import { Divider, Loader } from "@mantine/core"
import { IconCloudStar } from "@tabler/icons-react"
import React from "react"

export function SyncSummary({project}:{project:ProjectAggregate}) {
    const newDealAmount = calculateDealAmount(project)
    return <VStack>
        <ul>
            <li>
                <TextSm>
                    Remove {project.hubspot_deal_line_items?.length ?? 0} Deal LineItems
                </TextSm>
            </li>
            <li>
                <TextSm>Add {countProjectLineItems(project)} Deal LineItems</TextSm>
            </li>
            <li>
                <TextSm>
                    Change Deal Amount from {project.hubspot_deal_amount ?? "0"} to {newDealAmount}
                </TextSm>
            </li>
        </ul>
    </VStack>    
}

export function AdminProjectLineItemsSyncPanel({ project, home }: { project: ProjectAggregate, home: HomeAggregate }) {

    const { isPending: isSyncToHubspotPending, mutate: syncToHubspot } =
        trpc.ADMIN.pushProjectLineItemsToHubspotDeal.useMutation(
            getUseMutationOpt(trpc.useUtils()),
        )

    return <VStack>
        <Divider size={"xl"} label={"Sync Details"} labelPosition={"center"} />
        <SyncSummary project={project} />
        <HStack between center classNames={"mt-8"}>
            <DesignedIconButton
                disabled={isSyncToHubspotPending}
                icon={<IconCloudStar />}
                onClick={() => {
                    syncToHubspot({ project_id: project.project_id })
                }}
                tooltip={"Just Sync to Hubspot"}
            />
        </HStack>

        {isSyncToHubspotPending && (
            <AbsoluteCenterStack>
                <Loader />
            </AbsoluteCenterStack>
        )}
     </VStack>
}