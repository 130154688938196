import React from "react";
import { HubspotProduct, RecommendationDiscount} from "@seeair/schemas";

type ProductData = {
    products:Array<HubspotProduct>,
    incentives:Array<RecommendationDiscount>
}
const DefaultProductData:ProductData = {
    products: [],
    incentives: []
}
export const ProductDataContext = React.createContext<ProductData>(DefaultProductData)
