import {createFileRoute} from '@tanstack/react-router'
import {COLOR_WHITE, FullWithDesignedSection, Layout, PageTitle, PrivacyPolicy} from "@seeair/shared-components";
import React from "react";
import {Helmet} from "react-helmet-async";

export const Route = createFileRoute('/privacy')({
    component: function PrivacyPageComponent() {
        return <Layout>
            <Helmet>
                <title>SeeAir</title>
                <meta name={"description"}
                      content={"SeeAir specializes in no-cost home energy and health assessments and is a proud MassSave partner. Enhance your home's energy efficiency with our expert services."}/>
                <link rel={"canonical"} href={"https://seeair.com/privacy"}/>
            </Helmet>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <PageTitle text={"Privacy Policy"}/>
                <PrivacyPolicy/>
            </FullWithDesignedSection>
        </Layout>
    }
})
