import { AdminComboboxOption, CommonUILineItem, CommonUILineItems, HubspotProduct, RecommendationDiscount, RecommendationDiscounts } from "@seeair/schemas";
import React, { useContext, useState } from "react"
import { ProductDataContext } from './ProductDataContext.js';
import { genId, genShortId, productMatchesRecNumber, truncateString } from "@seeair/shared";
import { IconCloudPlus, IconDeviceFloppy, IconRowInsertBottom, IconTrash } from "@tabler/icons-react";
import { AdminCombobox } from './AdminCombobox.js';
import { VStack, TextSm, HStack, TextXs } from './DesignBase.js';
import { DesignedIconButton } from './DesignComponents.js';
import { Button, Checkbox, Modal, NumberInput, Popover, Table, Textarea, TextInput } from "@mantine/core";
import { NumberExpressionInput } from './NumberExpressionInput.js';
import { getUseMutationOpt } from './mutationHelper.js';
import { trpc } from './trpc.js';

export function CommonDiscountsTable(
    { discounts: data, setDiscounts: setData, original_rec_id, editing, isPending }:
        {
            isPending: boolean,
            editing: boolean,
            original_rec_id: string,
            setDiscounts: (newDiscounts: Record<string, RecommendationDiscount>) => void,
            discounts: Record<string, RecommendationDiscount>
        }) {
    const { incentives } = useContext(ProductDataContext)
    const filteredIncentives = incentives
        .filter(i => i.recNums.includes(original_rec_id))
        .map(i => ({ ...i, name: i.title, id: i.incentive_id }))
    return <VStack hAuto classNames={"my-4"}>
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Td colSpan={7}><VStack center><TextSm>Discounts</TextSm></VStack></Table.Td>
                </Table.Tr>
                <Table.Tr>
                    {/* <Table.Th>incentive_id</Table.Th> */}
                    <Table.Th>Title</Table.Th>
                    {/* <Table.Th>RecNums</Table.Th> */}
                    <Table.Th>Time Of Purchase</Table.Th>
                    <Table.Th>Discount On Remainder</Table.Th>
                    <Table.Th>url</Table.Th>
                    <Table.Th>percentage</Table.Th>
                    <Table.Th>cap</Table.Th>
                    {/* <Table.Th>order</Table.Th> */}
                    <Table.Th className={"w-8"}></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    Object.values(data).sort((d1, d2) => d1.order - d2.order).map(d => <DiscountInputRow
                        key={d.incentive_id}
                        incentive_id={d.incentive_id}
                        data={data}
                        editing={editing}
                        isPending={isPending}
                        setData={setData} />)
                }

            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={7}><VStack center><TextSm light>{`${Object.keys(data).length} discounts defined`}</TextSm></VStack></Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
        <HStack>
            {editing && <AdminCombobox
                options={filteredIncentives}
                onChange={d => {
                    const newData: RecommendationDiscounts = {
                        ...data,
                        [d.id]: d as unknown as RecommendationDiscount
                    }
                    setData(newData)
                }}
                disabled={isPending} />
            }
            {editing && <DesignedIconButton
                tooltip={"Add custom incentive"}
                onClick={() => {
                    const incentive_id = genShortId("incentive")
                    const newIncentive: RecommendationDiscount = {
                        incentive_id,
                        title: "",
                        timeOfPurchase: false,
                        discountOnRemainder: false,
                        url: "",
                        percentage: 0,
                        cap: 0,
                        recNums: [original_rec_id],
                        order: 10
                    }
                    const newData: RecommendationDiscounts = {
                        ...data,
                        [incentive_id]: newIncentive
                    }
                    setData(newData)
                }}
                disabled={isPending}
                icon={<IconRowInsertBottom />}
            />
            }
        </HStack>
    </VStack>
}
export function DiscountInputRow({ isPending, editing, data, setData, incentive_id }: {
    editing: boolean,
    isPending: boolean,
    data: RecommendationDiscounts,
    setData: (data: RecommendationDiscounts) => void,
    incentive_id: string
}) {
    const rowData: RecommendationDiscount = data[incentive_id]!
    const changeRowData = (k: keyof RecommendationDiscount, v: any) => {
        const newIncentiveRow = { ...rowData, [k]: v }
        const newData = { ...data }
        newData[incentive_id] = newIncentiveRow
        setData(newData)
    }
    const removeRow = () => {
        const newData = { ...data }
        delete newData[incentive_id]
        setData(newData)
    }
    return <Table.Tr>
        {/* <Table.Td>{incentive_id}</Table.Td> */}
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.title}
                onChange={(event) => changeRowData('title', event.currentTarget.value)}
            />
        </Table.Td>
        {/* <Table.Td>
            {JSON.stringify(rowData.recNums)}
        </Table.Td> */}
        <Table.Td>
            <Checkbox
                disabled={!editing}
                checked={rowData.timeOfPurchase}
                onChange={(event) => changeRowData('timeOfPurchase', event.currentTarget.checked)}
            />
        </Table.Td>
        <Table.Td>
            <Checkbox
                disabled={!editing}
                checked={rowData.discountOnRemainder}
                onChange={(event) => changeRowData('discountOnRemainder', event.currentTarget.checked)}
            />
        </Table.Td>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.url}
                onChange={(event) => changeRowData('url', event.currentTarget.value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.percentage}
                onChange={(value) => changeRowData('percentage', value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.cap}
                onChange={(value) => changeRowData('cap', value)}
            />
        </Table.Td>
        {/* <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.order}
                onChange={(value) => changeRowData('order', value)}
            />
        </Table.Td> */}
        <Table.Td>
            {editing && <DesignedIconButton
                tooltip={"Remove Incentive"}
                onClick={removeRow}
                icon={<IconTrash />}
                disabled={isPending} />}
        </Table.Td>
    </Table.Tr>
}

export function CommonLineItemTable(
    { lineItems: data, setLineItems: setData, original_rec_id, editing, isPending, hidePrice, allowCreateProduct, showLineItemDiscounts, hideAdminItems }:
        {
            isPending: boolean,
            hidePrice: boolean,
            allowCreateProduct: boolean,
            showLineItemDiscounts: boolean,
            hideAdminItems: boolean,
            editing: boolean,
            original_rec_id: string,
            setLineItems: (newLineItems: Record<string, CommonUILineItem>) => void,
            lineItems: Record<string, CommonUILineItem>
        }) {
    const [showCreateProductModel, setShowCreateProductModal] = useState(false)
    const { products } = useContext(ProductDataContext)
    const filteredProducts = products
        .filter(p => !p.properties.recommendation_id || productMatchesRecNumber(p, original_rec_id))
        .map(p => ({ ...p, name: p.properties.name ?? "" }))
    const lineItemsToShow = Object.values(data)
        .filter(li => {
            if (hideAdminItems) {
                const product = products.find(p => p.id == li.product_id)
                if (product && product.properties.seeair_admin_discount == "yes") {
                    return false
                }
            }
            return true
        })
    return <VStack>

        <Table
            striped
            withTableBorder
        >
            <Table.Thead>
                <Table.Tr>
                    <Table.Td colSpan={hidePrice ? 4 : 5}><VStack center><TextSm>Line Items</TextSm></VStack></Table.Td>
                </Table.Tr>
                <Table.Tr>
                    {/* <Table.Th>line_item_id</Table.Th> */}
                    <Table.Th>product</Table.Th>
                    <Table.Th>quantity</Table.Th>
                    {!hidePrice && <Table.Th>price_per_unit</Table.Th>}
                    <Table.Th>description</Table.Th>
                    {showLineItemDiscounts && <Table.Th>LineItem Discounts</Table.Th>}
                    <Table.Th className={"w-12"}></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    lineItemsToShow
                        .map(li => <LineItemInputRow
                            key={li.line_item_id}
                            original_rec_id={original_rec_id}
                            li={li}
                            data={data}
                            setData={setData}
                            filteredProducts={filteredProducts}
                            showLineItemDiscounts={showLineItemDiscounts}
                            editing={editing}
                            isPending={isPending}
                            hidePrice={hidePrice}
                        />)
                }
            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm>{`${lineItemsToShow.length} line items`}</TextSm></VStack></Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
        <HStack>
            {editing && <AdminCombobox
                options={filteredProducts}
                onChange={(p) => {
                    console.log(`Product chosen: ${JSON.stringify(p)}`)
                    const line_item_id = genId("li")
                    const newData: CommonUILineItems = {
                        ...data,
                        [line_item_id]: {
                            line_item_id,
                            name: p.name,
                            quantity: 1,
                            price_per_unit: parseFloat((p as unknown as HubspotProduct).properties.price ?? "0"),
                            product_id: p.id,
                            discounts: {}
                        }
                    }
                    setData(newData)
                }}
                disabled={isPending}
            />}
            {editing && allowCreateProduct && <DesignedIconButton
                tooltip={"Create Product"}
                onClick={() => {
                    setShowCreateProductModal(true)
                }}
                disabled={isPending}
                icon={<IconCloudPlus />}
            />}
        </HStack>
        <Modal title={"Create Hubspot Product"}
            opened={showCreateProductModel}
            onClose={() => {
                setShowCreateProductModal(false)
            }}>
            <CreateHubspotProductPanel initialRecommendationId={original_rec_id}
                close={() => setShowCreateProductModal(false)} />
        </Modal>
    </VStack>

}




export function LineItemInputRow({ li, data, setData, filteredProducts, editing, isPending, hidePrice, showLineItemDiscounts, original_rec_id }: {
    original_rec_id: string,
    editing: boolean,
    isPending: boolean,
    filteredProducts: Array<AdminComboboxOption & HubspotProduct>,
    li: CommonUILineItem,
    data: CommonUILineItems,
    showLineItemDiscounts: boolean,
    hidePrice: boolean
    setData: (data: CommonUILineItems) => void
}) {
    const [showingDetailsModal, setShowingDetailsModal] = useState(false)
    return <Table.Tr>
        {/* <Table.Td>{truncateString(li.line_item_id, 6)}</Table.Td> */}
        <Table.Td>
            <AdminCombobox
                initiallySelectedId={li.product_id}
                options={filteredProducts}
                disabled={!editing || isPending}
                onChange={p => {
                    const newData: CommonUILineItems = { ...data }
                    const newRow: CommonUILineItem = { ...newData[li.line_item_id]! }
                    newRow.product_id = p.id
                    newRow.price_per_unit = parseFloat((p as unknown as HubspotProduct).properties.price ?? "0")
                    newRow.name = p.name
                    newData[li.line_item_id] = newRow
                    setData(newData)
                }} /></Table.Td>
        <Table.Td>
            <NumberExpressionInput
                value={li.quantity}
                onChange={(newQuantity) => {
                    const newData: CommonUILineItems = { ...data }
                    const newRow: CommonUILineItem = { ...newData[li.line_item_id]! }
                    newRow.quantity = parseFloat(`${newQuantity}`)
                    newData[li.line_item_id] = newRow
                    setData(newData)
                }}
                disabled={!editing || isPending}
            />
        </Table.Td>
        {!hidePrice &&
            < Table.Td >
                {li.price_per_unit && <NumberExpressionInput
                    value={li.price_per_unit}
                    onChange={(newPrice) => {
                        const newData: CommonUILineItems = { ...data }
                        const newRow: CommonUILineItem = { ...newData[li.line_item_id]! }
                        newRow.price_per_unit = parseFloat(`${newPrice}`)
                        newData[li.line_item_id] = newRow
                        setData(newData)
                    }}
                    disabled={!editing || isPending}
                    money
                />}
            </Table.Td>
        }
        <Table.Td>
            <Popover width={300} trapFocus position={"bottom"} withArrow shadow={"md"}>
                <Popover.Target>
                    <Button><TextXs>{truncateString(li.description ?? "Customize", 15)}</TextXs></Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <Textarea size={"xs"} value={li.description ?? undefined} placeholder={"Description..."}
                        onChange={(e) => {
                            const newData: CommonUILineItems = { ...data }
                            const newRow: CommonUILineItem = { ...newData[li.line_item_id]! }
                            newRow.description = e.currentTarget.value
                            newData[li.line_item_id] = newRow
                            setData(newData)
                        }} />
                </Popover.Dropdown>
            </Popover>
        </Table.Td>
        {showLineItemDiscounts &&
            <Table.Td>
                <Popover width={600} trapFocus position={"bottom"} withArrow shadow={"md"}>
                    <Popover.Target>
                        <Button><TextXs>{`Discounts (${Object.keys(li.discounts ?? {}).length})`}</TextXs></Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <CommonDiscountsTable
                            isPending={isPending}
                            editing={editing}
                            original_rec_id={original_rec_id}
                            setDiscounts={(newDiscounts) => {
                                const newData: CommonUILineItems = { ...data }
                                const newRow: CommonUILineItem = { ...newData[li.line_item_id]! }
                                newRow.discounts = newDiscounts
                                newData[li.line_item_id] = newRow
                                setData(newData)
                            }}
                            discounts={li.discounts ?? {}}
                        />
                    </Popover.Dropdown>
                </Popover>
            </Table.Td>
        }
        <Table.Td>
            {editing &&
                <DesignedIconButton
                    tooltip={"Remove Line Item"}
                    onClick={() => {
                        const rest: CommonUILineItems = { ...data }
                        console.log(`current ids: ${JSON.stringify(Object.keys(rest))} - deleting: ${li.line_item_id}`)
                        delete rest[li.line_item_id]
                        setData(rest)
                    }}
                    disabled={isPending}
                    icon={<IconTrash />}
                />}
        </Table.Td>
    </Table.Tr >
}


function CreateHubspotProductPanel({ initialRecommendationId, close }: {
    initialRecommendationId: string,
    close: () => void
}) {
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [description, setDescription] = useState("")
    const [recommendation_id, setRecommendation_id] = useState(initialRecommendationId)
    const [seeair_part_number, setPartNumber] = useState("")
    const {
        mutate,
        isPending
    } = trpc.ADMIN.createHubspotProduct.useMutation(getUseMutationOpt(trpc.useUtils(), () => {
        setName("")
        setPrice("")
        setDescription("")
        setRecommendation_id(initialRecommendationId)
        close()
    }))
    return <VStack>
        <TextInput label={"Product Name"} value={name} onChange={(e) => setName(e.currentTarget.value)} />
        <TextInput label={"Product Price"} value={price} onChange={(e) => setPrice(e.currentTarget.value)} />
        <TextInput label={"Product Description"} value={description}
            onChange={(e) => setDescription(e.currentTarget.value)} />
        <TextInput label={"Recommendation Numbers"} value={recommendation_id}
            onChange={(e) => setRecommendation_id(e.currentTarget.value)} />
        <TextInput label={"Part Numbers"} value={seeair_part_number}
            onChange={(e) => setPartNumber(e.currentTarget.value)} />
        <DesignedIconButton disabled={isPending} icon={<IconDeviceFloppy />} tooltip={"Create"} onClick={() => {
            mutate({
                name,
                price,
                description,
                recommendation_id,
                seeair_part_number
            })
        }} />
    </VStack>
}