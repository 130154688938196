import React, { PropsWithChildren, useContext } from 'react'
import {
    DesignedHeaderFooterCap
} from "./DesignBase.js";
import { DesignedFooter } from "./DesignedFooter.js";
import { DesignedNavHeader } from "./DesignedNavHeader.js";
import classNames from "classnames";
import { UserDataContext } from "./UserDataContext.js";
import { LoadingDrawer } from './LoadingDrawer.js';

export function Layout({ newsletterSurvey = false, headerOverlap = false, loadingDrawer = false, children }: PropsWithChildren<{ loadingDrawer?: boolean, newsletterSurvey?: boolean, headerOverlap?: boolean }>) {
    const { user, isLoading } = useContext(UserDataContext)
    return <div className={"min-h-screen relative"}>
        <header className={"flex-grow-0 relative"}>
            <div className={"flex flex-col w-full absolute top-0 left-0 z-40"}>
                <DesignedHeaderFooterCap />
                <DesignedNavHeader user={user} isLoading={isLoading} />
            </div>
        </header>
        <div className={classNames("flex flex-col w-full absolute left-0")}
            style={{
                top: headerOverlap ? '0' : '11.5rem',
                minHeight: 'calc(100vh - 11.5rem)'
            }}>
            <main className={"flex-grow flex flex-col h-full"}>
                <div className={"flex-grow flex flex-col relative h-full"}>
                    {children}
                </div>
            </main>
            <footer className={"flex-grow-0"}>
                <DesignedFooter newsletterSurvey={newsletterSurvey} />
            </footer>
            <DesignedHeaderFooterCap />
        </div>
            {loadingDrawer&&<LoadingDrawer />}
    </div>
}
