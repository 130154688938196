import {GradientBox, VStack} from "./DesignBase.js";
import {PropsWithChildren} from "react";
import React from 'react'

export function ErrorBoxPage({children}:PropsWithChildren) {
    return <VStack center classNames={"flex-grow bg-white"}>
        <GradientBox classNames={"p-12"}>
            <VStack center>
                {children}
            </VStack>
        </GradientBox>
    </VStack>
}