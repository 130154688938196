import React from "react";
import { ContractorProperty, RecommendationDiscount} from "@seeair/schemas";

type ProductData = {
    products:Array<ContractorProperty>,
    incentives:Array<RecommendationDiscount>
}
const DefaultContractorProductData:ProductData = {
    products: [],
    incentives: []
}
export const ContractorProductDataContext = React.createContext<ProductData>(DefaultContractorProductData)
