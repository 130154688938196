import dayjs from 'dayjs'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {ElectrificationData} from "@seeair/schemas";
import React from 'react'


export function EnergyChart({electrification, show,allMonths, mobile = true}: {
    electrification: ElectrificationData,
    show: 'cost' | 'usage',
    allMonths: boolean,
    mobile?: boolean
}) {
    const chartData = [...electrification.energy_chart].sort((a, b) => b.Date - a.Date)
        .slice(allMonths ? 0 : 1, allMonths ? electrification.energy_chart.length : 13)
        .sort((a,b)=>a.Date-b.Date)
        .map((row) => ({
            name: dayjs.unix(row.Date).format('MMM-YY'),
            Other: Math.round(show == 'usage' ? row.Other_Usage : row.Other_Cost),
            Heating: Math.round(show == 'usage' ? row.Heating_Usage : row.Heating_Cost),
            Cooling: Math.round(show == 'usage' ? row.Cooling_Usage : row.Cooling_Cost),
        }))
    return (
        <ResponsiveContainer width={'100%'} height={350}>
            <BarChart
                data={chartData}
                stackOffset={"sign"}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray={"50000"} vertical={false}/>
                <XAxis dataKey={"name"} includeHidden/>
                <YAxis padding={{bottom: 1}} unit={show == "usage" ? "kWh" : "USD"}/>
                <Tooltip/>
                <Legend
                    layout={mobile ? "horizontal" : "vertical"}
                    align={mobile ? "center" : "right"}
                    verticalAlign={mobile ? "top" : "middle"}
                    wrapperStyle={{paddingLeft: 10}}
                />
                {/* <ReferenceLine y={0} stroke="#000" /> */}
                <Bar
                    dataKey={"Other"}
                    fill={"#E3DE03"}
                    barSize={25}
                    isAnimationActive={false}
                    stackId={"stack"}
                />
                <Bar
                    dataKey={"Heating"}
                    fill={"#DC9540"}
                    barSize={25}
                    isAnimationActive={false}
                    stackId={"stack"}
                />
                <Bar
                    dataKey={"Cooling"}
                    fill={"#59BBFD"}
                    barSize={25}
                    isAnimationActive={false}
                    stackId={"stack"}
                />
                <ReferenceLine
                    y={show == "usage"
                        ? electrification.baseload_electricity_usage + electrification.baseload_oil_usage + electrification.baseload_gas_usage
                        : (electrification.baseload_electricity_usage * electrification.avg_electricity_cost) + (electrification.baseload_oil_usage * electrification.avg_oil_cost) + (electrification.baseload_gas_usage * electrification.avg_gas_cost)}
                    stroke={"black"}
                    strokeDasharray={"2 4"}
                >
                    <Label value={"Baseload"} position={"insideBottomLeft"} stroke={"black"} fontWeight={"100"} fontSize={"12"}/>
                </ReferenceLine>
                {/*{electrification.baseload_oil_usage > 0 && <ReferenceLine*/}
                {/*    y={show == "usage"*/}
                {/*        ? electrification.baseload_oil_usage*/}
                {/*        : electrification.baseload_oil_usage * electrification.avg_oil_cost}*/}
                {/*    stroke={"black"}*/}
                {/*    strokeDasharray={"2 4"}*/}
                {/*>*/}
                {/*    <Label value={"Oil Baseload"} position={"insideBottomLeft"} stroke={"black"} fontWeight={"100"} fontSize={"12"}/>*/}
                {/*</ReferenceLine>*/}
                {/*}*/}
                {/*{electrification.baseload_gas_usage > 0 && <ReferenceLine*/}
                {/*    y={show == "usage"*/}
                {/*        ? electrification.baseload_gas_usage*/}
                {/*        : electrification.baseload_gas_usage * electrification.avg_gas_cost}*/}
                {/*    stroke={"black"}*/}
                {/*    strokeDasharray={"2 4"}*/}
                {/*>*/}
                {/*    <Label value={"Gas Baseload"} position={"insideBottomLeft"} stroke={"black"} fontWeight={"100"} fontSize={"12"}/>*/}
                {/*</ReferenceLine>*/}
                {/*}*/}
            </BarChart>
        </ResponsiveContainer>
    )
}