import { EditableSingleTextFieldWidget, getUseMutationOpt, trpc } from "@seeair/shared-components";
import React from "react";
import { Assessment } from "@seeair/schemas";

export function AssessmentProjectNumberWidget({ assessment }: { assessment: Assessment }) {
    const { isPending: isPending, mutate: mutate } = trpc.ADMIN.setAssessmentProjectNumber.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    return (
        <EditableSingleTextFieldWidget
            label={"hancock_project_number"}
            isPending={isPending}
            initialValue={assessment.project_number ?? ""}
            saveValue={(hancock_project_number) =>
                mutate({
                    assessment_id: assessment.assessment_id,
                    hancock_project_number,
                })
            }
        />
    );
}
