import { HomeAggregate, ProjectAggregate } from "@seeair/schemas"
import {
    AbsoluteCenterStack,
    AdminDocumentsTable,
    Badge,
    DesignedIconButton, getUseMutationOpt,
    HStack,
    ProductDataContext,
    TextBase,
    trpc,
    VStack
} from "@seeair/shared-components"
import {
    calculateInvoicePayments,
    calculateInvoiceTotal,
    formatMoney,
    getLineItemsWithDiscountsFromProject,
    PreloadedHubspotProductCatalog,
    summarizeQuotedLineItemChanges
} from "@seeair/shared"
import { Checkbox, Divider, Loader, NumberInput, Table, Textarea } from "@mantine/core"
import { IconFileText } from "@tabler/icons-react"
import React, { useContext, useState } from "react"
import { SyncSummary } from './AdminProjectLineItemsSyncPanel.js'

export function AdminQuotesPanel({ project, home }: { project: ProjectAggregate, home: HomeAggregate }) {
    const { products } = useContext(ProductDataContext)
    const catalog = new PreloadedHubspotProductCatalog(products)
    const [skipHubspot, setSkipHubspot] = useState(false)
    const [heatLoanAmount, setHeatLoanAmount] = useState(0)
    const [invoiceComment, setInvoiceComment] = useState("")
    const { isPending: isCreateQuotePending, mutate: createQuote } =
        trpc.ADMIN.createPandadocQuoteFromProject.useMutation(
            getUseMutationOpt(trpc.useUtils()),
        )
    const lineItems = getLineItemsWithDiscountsFromProject(project,catalog) 
    const invoiceTotal = calculateInvoiceTotal(lineItems)
    console.log(`line items: ${JSON.stringify(lineItems, null, 2)}`)
    const projectNet = calculateInvoiceTotal(getLineItemsWithDiscountsFromProject(project,catalog, { netAfterRebates: true }))
    const { remainderAmount, dueNowAmount } = calculateInvoicePayments(invoiceTotal, heatLoanAmount)
    const heatLoanMax = Math.min(25000, projectNet)
    console.log(`invoice total: ${invoiceTotal} project net: ${projectNet} dueNow: ${dueNowAmount} remainder: ${remainderAmount}`)
    const recChanges = summarizeQuotedLineItemChanges(project)
    const CreateQuoteButton = () => <DesignedIconButton
        disabled={isCreateQuotePending}
        icon={<IconFileText />}
        onClick={() => {
            createQuote({
                project_id: project.project_id,
                heatLoanAmount,
                comment: invoiceComment,
                skipHubspot
            })
        }}
        tooltip={`Sync & Create Quote${heatLoanAmount ? " (With Heat Loan)" : ""}`}
    />
    return <VStack>

        <SyncSummary project={project} />

        <Table className={"text-sm mb-8"}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Payment Schedule</Table.Th>
                    <Table.Th>Due</Table.Th>
                    <Table.Th>Amount</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                <Table.Tr>
                    <Table.Td>{dueNowAmount > 0 && <TextBase>Down Payment</TextBase>}</Table.Td>
                    <Table.Td>{dueNowAmount > 0 && <TextBase>On Signing</TextBase>}</Table.Td>
                    <Table.Td>{formatMoney(dueNowAmount, true)}</Table.Td>
                </Table.Tr>
                {heatLoanAmount > 0 && (
                    <Table.Tr>
                        <Table.Td>Heat Loan Payment</Table.Td>
                        <Table.Td>Upon Receipt</Table.Td>
                        <Table.Td>{formatMoney(heatLoanAmount, true)}</Table.Td>
                    </Table.Tr>
                )}
                {remainderAmount > 0 && (
                    <Table.Tr>
                        <Table.Td>Final Payment</Table.Td>
                        <Table.Td>Project Completion</Table.Td>
                        <Table.Td>{formatMoney(remainderAmount, true)}</Table.Td>
                    </Table.Tr>
                )}
            </Table.Tbody>
        </Table>
        <NumberInput
            label={"HeatLoan Amount"}
            placeholder={"Leave Blank For No HeatLoan"}
            min={500}
            max={heatLoanMax}
            clampBehavior={"strict"}
            allowDecimal={true}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={","}
            prefix={"$"}
            value={heatLoanAmount}
            onChange={(value) => {
                setHeatLoanAmount(typeof value == "string" ? parseFloat(value) : value)
            }}
            leftSection={
                <Checkbox
                    checked={heatLoanAmount > 0}
                    onChange={(e) =>
                        setHeatLoanAmount(e.currentTarget.checked ? heatLoanMax : 0)
                    }
                />
            }
        />
        <Textarea
            className={"w-full"}
            label={"Invoice Comments"}
            placeholder={"Leave Blank for no Comments"}
            value={invoiceComment}
            onChange={(value) => {
                setInvoiceComment(value.currentTarget.value)
            }}
        />
        <VStack>
            <HStack>
                {
                    project.quoted_line_items
                        ? recChanges.length > 0
                            ? <React.Fragment>
                                <Badge red>Quote is out of date with Recommendations</Badge>
                                <ul>{recChanges.map(c => <li key={c}>{c}</li>)}</ul>
                            </React.Fragment>
                            : <Badge green>Quote is up to date</Badge>
                        : <Badge blue>Not yet Quoted</Badge>
                }
            </HStack>

        </VStack>
        <HStack leftCenter classNames={"my-4"}>
            <CreateQuoteButton />
            <Checkbox
                checked={skipHubspot}
                onChange={(e)=>setSkipHubspot(e.currentTarget.checked)}
                label={"(danger) skip hubspot"}
            />
        </HStack>
        {isCreateQuotePending && (
            <AbsoluteCenterStack>
                <Loader />
            </AbsoluteCenterStack>
        )}
        <Divider size={"xl"} label={"Quotes"} labelPosition={"center"} />
        <AdminDocumentsTable home={home} project={project} filter={["quote"]} />

    </VStack>
}