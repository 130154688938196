import React, {Fragment, useContext, useState} from "react";
import {Swirls} from './Illustrations.js';
import {FullWithDesignedSection, HSpacer, RStack, Text2Xl, Text5Xl} from './DesignBase.js';
import {Widget} from "@typeform/embed-react";
import {DesignedButton} from './DesignComponents.js';
import {UtmDataContext} from './UtmDataContext.js';

export function DesignedHeroSection() {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [showingWaitlist, setShowingWaitlist] = useState(false)
    const backgroundBackground = "url(/design-assets/background1.png) lightgray 50% / cover no-repeat"
    const minHeight = "60rem"
    const {utm_source, utm_content} = useContext(UtmDataContext)
    console.log(`utm_source: ${utm_source}`)
    return <div className={"relative min-h-240"} style={{
        background: backgroundBackground,
    }}>
        <div className={"relative min-h-240 overflow-clip"}>
            <Swirls/>
        </div>
        <FullWithDesignedSection background={"none"} absolute minHeight={minHeight}>
            <div className={"relative z-30 min-h-240 pt-48"}>
                <RStack style={{marginTop: "-5rem", transform: "scale(0.8)"}}>
                    <div className={"flex flex-col h-full w-full"}>
                        <HSpacer/>
                    </div>
                    <div className={"flex flex-col h-full w-full rounded-2xl p-10 md:-mr-20"} style={{
                        background: "linear-gradient(111deg, rgba(202, 240, 248, 0.80) -5.88%, rgba(255, 255, 255, 0.80) 19.61%, rgba(255, 255, 255, 0.80) 49.34%, rgba(202, 240, 248, 0.80) 91.69%)",
                        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)"
                    }}>
                        <HSpacer/>
                        {
                            formSubmitted
                                ? <div id={"eligibility-form-submitted"}><Text2Xl>We&apos;ll reach out within the next 24 hours to start your assessment after we&apos;ve confirmed your eligibility</Text2Xl></div>
                                : showingWaitlist
                                    ? <Widget id={"DWTMBvAp"} className={"eligibility"}
                                              autoFocus={true}
                                              opacity={0}
                                              style={{
                                                  // marginTop: "-8rem",
                                                  justifyContent: "flex-start",
                                                  // transform: "scale(0.8)"
                                              }}
                                              hidden={{
                                                  "ad_source": utm_source,
                                                  "ad_content": utm_content,
                                              }}
                                              hideHeaders={true}
                                              disableScroll={true}
                                              autoResize={true}
                                              hideFooter={false}
                                              inlineOnMobile={true}
                                              noScrollbars={true}
                                              onSubmit={() => setFormSubmitted(true)}
                                    />
                                    : <Fragment>
                                        <Text5Xl>Home Improvement. Improved.</Text5Xl>
                                        {/*<Text4Xl>Home Improvement. Improved.</Text4Xl>*/}
                                        <Text2Xl>You want a sustainable and healthy home. We’re here to help. Get in touch to be one of the first to access our no-cost energy & health
                                            assessment.</Text2Xl>
                                        <DesignedButton onClick={() => {
                                            setShowingWaitlist(true)
                                        }}>Get In Touch</DesignedButton>
                                    </Fragment>
                        }

                    </div>
                </RStack>
            </div>
        </FullWithDesignedSection>
    </div>
}