import { Divider } from "@mantine/core";
import { ProjectAggregate, HomeAggregate, AssessmentFiles } from "@seeair/schemas";
import { documentExistsInProject, getLatestAssessment, PARTNUMBER_EXTERIOR_VINYL_SIDED_WALL_CAVITY, PARTNUMBER_EXTERIOR_WOOD_SIDED_WALL_CAVITY, PARTNUMBER_INTERIOR_WALL_CAVITY, projectIncludesPartNumber } from "@seeair/shared";
import { VStack, getUseMutationOpt, trpc, DesignedIconButton, TextBase, AdminDocumentsTable, ProductDataContext, HStack, ProjectFilesTable } from "@seeair/shared-components";
import React, { useContext } from "react";


export function AdminProjectPanelPermittingSection({
    project, home
}: {
    project: ProjectAggregate;
    home: HomeAggregate;
}) {
    const { products } = useContext(ProductDataContext)
    const { isPending: isGeneratePermitAuthPending, mutate: generatePermitAuth } =
        trpc.ADMIN.publishPermitAuthDoc.useMutation(getUseMutationOpt(trpc.useUtils()))
    const { isPending: isGenerateWallDisclosurePending, mutate: generateWallDisclosure } =
        trpc.ADMIN.publishWallDisclosureDoc.useMutation(getUseMutationOpt(trpc.useUtils()))
    const wallDisclosureAlreadyExists = documentExistsInProject(project, "wall-disclosure")
    const permitAuthAlreadyExists = documentExistsInProject(project, "permit-auth")
    const includesInteriorBlow = projectIncludesPartNumber(project, PARTNUMBER_INTERIOR_WALL_CAVITY, products)
    const includesExteriorVinyl = projectIncludesPartNumber(project, PARTNUMBER_EXTERIOR_VINYL_SIDED_WALL_CAVITY, products)
    const includesExteriorWood = projectIncludesPartNumber(project, PARTNUMBER_EXTERIOR_WOOD_SIDED_WALL_CAVITY, products)
    const requiresWallDisclosure = includesInteriorBlow || includesExteriorVinyl || includesExteriorWood
    const assessment=getLatestAssessment(home.assessments)
    const files: AssessmentFiles = assessment?.assessment_files_list ?? {}
    return <VStack>
        <Divider size={"xl"} label={"Permit Authorization"} />
        <HStack>
            <DesignedIconButton
                icon={<TextBase>Publish Permit Authorization 🐼</TextBase>}
                onClick={() => {
                    generatePermitAuth({
                        project_id: project.project_id,
                    })
                }}
                disabled={permitAuthAlreadyExists || isGeneratePermitAuthPending}
                tooltip={permitAuthAlreadyExists ? "Doc Already Exists" : "Generate In Pandadoc"}
            />
        </HStack>
        <Divider size={"xl"} label={"Wall Disclosure"} />
        <TextBase>Interior Blow {includesInteriorBlow ? "✅" : "❌"}</TextBase>
        <TextBase>Exterior Wood Blow {includesExteriorWood ? "✅" : "❌"}</TextBase>
        <TextBase>Exterior Vinyl Blow {includesExteriorVinyl ? "✅" : "❌"}</TextBase>
        <HStack>
            <DesignedIconButton
                icon={<TextBase>Publish Wall Disclosure 🐼</TextBase>}
                onClick={() => {
                    generateWallDisclosure({
                        project_id: project.project_id,
                    })
                }}
                disabled={!requiresWallDisclosure || wallDisclosureAlreadyExists || isGenerateWallDisclosurePending}
                tooltip={requiresWallDisclosure ? wallDisclosureAlreadyExists ? "Doc Already Exists" : "Generate In Pandadoc" : "Not Required"}
            />
        </HStack>
        <Divider size={"xl"} label={"Documents"} />
        <AdminDocumentsTable home={home} project={project} filter={["permit-auth", "wall-disclosure"]} />
        <Divider size={"xl"} label={"Files"} />
        <ProjectFilesTable home={home} projects={[project]} filter={["permit","permit_documentation"]} forRole={"admin"}/>
    </VStack>
}