import React, { PropsWithChildren } from "react";
import { trpc } from './trpc.js';
import { ProductDataContext } from './ProductDataContext.js';

export function ContractorProductsDataProvider({children}:PropsWithChildren){
    const [products] = trpc.CONTRACTOR.getProductList.useSuspenseQuery()
    const [incentives] = trpc.SHEETS.getIncentiveList.useSuspenseQuery()
    return <ProductDataContext.Provider value={{products, incentives}}>
        {children}
    </ProductDataContext.Provider>
}