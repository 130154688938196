// Custom assertion function
import _buildJsep, {CoreExpression, Expression} from "jsep";
import {match} from "ts-pattern";
import dayjs, {Dayjs} from "dayjs";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import weekOfYear from "dayjs/plugin/weekOfYear.js";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";

export function withinOneOf(actual: number, expected: number, delta = 1) {
    return Math.abs(actual - expected) <= delta;
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf('.')
    if (dotIndex < 0) {
        return ''
    } else {
        return fileName.slice(dotIndex + 1)
    }
}


// REGEX to build a string parser: (?<!\\){[\s\S]*?}
type Range = { color: string; simpleCondition: string }

export function getMatching(ranges: Array<Range>, val: number): string | undefined {
    const lookups = {val}
    for (const range of ranges) {
        const cond = mapSimple(range.simpleCondition)
        const res = getResult(_buildJsep(cond), lookups)
        if (res === true) {
            return range.color
        }
    }
}

export function getResult(cond: Expression, lookups: Record<string, any>): unknown {
    return (
        match(cond as CoreExpression)
            .with({type: 'Identifier'}, (expr) => {
                if (!(expr.name in lookups)) {
                    throw new Error(`[JSEP] Unknown symbol: ${expr.name}`)
                }
                return lookups[expr.name]
            })
            .with({type: 'Literal'}, (expr) => expr.value)
            .with({type: 'BinaryExpression'}, (expr) => {
                const left = getResult(expr.left, lookups)
                const right = getResult(expr.right, lookups)

                return match(expr.operator)
                    .with('&&', () => Boolean(left) && Boolean(right))
                    .with('||', () => Boolean(left) || Boolean(right))
                    .otherwise(() => {
                        // Numeric options
                        if (!isNumeric(left) || !isNumeric(right)) {
                            throw new Error(
                                `[JSEP] Left and Right operators must be numeric, got: [${typeof left}, ${typeof right}]`
                            )
                        }
                        return match(expr.operator)
                            .with('>', () => left > right)
                            .with('>=', () => left >= right)
                            .with('<', () => left < right)
                            .with('<=', () => left <= right)
                            .otherwise(() => {
                                throw new Error(`[JSEP] Unsupported operator: ${expr.operator}`)
                            })
                    })
            })
            // No
            // .with({type: 'MemberExpression'}, () => {})
            .otherwise(() => {
                throw new Error(`[JSEP] Unsupported expression type: ${cond.type}`)
            })
    )
}

/**
 * <val
 * >val
 * val-val
 * <val, >val, val-val
 */
export function mapSimple(str: string): string {
    return str
        .trim()
        .split(', ')
        .map((_part) => {
            const part = _part.trim()
            if (['>', '<'].includes(part[0] ?? '')) {
                return `val ${part[0]} ${part.slice(1)}`
            } else if (part.indexOf('-') > 0) {
                const [a, b] = part.split('-')
                if (!a || !b) {
                    throw new Error(`[JSEP] Error parsing "-" expression part: ${part}`)
                }
                return `val >= ${a.trim()} && val <= ${b.trim()}`
            } else {
                throw new Error(`[JSEP] Unsupported expression part: ${part}`)
            }
        })
        .map((part) => `(${part})`)
        .join(' || ')
}

export function truncateString(text: string, charMax: number) {
    if(!text) {
        return ""
    }
    if (text.length > charMax) {
        return `${text.slice(0, charMax)}...`
    }
    return text
}
export function reverseTruncateString(text: string, charMax: number) {
    if(!text) {
        return ""
    }
    if (text.length > charMax) {
        return `...${text.slice(text.length-charMax)}...`
    }
    return text
}
export function truncateEmail(text: string, charMax: number) {
    return text
    // if(!text) {
    //     return ""
    // }
    // const index = text.indexOf('@')
    // const remainingLength = text.length - index
    // if(index >=0 && remainingLength < charMax) {
    //     return `${truncateString(text.slice(0,index),charMax-remainingLength)}${text.slice(index)}`
    // } else {
    //     return truncateString(text,charMax)
    // }
}

export function shortDateFormat(date: string | number): string {
    return dayjs(date).format("ddd, MMM D YYYY")
}

export function extraShortDateFormat(date: string | number): string {
    return dayjs(date).format("YY-MM-DD hh:mm")
}


const isNumeric = (x: unknown): x is number => typeof x === 'number'

export function formatNumber(n: number, maximumFractionDigits: number = 2): string {
    return n.toLocaleString(undefined, {maximumFractionDigits})
}

export function lastNChars(s: string, n: number): string {
    if (s.length <= n) {
        return s
    } else {
        return `...${s.slice(-1 * n)}`
    }
}

export function firstNChars(s: string, n: number): string {
    if (s.length <= n) {
        return s
    } else {
        return `${s.slice(n)}...`
    }
}

export function formatMoney(n: number,exact: boolean = false): string {
    return n.toLocaleString(undefined, {maximumFractionDigits: exact || n<100 ? 2 : 0, style: "currency", currency: "USD"})
}

export function formatPercentage(n: number): string {
    return `${(n * 100).toLocaleString(undefined, {maximumFractionDigits: 0})}%`
}

export function formatQuantity(n: number): string {
    return n.toLocaleString(undefined, {maximumFractionDigits: 1})
}

export function formatYears(n: number): string {
    if(n==0) {
        return "Immediate"
    }
    if(n>5) {
        return `${Math.round(n)} years`
    } else {
        const years = Math.floor(n)
        const months = Math.floor((n-years)*12)
        const yearString = `${years} year${years>1?"s":""}`
        const monthString = `${months} month${years>1?"s":""}`
        if(months > 0 && years > 0) {
            return `${yearString}, ${monthString}`
        } else if(months == 0 && years > 0) {
            return yearString
        } else {
            return monthString
        }
    }
}
export function genHex(len:number):string {
    return Math.floor(Math.random() * Math.pow(16,len)).toString(16).padStart(len, '0')
}
export const genId = (type: string) => `${type}_${genHex(8)}-${genHex(4)}-${genHex(4)}-${genHex(4)}-${genHex(12)}`

export const genShortId = (type: string) => `${type}_${genHex(6)}`

export type EventLogger = {captureMessage:(message:string)=>void}
export const StdIoEventLogger = {
    captureMessage:(message:string)=>{
        console.log(message)
    }
}

export function isoStringArrayToDateArray(isoStrings:Array<string>|null|undefined):Array<Date> {
    if(!isoStrings) {
        return []
    }
    return isoStrings.map(o=>dayjs(o).toDate())
}
export function dateArrayToIsoStringArray(dates:Array<Date>):Array<string> {
    return dates.map(o=>dateToIsoString(o))
}
export function dateToIsoString(date:Date):string {
    return dayjs(date).format("YYYY-MM-DD")
}
export function isoStringToDate(isoString:string):Date {
    return dayjs(isoString).toDate()
}
export function dateIsToday(date:Date):boolean {
    const today = new Date()
    return date.getDate() == today.getDate() && date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth()
}
export function isoStringArrayContainsDate(isoStrings:Array<string>|null|undefined,date:Date):boolean {
    const d = dayjs(date).startOf('day').format("YYYY-MM-DD")
    return (isoStrings??[]).includes(d)
}
export function formatIsoStringArray(isoStrings:Array<string>):string {
    if(isoStrings.length==0){
        return "None"
    }
    const sorted = isoStrings.sort().map(s=>dayjs(s).startOf('day'))
    const years = Array.from(new Set(sorted.map(d=>d.year())))
    const months = Array.from(new Set(sorted.map(d=>`${d.year()}-${d.month()}`)))
    const groups:Array<{from:Dayjs,to:Dayjs|null}> = []
    let from:Dayjs|null = null
    let prev:Dayjs|null = null
    for(const d of sorted) {
        if(!from) { //start loop
            from = d
            prev = d
        } else {
            if(prev!.add(1,'day').isSame(d)) { //if it is a continuation of days
                prev = d
            } else { //breaks continuation, so start a new one and add group
                groups.push({from,to:prev})
                from = d
                prev = d
            }
        }
    }
    groups.push({from:from!,to:prev})
    // const prefix = years.length == 1
    //     ? months.length == 1
    //         ? sorted[0]!.format("MMM YYYY")+": "
    //         : sorted[0]!.format("YYYY")+": "
    //     : "" // multiple years so each date string will have a year component
    const prefix = years.length == 1
        ? sorted[0]!.format("YYYY")+": "
        : "" // multiple years so each date string will have a year component
    return prefix+groups.map(g=>formatDayRange(g.from,g.to,years.length==1,false/*months.length==1*/)).join(", ")
}
function formatDayRange(from:Dayjs,to:Dayjs|null,ignoreYear:boolean,ignoreMonth:boolean):string {
    if(to){
        return `${formatDay(from,ignoreYear,ignoreMonth)} - ${formatDay(to,ignoreYear,ignoreMonth)}`
    } else {
        return formatDay(from,ignoreYear,ignoreMonth)
    }
}
function formatDay(day:Dayjs,ignoreYear:boolean,ignoreMonth:boolean):string {
    return day.format(ignoreYear ? ignoreMonth ? "D" : "MMM DD" : "YYYY-MM-DD")
}
//returns the number of columns that a mantine datepicker should display given an list of days
//trivially this should just be the distinct number of months, but mantine will start to show dates on the previous month, if the are in the same week
// export function getNumberOfColumns(dates:Array<Date>):number {
//     if(dates.length == 0){
//         return 1
//     }
//     const months = Array.from(
//         new Set(
//             dates.map(o=>{
//                 const d = dayjs(o)
//                 const priorMonth = d.subtract(1,'month').endOf('month')
//                 //if day is the same week as the previous month, we need to count it as that previous month
//                 if(priorMonth.week() == d.week()) {
//                     // console.log(`${d} is in same week as end of prior month(${priorMonth}), returning ${priorMonth.month()}`)
//                     return priorMonth.month()
//                 } else {
//                     // console.log(`${d} is not in week of prior month(${priorMonth}), returning ${d.month()}`)
//                     return d.month()
//                 }
//             })
//         )
//     )
//     // console.log(`months: ${JSON.stringify(months)}`)
//     return months.length
// }
export function initDayJs() {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)
    dayjs.extend(weekOfYear)
    dayjs.extend(timezone)
    dayjs.extend(utc)
}
export function hashToNumber(input:string,n:number) {
    // Generate a hash code by summing the character codes of the string
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash += input.charCodeAt(i);
    }
    // Map the hash to 1, 2, or 3
    return (hash % n);
}
export function parseFirstAndLastName(fullName:string|null|undefined): {first_name:string,last_name:string} {
    if(!fullName) {
        return {first_name:'',last_name:''}
    } else {
        const [first_name,...rest] = fullName.split(' ')
        return {first_name:first_name!,last_name:(rest??[]).join(' ')}
    }
}
export function formatCamelCase(s:string):string{
    return s
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Add space before capital letters
    .replace(/_/g, ' ') // Add space before capital letters
    .replace(/^./, str => str.toUpperCase()); // Capitalize first letter
}