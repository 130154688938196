import {
    ContractorHome,
    ContractorProject,
    ProjectFile,
    ProjectFilesEnum,
    ProjectFileTypeFormatted,
    ProjectFileUploadNonce,
} from "@seeair/schemas";
import React, { useEffect, useState} from "react";
import {
    Divider,
    FileInput,
    Loader,
    NativeSelect,
    Table
} from "@mantine/core";
import {trpc} from "./trpc.js"
import {
    IconDownload,
    IconTrash
} from "@tabler/icons-react";
import { HStack, TextBase, TextSm, TextXs, VStack} from "./DesignBase.js";
import { DesignedButton} from "./DesignComponents.js";
import {getUseMutationOpt, showErrorNotification} from './mutationHelper.js';
import {
    extractBucketAndKeyFromHttpUrl,
    extractBucketAndKeyFromS3Url,
    extraShortDateFormat,
    getFileVersionsSortedByLatest,
    PROJECT_TYPE_ISM,
    reverseTruncateString
} from "@seeair/shared";

type TitledProjectFile=ProjectFile&{project_title:string,project_id:string,home_id:string}
export function ProjectFilesTable({projects, home, filter,forRole}: {
    projects: Array<ContractorProject>,
    home: ContractorHome,
    filter: Array<ProjectFilesEnum>,
    forRole:'admin'|'contractor'|'homeowner'
}) {
    const nonIsmProjects = projects.filter(p=>p.project_type!=PROJECT_TYPE_ISM)
    if(nonIsmProjects.length==0){
        return <VStack center><TextBase>No Active Projects</TextBase></VStack>
    }
    const files = projects.reduce((acc,v)=>
        [
            ...acc,
            ...Object.values(v.project_files??{})
                .map(f=>({...f,project_title:v.project_title,project_id:v.project_id,home_id:v.home_id}))
        ],[] as Array<TitledProjectFile>)
    return <VStack>
        <Table striped highlightOnHover withTableBorder classNames={{tr: "min-h-24"}}>
            <Table.Tbody>
                {filter.map((k) => <ProjectFilesRow
                    projects={nonIsmProjects}
                    key={k}
                    fileKey={k}
                    fileVersions={files.filter(f => f.type == k)}
                    home={home}
                    presignedImageUrls={[]}
                    showS3Url={forRole=="admin"}
                />)}
            </Table.Tbody>
        </Table>
    </VStack>
}

export function ProjectFilesRowVersionsTable({
    projects,
                                                    fileKey,
                                                    sortedFileVersions,
                                                    home,
                                                    presignedImageUrls,
                                                    showS3Url
                                                }: {
    projects:Array<ContractorProject>,
                                                    fileKey: ProjectFilesEnum
    sortedFileVersions: Array<TitledProjectFile>,
    home: ContractorHome,
    presignedImageUrls: Array<string>,
    showS3Url: boolean
}) {
    const {
        mutate: deleteFile,
        isPending
    } = trpc.HOMEOWNER.deleteProjectFileVersion.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <Table>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>File</Table.Th>
                {projects.length>1&&<Table.Th>For</Table.Th>}
                <Table.Th>Download</Table.Th>
                <Table.Th>Delete</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {sortedFileVersions.map(fv => {

                const presignedImageUrl = presignedImageUrls.find(o => {
                    const {Bucket: fvBucket, Key: fvKey} = extractBucketAndKeyFromS3Url(fv.s3_url)
                    const {Bucket: presignedBucket, Key: presignedKey} = extractBucketAndKeyFromHttpUrl(o)
                    return fvBucket == presignedBucket && fvKey == presignedKey
                })
                return <Table.Tr key={fv.s3_url}>
                    <Table.Td className={"w-96"}>
                        <HStack>
                            {presignedImageUrl && <img className={"w-16 h-16 mr-4"} src={presignedImageUrl}/>}
                            <VStack>
                                <TextSm>{fv.name}</TextSm>
                                <TextXs light classNames={"px-2"}>{extraShortDateFormat(fv.created_date)}</TextXs>
                                <TextXs>{fv.created_by}</TextXs>
                                {showS3Url&&<TextXs>{reverseTruncateString(fv.s3_url, 100)}</TextXs>}
                            </VStack>
                        </HStack>
                    </Table.Td>
                    {projects.length>1&&<Table.Td className={"w-40"}>{fv.project_title}</Table.Td>}
                    <Table.Td><ProjectFileDownloadWidget selectedFileVersion={fv}/></Table.Td>
                    <Table.Td><DesignedButton
                        disabled={isPending}
                        onClick={() => deleteFile({
                            home_id: fv.home_id,
                            project_id: fv.project_id,
                            file_enum: fileKey,
                            file_id: fv.file_id
                        })}>Delete <IconTrash/></DesignedButton>
                    </Table.Td>
                </Table.Tr>
            })}
        </Table.Tbody>
    </Table>
}

export function ProjectFilesRow({projects, fileVersions, fileKey, home, presignedImageUrls,showS3Url}: {
    projects: Array<ContractorProject>,
    home: ContractorHome,
    fileVersions: Array<TitledProjectFile>
    fileKey: ProjectFilesEnum,
    presignedImageUrls: Array<string>,
    showS3Url:boolean
}) {
    const sortedFileVersions = getFileVersionsSortedByLatest(fileVersions ?? [])
    return <Table.Tr className={"h-24"}>
        <Table.Td className={"w-28"}>{ProjectFileTypeFormatted[fileKey]}</Table.Td>
        <Table.Td>
            {
                sortedFileVersions.length > 0 &&
                <ProjectFilesRowVersionsTable projects={projects}
                sortedFileVersions={sortedFileVersions}
                                                 home={home}
                                                 fileKey={fileKey}
                                                 presignedImageUrls={presignedImageUrls}
                                                 showS3Url={showS3Url}/>
            }
        </Table.Td>
        <Table.Td><ProjectFileUploadWidget projects={projects} fileKey={fileKey}/></Table.Td>
    </Table.Tr>
}




function ProjectFileUploadWidget({projects, fileKey}: {
    projects:Array<ContractorProject>,
    fileKey: ProjectFilesEnum
}) {
    const [project_id,setProject_id]=useState(projects[0]?.project_id)
    const [fileUpload, setFileUpload] = useState<File | null>(null)
    const {
        data: saveUrlResponse,
        isError: isSaveUrlError,
        error: saveUrlError,
        isPending: isSaveUrlPending,
        mutate: saveUrl
    } =
        trpc.HOMEOWNER.saveProjectFileUploadUrl.useMutation(getUseMutationOpt(trpc.useUtils(), () => setFileUpload(null)))
    const {
        data: fileUploadUrl
        , isError: isUploadFileError,
        error: fileUploadError,
        isPending: isUploadFilePending,
        mutate: uploadFile
    } = trpc.HOMEOWNER.getProjectFileUploadUrl.useMutation({
        onSuccess: async ({url, nonce}: { url: string, nonce: ProjectFileUploadNonce }) => {
            try {
                await fetch(url, {
                    method: "PUT",
                    body: fileUpload!,
                    headers: {
                        "Content-Type": fileUpload!.type
                    }
                })
                saveUrl(nonce)
            } catch (e) {
                console.log("upload error", e)
            }
        },onError: ({})=>{
            showErrorNotification({message:"Upload Failed"})
        }
    })
    return <VStack center>
        <Divider size={"sm"} label={`Upload ${ProjectFileTypeFormatted[fileKey]}`}/>
        {projects.length>1&&<NativeSelect

            className={"w-full"}
            value={project_id}
            onChange={(e)=>setProject_id(e.currentTarget.value)}
            data={projects.map(p=>({label:p.project_title,value:p.project_id}))}
        />}
        <FileInput
            className={"w-full"}
            value={fileUpload}
            onChange={setFileUpload}
            placeholder={`Select File ...`}
            classNames={{input: "border-black text-black"}}
            clearable
        />
        <DesignedButton
            className={"w-full"}
         disabled={!fileUpload || isUploadFilePending || !project_id}
                onClick={() => uploadFile({
                    project_id:project_id!,
                    home_id:projects.find(p=>p.project_id==project_id)!.home_id,
                    contentType: fileUpload!.type,
                    projectFileEnum: fileKey,
                    fileName: fileUpload!.name
                })}
        >{isUploadFilePending || isSaveUrlPending ? <Loader/> : "Upload"}</DesignedButton>
        {isUploadFileError && <span className={"text-error"}>{fileUploadError.message}</span>}
        {isSaveUrlError && <span className={"text-error"}>{saveUrlError.message}</span>}
    </VStack>
}


function ProjectFileDownloadWidget({selectedFileVersion}: {
    selectedFileVersion?: TitledProjectFile
}) {
    const {
        data: fileDownloadUrl,
        isPending: isFileDownloadUrlPending,
        mutate: getDownloadUrl,
        reset: resetDownloadUrl
    } = trpc.HOMEOWNER.getProjectFileDownloadUrl.useMutation()
    useEffect(() => {
        resetDownloadUrl()
    }, [selectedFileVersion, resetDownloadUrl]);
    return <DesignedButton className={"h-full"} disabled={!selectedFileVersion} onClick={() => {
        if (fileDownloadUrl) {
            window.open(fileDownloadUrl.url, "_blank")
        } else if (selectedFileVersion) {
            getDownloadUrl({s3_url: selectedFileVersion.s3_url,home_id:selectedFileVersion.home_id,project_id:selectedFileVersion.project_id})
        }
    }}>
        {fileDownloadUrl ? "Open": isFileDownloadUrlPending ?
            <Loader/> :
            <React.Fragment>Download <IconDownload/></React.Fragment>}
    </DesignedButton>
}