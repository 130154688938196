import { EditableSingleTextFieldWidget,getUseMutationOpt, trpc } from "@seeair/shared-components";
import React from "react";
import { Assessment } from "@seeair/schemas";

export function AssessmentHubspotLinkWidget({ assessment }: { assessment: Assessment }) {
    const { isPending: saveHubspotDealIdIsPending, mutate: saveHubspotDealId } =
        trpc.ADMIN.setHubspotDealId.useMutation(getUseMutationOpt(trpc.useUtils()));
    return (
        <EditableSingleTextFieldWidget
            label={"hubspot_deal_id"}
            isPending={saveHubspotDealIdIsPending}
            initialValue={assessment.hubspot_deal_id ?? ""}
            saveValue={(hubspot_deal_id) =>
                saveHubspotDealId({
                    assessment_id: assessment.assessment_id,
                    hubspot_deal_id,
                })
            }
        />
    );
}
