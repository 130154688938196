import { ContractorProject } from "@seeair/schemas";
import React, { useContext, useState } from "react";
import { SheetsDataContext } from './SheetsDataContext.js';
import { getUseMutationOpt } from './mutationHelper.js';
import { trpc } from './trpc.js';
import { IconDeviceFloppy, IconCancel, IconPencil, IconCopy } from "@tabler/icons-react";
import { VStack, HStack, TextLg, TextError } from './DesignBase.js';
import { DesignedIconButton } from './DesignComponents.js';
import { CommonLineItemTable } from './LineItemsAndDiscountsCommon.js';

export function ContractorCompletedLineItemsInputPane({
    project,
}: {
    project: ContractorProject
}) {
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(project.completed_line_items ?? project.quoted_line_items ?? {})
    const { recommendationsSheet } = useContext(SheetsDataContext)
    const {
        mutate: setCompletedLineItems,
        isPending: setCompletedLineItemsIsPending
    } = trpc.CONTRACTOR.setCompletedLineItemsForProject.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))

    return <VStack>{
        Object.values(project.quoted_line_items ?? {}).length == 0
            ? <TextError>Missing LineItems from Quote</TextError>
            : <HStack hAuto rightCenter>
                {editing && <DesignedIconButton
                    tooltip={"Save Lineitems"}
                    disabled={setCompletedLineItemsIsPending}
                    icon={<IconDeviceFloppy />}
                    onClick={() => {
                        setCompletedLineItems({
                            home_id: project.home_id,
                            project_id: project.project_id,
                            completedLineItems: data
                        })
                    }} />}
                <DesignedIconButton
                    tooltip={editing ? "Cancel" : "Edit Lineitems"}
                    onClick={() => {
                        if (editing) {
                            setData(project.completed_line_items ?? project.quoted_line_items ?? {})
                        }
                        setEditing(!editing)
                    }}
                    disabled={setCompletedLineItemsIsPending}
                    icon={editing ? <IconCancel /> : <IconPencil />}
                />
                {Object.values(project.quoted_line_items ?? {}).length > 0 && Object.values(project.completed_line_items ?? {}).length == 0 &&
                    <DesignedIconButton
                        tooltip={"No Changes From Quote"}
                        disabled={setCompletedLineItemsIsPending}
                        icon={<IconCopy />}
                        onClick={() => {
                            setCompletedLineItems({
                                home_id: project.home_id,
                                project_id: project.project_id,
                                completedLineItems: data
                            })
                        }} />
                }
            </HStack>
    }
        {
            Object.keys(data).map((recNum) => <VStack key={recNum}>
                <TextLg>{recommendationsSheet.find(r => r.id == recNum)?.title ?? "Unknown"}</TextLg>
                <CommonLineItemTable
                    hideAdminItems={true}
                    showLineItemDiscounts={false}
                    editing={editing}
                    isPending={setCompletedLineItemsIsPending}
                    original_rec_id={recNum}
                    lineItems={data[recNum]!.line_items}
                    setLineItems={(newLineItems) => {
                        const newData = { ...data, [recNum]: { line_items: newLineItems } }
                        setData(newData)
                    }}
                    hidePrice={true}
                    allowCreateProduct={false}
                />
            </VStack>)
        }</VStack>
}