import {
    DesignedIconButton,
    HStack,
    showErrorNotification,
    TextSmItalic,
    trpc,
    VStack
} from "@seeair/shared-components";
import { AdminAssessmentsTable } from "./AdminAssessmentsTable.js";
import React, { useEffect, useState } from "react";
import { useUserSearch } from "./admin-utils.js";
import { TextInput, UnstyledButton } from "@mantine/core";
import { IconRewindForward20, IconX } from "@tabler/icons-react"
import { AdminAssessmentSummary } from "@seeair/schemas";

export function AdminRecentAssessmentsTable() {
    const [isFetching,setIsFetching] = useState(false)
    const [page, setPage] = useState(0);
    const [loadedUsers, setLoadedUsers] = useState([] as Array<AdminAssessmentSummary>);
    const { usersData, searchText, setSearchText } = useUserSearch(loadedUsers ?? []);
    const utils = trpc.useUtils()
    const fetchPageHandler = () => {
        setIsFetching(true)
        utils.ADMIN.listRecentUsersAggregate.fetch({
            limit:200,
            page:page
        }).then((latestLoadedUsers)=>{
            setLoadedUsers((previousValue)=>[...previousValue, ...(latestLoadedUsers ?? [])]);
            setPage((previousValue)=>previousValue+1)
        }).catch((e)=>{
            showErrorNotification(e as {message:string})
        }).finally(()=>{
            setIsFetching(false)
        })
    }

    useEffect(() => {
        fetchPageHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <VStack>
            <HStack leftCenter>
                <TextInput
                    placeholder={"Search"}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    rightSection={<UnstyledButton onClick={()=>setSearchText("")} className={"hover:bg-gray-300 pt-2"}><IconX /></UnstyledButton>}
                />
                <TextSmItalic classNames={"mx-8"}>{"loaded: "}{loadedUsers.length}{" homes"}</TextSmItalic>
            </HStack>
            <AdminAssessmentsTable summaries={usersData} />
            <HStack>
                <DesignedIconButton
                    icon={<IconRewindForward20 />}
                    onClick={fetchPageHandler}
                    disabled={isFetching}
                    tooltip={"Load More"}
                />
            </HStack>
        </VStack>
    );
}
