import {
    COLOR_LIGHT_GREY, DesignedButton, FullWithDesignedSection,
    HStack,
    RStack,
    TextError, TextRXl,
    VStack
} from "@seeair/shared-components";
import {Loader} from "@mantine/core";
import {BlogBox} from "./BlogBox.js";
import React from "react";
import {useNavigate} from "@tanstack/react-router";
import {trpc} from "@seeair/shared-components";

export function BlogSection({title,skipId}:{title:string,skipId?:string}) {
    const navigate = useNavigate()
    const {isLoading: isBlogsLoading, data, isError: isBlogsError} = trpc.PUBLIC.getLatestBlogs.useQuery()
    const blogs = (data||[]).filter(b=>b.id!=skipId)
    return <FullWithDesignedSection background={COLOR_LIGHT_GREY}>
        <TextRXl wFull center>{title}</TextRXl>
        <VStack center>
            {isBlogsLoading && <Loader/>}
            {isBlogsError && <TextError>Error Loading Blogs</TextError>}
        </VStack>
        <VStack>
            <RStack lg>
                {blogs && blogs[0] && <BlogBox blog={blogs[0]} classNames={"w-full lg:w-1/2"}/>}
                {blogs && blogs[1] && <BlogBox blog={blogs[1]} classNames={"w-full lg:w-1/2"}/>}
            </RStack>
            <RStack lg>
                {blogs && blogs[2] && <BlogBox blog={blogs[2]} classNames={"w-full lg:w-1/2"}/>}
                {blogs && blogs[3] && <BlogBox blog={blogs[3]} classNames={"w-full lg:w-1/2"}/>}
            </RStack>
        </VStack>
        <HStack><DesignedButton onClick={() => navigate({to: "/blog"})}>Explore More</DesignedButton></HStack>
    </FullWithDesignedSection>
}