import { COLOR_WHITE, FullWithDesignedSection } from "@seeair/shared-components";
import { Tabs } from "@mantine/core";
import { IconClock, IconMailDollar, IconSelectAll, IconToolsKitchen } from "@tabler/icons-react";
import { AdminRecentAssessmentsTable } from "./AdminRecentAssessmentsTable.js";
import { AdminUserManager } from "./AdminUserManager.js";
import React from "react";
import { AdminToolsPane } from "./AdminToolsPane.js";
import { AdminPaymentsManager } from "./AdminPaymentsManager.js";

export function AdminDashboard() {
    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <Tabs defaultValue={"recent"} keepMounted={false}>
                <Tabs.List>
                    <Tabs.Tab value={"recent"} leftSection={<IconClock />}>
                        Recent
                    </Tabs.Tab>
                    <Tabs.Tab value={"users"} leftSection={<IconSelectAll />}>
                        Users
                    </Tabs.Tab>
                    <Tabs.Tab value={"tools"} leftSection={<IconToolsKitchen />}>
                        Tools
                    </Tabs.Tab>
                    <Tabs.Tab value={"payments"} leftSection={<IconMailDollar />}>
                        Payments
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={"recent"}>
                    <AdminRecentAssessmentsTable />
                </Tabs.Panel>
                <Tabs.Panel value={"users"}>
                    <AdminUserManager />
                </Tabs.Panel>
                <Tabs.Panel value={"payments"}>
                    <AdminPaymentsManager />
                </Tabs.Panel>
                <Tabs.Panel value={"tools"}>
                    <AdminToolsPane />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    );
}
