import { Box, MantineSize, Stepper } from "@mantine/core";
import classNames from "classnames";
import React, { ReactNode, useState } from "react"
import { z } from "zod";
import { VStack } from './DesignBase.js';

type StepperTabStep = {
    content: ReactNode;
    label?: string;
    description?: string;
}

export function StepperTabs<E extends z.ZodEnum<[string, ...string[]]>>(
    { schema, steps, status, size = "xl" }: {
        schema: E,
        steps: Record<z.infer<E>, StepperTabStep>,
        status: z.infer<E>,
        size?: MantineSize
    }) {
    const [selectedTab, setSelectedTab] = useState(status)
    return <VStack classNames={classNames("my-4 pb-4",{"border":size!="xl"} )}>
        <Stepper
            size={size}
            active={schema.options.indexOf(status)}
            onStepClick={(value) => {
                console.log(`setting tab: ${value} ${schema.options[value]}`)
                setSelectedTab(schema.options[value]!)
            }}
            classNames={{
                stepDescription: size=="xl"?"max-w-36":"max-w-16",
                stepLabel: size=="xs"?"max-w-12":"max-w-36",
                stepBody: size=="xs"?"ml-2":"",
                stepIcon: size=="xs"?"max-w-4 w-4":"",
                steps: "border-b-slate-200 border-b border-t-0 border-l-0 border-r-0 border-solid"
            }}
        // allowNextStepsSelect={false}
        >
            {schema.options.map(e => <Stepper.Step
                key={e}
                label={steps[e as z.infer<E>].label ?? e}
                description={steps[e as z.infer<E>].description}
                className={classNames("hover:bg-slate-200 h-full p-2 rounded-sm", { "border-b-2-primary-light-blue": e == selectedTab })}
            />
            )}
        </Stepper>
        {
            Object.keys(steps).map(s=><Box className={classNames({hidden:selectedTab!=s})} key={s} pos={"relative"}>
                    {/* <LoadingOverlay visible={loading} /> */}
                    {steps[s as z.infer<E>].content}
                </Box>)
        }
    </VStack>
}