import React, {PropsWithChildren, useEffect} from "react";
import {trpc} from "./trpc.js"
import * as Sentry from "@sentry/react";
import { UserDataContext } from "./UserDataContext.js";


export function QueryUserDataProvider({children}:PropsWithChildren){
    const {data:user,isLoading} = trpc.PUBLIC.getCurrentUser.useQuery()
    useEffect(()=>{
        Sentry.setUser(user ?? null)
    },[user])
    return (
        <UserDataContext.Provider value={{user:user||null,isLoading}}>
            {children}
        </UserDataContext.Provider>
    )
}