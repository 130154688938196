import {
    ContractorLineItem,
    ContractorProject,
    HubspotLineItem,
    ProjectAggregate,
    RecommendationDiscounts,
    RecommendationLineItem,
    RecommendationLineItems,
} from "@seeair/schemas"
import { Decimal } from "decimal.js"
import { IHubspotProductCatalog } from "./iHubspotCrmClient.js"

export type RecommendationLineItemWithDiscount = RecommendationLineItem & { discount_percentage: Decimal }
export type RecommendationLineItemWithDiscountAndQuotedQuantity = RecommendationLineItemWithDiscount & {
    quoted_quantity: number
}

const Money = Decimal.clone()

export function mapRecommendationLineItemWithDiscountsToHubspotLineItems(
    items: Array<RecommendationLineItemWithDiscount>,
): Array<HubspotLineItem["properties"]> {
    return items.map((li) => ({
        name: li.name,
        hs_product_id: li.product_id,
        quantity: `${li.quantity}`,
        price: `${li.price_per_unit}`,
        discount: null,
        hs_discount_percentage: `${roundPercentageToThousanth(li.discount_percentage).toNumber()}`,
        tax: null,
        amount: `${roundPenny(calculateAmount(li)).toNumber()}`,
    }))
}

export function calculateDiscountPercentageForIndividualLineItem(
    lineItem: RecommendationLineItem,
    options: DiscountCalculationOptions,
): Decimal {
    let discount = new Money(0)
    for (const d of Object.values(lineItem.discounts ?? {})) {
        if (options.net || (options.netAfterRebates && d.rebate) || d.timeOfPurchase) {
            const quantity = new Money(lineItem.quantity)
            const amount = quantity.times(lineItem.price_per_unit)
            const potentialPercentDiscount = amount.times(d.percentage)
            const cap = new Money(d.cap)
            //make sure we don't discount beyond the cap amount
            if (cap.lessThan(potentialPercentDiscount)) {
                const partialDiscount = cap.dividedBy(amount)
                discount = discount.plus(partialDiscount)
            } else {
                //make sure this won't put us over 100% discount
                if (discount.plus(d.percentage).lessThanOrEqualTo(1)) {
                    //hubspot won't allow over 100%
                    discount = discount.plus(d.percentage)
                } else {
                    discount = new Money(1)
                }
            }
        } else {
            console.log("skipping individual line item incentives")
        }
    }
    return discount
}

export type DiscountCalculationOptions = {
    net?: boolean
    netAfterRebates?: boolean
    hideAdminItems?: boolean
}
export function applyDiscountsToLineItems(
    line_items: RecommendationLineItems,
    discounts: RecommendationDiscounts,
    options: DiscountCalculationOptions = { net: false, netAfterRebates: false, hideAdminItems: false },
): Array<RecommendationLineItemWithDiscount> {
    const lis: Array<RecommendationLineItemWithDiscount> = Object.values(line_items).map((li) => ({
        ...li,
        discount_percentage: calculateDiscountPercentageForIndividualLineItem(li, options),
    })) //.sort((o1,o2)=>(o2.quantity*o2.price_per_unit)-(o1.quantity*o1.price_per_unit))
    for (const d of Object.values(discounts)) {
        // console.log(`calculating: ${d.incentive_id}`)
        if (options.net || (options.netAfterRebates && d.rebate) || d.timeOfPurchase) {
            console.log(`processing incentive: ${d.incentive_id}`)
            let remainingCap = new Money(d.cap)
            for (let li of lis) {
                if (remainingCap.greaterThan(0)) {
                    const quantity = new Money(li.quantity)
                    const price_per_unit = new Money(li.price_per_unit)
                    const alreadyAppliedDiscount = li.discount_percentage
                    const discountPercentage = alreadyAppliedDiscount.plus(d.percentage).greaterThan(1)
                        ? new Money(1).minus(alreadyAppliedDiscount)
                        : new Money(d.percentage)
                    const amount = quantity.times(price_per_unit)
                    const potentialPercentDiscount = amount.times(discountPercentage)
                    if (remainingCap.lessThan(potentialPercentDiscount)) {
                        //then we can't give the full percentage discount
                        //we can calculate the true percentage to give, and update the cap to 0
                        const partialDiscount = remainingCap.dividedBy(amount)
                        li.discount_percentage = li.discount_percentage.plus(partialDiscount)
                        remainingCap = new Money(0)
                        console.log(
                            `calculated partial discount: ${partialDiscount.toNumber()} because of exhausted cap: ${JSON.stringify(li)}`,
                        )
                    } else {
                        //make sure this won't put us over 100% discount
                        if (li.discount_percentage.plus(d.percentage).lessThanOrEqualTo(1)) {
                            //hubspot won't allow over 100%
                            remainingCap = remainingCap.minus(potentialPercentDiscount)
                            li.discount_percentage = li.discount_percentage.plus(d.percentage)
                        } else {
                            li.discount_percentage = new Money(1)
                            remainingCap = remainingCap.minus(
                                amount.times(new Money(1).minus(li.discount_percentage)),
                            )
                        }
                    }
                    // console.log(`li updated; ${JSON.stringify(li)}`)
                } else {
                    // console.log("remaining cap 0")
                }
            }
        } else {
            console.log(`skipping incentive ${d.incentive_id}`)
        }
    }
    return lis
}

function calculateAmount(li: RecommendationLineItemWithDiscount): Decimal {
    const undiscountedAmount = new Money(li.quantity).times(li.price_per_unit)
    return undiscountedAmount.times(new Money(1).minus(li.discount_percentage))
}

function roundPenny(d: Decimal): Decimal {
    return d.times(100).floor().dividedBy(100)
}

function roundPercentageToThousanth(d: Decimal): Decimal {
    return d.times(100000).floor().dividedBy(1000)
}

export function calculateInvoiceTotal(lis: Array<RecommendationLineItemWithDiscount>): number {
    return lis
        .reduce(
            (acc, v) =>
                acc.plus(
                    new Money(v.price_per_unit)
                        .times(v.quantity)
                        .times(new Money(1).minus(v.discount_percentage)),
                ),
            new Money(0),
        )
        .toNumber()
}

export function calculateInvoicePayments(
    total: number,
    heatLoanAmount: number,
): {
    dueNowAmount: number
    remainderAmount: number
} {
    const totalAfterHeatLoan = new Money(total).minus(heatLoanAmount)
    if (totalAfterHeatLoan.lessThanOrEqualTo(0)) {
        return { dueNowAmount: 0, remainderAmount: 0 }
    } else {
        return {
            dueNowAmount: roundPenny(totalAfterHeatLoan.times(0.3)).toNumber(),
            remainderAmount: roundPenny(totalAfterHeatLoan.times(0.7)).toNumber(),
        }
    }
}
function getLineItemsFromProject(project: ProjectAggregate): Array<RecommendationLineItem> {
    const completed = Object.values(project.completed_line_items ?? {})
    const quoted = Object.values(project.quoted_line_items ?? {})
    if (completed.length > 0) {
        return completed.flatMap((r) => Object.values(r.line_items ?? {}))
    } else if (quoted.length > 0) {
        return quoted.flatMap((r) => Object.values(r.line_items ?? {}))
    } else {
        return project.recommendations.flatMap((r) => Object.values(r.line_items ?? {}))
    }
}
export function calculateDealAmount(project: ProjectAggregate): string {
    const lineItems = getLineItemsFromProject(project)
    const dealAmount = lineItems.reduce(
        (acc, v) => acc.plus(new Money(v.quantity).times(v.price_per_unit)),
        new Money(0),
    )
    return roundPenny(dealAmount).toString()
}
function getLineItemsAndDiscountsFromProject(
    project: ProjectAggregate,
): Array<{ line_items: RecommendationLineItems; discounts: RecommendationDiscounts }> {
    const completed = Object.values(project.completed_line_items ?? {})
    const quoted = Object.values(project.quoted_line_items ?? {})
    if (completed.length > 0) {
        return completed.map((r) => ({
            line_items: r.line_items ?? {},
            discounts: r.discounts ?? {},
        }))
    } else if (quoted.length > 0) {
        return completed.map((r) => ({
            line_items: r.line_items ?? {},
            discounts: r.discounts ?? {},
        }))
    } else {
        return project.recommendations.map((r) => ({
            line_items: r.line_items ?? {},
            discounts: r.discounts ?? {},
        }))
    }
}
export function getContractorLineItemsFromProject(
    project: ContractorProject,
): Array<ContractorLineItem & { recNum: string }> {
    const completed = Object.keys(project.completed_line_items ?? {})
    const quoted = Object.keys(project.quoted_line_items ?? {})
    if (completed.length > 0) {
        return completed.flatMap((recNum) =>
            Object.values(project.completed_line_items![recNum]!.line_items ?? {}).map((li) => ({
                ...li,
                recNum,
            })),
        )
    } else if (quoted.length > 0) {
        return quoted.flatMap((recNum) =>
            Object.values(project.quoted_line_items![recNum]!.line_items ?? {}).map((li) => ({
                ...li,
                recNum,
            })),
        )
    } else {
        return project.recommendations.flatMap((r) =>
            Object.values(r.line_items ?? {}).map((li) => ({ ...li, recNum: r.original_rec_id })),
        )
    }
}
export function calculateTotalUndiscountedProjectCost(
    project: ProjectAggregate,
    catalog: IHubspotProductCatalog,
    hideAdminItems: boolean = false,
): number {
    const lineItemsWithDiscounts = getLineItemsWithDiscountsFromProject(project, catalog, { hideAdminItems })
    return lineItemsWithDiscounts
        .reduce((acc, v) => acc.plus(calculateUndiscountedCost(v)), new Decimal(0))
        .toNumber()
}
export function getLineItemsWithDiscountsFromProject(
    project: ProjectAggregate,
    catalog: IHubspotProductCatalog,
    options: DiscountCalculationOptions = { net: false, netAfterRebates: false, hideAdminItems: false },
): Array<RecommendationLineItemWithDiscount> {
    const lineItemsAndDiscounts = getLineItemsAndDiscountsFromProject(project)
    return lineItemsAndDiscounts
        .flatMap((r) => applyDiscountsToLineItems(r.line_items ?? {}, r.discounts ?? {}, options))
        .filter((li) => {
            if (options.hideAdminItems) {
                const p = catalog.getProductById(li.product_id)
                if (p != "not_found" && p.properties.seeair_admin_discount == "yes") {
                    return false
                }
            }
            return true
        })
}

export function getLineItemsWithDiscountsAndQuotedQuantitiesFromProject(
    project: ProjectAggregate,
    catalog: IHubspotProductCatalog,
    options: DiscountCalculationOptions = { net: false, netAfterRebates: false, hideAdminItems: false }
): Array<RecommendationLineItemWithDiscountAndQuotedQuantity> {
    const completed = Object.values(project.completed_line_items ?? {})
    const quoted = Object.values(project.quoted_line_items ?? {})
    if (completed.length == 0) {
        throw `project ${project.project_id} has no completed line items`
    }
    if (quoted.length == 0) {
        throw `project ${project.project_id} has no quoted line items`
    }
    const completedLineItems = completed.flatMap((r) => applyDiscountsToLineItems(r.line_items, r.discounts,options))
    const quotedLineItems = quoted.flatMap((r) => applyDiscountsToLineItems(r.line_items, r.discounts,options))
    const lineItemsWithQuotedQuantity: Array<RecommendationLineItemWithDiscountAndQuotedQuantity> =
        completedLineItems.map((li) => ({
            ...li,
            quoted_quantity: quotedLineItems.find((q) => q.product_id == li.product_id)?.quantity ?? 0,
        }))
    const removedLineItems = quotedLineItems.filter(
        (q) => !lineItemsWithQuotedQuantity.find((li) => li.product_id == q.product_id),
    )
    for (const rli of removedLineItems) {
        lineItemsWithQuotedQuantity.push({
            ...rli,
            quoted_quantity: rli.quantity,
            quantity: 0,
        })
    }
    return lineItemsWithQuotedQuantity
    .filter((li) => {
        if (options.hideAdminItems) {
            const p = catalog.getProductById(li.product_id)
            if (p != "not_found" && p.properties.seeair_admin_discount == "yes") {
                return false
            }
        }
        return true
    })
}

export function calculateCostForDiscountProvider(lineItem: RecommendationLineItemWithDiscount): Decimal {
    return new Money(lineItem.discount_percentage).times(lineItem.price_per_unit).times(lineItem.quantity)
}
export function calculateUndiscountedCost(lineItem: RecommendationLineItemWithDiscount): Decimal {
    return new Money(lineItem.price_per_unit).times(lineItem.quantity)
}
export function calculateTotalPayments(project: ProjectAggregate): number {
    return (project.payments ?? [])
        .reduce((acc, v) => acc.plus(v.amount), new Money(0))
        .dividedBy(100 /* stripe sends pennies */)
        .toNumber()
}
