import {
    timestamp,
    pgTable,
    primaryKey,
    varchar,
    text,
    json,
    pgEnum,
    jsonb,
    boolean, bigint
} from 'drizzle-orm/pg-core'
import {
    HubspotLineItemAggregate, HubspotMeetingProperties,
} from './hubspot.js'
import {
    Address,
    AirSensorData,
    AssessmentDataCollectionConfiguration, AssessmentDocuments, AssessmentFile,
    AssessmentFilesEnum,
    AssessmentNote,
    AssessmentStatusSchema,
    BlowerDoorTest,
    CombustionSafetyTest,
    EnergyDataRecord,
    HomeDetails, ProjectDocuments, ProjectEvents,
    ProjectFiles,
    ProjectLineItems,
    ProjectStatus,
    RecData,
    RecommendationDiscounts,
    RecommendationLineItems,
    SolarPotential
} from "./jsonb-schemas.js";
import { StripePaymentEvent, StripePaymentEventType } from './stripe.js';


export const REC_STATUS_ENUM = pgEnum('recommendation_status', [
    'not_started',
    'in_progress',
    'done',
])
export const ACCESS_LEVEL_ENUM = pgEnum('access_level_enum', ['read', 'read-write'])

export const homes = pgTable('homes', {
    home_id: varchar('home_id', { length: 255 }).notNull().primaryKey(),
    owner_user_id: varchar('owner_user_id', { length: 255 }).notNull(),
    address: json('address').notNull().$type<Address>(),
    full_address_id: text('full_address_id').notNull(),
    // home_details: jsonb('home_details').notNull().$type<HomeDetailsDB>(),
    lat: varchar('lat', { length: 255 }).notNull(),
    long: varchar('long', { length: 255 }).notNull(),
    thumbnail_url: varchar('thumbnail_url'),
    aggregate_last_modified: bigint('aggregate_last_modified', { mode: 'number' }),
    deleted: boolean('deleted')
})


export const ASS_STATUS_ENUM = pgEnum('assessment_status', AssessmentStatusSchema.options)
export const assessments = pgTable('assessments', {
    assessment_id: varchar('assessment_id', { length: 255 }).notNull(),
    home_id: varchar('home_id', { length: 255 }).notNull(),
    hubspot_deal_id: varchar('hubspot_deal_id', { length: 255 }),
    assessment_label: varchar('assessment_label', { length: 255 }).notNull(),
    electric_usage: json('electric_usage').$type<Record<string, EnergyDataRecord>>(),
    gas_usage: json('gas_usage').$type<Record<string, EnergyDataRecord>>(),
    oil_usage: json('oil_usage').$type<Record<string, EnergyDataRecord>>(),
    location_id: varchar('awair_location_id', { length: 255 }),
    sensor_type: varchar('sensor_type', { length: 255 }),
    last_analysis_run_at: timestamp('last_analysis_run_at'),
    created_at: timestamp('created_at').defaultNow().notNull(),
    home_details: jsonb('home_details').$type<HomeDetails>(),
    air_sensor_data: jsonb('air_sensor_data').$type<Record<string, AirSensorData>>(),
    // weather_data: jsonb('weather_data').$type(), //TODO add zod for this
    solar_data: jsonb('solar_data').$type<SolarPotential>(),
    // assessment_files: jsonb('assessment_files').$type<OldAssessmentFiles>(),
    assessment_notes: jsonb('assessment_notes').$type<Record<string, AssessmentNote>>(),
    assessment_status: ASS_STATUS_ENUM('assessment_status'),
    assessment_meetings: jsonb('assessment_meetings').$type<Record<string, HubspotMeetingProperties>>(),
    data_collection_requirements: jsonb('data_collection_requirements').$type<AssessmentDataCollectionConfiguration>(),
    assessment_files_list: jsonb('assessment_files_list').$type<Record<string, AssessmentFile>>(),
    assessment_documents: jsonb('assessment_documents').$type<AssessmentDocuments>(),
    project_number: varchar('project_number', { length: 255 }),
    utility_company: varchar('utility_company', { length: 255 })
},
    (assess) => ({
        compoundKey: primaryKey({ columns: [assess.home_id, assess.assessment_id] }),
    })
)

export const recommendations = pgTable(
    'recommendations',
    {
        home_id: varchar('home_id', { length: 255 }).notNull(),
        recommendation_id: varchar('recommendation_id', { length: 255 }).notNull(),
        original_rec_id: varchar('original_rec_id', { length: 255 }).notNull(),
        assessment_id: varchar('assessment_id', { length: 255 }),
        rec_data: json('recommendation_data').$type<RecData>().notNull(),
        category: varchar('category', { length: 255 }).notNull(),
        type: varchar('type', { length: 255 }).notNull(),
        title: varchar('recommendation_title', { length: 255 }).notNull(),
        description: text('description'),
        status: REC_STATUS_ENUM('status').notNull(),
        project_id: varchar('project_id', { length: 255 }),
        hidden: boolean('hidden'),
        discounts: jsonb('discounts').$type<RecommendationDiscounts>(),
        line_items: jsonb('line_items').$type<RecommendationLineItems>()
    },
    (rec) => ({
        compoundKey: primaryKey({ columns: [rec.home_id, rec.recommendation_id] }),
    })
)
export const projects = pgTable(
    'projects',
    {
        home_id: varchar('home_id', { length: 255 }).notNull(),
        project_id: varchar('project_id', { length: 255 }).primaryKey().notNull(),
        project_title: varchar('project_title', { length: 255 }).notNull(),
        project_type: varchar('project_type', { length: 255 }),
        hubspot_deal_name: varchar('hubspot_deal_name', { length: 255 }),
        hubspot_deal_id: varchar('hubspot_deal_id', { length: 255 }),
        hubspot_deal_stage: varchar('hubspot_deal_stage', { length: 255 }),
        hubspot_deal_line_items: jsonb('hubspot_deal_line_items').$type<Array<HubspotLineItemAggregate>>(),
        hubspot_deal_amount: varchar('hubspot_deal_amount', { length: 255 }),
        // hubspot_quote_id: varchar('hubspot_quote_id', { length: 255 }),
        // hubspot_quote_url: text('hubspot_quote_url'),
        // hubspot_quote_status: varchar('hubspot_quote_status', { length: 255 }),
        // hubspot_quote_line_items: jsonb('hubspot_quote_line_items').$type<Array<HubspotLineItemAggregate>>(),
        // hubspot_quote_amount: varchar('hubspot_quote_amount', { length: 255 }),
        // hubspot_quote_signature_status: varchar('hubspot_quote_signature_status', { length: 255 }),
        // hubspot_quote_payment_status: varchar('hubspot_quote_payment_status', { length: 255 }),
        hubspot_last_modified: varchar('hubspot_last_modified', { length: 255 }),
        homeowner_availability: jsonb('homeowner_availability').$type<Array<string>>(),
        scheduled_dates: jsonb('scheduled_dates').$type<ProjectEvents>(),
        project_crew: jsonb('project_crew').$type<Array<string>>(),
        project_documents: jsonb('project_documents').$type<ProjectDocuments>(),
        quoted_line_items: jsonb('quoted_line_items').$type<ProjectLineItems>(),
        completed_line_items: jsonb('completed_line_items').$type<ProjectLineItems>(),
        combustion_safety_tests: jsonb('combustion_safety_tests').$type<CombustionSafetyTest>(),
        blower_door_tests: jsonb('blower_door_tests').$type<BlowerDoorTest>(),
        project_status: varchar('project_status', { length: 255 }).$type<ProjectStatus>(),
        deleted:boolean('deleted'),
        project_files: jsonb('project_files').$type<ProjectFiles>(),
    }
)
export const payment_events = pgTable(
    'payment_events',
    {
        id: varchar('id', { length: 255 }).primaryKey().notNull(),
        created: bigint('created', { mode: 'number' }),
        event_type: varchar('event_type', { length: 255 }).$type<StripePaymentEventType>(),
        body: jsonb('body').$type<StripePaymentEvent>(),
        project_id: varchar('project_id', { length: 255 })
    }
)
export const manual_payments = pgTable(
    'manual_payments',
    {
        id: varchar('id', { length: 255 }).primaryKey().notNull(),
        note: varchar('note', { length: 255 }),
        amount: varchar('amount', { length: 255 }),
        created: bigint('created', { mode: 'number' }),
        project_id: varchar('project_id', { length: 255 })
    }
)
export const ai_validation = pgTable(
    'ai_validation',
    {
        v: varchar('v', { length: 255 }).notNull(),
        s3_url: varchar('s3_url', { length: 255 }).notNull(),
        assessment_file_type: varchar('assessment_file_type', { length: 255 }).$type<AssessmentFilesEnum>().notNull(),
        inferred_assessment_file_type: varchar('inferred_assessment_file_type', { length: 255 }).$type<AssessmentFilesEnum>(),
        home_details: jsonb('home_details').$type<HomeDetails>(),
        inferred_home_details: jsonb('inferred_home_details').$type<HomeDetails>(),
    }
)