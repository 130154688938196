import {
    FullWithDesignedSection
} from './DesignBase.js';
import { DesignedTestimonialCard } from './DesignComponents.js';
import React from "react";

export function DesignedTestimonialsSection() {
    const outerClassName = "flex flex-col items-center w-56 h-64"
    const innerClassName = "flex flex-col justify-end h-20"
    return <FullWithDesignedSection background={`#03045E`} minHeight={"49rem"}>
        <div className={"flex flex-col xl:flex-row"}>
            <DesignedTestimonialCard
                text={"SeeAir performed a MassSave Home Energy Assessment for us. Guys were very helpful, knowledgeable and efficient in terms of communication and scheduling. It was a pleasure working with them."}
                name={"Adelya"}
                imgSrc={"/testimonials/adelya.png"}
            />
            <DesignedTestimonialCard
                text={"Great experience with SeeAir modeling my house and identifying insulation improvements that were mostly paid for by Mass Save. Looking forward to a warmer house this winter!"}
                name={"Mike"}
                imgSrc={"/testimonials/mike.png"}
            />
        </div>
        <DesignedTestimonialCard
            text={"The SeeAir team did a great job modeling my home, suggesting improvements, and managing the ultimate project of installing additional insulation and weatherproofing. The SeeAir team also coordinated the MassSaves program, permitting, etc, for me. I was very happy with the service and support I received every step of the process and couldn't recommend SeeAir highly enough for someone looking to improve their home's health and sustainability."}
            name={"Peter"}
            imgSrc={"/testimonials/peter.png"}
        />
    </FullWithDesignedSection>
}

