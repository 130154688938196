import { Divider, Table } from "@mantine/core";
import Decimal from "decimal.js";
import { ProjectAggregate, Assessment, CombustionSafetyTest, CombustionSafetyTestSchema, HomeAggregate } from "@seeair/schemas";
import { getLineItemsWithDiscountsFromProject, calculateCostForDiscountProvider, formatMoney, formatCamelCase, PreloadedHubspotProductCatalog } from "@seeair/shared";
import React, { useContext, useRef } from "react";
import { VStack, HStack, DesignedIconButton, TextBase, TextSm, TextXs, ProductDataContext } from "@seeair/shared-components";
import { ZodNullable, ZodObject, ZodOptional } from "zod";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";

export function WrapHeader({ home, assessment }: { home:HomeAggregate, assessment: Assessment; }) {
    return <Table withRowBorders withTableBorder className={"mb-4"}>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Customer Name</Table.Th>
                <Table.Th>Customer Address</Table.Th>
                <Table.Th>Project Number</Table.Th>
                <Table.Th>Date</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            <Table.Tr>
                <Table.Td>{home.owner.name}</Table.Td>
                <Table.Td>{home.full_address_id}</Table.Td>
                <Table.Td>{assessment.project_number}</Table.Td>
                <Table.Td>{dayjs().format('YYYY-MM-DD')}</Table.Td>
            </Table.Tr>
        </Table.Tbody>
    </Table>
}

export function ProjectPanelAbodeSection({ home, project, assessment }: { home:HomeAggregate, project: ProjectAggregate; assessment: Assessment; }) {
    const { products } = useContext(ProductDataContext)
    const catalog = new PreloadedHubspotProductCatalog(products)
    const printRef = useRef<HTMLDivElement>(null);
    const printFn = useReactToPrint({
        contentRef: printRef,
        documentTitle: `${project.project_title} - ${assessment.project_number}`
    });
    const lineItemsWithDiscounts = getLineItemsWithDiscountsFromProject(project,catalog);
    const abodeSubtotle = lineItemsWithDiscounts.reduce((acc, v) => acc.plus(calculateCostForDiscountProvider(v)), new Decimal(0));
    return <VStack>
        <HStack>
            Abode Packet
            <DesignedIconButton
                icon={<TextBase>Print</TextBase>}
                onClick={() => {
                    printFn();
                }}
                disabled={false}
                tooltip={"Print"} />
        </HStack>
        <VStack>
            <div ref={printRef} className={"print:mx-8 print:my-12"}>
                <h1>{`${project.project_title} - ${assessment.project_number}`}</h1>
                <div className={"print:break-after-page"}>
                    <h2>Weatherization Invoice</h2>
                    <WrapHeader home={home} assessment={assessment}/>
                    <Table>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Name</Table.Th>
                                <Table.Th>Quantity</Table.Th>
                                <Table.Th>Price Per Unit</Table.Th>
                                <Table.Th>Provided Discount</Table.Th>
                                <Table.Th>Subtotal</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {lineItemsWithDiscounts.map(li => <Table.Tr key={li.line_item_id}>
                                <Table.Td>{li.name}</Table.Td>
                                <Table.Td>{li.quantity}</Table.Td>
                                <Table.Td>{formatMoney(li.price_per_unit, true)}</Table.Td>
                                <Table.Td>{li.discount_percentage.times(100).toNumber()}</Table.Td>
                                <Table.Td>{formatMoney(calculateCostForDiscountProvider(li).toNumber(), true)}</Table.Td>
                            </Table.Tr>)}
                        </Table.Tbody>
                        <Table.Tfoot>
                            <Table.Tr>
                                <Table.Th>Total</Table.Th>
                                <Table.Th></Table.Th>
                                <Table.Th></Table.Th>
                                <Table.Th></Table.Th>
                                <Table.Th>{formatMoney(abodeSubtotle.toNumber(), true)}</Table.Th>
                            </Table.Tr>
                        </Table.Tfoot>
                    </Table>
                </div>
                <div className={"print:break-after-page"}>
                    <h2>Wx Combustion Safety Test</h2>
                    <WrapHeader home={home} assessment={assessment}/>
                    {
                        Object.keys(CombustionSafetyTestSchema.shape).filter(k => k != 'v').map(s => {                        
                            const wrappedSkectionSchema = CombustionSafetyTestSchema.shape[s as keyof CombustionSafetyTest] as ZodObject<any>|ZodOptional<ZodNullable<ZodObject<any>>>
                            const sectionSchema= "unwrap" in wrappedSkectionSchema ? wrappedSkectionSchema.unwrap().unwrap() : wrappedSkectionSchema
                            const sectionValue = (project.combustion_safety_tests??{})[s as keyof CombustionSafetyTest] ??{}
                            // console.log(`section value: ${sectionValue}`)
                            return <div key={s}>
                                <Divider label={s} classNames={{label:"text-black"}} labelPosition={"left"}/>
                                <HStack wrap>{
                                    Object.keys(sectionSchema.shape).map((k) => {
                                        return <div key={k} className={"p-1 m-1 flex flex-col border border-solid border-gray-300"}>
                                            <TextXs m0 center classNames={"text-gray-500"}>{formatCamelCase(k)}</TextXs>
                                            <TextSm m0 center>{sectionValue?(sectionValue as any)[k]?.toString() ?? "":""}</TextSm>
                                        </div>
                                    })
                                }
                                </HStack>
                            </div>
                        })
                    }
                </div>
                {/* 
                TODO render permits
                TODO render pandadoc
        */}
            </div>
        </VStack>
    </VStack >;
}
