import {
    AdminCombobox,
    DesignedIconButton,
    getUseMutationOpt,
    HStack,
    ProductDataContext, SheetsDataContext,
    TextBase,
    trpc,
    VStack
} from "@seeair/shared-components";
import { Assessment, HomeAggregate } from "@seeair/schemas";
import React, { useContext } from "react";
import { ISM_REC_NUMBERS, PROJECT_TYPE_ISM } from "@seeair/shared";
import { Loader, Table } from "@mantine/core";
import { IconDatabaseMinus, IconDatabasePlus } from "@tabler/icons-react";

export function AdminIsmsPanel({ home, assessment }: { home: HomeAggregate; assessment: Assessment }) {
    const { isPending: isAddPending, mutate: addIsm } = trpc.ADMIN.addIsm.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    const { isPending: isDeletePending, mutate: deleteIsm } = trpc.ADMIN.removeIsm.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    const { isPending: isPublishPending, mutate: publishIsmDoc } = trpc.ADMIN.publishIsmDoc.useMutation(
        getUseMutationOpt(trpc.useUtils()),
    );
    const { products } = useContext(ProductDataContext);
    const { recommendationsSheet } = useContext(SheetsDataContext)
    const filteredProducts = products
        .filter((p) => ISM_REC_NUMBERS.includes(p.properties.recommendation_id ?? ""))
        .map((p) => ({ ...p, name: p.properties.name ?? "", group: `${p.properties.recommendation_id} ${recommendationsSheet.find(s => s.id == p.properties.recommendation_id)?.title ?? ""}` }));
    const ismProject = home.projects.find((p) => p.project_type == PROJECT_TYPE_ISM);
    const isms = ismProject?.recommendations?.flatMap((r) => Object.values(r.line_items ?? {})) ?? [];
    const docAlreadyExists = !!Object.values(assessment.assessment_documents ?? {}).find(d => d.type == 'ism-eversource' || d.type == 'ism-national-grid')
    return (
        <VStack>
            <HStack leftCenter>
                <AdminCombobox
                    options={filteredProducts}
                    disabled={isAddPending}
                    label={"Add ISM"}
                    onChange={(p) => {
                        addIsm({
                            home_id: home.home_id,
                            assessment_id: assessment.assessment_id,
                            product_id: p.id,
                        });
                    }}
                />
                <DesignedIconButton
                    icon={<TextBase>Publish Pandadoc 🐼</TextBase>}
                    onClick={() => {
                        publishIsmDoc({
                            home_id: home.home_id,
                            assessment_id: assessment.assessment_id,
                        });
                    }}
                    disabled={docAlreadyExists || isAddPending || isDeletePending}
                    tooltip={docAlreadyExists ? "ISM Doc Already Exists" : "Publish"}
                />
                {(isAddPending || isDeletePending || isPublishPending) && <Loader />}
            </HStack>

            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Item</Table.Th>
                        <Table.Th>Quantity</Table.Th>
                        <Table.Th>Remove</Table.Th>
                        <Table.Th>Add</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {isms.map((i) => (
                        <Table.Tr key={i.line_item_id}>
                            <Table.Th>{i.name}</Table.Th>
                            <Table.Th>{i.quantity}</Table.Th>
                            <Table.Th>
                                <DesignedIconButton
                                    icon={<IconDatabaseMinus />}
                                    onClick={() => {
                                        deleteIsm({
                                            home_id: home.home_id,
                                            assessment_id: assessment.assessment_id,
                                            product_id: i.product_id,
                                        });
                                    }}
                                    disabled={isDeletePending || isAddPending}
                                    tooltip={"Remove One"}
                                />
                            </Table.Th>
                            <Table.Th>
                                <DesignedIconButton
                                    icon={<IconDatabasePlus />}
                                    onClick={() => {
                                        addIsm({
                                            home_id: home.home_id,
                                            assessment_id: assessment.assessment_id,
                                            product_id: i.product_id,
                                        });
                                    }}
                                    disabled={isDeletePending || isAddPending}
                                    tooltip={"Add One"}
                                />
                            </Table.Th>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
            {/*{ismProject?.hubspot_deal_amount && (*/}
            {/*    <TextBase>*/}
            {/*        Total Cost of ISMs: {formatMoney(parseFloat(ismProject!.hubspot_deal_amount!))}*/}
            {/*    </TextBase>*/}
            {/*)}*/}
        </VStack>
    );
}
