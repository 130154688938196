import React, { useMemo, useState } from "react";
import { Viewer } from './Viewer.js';
import { ContractorHome, ContractorRecommendation, HomeAggregate, Recommendation } from "@seeair/schemas";
import { RecommendationsAccordion } from './RecommendationsAccordion.js';
import { IsHomeAggregate } from "@seeair/shared";
import { RSplitLayout } from './RSplitLayout.js';

export function DigitalTwin({ home, urn, isContractor }: { home: HomeAggregate | ContractorHome, urn: string, isContractor: boolean }) {
    const [selectedRecs, setSelectedRecs] = useState([] as Array<number>)
    const recs: Array<Recommendation> | Array<ContractorRecommendation> = useMemo(() => IsHomeAggregate(home)
        ? home.recommendations.filter(r=>!r.hidden)
        : home.projects.flatMap(p => p.recommendations), [home]) //without this, the viewer remounts on every change
    return <RSplitLayout
        left={<RecommendationsAccordion
            hideAdminItems={true}
            recs={recs}
            projects={home.projects}
            filteredRecs={selectedRecs}
            includeProjects={true}
            isContractor={isContractor}
        />}
        right={<Viewer
            urn={urn}
            onRecToggleChange={(selectedRecNums) => {
                setSelectedRecs(selectedRecNums)
            }}
            recs={recs}
            isContractor={isContractor}
        />}
    />
}