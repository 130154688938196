import {DesignedIconButton, HStack, trpc} from "@seeair/shared-components";
import {Modal, NativeSelect, Table, TextInput} from "@mantine/core";
import React, {useState} from "react";
import {SeeAirUser, UserRole, UserRolesSchema} from "@seeair/schemas";
import {getUseMutationOpt, Text3Xl, VStack} from "@seeair/shared-components";
import classNames from "classnames";
import {useForm} from "@mantine/form";
import {IconUser, IconUserPlus} from "@tabler/icons-react";

export function AdminUserManager() {
    const [createUserModalOpen,setCreateUserModalOpen] = useState(false)
    const [users] = trpc.ADMIN.listUsers.useSuspenseQuery()
    const {isPending, mutate} = trpc.ADMIN.setUserRole.useMutation(getUseMutationOpt(trpc.useUtils(),()=>setCreateUserModalOpen(false)))
    return <VStack>
        <HStack leftCenter>
            <Text3Xl>User Manager</Text3Xl>
            <DesignedIconButton
                disabled={false}
                icon={<IconUserPlus />}
                onClick={()=>setCreateUserModalOpen(true)}
                tooltip={"Add User"} />
        </HStack>

        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Role</Table.Th>
                    <Table.Th>Company</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    [...users]
                        .sort(sortUsers)
                        .map(u => <Table.Tr key={u.id}>
                        <Table.Th>{u.email}</Table.Th>
                        <Table.Th>{u.name}</Table.Th>
                        <Table.Th><NativeSelect
                            disabled={isPending}
                            value={u.role ?? undefined}
                            onChange={(e) => mutate({
                                user_id: u.id,
                                role: e.currentTarget.value as UserRole
                            })}
                            data={["",...UserRolesSchema.options]}
                        /></Table.Th>
                            <Table.Th>{u.company_id}</Table.Th>
                    </Table.Tr>)
                }
            </Table.Tbody>
        </Table>
        <Modal title={"Create User"}
               opened={createUserModalOpen}
               onClose={() => {
                   setCreateUserModalOpen(false)
               }}>
            <CreateUserPanel />
        </Modal>
    </VStack>
}
function CreateUserPanel() {
    const {isPending, mutate} = trpc.ADMIN.createUser.useMutation(getUseMutationOpt(trpc.useUtils()))
    const emailForm = useForm({
        initialValues: {email: '',role:'homeowner' as UserRole},
        validate: {
            email: (value) => (/^\S+@\S+\.\S{2,}$/.test(value) ? null : 'Invalid email'),
        },
    })
    return <VStack>
        <TextInput
            type={"email"}
            className={"mb-6 w-full"}
            classNames={{input: classNames("border border-[#037CB0] flex-shrink-0 h-12 bg-white designed-input text-base leading-7 pl-5 w-full rounded-2xl")}}
            placeholder={"Email Address"}
            {...emailForm.getInputProps('email')}
        />
        <NativeSelect
            data={[...UserRolesSchema.options]}
            {...emailForm.getInputProps('role')}

        />
        <DesignedIconButton
            disabled={isPending}
            icon={<IconUser />}
            onClick={()=>mutate({email:emailForm.values.email,role:emailForm.values.role})}
            tooltip={"Create User"}
        />
    </VStack>
}
function sortUsers(u1:SeeAirUser,u2:SeeAirUser):number {
    const roleCompare = ((u1.role ?? "homeowner") as string).localeCompare((u2.role ?? "homeowner") as string)
    const emailCompare = u1.email.localeCompare(u2.email,undefined,{sensitivity:"base"})
    if(roleCompare == 0) {
        return emailCompare
    }
    return roleCompare
}