import {notifications} from "@mantine/notifications";
import {TRPCClientErrorLike} from "@trpc/client";

type hasInvalidate = {invalidate:()=>Promise<void>}
type UtilsType = {
    ADMIN:hasInvalidate,
    HOMEOWNER:hasInvalidate,
    CONTRACTOR:hasInvalidate
}
export function getUseMutationOpt(utils:UtilsType,onSuccess?:()=>void,onError?:(error:TRPCClientErrorLike<any>)=>void){
    return {
        onSuccess: async () => {
            await utils.ADMIN.invalidate()
            await utils.HOMEOWNER.invalidate()
            await utils.CONTRACTOR.invalidate()
            showSuccessNotification()
            if(onSuccess) {
                onSuccess()
            }
        },
        onError: (error:TRPCClientErrorLike<any>) => {
            showErrorNotification(error)
            if(onError) {
                onError(error)
            }
        }
    }
}
export function showSuccessNotification() {
    notifications.show({
        message:"Success!",
        color: "green",
        autoClose: 3000
    })
}
export function showErrorNotification(error:{message:string}) {
    console.log(`showErrorNotification: ${error.message}`)
    notifications.show({
        title: "Failure",
        message: error.message,
        color: 'red',
        autoClose: false
    })
}