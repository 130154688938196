import {
    ElectrificationData,
    HubspotProduct,
    LightbulbEnum,
    LocationId,
    RecInputData,
    RecommendationDiscounts,
    RecommendationLineItem,
    SolarFinancialAnalysis,
    StoveEnum,
} from "@seeair/schemas"
import {
    generateAirSealingIncentives,
    generateAirSourceElectricHeatPumpIncentive,
    generateBatteryIncentive,
    generateElectricalPanelUpgradeIncentive,
    generateGroundSourceElectricHeatPumpIncentive,
    generateInductionStoveIncentive,
    generateInsulationIncentives,
    generateMiniSplitHeatPumpIncentive,
    generatePortableFilterIncentive,
    generatePowerstripIncentives,
    generateSolarPanelIncentive,
    generateThermostatIncentive,
    generateWaterEfficiencyIncentives,
    generateWaterHeaterIncentive,
    generateWindowIncentive,
    IncentiveMassSaveAdministrative,
} from "./incentives.js"
import { genId } from "./util.js"
import { IHubspotProductCatalog } from "./iHubspotCrmClient.js"

function getLineItemDiscounts(product:HubspotProduct):RecommendationDiscounts {
    if (product.properties.seeair_admin_discount == "yes") {
        return {
            [IncentiveMassSaveAdministrative.incentive_id]:IncentiveMassSaveAdministrative
        }
    } else {
        return {}
    }
}

function buildLineItemsFromParts(
    {
        recNumber,
        partNumbers,
    }: {
        recNumber: string
        partNumbers: Record<string, number>
    },
    catalog: IHubspotProductCatalog,
): Array<RecommendationLineItem> {
    let line_items: Array<RecommendationLineItem> = []
    for (const partNumber in partNumbers) {
        const product = catalog.getPreferredProduct(recNumber, partNumber)
        if (product != "not_found") {
            line_items.push({
                line_item_id: genId("li"),
                product_id: product.id,
                name: product.properties.name ?? "",
                description: product.properties.description,
                quantity: partNumbers[partNumber]!,
                price_per_unit: parseFloat(product.properties.price ?? "0"),
                discounts: getLineItemDiscounts(product),
            })
        } else {
            // console.log(`warn no preferred product for part num: ${partNumber}`)
        }
    }
    return line_items
}
export const ISM_REC_NUMBERS = ["11", "12", "30"]
export function generateBatteryRec(
    {
        electricCostAnnual,
    }: {
        electricCostAnnual: number
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const Upfront_Cost_Low = (electricCostAnnual / (365 * 13.5)) * 5000 + 6000
    const Upfront_Cost_High = (electricCostAnnual / (365 * 13.5)) * 10000 + 10000
    const incentives = generateBatteryIncentive()
    return {
        type: "PRO",
        description: "Install a battery backup",
        title: "Install Battery",
        original_rec_id: "74",
        rec_data: {
            Annual_Savings_High: 1800,
            Annual_Savings_Low: 1200,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: ["50", "72"],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "74",
                partNumbers: { "74.0": 1 /*battery*/, "74.1": 1 /*inverter*/ },
            },
            catalog,
        ),
    }
}

export function generateSolarPanelRec(
    {
        solarFinances,
    }: {
        solarFinances: SolarFinancialAnalysis
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const annualSavingsEstimate = parseFloat(
        solarFinances?.cashPurchaseSavings?.savings.savingsYear1.units ?? "0",
    )
    const upfrontCostEstimate = parseFloat(solarFinances?.cashPurchaseSavings?.upfrontCost.units ?? "0")
    // const rebateValue = parseFloat(solarFinances?.cashPurchaseSavings?.rebateValue.units ?? "0")
    const Upfront_Cost_Low = upfrontCostEstimate * 0.8
    const Upfront_Cost_High = upfrontCostEstimate * 1.2
    const incentives = generateSolarPanelIncentive()
    return {
        type: "PRO",
        description: "Install solar panels",
        title: "Solar Panels",
        original_rec_id: "72",
        rec_data: {
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Annual_Savings_Low: annualSavingsEstimate * 0.8,
            Annual_Savings_High: annualSavingsEstimate * 1.2,
            Prerequisites: ["50"],
        },
        category: "Solar",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "72",
                partNumbers: { "72.0": 1 /*TODO get Panel count*/, "72.1": 1 /*inverter*/ },
            },
            catalog,
        ),
    }
}

export function generatePanelUpgradeRec(catalog: IHubspotProductCatalog): RecInputData {
    const Upfront_Cost_Low = 1500
    const Upfront_Cost_High = 4500
    const incentives = generateElectricalPanelUpgradeIncentive()
    return {
        type: "PRO",
        description: "Upgrade your electrical panel",
        title: "Electrical Panel",
        original_rec_id: "50",
        rec_data: {
            Annual_Savings_High: 0,
            Annual_Savings_Low: 0,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "50",
                partNumbers: { "50.0": 1 },
            },
            catalog,
        ),
    }
}

const insulationSavingsDenominatorMap: Record<LocationId, Record<"high" | "low", number>> = {
    CA: { high: 0.2, low: 0.1 },
    MA: { high: 0.3, low: 0.2 },
}

export function generateInsulationRec(
    {
        location_id,
        hvacCostAnnual,
        income_eligible_ma,
        sqft,
    }: {
        location_id: LocationId
        hvacCostAnnual: number
        income_eligible_ma: boolean
        aer: number
        sqft: number
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const Upfront_Cost_Low = 1.5 * sqft
    const Upfront_Cost_High = 2.5 * sqft
    const insulationSavingsDenominatorHigh = insulationSavingsDenominatorMap[location_id]["high"]
    const insulationSavingsDenominatorLow = insulationSavingsDenominatorMap[location_id]["low"]
    const incentives = generateInsulationIncentives({
        location_id,
        income_eligible_ma,
        Upfront_Cost_Low,
        Upfront_Cost_High,
    })
    return {
        type: "PRO",
        description: "Improve Insulation",
        title: "Improve Insulation",
        original_rec_id: "15",
        rec_data: {
            Annual_Savings_High: hvacCostAnnual * insulationSavingsDenominatorHigh,
            Annual_Savings_Low: hvacCostAnnual * insulationSavingsDenominatorLow,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: [],
        },
        category: "Insulation",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "15",
                partNumbers: {
                    "15.1": 100, //TODO calculate rim joist insulation
                    "15.99":1,//permit admin fee
                    "15.98":1,//permit fee
                    "15.97":1,//acquisition fee
                },
            },
            catalog,
        ),
    }
}

export function generateTouchUpRec(catalog: IHubspotProductCatalog): RecInputData {
    return {
        type: "PRO",
        description: "Touch up any leftover superficial and cosmetic issues after insulation install ",
        title: "Post Insulation Install Touch Up",
        original_rec_id: "16",
        rec_data: {
            Annual_Savings_High: 0,
            Annual_Savings_Low: 0,
            Upfront_Cost_Low: 250,
            Upfront_Cost_High: 500,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: [],
        line_items: buildLineItemsFromParts({ recNumber: "16", partNumbers: { "16.0": 1 } }, catalog),
    }
}

export function generatePowerStripRec({ location_id }: { location_id: LocationId }): RecInputData {
    const incentives = generatePowerstripIncentives({ location_id })
    return {
        type: "DIY",
        description: "Install a smart power strip",
        title: "Smart Power Strip",
        original_rec_id: "11",
        rec_data: {
            Annual_Savings_High: 75,
            Annual_Savings_Low: 15,
            Upfront_Cost_Low: 26,
            Upfront_Cost_High: 26,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: [], //buildLineItemsFromParts({recNumber: "11", partNumbers: {"11.0": 1}}, catalog)
    }
}

export function generateWaterEfficiencyRec({
    location_id,
    baths,
}: {
    location_id: LocationId
    baths: number
}): RecInputData {
    const incentives = generateWaterEfficiencyIncentives({ location_id })
    return {
        type: "DIY",
        description: "Install high efficiency shower fixtures and faucet aerators",
        title: "Low Flow Water Fixtures",
        original_rec_id: "12",
        rec_data: {
            /*
usage: 2 showers/day x 17.2 gallons/shower x 365 = 12538 gallons
Cost per gallon to heat water: = 0.012
Savings factor:S = 0.2
             */
            Annual_Savings_High: 40 * baths,
            Annual_Savings_Low: 20 * baths,
            Upfront_Cost_Low: 5 * baths,
            Upfront_Cost_High: 5 * baths,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: [],
    }
}

export function generateInductionStoveRec(
    {
        location_id,
        stove,
    }: {
        location_id: LocationId
        stove: StoveEnum
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    let savingsEstimate: number
    const typicalGasStoveCost = 40
    const typicalElectricStoveUsageCost = 145
    const typicalInductionStoveUsageCost = 130
    if (stove == "Gas") {
        savingsEstimate = typicalGasStoveCost - typicalInductionStoveUsageCost
    } else {
        // electric
        savingsEstimate = typicalElectricStoveUsageCost - typicalInductionStoveUsageCost
    }
    let incentives = stove == "Gas" ? generateInductionStoveIncentive({ location_id }) : []
    return {
        type: "DIY",
        description: "Install an induction stove",
        title: "Induction Stove",
        original_rec_id: "70",
        rec_data: {
            Annual_Savings_High: 0.75 * savingsEstimate,
            Annual_Savings_Low: 1.25 * savingsEstimate,
            Upfront_Cost_Low: 1000,
            Upfront_Cost_High: 4000,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts({ recNumber: "70", partNumbers: { "70.0": 1 } }, catalog),
    }
}

const airSourceHeatPumpInstallFixedCost: Record<LocationId, number> = {
    MA: 10871,
    CA: 12790,
}
const airSourceHeatPumpInstallDynamicCost: Record<LocationId, number> = {
    MA: 0.3184,
    CA: 0.1422,
}

export function generateAirSourceCentralElectricHeatPumpRec(
    {
        location_id,
        heatingLoad,
        sqft,
        income_eligible_ma,
        heatingUsage,
        heating_afue,
        meanElectricCostPerUsage,
        electrificationData,
    }: {
        location_id: LocationId
        heatingLoad: number
        sqft: number
        income_eligible_ma: boolean
        heatingUsage: number
        heating_afue: number
        meanElectricCostPerUsage: number
        electrificationData: ElectrificationData
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const runningCostEstimate = (heatingUsage * heating_afue * meanElectricCostPerUsage) / 3.2
    const installCostEstimate =
        airSourceHeatPumpInstallFixedCost[location_id] +
        airSourceHeatPumpInstallDynamicCost[location_id] * heatingLoad * sqft
    const Upfront_Cost_Low = 0.8 * installCostEstimate
    const Upfront_Cost_High = 1.2 * installCostEstimate
    const incentives = generateAirSourceElectricHeatPumpIncentive({ location_id, income_eligible_ma })
    return {
        type: "PRO",
        description: "Install an electric air source heat pump",
        title: "Electric Heat Pump",
        original_rec_id: "60",
        rec_data: {
            Annual_Savings_High: 1.2 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Annual_Savings_Low: 0.8 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: ["22", "50"],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "60",
                partNumbers: {
                    "60.0": 1 /*air handler*/,
                    "60.1": 1 /*condenser*/,
                    "62.9": 1 /*disposal*/,
                },
            },
            catalog,
        ),
    }
}

const groundSourceAdditionalFixedCost = 15000

export function generateGroundSourceCentralElectricHeatPumpRec(
    {
        location_id,
        heatingLoad,
        sqft,
        income_eligible_ma,
        heatingUsage,
        heating_afue,
        meanElectricCostPerUsage,
        electrificationData,
    }: {
        location_id: LocationId
        heatingLoad: number
        sqft: number
        income_eligible_ma: boolean
        heatingUsage: number
        heating_afue: number
        meanElectricCostPerUsage: number
        electrificationData: ElectrificationData
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const runningCostEstimate = (heatingUsage * heating_afue * meanElectricCostPerUsage) / 4.2
    const installCostEstimate =
        airSourceHeatPumpInstallFixedCost[location_id] +
        groundSourceAdditionalFixedCost +
        airSourceHeatPumpInstallDynamicCost[location_id] * heatingLoad * sqft
    const Upfront_Cost_Low = 0.8 * installCostEstimate
    const Upfront_Cost_High = 1.2 * installCostEstimate
    const incentives = generateGroundSourceElectricHeatPumpIncentive({ location_id, income_eligible_ma })
    return {
        type: "PRO",
        description: "Install an electric ground source (geothermal) heat pump",
        title: "Geothermal Electric Heat Pump",
        original_rec_id: "61",
        rec_data: {
            Annual_Savings_High: 1.2 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Annual_Savings_Low: 0.8 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: ["22", "50"],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "61",
                partNumbers: {
                    "60.0": 1 /*air handler*/,
                    "61.1": 1 /*geo-condenser*/,
                    "62.9": 1 /*disposal*/,
                },
            },
            catalog,
        ),
    }
}
const miniSplitInstallFixedCost: Record<LocationId, number> = {
    MA: 13875,
    CA: 5345,
}
const miniSplitInstallDynamicCost: Record<LocationId, number> = {
    MA: 0.2926,
    CA: 0.4558,
}

export function generateMiniSplitHeatPumpRec(
    {
        location_id,
        heatingLoad,
        beds,
        income_eligible_ma,
        heatingUsage,
        heating_afue,
        meanElectricCostPerUsage,
        electrificationData,
    }: {
        location_id: LocationId
        heatingLoad: number
        beds: number
        income_eligible_ma: boolean
        heatingUsage: number
        heating_afue: number
        meanElectricCostPerUsage: number
        electrificationData: ElectrificationData
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const runningCostEstimate = (heatingUsage * heating_afue * meanElectricCostPerUsage) / 3.2
    const installCostEstimate =
        miniSplitInstallFixedCost[location_id] + miniSplitInstallDynamicCost[location_id] * 12000 * beds
    const Upfront_Cost_Low = 0.8 * installCostEstimate
    const Upfront_Cost_High = 1.2 * installCostEstimate
    const incentives = generateMiniSplitHeatPumpIncentive({ location_id, income_eligible_ma, beds })
    return {
        type: "PRO",
        description: "Install mini split heat pumps",
        title: "Electric Mini-Split Heat Pump(s)",
        original_rec_id: "62",
        rec_data: {
            Annual_Savings_High: 1.2 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Annual_Savings_Low: 0.8 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: ["22", "50"],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "62",
                partNumbers: {
                    "62.0": beds /*heads*/,
                    "62.1": 1 /*condenser*/,
                    "62.2": 1 /*lineset*/,
                    "62.9": 1 /*disposal*/,
                },
            },
            catalog,
        ),
    }
}

export function generateMiniSplitGroundSourceHeatPumpRec(
    {
        location_id,
        heatingLoad,
        beds,
        income_eligible_ma,
        heatingUsage,
        heating_afue,
        meanElectricCostPerUsage,
        electrificationData,
    }: {
        location_id: LocationId
        heatingLoad: number
        beds: number
        income_eligible_ma: boolean
        heatingUsage: number
        heating_afue: number
        meanElectricCostPerUsage: number
        electrificationData: ElectrificationData
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const runningCostEstimate = (heatingUsage * heating_afue * meanElectricCostPerUsage) / 3.2
    const installCostEstimate =
        miniSplitInstallFixedCost[location_id] +
        groundSourceAdditionalFixedCost +
        miniSplitInstallDynamicCost[location_id] * 12000 * beds
    const Upfront_Cost_Low = 0.8 * installCostEstimate
    const Upfront_Cost_High = 1.2 * installCostEstimate
    const incentives = generateMiniSplitHeatPumpIncentive({ location_id, income_eligible_ma, beds })
    return {
        type: "PRO",
        description: "Install mini split heat pumps",
        title: "Electric Mini-Split Heat Pump(s)",
        original_rec_id: "62",
        rec_data: {
            Annual_Savings_High: 1.2 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Annual_Savings_Low: 0.8 * runningCostEstimate - electrificationData.energy_cost.Electric,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: ["22", "50"],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "62",
                partNumbers: {
                    "62.0": beds /*heads*/,
                    "62.1": 1 /*condenser*/,
                    "62.2": 1 /*lineset*/,
                    "62.9": 1 /*disposal*/,
                },
            },
            catalog,
        ),
    }
}

export function generateWaterHeaterRec(
    {
        beds,
        location_id,
    }: {
        beds: number
        location_id: LocationId
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const incentives = generateWaterHeaterIncentive({ location_id })
    return {
        type: "PRO",
        description: "Upgrade to Electric Heat Pump Water Heater",
        title: "Electric Heat Pump Water Heater",
        original_rec_id: "64",
        rec_data: {
            Annual_Savings_High: beds < 3 ? 550 : beds < 5 ? 700 : 850,
            Annual_Savings_Low: beds < 3 ? 250 : beds < 5 ? 400 : 700,
            Upfront_Cost_Low: beds < 3 ? 2500 : beds < 5 ? 2800 : 3100,
            Upfront_Cost_High: beds < 3 ? 3300 : beds < 5 ? 4400 : 5500,
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            {
                recNumber: "64",
                partNumbers: {
                    "64.0": 1,
                },
            },
            catalog,
        ),
    }
}

const airSealingAerAdjustmentMap: Record<LocationId, number> = {
    CA: 5,
    MA: 1.5,
}
const airSealingSavingsDenominatorMap: Record<LocationId, Record<"high" | "low", number>> = {
    CA: { high: 0.08, low: 0.06 },
    MA: { high: 0.09, low: 0.07 },
}

export function generateSealAirLeakRec(
    {
        location_id,
        hvacCostAnnual,
        aer,
    }: {
        location_id: LocationId
        hvacCostAnnual: number
        aer: number
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const hourlyAirSealingRate = 106.59
    const Upfront_Cost_Low = hourlyAirSealingRate
    const Upfront_Cost_High = hourlyAirSealingRate
    const airSealingAerAdjustment = airSealingAerAdjustmentMap[location_id]
    const airSealingSavingsDenominatorHigh = airSealingSavingsDenominatorMap[location_id]["high"]
    const airSealingSavingsDenominatorLow = airSealingSavingsDenominatorMap[location_id]["low"]
    const incentives = generateAirSealingIncentives({ location_id, Upfront_Cost_Low, Upfront_Cost_High })
    return {
        type: "PRO",
        description: "Seal air leaks",
        title: "Seal Air Leaks",
        original_rec_id: "22",
        rec_data: {
            Annual_Savings_High:
                (hvacCostAnnual * airSealingSavingsDenominatorHigh * (airSealingAerAdjustment - aer)) / 2,
            Annual_Savings_Low:
                (hvacCostAnnual * airSealingSavingsDenominatorLow * (airSealingAerAdjustment - aer)) / 2,
            Upfront_Cost_Low,
            Upfront_Cost_High,
            Prerequisites: [],
        },
        category: "Insulation",
        discounts: incentives,
        line_items: buildLineItemsFromParts({ recNumber: "22", partNumbers: { "22.0": 1 } }, catalog),
    }
}

export function generateFilterEfficiencyRec(
    {
        hvacCostAnnual,
    }: {
        hvacCostAnnual: number
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    return {
        type: "DIY",
        description: "Change your filter to a high efficiency filter",
        title: "Improve Filter Efficiency",
        original_rec_id: "26",
        rec_data: {
            Annual_Savings_High: -50 - hvacCostAnnual * 0.02,
            Annual_Savings_Low: -25 - hvacCostAnnual * 0.02,
            Net_Cost_Low: 10,
            Net_Cost_High: 20,
            Upfront_Cost_Low: 10,
            Upfront_Cost_High: 20,
            Prerequisites: [],
        },
        category: "Insulation",
        discounts: [],
        line_items: [],
    }
}

export function generatePortableFilterRec(
    {
        location_id,
    }: {
        location_id: LocationId
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    const incentives = generatePortableFilterIncentive({ location_id })
    return {
        type: "DIY",
        description: "Install a portable air filter",
        title: "Portable Air Filter",
        original_rec_id: "27",
        rec_data: {
            Annual_Savings_High: -30,
            Annual_Savings_Low: -20,
            Upfront_Cost_Low: 200,
            Upfront_Cost_High: 400,
            Prerequisites: [],
        },
        category: "Insulation",
        discounts: incentives,
        line_items: [],
    }
}

export function generateThermostatRec({
    hvacCostAnnual,
    location_id,
    floors,
}: {
    location_id: LocationId
    hvacCostAnnual: number
    floors: number
}): RecInputData {
    const incentives = generateThermostatIncentive({ location_id })
    return {
        type: "DIY",
        description: "Change your thermostat to a smart thermostat",
        title: "Smart Thermostat",
        original_rec_id: "30",
        rec_data: {
            Annual_Savings_High: hvacCostAnnual * 0.12,
            Annual_Savings_Low: hvacCostAnnual * 0.05,
            Upfront_Cost_Low: 90,
            Upfront_Cost_High: 190,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: [], //buildLineItemsFromParts({recNumber: "30", partNumbers: {"30.0": floors}}, catalog)
    }
}

export function generateLedRec(
    {
        lightbulbs,
    }: {
        lightbulbs: Array<LightbulbEnum>
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    //TODO do we want to collect lightbulb count in the home?
    const lightbulbCount = 15
    const percentageReplaced = lightbulbs.filter((o) => o != "LED").length / lightbulbs.length
    const lightbulbsReplaced = lightbulbCount * percentageReplaced
    return {
        type: "DIY",
        description: "Replace any non-LED lightbulbs with more energy efficient LEDs",
        title: "LED Lightbulbs",
        original_rec_id: "10",
        rec_data: {
            Annual_Savings_High: lightbulbsReplaced * 6,
            Annual_Savings_Low: lightbulbsReplaced * 10,
            Upfront_Cost_Low: lightbulbsReplaced * 1,
            Upfront_Cost_High: lightbulbsReplaced * 5,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: [],
        line_items: [],
    }
}

export function generateWindowRec(
    {
        location_id,
        heatingCostAnnual,
        coolingCostAnnual,
    }: {
        location_id: LocationId
        heatingCostAnnual: number
        coolingCostAnnual: number
    },
    catalog: IHubspotProductCatalog,
): RecInputData {
    /*
        #68 - Upgrade Windows
    if(windows=="Single Pane" & analysis[[length(analysis)]]$rvalue < 10){
      recommendations[['C68']] <- list(ID=68,
                                       Upfront_Cost_Low = number_of_windows*400,
                                       Upfront_Cost_High = number_of_windows*1200,
                                       Net_Cost_Low = ifelse(state=='MA', number_of_windows*325-min(600, number_of_windows*325*.3), number_of_windows*400-min(600, number_of_windows*400*.3)),
                                       Net_Cost_High = ifelse(state=='MA', number_of_windows*1125-min(600, number_of_windows*1125*.3), number_of_windows*1200-min(600, number_of_windows*1200*.3)),
                                       Annual_Savings_Low = 0.08*heating_cost_annual+0.11*cooling_cost_annual,
                                       Annual_Savings_High = 0.12*heating_cost_annual+0.15*cooling_cost_annual)
     */

    const numberOfWindows = 15
    const incentives = generateWindowIncentive({ location_id })
    return {
        type: "PRO",
        description: "Replace single pane windows with better insulating double or triple pane windows.",
        title: "Upgrade Windows",
        original_rec_id: "68",
        rec_data: {
            Annual_Savings_High: 0.08 * heatingCostAnnual + 0.11 * coolingCostAnnual,
            Annual_Savings_Low: 0.08 * heatingCostAnnual + 0.11 * coolingCostAnnual,
            Upfront_Cost_Low: numberOfWindows * 400,
            Upfront_Cost_High: numberOfWindows * 1200,
            Prerequisites: [],
        },
        category: "Electrification",
        discounts: incentives,
        line_items: buildLineItemsFromParts(
            { recNumber: "68", partNumbers: { "68.0": numberOfWindows } },
            catalog,
        ),
    }
}
