import {z} from "zod";

export const PandadocStatusEnumSchema = z.enum([
    "document.uploaded",
    "document.draft",
    "document.sent",
    "document.viewed",
    "document.completed",
    "document.paid",
    "document.waiting_pay",
    "document.voided",
])
export type PandadocStatusEnum = z.infer<typeof PandadocStatusEnumSchema>
export const PandadocInfoSchema = z.object({
    status: PandadocStatusEnumSchema,
    id: z.string(),
    name: z.string(),
    date_created: z.string().nullish(),
    date_modified: z.string().nullish(),
    date_completed: z.string().nullish(),
    expiration_date: z.string().nullish()
})
export const PandadocPublishRecipientsInfoSchema = z.object({
    email: z.string(),
    recipient_type: z.string(),
    signing_order: z.number().nullish(),
    shared_link: z.string().nullish()
})
export const PandadocPublishInfoSchema = PandadocInfoSchema.merge(z.object({
    recipients: z.array(PandadocPublishRecipientsInfoSchema)
}))
export type PandadocPublishInfo = z.infer<typeof PandadocPublishInfoSchema>
export const PandadocFullRecipientsInfoSchema = PandadocPublishRecipientsInfoSchema.merge(z.object({
    has_completed: z.boolean().nullish(),
    role: z.string().nullish(),
    roles: z.array(z.string())
}))
export type PandadocFullRecipientsInfo = z.infer<typeof PandadocFullRecipientsInfoSchema>
export const PandadocDetailsSchema = PandadocPublishInfoSchema.merge(z.object({
    recipients: z.array(PandadocFullRecipientsInfoSchema),
    template: z.object({
        name: z.string().nullish(),
        id: z.string().nullish()
    }).nullish()
}))
export type PandadocDetails = z.infer<typeof PandadocDetailsSchema>
export type PandadocInfo = z.infer<typeof PandadocInfoSchema>

const PandadocFieldsInputSchema = z.record(z.string(), z.object({value: z.any()}))
const PandadocFieldsOutputSchema = z.array(z.object({
    field_id: z.string(),
    value: z.any(),
    assigned_to: z.object({
        email: z.string().nullish(),
        role: z.string().nullish(),
        signing_order: z.number().nullish()
    }).nullish()
}))
const PandadocTokensSchema = z.array(z.object({name: z.string(), value: z.string()}))
const PandadocPricingTableSchema = z.array(z.object({name: z.string(), value: z.string()}))
export type PandadocFieldsOutput = z.infer<typeof PandadocFieldsOutputSchema>
export type PandadocFieldsInput = z.infer<typeof PandadocFieldsInputSchema>
export type PandadocTokens = z.infer<typeof PandadocTokensSchema>
export type PandadocLineItemInput = {
    name: string,
    description: string,
    price: number,
    qty: number,
    discount: {type:"percent",value:number}
    // SKU: string,
    // ContractedQTY: number,
}
export const PandadocMetadataSchema = z.object({
    home_id:z.string().nullish(),
    project_id:z.string().nullish(),
    assessment_id:z.string().nullish()
})
export type PandadocMetadata = z.infer<typeof PandadocMetadataSchema>
export type PandadocRecipientInput = {
    email: string,
    phone?: string,
    company?: string,
    first_name: string,
    last_name: string
}
export type PandadocRecipientsInput = {
    client?: PandadocRecipientInput,
    crewChief?: PandadocRecipientInput
    seeAirRep?: PandadocRecipientInput
}
export type PandadocTokensInput = Array<{name:string,value:string}>
export type PandadocCreateDocInput = {
    templateId: string,
    folderId: string,
    tags: Array<string>,
    fields: PandadocFieldsInput,
    tokens: PandadocTokensInput,
    dueNowDiscount: number,
    measuresLineItems: Array<PandadocLineItemInput>,
    recipients: PandadocRecipientsInput,
    metadata: PandadocMetadata
}
export const PANDADOC_ROLE_CREW_CHIEF = "Crew Chief"
export const PANDADOC_ROLE_CLIENT = "Client"
export const PANDADOC_ROLE_SEEAIR = "SeeAir"
