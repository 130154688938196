import {createFileRoute} from '@tanstack/react-router'
import {z} from 'zod'
import {AuthenticationCheck, AuthError, SignIn, VStack} from "@seeair/shared-components";
import React from 'react'

export const Route = createFileRoute('/auth/_auth/signin')({
    component: function SignInComponent() {
        const {redirect, error,message} = Route.useSearch()
        console.log(`redirect: ${redirect} decoded:${decodeURIComponent(redirect??"")}`)
        return <AuthenticationCheck
            shouldRedirect={(user) => user != null}
            redirectHome={!redirect}
            redirectTo={redirect
                ? decodeURIComponent(redirect)
                : ''
            }
            notify={'success'}
        >
            <VStack grow center classNames={"bg-white"}>
                {
                    error
                        ? <AuthError error={error}/>
                        : <SignIn redirect={redirect} message={message}/>
                }

            </VStack> 
        </AuthenticationCheck>
    },
    validateSearch: z.object({redirect: z.string().optional(), error: z.string().optional(),message:z.string().optional()})
})


