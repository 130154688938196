import { z } from "zod"

export const StripeWebhookTypeEnumSchema = z.enum([
    "payment_intent.created",
    "payment_intent.succeeded",
    "payment_intent.processing",
    "payment_intent.payment_failed",
    "payment_intent.canceled",
    "charge.succeeded",
    "charge.updated",
    "charge.failed",
    "charge.pending",
    "payment_intent.requires_action",
])
export type StripePaymentEventType = z.infer<typeof StripeWebhookTypeEnumSchema>
export const StripePaymentEventSchema=z.object({
    id:z.string(),
    type:StripeWebhookTypeEnumSchema,//payment_intent.created
    livemode: z.boolean(),
    data:z.object({
        object: z.object({
            id: z.string(),
            object: z.string(), //"payment_intent",
            application_fee_amount:z.number().nullish(),
            canceled_at:z.number().nullish(),
            created:z.number().nullish(),
            amount: z.number().nullish(),
            amount_capturable: z.number().nullish(),
            amount_received: z.number().nullish(),
            amount_captured: z.number().nullish(),
            amount_refunded: z.number().nullish(),
            metadata:z.object({
                DOCUMENT_URL:z.string().nullish()
            }).nullish(),

            billing_details:z.object({
                email:z.string().nullish()
            }).nullish(),
            failure_code:z.string().nullish(),
            failure_message:z.string().nullish(),
            cancellation_reason:z.string().nullish(),
            customer:z.string().nullish(),
            status:z.string().nullish()

        })
    })
})
export type StripePaymentEvent=z.infer<typeof StripePaymentEventSchema> 