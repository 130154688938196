import {
    DesignedIconButton,
    DesignedButton,
    getUseMutationOpt,
    HStack,
    showSuccessNotification,
    TextLg,
    trpc,
    VStack,
    showErrorNotification,
    TextSm
} from "@seeair/shared-components";
import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'
import React, { useState } from 'react'
import { Divider, Loader, Menu, NativeSelect, Table, Tabs } from "@mantine/core";
import { IconAi, IconBrandAws, IconDots, IconError404, IconTrash } from "@tabler/icons-react";
import ReactJson from "@microlink/react-json-view";
import { Message } from "@aws-sdk/client-sqs";
import { AiTestingRecord, AiTestingSummary, AssessmentFilesEnum, AssessmentFilesEnumSchema, HomeDetails } from "@seeair/schemas";
import { formatPercentage } from "@seeair/shared";
export function AdminToolsPane() {
    return <Tabs defaultValue={"cache"}>
        <Tabs.List>
            <Tabs.Tab value={"cache"}>Cache</Tabs.Tab>
            <Tabs.Tab value={"ai"}>AI</Tabs.Tab>
            <Tabs.Tab value={"dlq"}>DLQ</Tabs.Tab>
            <Tabs.Tab value={"sentry"}>Sentry</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={"sentry"}>
            <AdminToolsPaneSentryTab />
        </Tabs.Panel>
        <Tabs.Panel value={"cache"}>
            <AdminToolsPaneCacheTab />
        </Tabs.Panel>
        <Tabs.Panel value={"dlq"}>
            <AdminToolsPaneDlqTab />
        </Tabs.Panel>
        <Tabs.Panel value={"ai"}>
            <AdminToolsPaneAiTab />
        </Tabs.Panel>
    </Tabs>
}
export function AdminToolsPaneSentryTab() {
    const [level, setLevel] = useState("info" as SeverityLevel)
    const {
        mutate: sentryBackendTest,
        isPending: isSentryBackendPending
    } = trpc.ADMIN.sentryTest.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: clearSheetsCache,
        isPending: isSheetsCachePending
    } = trpc.ADMIN.clearSheetsAndDocsCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: clearProductCache,
        isPending: isProductCachePending
    } = trpc.ADMIN.clearProductCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack center>
        <HStack>
            <DesignedButton onClick={() => {
                Sentry.captureMessage("Test Frontend Message", level)
                showSuccessNotification()
            }}>Sentry FrontEnd Test</DesignedButton>
            <NativeSelect value={level} onChange={(e) => setLevel(e.currentTarget.value as SeverityLevel)}
                data={["fatal", "error", "warning", "log", "info", "debug"] as Array<SeverityLevel>} />
        </HStack>
        <DesignedButton disabled={isSentryBackendPending} onClick={() => {
            console.log("mutate called")
            sentryBackendTest()
        }}>Sentry Backend Test</DesignedButton>
        <DesignedButton disabled={isSheetsCachePending} onClick={() => clearSheetsCache()}>Clear Sheets/Docs
            Cache</DesignedButton>
        <DesignedButton disabled={isProductCachePending} onClick={() => clearProductCache()}>Clear Hubspot Product
            Cache</DesignedButton>
    </VStack>
}
export function AdminToolsPaneCacheTab() {
    const {
        mutate: clearSheetsCache,
        isPending: isSheetsCachePending
    } = trpc.ADMIN.clearSheetsAndDocsCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: clearProductCache,
        isPending: isProductCachePending
    } = trpc.ADMIN.clearProductCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack center>
        <DesignedButton disabled={isSheetsCachePending} onClick={() => clearSheetsCache()}>Clear Sheets/Docs
            Cache</DesignedButton>
        <DesignedButton disabled={isProductCachePending} onClick={() => clearProductCache()}>Clear Hubspot Product
            Cache</DesignedButton>
    </VStack>
}

export function AdminToolsPaneDlqTab() {
    const {
        data: dlqMessages,
        isFetching: isDlqFetching,
        refetch: refetchDqlMessages
    } = trpc.ADMIN.readAllDlqMessages.useQuery({}, { enabled: false })
    return <VStack center>
        <HStack leftCenter>
            <TextLg>DLQs</TextLg>
            <DesignedIconButton
                disabled={isDlqFetching}
                tooltip={"Refetch DLQ Messages"}
                icon={<IconBrandAws />}
                onClick={() => refetchDqlMessages()}
            />
        </HStack>
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Body</Table.Th>
                    <Table.Th>Attributes</Table.Th>
                    <Table.Th>Actions</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    isDlqFetching
                        ? <HStack center><Loader /></HStack>
                        : dlqMessages?.map((m) => {
                            return <DlqMessageRow message={m} key={m.MessageId} />
                        })
                }
            </Table.Tbody>
        </Table>
    </VStack>
}
function DlqMessageRow({ message: m }: { message: Message & { QueueUrl: string } }) {

    const {
        mutate: deleteMessage,
        isPending: isDeletePending
    } = trpc.ADMIN.deleteDlqMessage.useMutation(getUseMutationOpt(trpc.useUtils()))
    const sent = parseFloat((m.Attributes ?? {})['SentTimestamp'] ?? "0")
    return <Table.Tr>
        <Table.Td>
            {m.MessageId}
        </Table.Td>
        <Table.Td>
            <ReactJson src={JSON.parse(m.Body ?? "{}")} shouldCollapse={(k) => k.name == "headers"} />
        </Table.Td>
        <Table.Td>
            <ReactJson src={m.Attributes ?? {}} collapsed={false} />
        </Table.Td>
        <Table.Td>
            {sent &&
                <a
                    href={`https://one.newrelic.com/logger?account=4710065&begin=${sent}&end=${sent + 5000}`}
                    target={"_blank"}
                    rel={"noreferrer"}>NR</a>}
        </Table.Td>
        <Table.Td>
            <Menu>
                <Menu.Target>
                    <div>
                        <DesignedIconButton
                            tooltip={"Actions"}
                            disabled={isDeletePending}
                            icon={<IconDots />}
                            onClick={() => {
                            }}
                        />
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        leftSection={<IconTrash />}
                        disabled={isDeletePending}
                        onClick={() => deleteMessage({
                            QueueUrl: m.QueueUrl,
                            ReceiptHandle: m.ReceiptHandle!
                        })}
                    >
                        Delete
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Table.Td>
    </Table.Tr>
}
export function AdminToolsPaneAiTab() {
    const [recordType, setRecordType] = useState(AssessmentFilesEnumSchema.options[0] as string)
    const [isFetching, setIsFetching] = useState(false)
    const [vOptions]=trpc.ADMIN.getAiValidationVersions.useSuspenseQuery()
    const [v,setV] = useState(vOptions[0])
    const [isFetchingSummary, setIsFetchingSummary] = useState(false)
    const [page, setPage] = useState(0)
    const [summary, setSummary] = useState([] as Array<AiTestingSummary>)
    const [loadedRecords, setLoadedRecords] = useState([] as Array<AiTestingRecord>)
    const utils = trpc.useUtils()
    const fetchSummaryHandler = () => {
        setIsFetchingSummary(true)
        utils.ADMIN.loadAiValidationSummary.fetch({v:v!})
            .then((summary) => setSummary(summary))
            .catch((e) => {
                showErrorNotification(e as { message: string })
            })
            .finally(() => setIsFetchingSummary(false))
    }
    const fetchPageHandler = () => {
        setIsFetching(true)
        utils.ADMIN.loadAiValidationRecords.fetch({
            v:v!,
            count: 20,
            start: page * 20,
            type: recordType as AssessmentFilesEnum
        }).then((latestRecords) => {
            setLoadedRecords((previousValue) => [ ...(latestRecords ?? []),...previousValue,]);
            setPage((previousValue) => previousValue + 1)
        }).catch((e) => {
            showErrorNotification(e as { message: string })
        }).finally(() => {
            setIsFetching(false)
        })
    }
    console.log(`record count: ${loadedRecords.length}`)
    return <VStack center>
        <NativeSelect value={v} onChange={(e) => setV(e.currentTarget.value)} data={vOptions} />
        <Divider label={"Summary"} />
        <HStack leftCenter>
            <DesignedIconButton
                disabled={isFetchingSummary}
                tooltip={"Fetch AI Validation Summary"}
                icon={<TextSm>Fetch Summary</TextSm>}
                onClick={() => {
                    fetchSummaryHandler()
                }}
            />
            {(isFetchingSummary) && <Loader />}
        </HStack>
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Category</Table.Th>
                    <Table.Th>Total</Table.Th>
                    <Table.Th>Attempted</Table.Th>
                    <Table.Th>Correct</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    summary.map((r) => {
                        return <AiSummaryRow record={r} key={r.type} />
                    })
                }
            </Table.Tbody>
        </Table>
        <Divider label={"Records"} />
        <HStack leftCenter>
            <NativeSelect
                value={recordType}
                onChange={(e) => {
                    setRecordType(e.currentTarget.value)
                    setPage(0)
                }}
                data={AssessmentFilesEnumSchema.options}
            />
            <DesignedIconButton
                disabled={isFetching}
                tooltip={"Fetch 20 AI Validation Messages"}
                icon={<IconAi />}
                onClick={() => {
                    fetchPageHandler()
                }}
            />
            {(isFetching) && <Loader />}
        </HStack>
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Image</Table.Th>
                    <Table.Th>Classification</Table.Th>
                    <Table.Th>Inferred Classification</Table.Th>
                    <Table.Th>Home Details</Table.Th>
                    <Table.Th>Inferred Home Details</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    loadedRecords.map((r) => {
                        return <AiValidationRow record={r} key={r.s3_url} />
                    })
                }
            </Table.Tbody>
        </Table>
    </VStack>
}
function AiValidationRow({ record }: { record: AiTestingRecord }) {
    const filteredHomeDetails = Object.keys(record.inferred_home_details ?? {})
    .reduce((acc,v)=>({...acc,[v]:record.home_details[v as keyof HomeDetails]??undefined}),{})
    return <Table.Tr>
        <Table.Td>
            {record.presigned_s3_url
                ? <img className={"h-96 w-auto"} src={record.presigned_s3_url} />
                : <IconError404 />}
        </Table.Td>
        <Table.Td>
            <TextLg>{record.assessment_file_type}</TextLg>
        </Table.Td>
        <Table.Td>
            <TextLg>{record.inferred_assessment_file_type}</TextLg>
        </Table.Td>
        <Table.Td>
            <div className={"h-96 overflow-auto"}>
                <ReactJson src={filteredHomeDetails} collapsed={false} />
                <ReactJson src={record.home_details} collapsed={true} />
            </div>
        </Table.Td>
        <Table.Td>
            <div className={"h-96 overflow-auto"}>
                <ReactJson src={record.inferred_home_details ?? {}} collapsed={false} />
            </div>
        </Table.Td>
    </Table.Tr>
}
function AiSummaryRow({ record }: { record: AiTestingSummary }) {
    return <Table.Tr>
        <Table.Td>
            <TextLg>{record.type}</TextLg>
        </Table.Td>
        <Table.Td>
            <TextLg>{record.total}</TextLg>
        </Table.Td>
        <Table.Td>
            <TextLg>{record.attempted} ({formatPercentage(record.attempted/record.total)})</TextLg>
        </Table.Td>
        <Table.Td>
            <TextLg>{formatPercentage(record.correct/record.attempted)}</TextLg>
        </Table.Td>
    </Table.Tr>
}

