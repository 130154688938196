import { Modal, NumberInput, Table, Textarea } from "@mantine/core";
import ReactJson from "@microlink/react-json-view";
import { ProjectAggregate } from "@seeair/schemas";
import { formatMoney } from "@seeair/shared";
import { DesignedIconButton, getUseMutationOpt, HStack, TextSm, trpc, VStack } from "@seeair/shared-components";
import { IconDeviceFloppy, IconMailPlus } from "@tabler/icons-react";
import dayjs from "dayjs";
import React, { useState } from 'react'
export function AdminProjectPaymentsPanel({ project }: { project: ProjectAggregate }) {
    const [showModal, setShowModal] = useState(false)
    const [note,setNote] = useState("")
    const [amount,setAmount] = useState(0)
    const {
        mutate:savePayment,
        isPending:isSavePaymentPending
    } = trpc.ADMIN.insertManualPayment.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>
                        ID
                    </Table.Th>
                    <Table.Th>
                        Amount
                    </Table.Th>
                    <Table.Th>
                        Created
                    </Table.Th>
                    <Table.Th>
                        Body
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {(project.payments ?? []).map((p) => <Table.Tr key={p.payment_id}>
                    <Table.Td>
                        {p.payment_id}
                    </Table.Td>
                    <Table.Td>
                        {formatMoney(p.amount / 100)}
                    </Table.Td>
                    <Table.Td>
                        {p.created ? dayjs.unix(p.created).format() : "unknown"}
                    </Table.Td>
                    <Table.Td>
                        <ReactJson src={p.source} collapsed={true} />
                    </Table.Td>
                </Table.Tr>)}
            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={4}>
                        <VStack center>
                            <TextSm>Found {project.payments?.length ?? 0} payments attributed to this project</TextSm>
                        </VStack>
                    </Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
        <HStack>
            <DesignedIconButton
                icon={<IconMailPlus />}
                onClick={() => setShowModal(true)}
                disabled={false}
                tooltip={"Enter Manual Payment"} />
        </HStack>
        <Modal opened={showModal} onClose={() => setShowModal(false)} title={"New Manual Payment"}>
            <NumberInput 
            allowDecimal={true}
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={amount/100}
            onChange={(v)=>setAmount((typeof v == 'string' ? parseFloat(v) : v)*100)}
            />
            <Textarea value={note} onChange={(v=>setNote(v.currentTarget.value))}/>
            <DesignedIconButton
            icon={<IconDeviceFloppy />}
            onClick={()=>savePayment({payment:{amount:amount.toString(),note,project_id:project.project_id}})}
            disabled={amount==0||note.length==0||isSavePaymentPending}
            tooltip={"save"}
            />
        </Modal>
    </VStack>
}