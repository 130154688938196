import {HubspotBlog} from "@seeair/schemas";
import {DesignedLink, TextRSm, TextSm, TextXl, VStack} from "@seeair/shared-components";
import classNames from "classnames";
import React from "react";
import {shortDateFormat,truncateString} from "@seeair/shared";
import {Skeleton} from "@mantine/core";

export function BlogBoxSkeleton({classNames:cns}:{classNames?:string}){
    return <div className={classNames("flex flex-row p-8 my-4 rounded-2xl",cns)}>
        <Skeleton height={192} width={192/*12rem*/} radius={"xl"} />
        <div className={"h-48"}>
            <div className={"flex flex-col mx-8 h-44 overflow-hidden"}>
                <Skeleton height={16} mt={12} width={100} radius={"xl"}/>
                <Skeleton height={8} mt={12} width={50} radius={"xl"}/>
                <VStack classNames={"mt-8"}>
                    <Skeleton height={8} mt={8} radius={"xl"}/>
                    <Skeleton height={8} mt={8} radius={"xl"}/>
                    <Skeleton height={8} mt={8} width={"70%"} radius={"xl"}/>
                </VStack>
            </div>
            <div className={"ml-8 mb-4"}>
                <Skeleton height={8} mt={12} width={50} radius={"xl"}/>
            </div>
        </div>
    </div>
}

export function BlogBox({blog,classNames:cns}:{blog:HubspotBlog,classNames?:string}){
    return <div className={classNames("flex flex-row p-2 sm:p-8 my-4 rounded-2xl",cns)}>
        <img src={blog.featuredImage||'/design-assets/img-placeholder.svg'} height={192} width={192/*12rem*/} className={"rounded-2xl object-cover"}/>
        <div className={"h-48"}>
            <div className={"flex flex-col mx-2 sm:mx-8 h-44 overflow-hidden"}>
                <TextXl m0>{truncateString(blog.name,35)}</TextXl>
                <TextSm light m0>{shortDateFormat(blog.publishDate)}</TextSm>
                <TextRSm m0 classNames={"mt-2 mb-4"}>{truncateString(blog.metaDescription ?? "",125)}</TextRSm>
            </div>
            <div className={"ml-8 mb-4"}>
                <DesignedLink to={`/blog/${blog.id}`}>
                    <span className={"underline"}>{"Learn More >"}</span>
                </DesignedLink>
            </div>
        </div>
    </div>
}