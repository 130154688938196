import {z} from "zod";
import {HubspotEligibilitySurveySchema, HubspotRentcastDetailsSchema} from './jsonb-schemas.js';
import {HubspotObjectTypeIdEnum} from './worker-schemas.js';

export const HUBSPOT_DEAL_PIPELINE_ASSESSMENT = '114638991'
export const HUBSPOT_DEAL_PIPELINE_PROJECT = 'default'

export const HUBSPOT_ADMIN_TASK_ASSIGNEE ="375965061" //maahi's owner id from hubspot
export const HUBSPOT_ADMIN_TASK_QUEUE = '9193907'
export const HUBSPOT_TASK_PRIORITY_MEDIUM = 'MEDIUM'
export const HUBSPOT_TASK_TYPE_TODO = 'TODO'

export const HubspotTaskPropertiesSchema = z.object({
    hs_createdate:z.string().nullish(),
    hs_queue_membership_ids:z.string().nullish(),
    hs_task_body:z.string().nullish(),
    hs_task_completion_date:z.string().nullish(),
    hs_task_priority:z.string().nullish(),
    hs_timestamp: z.string().nullish(),
    hs_task_subject: z.string().nullish(),
    hs_task_type: z.string().nullish(),
    hubspot_owner_id: z.string().nullish(),
    hs_task_send_default_reminder: z.boolean().nullish(),
})

export const HubspotTaskSchema = z.object({
    id: z.coerce.string(),
    properties:HubspotTaskPropertiesSchema
})
export type HubspotTask = z.infer<typeof HubspotTaskSchema>
export const HubspotContactPropertiesSchema = z.object({
    lifecyclestage:z.string().nullish(),
    contact_type:z.string().nullish(),
    hubspot_owner_id:z.coerce.string().nullish(),
    seeair_active_user:z.string().nullish(),
    email:z.string().nullish(),
    lastname:z.string().nullish(),
    firstname:z.string().nullish()
})
export const HubspotContactMinimalSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotContactPropertiesSchema
})
export const HubspotHomeownerContactSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotContactPropertiesSchema.merge(HubspotEligibilitySurveySchema).merge(HubspotRentcastDetailsSchema)
})
export type HubspotHomeownerContact = z.infer<typeof HubspotHomeownerContactSchema>
export const HubspotCompanySchema = z.object({
    id:z.coerce.string(),
    properties: z.object({
        name:z.string(),
        domain:z.string().nullish(),
    })
})
export type HubspotCompany = z.infer<typeof HubspotCompanySchema>
export const HubspotQuoteTemplateSchema = z.object({
    id:z.coerce.string(),
    properties: z.object({
        hs_name:z.string(),
    })
})
export const HubspotContractorContactSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotContactPropertiesSchema,
    companies:z.array(HubspotCompanySchema)
})
export type HubspotContractorContact = z.infer<typeof HubspotContractorContactSchema>
export const HubspotProductSchema = z.object({
    id:z.coerce.string(),
    properties:z.object({
        name: z.string().nullish(),
        description: z.string().nullish(),
        recommendation_id: z.string().nullish(),
        price:z.string().nullish(),
        seeair_part_number:z.string().nullish(),
        seeair_admin_discount:z.string().nullish()
    })
})
export type HubspotProduct = z.infer<typeof HubspotProductSchema>
export const HubspotLineItemSchema = z.object({
    id:z.coerce.string(),
    properties:z.object({
        name:z.string().nullish(),
        quantity:z.string().nullish(), // number of units
        price:z.string().nullish(), // price per unit
        amount:z.string().nullish(), //total price
        discount:z.string().nullish(), //discount concrete amount if any
        hs_discount_percentage:z.string().nullish(), //discount percentage if any
        tax:z.string().nullish(),
        description:z.string().nullish(), //TODO verify
        hs_product_id:z.string().nullish() //foreign key to product api
    })
})
export type HubspotLineItem = z.infer<typeof HubspotLineItemSchema>
export const HubspotLineItemAggregateSchema = HubspotLineItemSchema.merge(z.object({
    product:HubspotProductSchema.nullish()
}))
export type HubspotLineItemAggregate = z.infer<typeof HubspotLineItemAggregateSchema>
export const HubspotDealSchema = z.object({
    id:z.coerce.string(),
    properties:z.object({
        pipeline:z.string().nullish(),
        hs_lastmodifieddate:z.string().datetime().nullish(),
        closedate:z.string().datetime().nullish(),
        hubspot_owner_id:z.coerce.string().nullish(),
        dealstage:z.string().nullish(),
        dealname:z.string(),
        address:z.string().nullish(),
        amount:z.string().nullish(),
        project_number:z.string().nullish(),//HANCOCK PROJECT IDENTIFIER
        utility_company:z.string().nullish(),
        seeair_project_id:z.string().nullish(),
        seeair_assessment_id:z.string().nullish(),
        seeair_recommendation_number:z.string().nullish(),
        seeair_recommendation_status:z.string().nullish(),
        seeair_assessment_status:z.string().nullish(),
        seeair_bim_status:z.string().nullish(),
        seeair_install_dates:z.string().nullish()
    })
})
export type HubspotDeal = z.infer<typeof HubspotDealSchema>
export const HubspotDealAggregateSchema = HubspotDealSchema.merge(z.object({
    stage_name:z.string().nullish(),
    lineItems:z.array(HubspotLineItemAggregateSchema)
}))
export type HubspotDealAggregate = z.infer<typeof HubspotDealAggregateSchema>
export const HubspotMeetingPropertiesSchema = z.object({
    hs_meeting_start_time:z.string().datetime().nullish(),
    hs_meeting_end_time:z.string().datetime().nullish(),
    hs_meeting_title:z.string().nullish(),
    hs_meeting_location:z.string().nullish(),
    hs_meeting_outcome:z.string().nullish(),
})
export type HubspotMeetingProperties = z.infer<typeof HubspotMeetingPropertiesSchema>
export const HubspotMeetingSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotMeetingPropertiesSchema
})
export type HubspotMeeting = z.infer<typeof HubspotMeetingSchema>
export const HubspotQuotePropertiesSchema = z.object({
    hs_createdate:z.string().datetime().nullish(),
    hs_lastmodifieddate:z.string().datetime().nullish(),
    hs_quote_link:z.string().nullish(),
    hs_status:z.string().nullish(),
    hs_title:z.string().nullish(),
    hs_quote_amount:z.string().nullish(),
    hs_payment_status:z.string().nullish(),
    hs_sign_status:z.string().nullish(),
})
export const HubspotQuoteSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotQuotePropertiesSchema,
    lineItems:z.array(HubspotLineItemAggregateSchema)
})
export type HubspotQuote = z.infer<typeof HubspotQuoteSchema>
export type HubspotCrmEntity = 'task' | 'company'|'homeowner-contact'|'contractor-contact'|'deal'|'quote'|'line_items'|'meeting'|'product'|'quote_template'
export type HubspotCmsEntity = 'posts' | 'authors'
export const HubspotPipelineStage = z.object({
    id:z.coerce.string(),
    label:z.string()
    //there are more that we don't need
})
export const HubspotBlogSchema = z.object({
    id:z.coerce.string(),
    slug:z.string(),
    name:z.string(),
    postBody:z.string(),
    currentlyPublished:z.boolean(),
    publishDate:z.string().datetime(),
    metaDescription:z.string().nullish(),
    featuredImage:z.string().nullish(),
    authorName:z.string().nullish(),
    blogAuthorId:z.string().nullish(),
})
export type HubspotBlog = z.infer<typeof HubspotBlogSchema>
export const HubspotAuthorSchema = z.object({
    id:z.coerce.string(),
    slug:z.string(),
    email:z.string().nullish(),
    displayName:z.string(),
    bio:z.string().nullish(),
    avatar:z.string().url().nullish()
})
export type HubspotAuthor = z.infer<typeof HubspotAuthorSchema>
export const unknownHubspotAuthor = {
    id: "",
    bio: null,
    email: null,
    avatar: null,
    slug: "",
    displayName: "Unknown"
}
export const HubspotBlogAggregateSchema = z.object({
    author:HubspotAuthorSchema
}).merge(HubspotBlogSchema)
export type HubspotBlogAggregate = z.infer<typeof HubspotBlogAggregateSchema>

export const entityTypeIdMap: Record<HubspotCrmEntity, HubspotObjectTypeIdEnum> = {
    'homeowner-contact': '0-1',
    'contractor-contact': '0-1',
    'deal': '0-3',
    'company': '0-2',
    'product': '0-7',
    'line_items': '0-8',
    'quote': '0-14',
    'meeting': '0-47',
    'quote_template':'NONE',
    'task':'NONE'
}
export const CONTACT_LIFECYCLESTAGES_ACTIVE = ['salesqualifiedlead', 'customer', 'evangelist', 'other']
export const ASSESSMENT_DEAL_STAGE_NAME_ELIGIBLE_FOR_ASSESSMENT = 'Eligible for Assessment'
export const ASSESSMENT_DEAL_STAGE_NAME_ACCOUNT_CREATED = 'Account Created'
export const DEFAULT_DEAL_STAGE_QUOTE_CREATED='decisionmakerboughtin'
export const DEAL_STAGE_WON = 'closedwon'

export const adminProductIds = [
    "17035991729",//permit fee
    "17036518069",//permit admin fee
    "17035991728"//acquisition fee
]