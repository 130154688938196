import { createFileRoute, Outlet } from "@tanstack/react-router";
import { ContractorUserRedirectToSignInCheck, Layout, SheetsDataProvider } from "@seeair/shared-components";
import { Helmet } from "react-helmet-async";
import React from "react";
import { ContractorProductsDataProvider } from "@seeair/shared-components";

export const Route = createFileRoute('/contractor/_contractor')({
    component: function ContractorParentRoute() {
        return <Layout loadingDrawer>
            <ContractorUserRedirectToSignInCheck>
                <SheetsDataProvider>
                    <ContractorProductsDataProvider>
                        <Helmet>
                            <title>SeeAir Contractor</title>
                            <meta name={"description"}
                                content={"SeeAir Contractor Dashboard"} />
                        </Helmet>
                        <Outlet />
                    </ContractorProductsDataProvider>
                </SheetsDataProvider>
            </ContractorUserRedirectToSignInCheck>
        </Layout>
    }
})