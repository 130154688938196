import { Table } from "@mantine/core";
import ReactJson from "@microlink/react-json-view";
import { formatMoney } from "@seeair/shared";
import { AdminCombobox, getUseMutationOpt, trpc, VStack } from "@seeair/shared-components";
import dayjs from "dayjs";
import React from 'react'
export function AdminUnattributedPaymentsPanel() {
    const [unattributedPayments] = trpc.ADMIN.loadUnattributedPayments.useSuspenseQuery()
    const [projectOptions] = trpc.ADMIN.loadProjectOptions.useSuspenseQuery()
    const { mutate: attributePayment, isPending: isAttributePaymentPending } = trpc.ADMIN.attributePaymentToProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>
                        ID
                    </Table.Th>
                    <Table.Th>
                        Amount
                    </Table.Th>
                    <Table.Th>
                        Created
                    </Table.Th>
                    <Table.Th>
                        Event
                    </Table.Th>
                    <Table.Th>
                        Body
                    </Table.Th>
                    <Table.Th>
                        Project
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {unattributedPayments.payments.map((p) => <Table.Tr key={p.id}>
                    <Table.Td>
                        {p.id}
                    </Table.Td>
                    <Table.Td>
                        {formatMoney((p.body?.data?.object?.amount ?? 0)/100)}
                    </Table.Td>
                    <Table.Td>
                        {p.created ? dayjs.unix(p.created).format() : "unknown"}
                    </Table.Td>
                    <Table.Td>
                        {p.event_type ?? "unknown"}
                    </Table.Td>
                    <Table.Td>
                        <ReactJson src={p.body as object} collapsed={true} />
                    </Table.Td>
                    <Table.Td>
                        {p.event_type == "charge.succeeded"&&
                        <AdminCombobox
                            disabled={isAttributePaymentPending}
                            onChange={({ id: project_id }) => attributePayment({ payment_event_id: p.id, project_id })}
                            options={projectOptions.filter(o=>o.name!="Instant Savings Measures")}
                        />}
                    </Table.Td>
                </Table.Tr>)}
            </Table.Tbody>
        </Table>
    </VStack>
}